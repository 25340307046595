import React, { useState, useEffect, useReducer } from "react";
import { Button, Form, Toast } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import colors from "../../data/Colors";
import { reducer } from '../../reducers/Status';

const initialState = { todos: [], nextLabelId: 0, newLabelTitle: "", newLabelColor: "chartreuse"};

export default function ItemStatus({
  workflow_id,
  items,
  setItems,
  dispatchField,
  setOnEdit,
  type,
  field,
  ...props
}){

      const [state, dispatch] = useReducer(reducer, initialState);
      const { todos, newLabelTitle, newLabelColor } = state;
      let [show, setShow] = useState(false);
      let [todo, setTodo] = useState();
      const listDefaultValue = translate('stautsDefault')

      let textInput = React.useRef();

      useEffect(() => {
        var data = []
        var lists = null
        if(items){
          data = items
        }else {
          lists = listDefaultValue.toString().split(',')
          lists.map((t, key) =>{
              data.push({name: t, id: key, color: colors[key], status: key == 0 ? 'done' : null})
          })
        }

        dispatch({ type: "reset", payload: data, nextLabelId: data.length + 1})
     }, []);

     useEffect(() => {
        var list = []
        if(todos.length) {
          dispatchField({ type, payload: type === 'editEnum' ? field.id : 'status', enum: todos })
          setOnEdit(true)
        }
     }, [todos]);

      //Add or Update tags to workflow
      const handleTagAdd = async() => {
          dispatch({ type: "add" })
      };

      return (
        <>
          <div className="mt-2">
          {todos.map( (todo, key) => (
            <div key={key} className="text-center d-flex align-items-center">
              <span className="circle text-white rounded-pill p-1 m-1 border-0" style={{backgroundColor: todo.color ? todo.color : colors[key] }} >
                <span className="d-none">{todo.color}</span>
              </span>
              <input
                onKeyPress={({ key, target }) => {
                  if(key === 'Enter'){
                    if(target.value.length > 0){
                      setTimeout(() => {
                        textInput.current?.focus()
                      }, 600);
                    }else {
                      dispatch({ type: "remove", payload: todo.id })
                    }
                  }
                }}
                onChange={({ target }) => {
                  dispatch({ type: 'editTitle', payload: todo.id, title: target.value })
                }}
                value={todo.name}
                className={"form-control w-75"}
              />
              <Button icon="trash" onClick={() => {
                  setShow(true)
                  setTodo(todo)
              }}/>
            </div>
          ))}

            <div className="text-center d-flex align-items-center">
              <span className="circle text-white rounded-pill p-1 m-1 border-0" style={{backgroundColor: newLabelColor }} >
                <span className="d-none">{newLabelTitle}</span>
              </span>
              <input className={"form-control w-75"} type="text" placeholder={translate('addNewItem')}
                  value={newLabelTitle}
                  onChange={e => dispatch({
                    type: "updateTitle",
                    payload: e.target.value,
                  })}
                  focus="true"
                  onKeyPress={({ key, target }) => {
                    if(key === 'Enter'){
                      handleTagAdd()
                    }
                  }}
                  ref={textInput}
              />
              <Button icon={newLabelTitle ? "save" : "create"} className={newLabelTitle ? "text-primary" : "text-secondary"} onClick={handleTagAdd}/>
            </div>
            <div>{translate('choose')}</div>
            {colors.map( (color, key) => (
              <span key={key} onClick={() => dispatch({
                type: "updateColor",
                payload: color,
              })} style={{backgroundColor: color}} className={"circle rounded-pill text-white"}>
                {newLabelColor === color ? <Icon size="sm" color="white" icon={"check"} /> : <span className="d-none">{color}</span> }
              </span>
            ))}
          </div>
          {show &&
          <Toast size="sm" placement="right" show={show} onClose={() => setShow(false)} >
           <div className="p-2 text-center">
              <h4 className="h4">{`${translate('delete')} ${todo.name}?`}</h4>
              <Button
                title={translate('delete')}
                variant="danger"
                onClick={() => {
                  dispatch({
                    type: "remove",
                    payload: todo.id
                  })
                  setShow(false)
              }}
              />
              <Button
                title={translate('close')}
                variant="link"
                onClick={() => setShow(false)}
              />
            </div>
            </Toast>}
        </>
      );
}
