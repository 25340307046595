import React, { useState, useEffect } from 'react';
import { UpdateTask } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { Button } from '../../components/Uix';

export default function EditName({
  taskDispatch,
  task,
  allowEdit,
  ...props
}){
  const [isEdit, setIsEdit] = useState(false);
  const [nameValue, setNameValue] = useState(task.name);
  let textInput = React.useRef();
  const [updateTask] = useMutation(UpdateTask);

  const handleClose = () => {
    setIsEdit(false)
  }

  const handleUpdate = () => {
      updateTask({variables: {id: task.id, name: nameValue}})
      if(taskDispatch){
        taskDispatch({ type: "updateTitle", payload: task.id, title: nameValue})
      }
  }

   const setShow = () => {
     setIsEdit(true)
     setTimeout(() => {
       textInput.current?.focus()
     }, 60)
   }

   useEffect(() => {
     setNameValue(task.name)
   },[task.id])

   useEffect(() => {
       // Bind the event listener
       document.addEventListener("click", handleClickOutside, true);
       return () => {
         // Unbind the event listener on clean up
         document.removeEventListener("click", handleClickOutside, true);
       };
   });

   const handleClickOutside = (event) => {
      if (textInput.current && !textInput.current.contains(event.target)) {
         handleClose()
         handleUpdate()
      }
   }

  return (
    <>
      {isEdit ? <><input
        type="text"
        ref={textInput}
        value={nameValue}
        onMouseOver={() => {
          setTimeout(function () {
            handleClose()
          }, 15000);
        }}
        onBlur={handleUpdate}
        className="form-control fw-bold"
        onChange={e => setNameValue(e.target.value)}
      /> <Button size={'sm'} icon="save" onClick={handleUpdate}/></>: <Button variant="link" className="text-dark-70 text-start fw-bold border-hover btn-edit px-1" onClick={(e) => allowEdit ? setShow() : e.preventDefault()}>
        {nameValue}
      </Button>
    }
    </>
  );
}
