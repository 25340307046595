import React, { useState, useEffect } from "react";
import { Form, Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import fieldDefault from '../../data/Fields';
import { translate } from '../../services/Language';

export default function ItemProgress({
  dispatch,
  field,
  schema,
  type,
  setOnEdit
}){

    const [option, setOption] = useState('status');
    const [config, setConfig] = useState([]);
    const [links, setLinks] = useState([]);
    const [columns, setColumns] = useState([]);
    const [status, setStatus] = useState([]);
    const [xPercent, setXPercent] = useState("");
    const [ofNumber, setOfNumber] = useState("");

    useEffect(() => {
      if(field && field.config) {
        let config = field.config ? JSON.parse(field.config) : []
        setConfig(config)
        setOption(config.type)
        if(config.type === "fixed"){
          setXPercent(config.xPercent)
          setOfNumber(config.ofNumber)
        }else if(config.type === "status"){
          setLinks(config.status)
        }
      }
    },[field]);

    useEffect(() => {
      var ops = []; var sts = [];
      if(schema) {
        Object.keys(schema).map(key => {
          var row = schema[key];
          var field = {id: row.id, title: row.title, name: row.name}
          if(row.name === 'status') {
            sts.push(field)
          }
          if(row.name === 'integer' || row.name === 'text') {
            ops.push(field)
          }
        })
        setColumns(ops)
        setStatus(sts)
      }
    },[schema]);

    const handleChange = () => {
      let config = []
      if(option === "status"){
        config = {
          type: option,
          status: links
        }
      }else if(option === "fixed"){
        config = {
          type: option,
          xPercent,
          ofNumber
        }
      }else {
        config = {
          type: option
        }
      }
      dispatch({
         type,
         payload: type === "editConfig" ? field.id : "config",
         config: JSON.stringify(config)
      })
      if(setOnEdit) setOnEdit(true)
    }

    const handleAdd = (link) => {
      var newList = links.length ? links : []
      newList.push(link)
      const linksUnique = Array.from(new Set(newList.map(a => a.id)))
       .map(id => {
         return newList.find(a => a.id === id)
       })
       setLinks(linksUnique)
    }

    const handleRemove = (link) => {
       const linksUnique = links.filter(item => item.id !== link.id)
       setLinks(linksUnique)
    }

      return (
        <>
        <div className="text-start flex-start">
          <div className="">{translate('option')}</div>
          <div>
            <Form.Radio checked={option === 'status' ? true : false} label={translate('status')}onTextChange={setOption} value={'status'} name="status"/>
            <Form.Radio checked={option === 'fixed' ? true : false} label={translate('fixed')} onTextChange={setOption} value={'fixed'} name="fixed"/>
            <Form.Radio checked={option === 'text' ? true : false} label={translate('text')} onTextChange={setOption} value={'text'} name="text"/>
          </div>

          <div className="overflow-auto scroll-style" style={{height: 160}}>
          {option === "status" && <>
            <div className="">{translate('fields')}</div>
            <div>
              {status.map((item, key) => {
                let find = links.length ? links.find(link => link.id === item.id) : false;
                let icon = fieldDefault[item.name].icon;
                return(
                <Button key={key} variant="link" className="text-secondary d-flex justify-content-between align-items-center w-100" onClick={() => find ? handleRemove(item) : handleAdd(item)}>
                  <span>
                    <Icon icon={icon}/> {item.title}
                  </span>
                  {find ? <span className="text-primary"><Icon icon={'checkbox'}/></span> : <Icon icon={'square'}/>}
                </Button>);
              })}
            </div>
            </>
          }
          {option === "fixed" && <>
          <div className="">{translate('xPercent')}</div>
          <div>
            {columns.map((item, key) => {
              let find = xPercent === item.id ? true : false;
              let icon = fieldDefault[item.name].icon;
              return(
              <Button disabled={ofNumber === item.id} key={key} variant="link" className="text-secondary d-flex justify-content-between align-items-center w-100" onClick={() => setXPercent(find ? "" : item.id)}>
                <span>
                  <Icon icon={icon}/> {item.title}
                </span>
                {find ? <span className="text-primary"><Icon icon={'checkbox'}/></span> : <Icon icon={'square'}/>}
              </Button>);
            })}
          </div>
          <div className="">{translate('ofNumber')}</div>
          <div >
            {columns.map((item, key) => {
              let find = ofNumber === item.id ? true : false;
              let icon = fieldDefault[item.name].icon;
              return(
              <Button disabled={xPercent === item.id} key={key} variant="link" className="text-secondary d-flex justify-content-between align-items-center w-100" onClick={() => setOfNumber(find ? "" : item.id)}>
                <span>
                  <Icon icon={icon}/> {item.title}
                </span>
                {find ? <span className="text-primary"><Icon icon={'checkbox'}/></span> : <Icon icon={'square'}/>}
              </Button>);
            })}
          </div>
          </>}
          </div>
          <Button onClick={handleChange} icon="save" title={translate('update')} variant="light"/>
        </div>
    </>
   );
}
