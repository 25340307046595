import React, { useState , useEffect} from "react";
import { Button, Menu} from '../../components/Uix';
import Icon from '../../components/Icon';
import { imageStorage } from '../../services/Function';
import { sendNotification } from '../../services/Api';
import { automation } from '../../store/automation';
import { setInboxes, setNotification, setBadges } from '../../services/Firebase';
import { TaskUser, NotificationEmail } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { translate } from '../../services/Language';

export default function User({
  workflow_id,
  task_id,
  task_name,
  members,
  users,
  allowEdit,
}){
      const [show, setShow] = useState(false);
      const [userList, setUserList] = useState([]);
      const [options, setOptions] = useState([]);
      const [onEvent, setOnEvent] = useState(false);
      const [keyword, setKeyword] = useState("");
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      const [taskUser] = useMutation(TaskUser);
      const [notificationEmail] = useMutation(NotificationEmail);

      let userName = localStorage.getItem('user_name');
      let user_id = localStorage.getItem('user_id');
      let app_id = localStorage.getItem('app_id');

      useEffect(() => {
          let arr = []
          var newList = []
          if(members && members.length > 0){
            members.map(u => {
              arr[u.id] = u
              newList.push(u)
            })
          }
          if(typeof arr !== 'undefined') {
            setUserList(arr)
            setOptions(newList)
          }
        },
        [members],
      );

      //Update Task with tags
      async function handleClick(link, type) {
        if(link.id) {
          taskUser( {variables: {
            task_id,
            user_id: link.id
          }})
        }
        if(type == 'add') {
          //Process automation
          setInboxes([link.id], task_name, task_id, workflow_id, 'assignTaskUser')
          let reresh = automation('personAssigned', {id: task_id, assign_id: link.id, workflow_id})
          //Update user options
          var newList = options;
          newList.push(link)
          setOptions(newList)

          //Update user list
          var arr = []
          newList.map(u => {
            arr[u.id] = u
          })
          if(typeof arr !== 'undefined') setUserList(arr)
          
        }else {
          var oldList = options;
          var arr = []
          var newList = []
          oldList.map(u => {
            if(u.id !== link.id) {
              arr[u.id] = u
              newList.push(u)
            }
          })
          if(typeof arr !== 'undefined') setUserList(arr)
          setOptions(newList)
        }
        setShow(false)
        setOnEvent(true)

        if(link.id != user_id && type == 'add') {
          //Add notify
          if(link.notify === true) {
            sendNotification(link, task_name, 'subscribeUser');
          }
          //End Notify

          //Set badge

          setBadges(link.id, task_id, workflow_id)

          setNotification(link, task_name, task_id, 'task')


          if(link.notify === true) {
            notificationEmail({
              variables: {
                user_id: link.id,
                model_id: task_id,
                user_name: link.name,
                email: link.email,
                send_name: userName,
                name: task_name,
                type: 'task',
              },
            });
          }

        }

      };

      var colors = ['primary', 'info', 'success', 'danger', 'warning'];

      if(keyword){
        users = users.filter(u => (u.name == keyword || u.name == keyword.toUpperCase() || u.name == keyword.charAt(0).toUpperCase() + keyword.slice(1)))
      }

      const customTitle = (
        <div className={"d-flex align-items-center"}>
          {options.map( (user, key) => {
            var colorAvatar = colors[Math.floor(Math.random() * colors.length)]
            var strLast = user.name.split(' ').slice(-1)[0]
            var photo = imageStorage(user.photo);
            return(<span key={key} className="d-inline-block">
              {user.photo ? <img className="avatar rounded-pill d-inline-block" src={photo}/> :  <span className={"bg-"+colorAvatar+ " avatar text-white primary rounded-pill"} >{strLast && strLast.toString().charAt(0)}
              </span>}
            </span>
          )})}
        </div>
      )

      return (
        <>
          <div style={{position: 'relative'}}>
              <Menu disabled={!allowEdit} icon={options.length === 0 && "person"} title={options.length > 0 && customTitle} onToggle={setShow} onClose={handleClose} >
               <div className="px-2">
               <div className="input-group d-flex justify-content-center align-items-center border-bottom py-1">
                  <div className="input-group-prepend">
                    <Icon icon={'search'} color="#ccc"/>
                  </div>
                  <input type="text"
                   className="form-control input-great border-bottom border-0 p-1" placeholder={translate('search')}
                   onChange={(e) =>
                     setKeyword(e.target.value)
                   }/>
                </div>
               <ul className="nav flex-column">
                 {users && users.map( (user, key) => (
                     <li key={user.id} className="d-flex justify-content-start">
                       <Button disabled={!allowEdit} key={user.id} className="d-flex justify-content-between align-items-center"
                         onClick={() => handleClick(user, userList[user.id]?'remove':'add')}>
                           {user.photo ? <img className="avatar rounded-pill d-inline" src={imageStorage(user.photo)}/> : <span className="avatar rounded-pill shadow bg-light"><Icon size="sm" icon="person"/></span>}
                           <span className="ps-2">{user.name}</span>
                           {userList[user.id] && <Icon icon="check"/>}
                         </Button>
                     </li>
                 ))}
               </ul>
               </div>
              </Menu>
          </div>
        </>

      );
}
