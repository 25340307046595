import React, { useEffect, useState } from "react";
import { automation } from '../../store/automation';
import { Form } from '../../components/Uix';
import Icon from '../../components/Icon';
import * as EmailValidator from 'email-validator';

export default function InputEmail({
  task,
  dispatch,
  field,
  setOnEvent,
  isEmail,
  allowEdit,
  smartForm,
  ...props
}){

  const [nameValue, setNameValue] = useState("");
  const [initValue, setInitValue] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setNameValue(field.value)
    setInitValue(field.value)
  }, [field.value]);

 const handleChange = async(text) => {
     if(!EmailValidator.validate(text)){
        setError( true )
     }else {
       await dispatch({ type: 'updateField', payload: field.id, value: text })
       setTimeout(function () {
         if(initValue !== text){
           var data = Object.assign({}, task);
           data.field = field.id;
           data.value = text;
           automation('columnChanges', data)
         }
       }, 5000);
       setOnEvent(true)
       setError(false)
     }
     setNameValue( text )
 }

 const inputContent = (mode) => {
   return(
     <Form.Input placeholder={field.title}
        onTextChange={handleChange}
        required={field.required}
        disabled={!mode} value={nameValue} type="email"
        className={"input-great text-center ps-1"}/>
   )
 }

  return (<>
    <div className={`${!smartForm && "d-flex"} justify-content-center align-items-center ps-1`}>
    {(isEmail && smartForm) && <Icon icon="mail"/>}
      <div className="w-100">
        {inputContent(allowEdit)}
      </div>
    </div>
  </>)
};
