import React, { useState, useEffect, useReducer, useMemo } from 'react';
import Icon from '../../components/Icon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Spinner } from '../../components/Uix';
import AddLine from '../../components/Task/AddLine';
import Task from '../../components/Task/Task';
import Heading from './Heading';
import ColumnHead from '../../components/Column/ColumnHead';
import { SubTasksQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";
import { translate } from '../../services/Language';
import { reducerTask } from "../../reducers/Groups";

export default function Subitem({
  workflow_id,
  stage_id,
  group_id,
  task_id,
  setOnEvent,
  setOnTask,
  onTask,
  subVisiable,
  setSchema,
  schema,
  fields,
  index,
  next,
  prev,
  badges,
  setUpdate,
  setContent,
  stage_index,
  subField,
  type,
  single,
  users,
  permission,
  allowEdit
}){

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  const [rows, setRows] = useState([]);
  const [onSubTask, setOnSubTask] = useState(false);
  const [items, setSubitems] = useState([]);
  const [subFields, setFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [taskState, taskDispatch] = useReducer(reducerTask, {tasks: []});
  const { tasks } = taskState;

  const { loading, refetch, data } = useQuery(SubTasksQuery, {
    variables: { id: task_id },
  });

  useEffect(() => {
    if(data && data.subtasks){
       setSubitems(data.subtasks)
    }
  },[data])

  useEffect(() => {
    if(onTask || onSubTask){
       refetch()
       setOnTask(false)
       setOnSubTask(false)
    }
  },[onTask, onSubTask])

  useEffect(() => {
    taskDispatch({ type: "reset", payload: items})
  },[items])

  let sortedTasks = Object.values(tasks).sort(function(obj1, obj2) {
    if (obj1.index > obj2.index) return 1;
    if (obj2.index > obj1.index) return -1;
    return 0;
  });

  let startwith = 800;
  let colWidth = 140;
  let fullWith = (schema && Object.values(schema).length) ? Object.values(schema).length * colWidth + startwith : startwith;

  const content = (mode) => {
    return(
      <>
      <div className="w-100 overflow-x scroll-style-horizontal">
      <div className={`ms-${single?0:4} subitems my-2 position-relative`}  style={{width: fullWith}}>
      {isLoading && <div className="d-flex align-items-center justify-content-center"><Spinner animation="border" variant="warning" /></div>}
      <div className={"row g-0 task-list align-items-center"}
       >
       <div className={"col nav-item title p-0 ps-4 cell-name"}>
         <label className="">{subField}</label>
       </div>
       {schema && <>
       <Heading workflow_id={workflow_id} setFields={setFields} fields={fields} allowEdit={false} schema={schema} setSchema={setSchema} setOnEvent={setOnEvent} onEvent={null}/>
       <ColumnHead workflow_id={workflow_id} schema={schema} setSchema={setSchema} allowEdit={allowEdit} isSubItem/></>}
      </div>
      <DragDropContext
        //onDragEnd={onDragEnd}
      >
      <Droppable droppableId={stage_id} key={stage_id}>
        {(provided, snapshot) => (
          <div
                ref={provided.innerRef}
                style={{minHeight: 15}}
                >
        {sortedTasks && sortedTasks.map((task, index) => (
          <Task task_id={task?.id} key={index} index={index} stage_id={task.stage_id} workflow_id={workflow_id} app_id={app_id} user_id={user_id} next={next} prev={prev} setOnTask={setOnSubTask} setOnEvent={setOnEvent} schema={schema} task={task} fields={fields} badges={badges} setUpdate={setUpdate} permission={permission} scrollX={0} setContent={setContent} group_id={group_id} stage_index={stage_index} color={'#ccc'} last={rows.length - 4 <= index} type={type} setOnField={setOnSubTask} onTask={onTask} users={users} stages={[]} taskDispatch={taskDispatch} subitem/>
        ))}
        </div>)}
      </Droppable>
      </DragDropContext>
      {allowEdit ? <div className="ps-4"><AddLine stage_id={stage_id} user_id={user_id} setOnTask={setOnSubTask} inline={false} count={tasks && tasks.length} workflow_id={workflow_id} parent_id={task_id} label={subField} taskDispatch={taskDispatch}/></div>:
      <div className="py-3"></div>}
    </div>
    </div>
    </>
  )};

  return (
    <>
     {content(allowEdit)}
    </>
  );
}
