import React, { useState } from "react";
import Icon from '../../components/Icon';
import { Button } from '../../components/Uix';
import Confirm from '../Confirm';
import Files from "../Media/Files";
import TodoList from '../Todo/TodoList';
import moment from "moment"

export default function Comment({
  comment,
  allowEdit,
  setContent,
  setOnActivity,
  ...props
}) {

  let userId = localStorage.getItem('user_id');
  const [show, setShow] = useState(true);

  const renderAttach = ({attach, workflow_id, task_id}) => {
    let data = attach ? JSON.parse(attach) : []
    if(data){
      return(
        <>
          {Array.isArray(data) && data.map((row, key) => {
            switch (row.type) {
              case 'email':
                 return (<span key={key} className="fw-bold small"><Icon icon="document-text"/> {row.name}</span>)
              case 'call':
                 return (<span key={key} className="fw-bold small"><Icon icon="call"/> {row.name}</span>)
              case 'meet':
                 return (<span key={key} className="fw-bold small"><Icon icon="calendar"/> {row.name}</span>)
              case 'note':
                 return (<span key={key} className="fw-bold small"><Icon icon="document-text"/> {row.name}</span>)
              case 'chat':
                return (<span key={key} className="fw-bold small"><Icon icon="chatbubbles"/> {row.name}</span>)
              case 'user':
                 return (<span key={key} className="fw-bold small"><Icon icon="person"/> {row.name}</span>)
              case 'task':
                 return (<Button key={key} className="p-0 m-0 small" variant="link" onClick={() => setContent(row)}>{row.name}</Button>)
              case 'todo':
                return(<TodoList todo_id={row.id} workflow_id={workflow_id} checkname={row.name} task_id={task_id} setOnActivity={setOnActivity} allowEdit={allowEdit}/>)
              default:
                return (<Files file={row} key={key} setOnDelete={setOnActivity}/>);
            }
          })}
        </>
      )
    }
  }

  return (
    <>
    <div className="d-flex justify-content-between align-items-center can-edit">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex text-secondary align-items-center">
          <span className="fw-bold">{comment.name}</span>
          <span className="small ms-2">{moment(Number(comment.created_at)).calendar()}</span>
        </div>
      </div>
      <div>
      {comment.user_id === userId &&
        <Confirm type="comment" id={comment.id} title={comment.name} setOnDelete={setOnActivity} icon={true} customIcon="ellipsis-horizontal" placement="right"/> }
      </div>
    </div>
    <div>
    {comment.attach ?
    <Button variant="link" className="p-0 m-0 d-flex text-secondary align-items-center" onClick={() => setShow(!show)}>
        {comment.description && <span className="small" dangerouslySetInnerHTML={{__html: comment.description.replace(/\n\r?/g, '<br />')}}></span>}
        <Icon icon={show ? "chevron-down" : "chevron-up"} size="sm" className={"text-secondary ms-1"}/>
   </Button>: <>
    {comment.description && <div className="small" dangerouslySetInnerHTML={{__html: comment.description.replace(/\n\r?/g, '<br />')}}></div>}
   </>}
    {show && renderAttach(comment)}
    </div>
    </>
  );
}
