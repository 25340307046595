import React, { useEffect, useState } from "react";
import { Form } from '../../components/Uix';

export default function InputSub({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  setSubVisiable,
  ...props
}){

  const [nameValue, setNameValue] = useState("");

  useEffect(() => {
    setNameValue(field.value)
  }, [field.value]);

  const handleChange = async(value) => {
     await dispatch({ type: 'updateField', payload: field.id, value })
     setNameValue(value)
     setSubVisiable(value??true)
     setOnEvent(true)
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Form.Check label={""} checked={nameValue}
    onTextChange={handleChange} disabled={!allowEdit}/>
    </div>
  )
};
