import React, { useState, useEffect } from "react";
import BaseModal from 'react-overlays/Modal';
import { motion } from 'framer-motion'
import Icon from "../Icon";
import {Button} from "../Uix";

export default function Modal({ children, title, size, show, onClose, backdrop, center }){

  let ref = React.useRef();

  const handleClose = () => {
    if(onClose) onClose()
  }

  const handleShow = () => {

  }


return (
  <>
    <BaseModal
      show={show}
      ref={ref}
      backdrop={backdrop??true}
      onShow={handleShow}
      onHide={handleClose}
      renderBackdrop={(props) => (
          <div {...props} className="modal-backdrop fade show" />
      )}
      renderDialog={(props) => (
          <motion.div animate={{
           y: 20,
           visible: { opacity: 1 },
           hidden: { opacity: 0 },
         }} transition={{
        type: "spring",
        stiffness: 260,
        damping: 20
      }}
            {...props}
            className="modal fade show"
            style={{display: 'block', zIndex: 9999}}
          >
            <div className={`modal-dialog modal-${size} ${center&&"modal-dialog-centered"}`}>
              <div className="d-flex bg-white rounded shadow z-index-2 position-relative flex-column pe-auto">
              <div className="d-flex flex-row-reverse">
                <Button icon="close" onClick={handleClose}/>
              </div>
              <div className="px-4 pb-4">
                {children}
              </div>
            </div>
           </div>
          </motion.div>
        )}
    />
  </>
);
}
