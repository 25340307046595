import { realtime, db } from './FirebaseInit';
import { random } from './Function';
import moment from "moment"
let app_id = localStorage.getItem('app_id');
let user_id = localStorage.getItem('user_id');

async function setInboxes(ids, task_name, task_id, workflow_id, type) {
    let doc_id = random(10);
    let created_at = moment().valueOf();
    var inboxes = {
        ids,
        id: task_id,
        name: task_name,
        send_id: user_id,
        type: type ?? 'createTask',
        read: 0,
        created_at,
    };
    db.collection('inboxes').doc(app_id).collection(workflow_id).doc(doc_id).set(inboxes);
}

async function setNotification(user, task_name, task_id, type) {
    let created_at = moment().valueOf();
    let doc_id = random(10);
    let { id } = user;
    var notification = {
        id: task_id,
        name: task_name,
        user_id: id,
        send_id: user_id,
        type: type ?? 'createTask',
        read: 0,
        created_at,
    };
    db.collection('notifications').doc(app_id).collection(id).doc(doc_id).set(notification);
}

async function setBadges(user_id, task_id, workflow_id) {
    let doc_id = random(10);
    realtime.ref(`badges/${user_id}/${workflow_id}/${doc_id}`).set({
        read: false,
        id: task_id
    });
}

async function syncRemoveNotification(app_id, user_id, id) {
    try {
        db.collection('notifications')
            .doc(app_id)
            .collection(user_id)
            .doc(id)
            .delete();
    } catch (error) {
        console.error(error);
    }
}

async function syncRemoveBadge(workflow_id, user_id) {
    try {
        realtime.ref(`badges/${user_id}/${workflow_id}`).remove();
    } catch (error) {
        console.error(error);
    }
}

async function syncRemoveInbox(app_id, workflow_id, id) {
    try {
        db.collection('inboxes')
            .doc(app_id)
            .collection(workflow_id)
            .doc(id)
            .delete();
    } catch (error) {
        console.error(error);
    }
}

export {
    setBadges,
    setNotification,
    setInboxes,
    syncRemoveNotification,
    syncRemoveInbox,
    syncRemoveBadge

}