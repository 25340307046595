import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import ItemTab from './ItemTab'

const Tab = ({ children, activeKey, onToggle, full }) => {
  const [active, setActive] = useState(activeKey)
  let className = 'btn-group px-0 rounded-0';
  let classContent = full ? 'tab-content' : 'tab-content p-3';

  if(full) {
    className += ' w-100';
  }

  useEffect(() => {
    setActive(activeKey)
  },[activeKey])

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  const item = {
    initial: { opacity: 0, y: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  }

  return(<>
    <div className={"tabs"}>
      <div className={`d-flex justify-content-center`}>
        <ul className={className} role="group">
          {children.map(child => {
            if(child) {
              const { title, text, eventKey } = child.props;
              return (<ItemTab center key={title} eventKey={eventKey} text={text} title={title} active={active} setActive={setActive} onToggle={onToggle}/>)
            }
          })}
        </ul>
      </div>
      <motion.div className={classContent} variants={variants} initial="hidden" animate="visible">
         {children.map(child => {
           if(child) {
             if(active != child.props.eventKey) return undefined;
             return <motion.div variants={item}>{child}</motion.div>;
          }
         })}
      </motion.div>
    </div>
  </>
)}

export default Tab;
