import React, { useState, useEffect, useMemo } from 'react';
import Update from '../../components/Task/Update';
import Content from '../../components/Task/Content';
import { WorkflowQuery } from '../../services/GraphqlApi';
import { useLazyQuery } from "@apollo/client";

export default function TaskWorkflow({id, content, update, share, setContent, setUpdate, location, history, setOnTask, onTask}) {

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  let [view, setView] = useState();
  const [onEvent, setOnEvent] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [newBoard, setNewBoard] = useState();
  const [workflow, setWorkflow] = useState();
  const [permission, setPermission] = useState("");
  const [keyword, setKeyword] = useState("");
  const [filters, setFilters] = useState("");
  const [members, setMembers] = useState([]);
  const [automations, setAutomations] = useState([]);
  const [users, setUsers] = useState([]);
  const [stages, setStages] = useState([]);
  const [notify, setNotify] = useState(false);
  const [count, setCount] = useState(true);
  const [badges, setBadges] = useState([]);
  const [group, setGroup] = useState();
  const [permissions, setPermissions] = useState([]);
  const [views, setViews] = useState([]);
  const [schema, setSchema] = useState([]);
  const [fields, setFields] = useState([]);
  const [columns, setColumns] = useState([]);
  const [app, setApp] = useState([]);

  const [dataQuery,  { loading, refetch, data }] = useLazyQuery(WorkflowQuery);

  useEffect(() => {
    if(content && content.id){
      dataQuery({ variables: { id: content.workflow_id } })
    }
  },[content])

  useEffect(() => {
    if(update && update.id){
      dataQuery({ variables: { id: update.workflow_id } })
    }
  },[update])

  useMemo(() => {
    function loadPageWorkflow(workflow) {
       //cleanup
       let data = Object.assign({}, workflow);
       delete data.schema;
       delete data.fields;
       delete data.permissions;
       delete data.views;
       delete data.tasks;
       delete data.stages;
       delete data.app;
       delete data.users;
       delete data.group;
       setWorkflow(data)
    }

    function loadPermissions(permissions, userId) {
      var perms;
      var isEdit;
      if(!share && user_id){
        if(permissions){
          perms = JSON.parse(permissions)
          if(userId === user_id){
              setPermission('owner')
              isEdit = true
          }else {
             setPermission(perms.name)
             if(perms.name === 'anything'){
               isEdit = true
             }else {
               isEdit = false
             }
          }
          setAllowEdit(isEdit)
        }else {
          //default
          setPermission('anything')
          setAllowEdit(true)
        }
      }else {
        setPermission('onlyview')
        setAllowEdit(false)
      }

    }

    if(data && data.workflow){
      //permission
      loadPermissions(data.workflow.permissions, data.workflow.user_id)
      //end permission

      loadPageWorkflow(data.workflow)

      if(data.workflow.schema) { setSchema(JSON.parse(data.workflow.schema)) }
      if(data.workflow.fields) { setFields(JSON.parse(data.workflow.fields)) }
      if(data.workflow.columns) { setColumns(JSON.parse(data.workflow.columns)) }

      if(data.workflow.automations) { setAutomations(data.workflow.automations) }
      if(data.workflow.views) { setViews(data.workflow.views) }
      if(data.workflow.users) { setMembers(data.workflow.users) }
      if(data.workflow.app) {
         setApp(data.workflow.app)
         localStorage.setItem('wfWorkflows', JSON.stringify(data.workflow.app.workflows))
      }
      if(data.workflow.group) { setGroup(data.workflow.group) }
      if(data.workflow.stages){
        setStages(data.workflow.stages)
        setCount(data.workflow.stages.length)
      }

      setUsers(data.workflow.app.users)


      //Rules
      if(data.workflow.users) {
        if(data.workflow.privacy === 'private') {
          let rule = data.workflow.users.find(u => u.id === user_id);
          if(rule && rule.id){
            return;
          }else {
            history.push('/w/home');
          }
        }
      }
      //End rules
    }
  },[data])

  return (
    <>
    {workflow && update && <Update update={update} setUpdate={setUpdate} location={location} history={history} setOnTask={setOnTask} onTask={onTask} setContent={setContent} workflow={{schema, users, columns, stages, fields, permission, type: workflow.type, user_id: workflow.user_id, group_id: workflow.group_id}} />}
    {workflow && content && <Content content={content} setContent={setContent} setUpdate={setUpdate} location={location} history={history} setOnTask={setOnTask} onTask={onTask} workflow={{schema, users, fields, columns, stages, permission, type: workflow.type, user_id: workflow.user_id, group_id: workflow.group_id}}/>}
    </>
  )
}
