import React, { useState, useEffect } from "react";
import { Form, Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import fieldDefault from '../../data/Fields';
import { translate } from '../../services/Language';
import { jsonParse } from '../../services/Function';

export default function ItemLink({
  dispatch,
  field,
  type,
  btnTitle,
  setOnEdit,
  setConnect
}) {

  const [group, setGroup] = useState([]);
  const [columns, setColumns] = useState([]);
  const [links, setLinks] = useState([]);
  const [groupId, setGroupId] = useState('');

  useEffect(() => {
    const groups = localStorage.getItem('wfWorkflows');
    setGroup(groups ? jsonParse(groups) : []);
  }, []);

  useEffect(() => {
    var ops = []
    if (group && groupId) {
      let workflow = group.find(g => g.id === groupId)
      let schema = workflow.schema ? jsonParse(workflow.schema) : [];
      Object.keys(schema).map(key => {
        var row = schema[key];
        var field = { id: key, title: row.title, field: row.field, enum: row.enum }
        ops.push(field)
      })
      setColumns(ops)
    }
  }, [groupId]);

  const handleChange = (value) => {
    if(value) {
      dispatch({
        type,
        payload: type === "editLink" ? field.id : 'link',
        link: value
      })
      setOnEdit(true)
      setGroupId(value)
    }
  }

  const handleAdd = (link) => {
    var newList = links.length ? links : []
    newList.push(link)
    const linksUnique = Array.from(new Set(newList.map(a => a.id)))
      .map(id => {
        return newList.find(a => a.id === id)
      })
    if (linksUnique && linksUnique.length > 0) {
      dispatch({ type: 'updateConnect', payload: type === "editLink" ? field.id : 'link', connect: JSON.stringify(linksUnique) })
      setLinks(linksUnique)
      setConnect(linksUnique)
    }
    setOnEdit(true)
  }

  const handleRemove = (link) => {
    const linksUnique = links.filter(item => item.id !== link.id)
    dispatch({ type: 'updateConnect', payload: type === "editLink" ? field.id : 'link', connect: JSON.stringify(linksUnique) })
    setLinks(linksUnique)
    setConnect(linksUnique)
    setOnEdit(true)
  }

  return (
    <>
      {columns.length > 0 ? <div>
        <div className="">{translate('connectFields')}</div>
        <div className="overflow-auto scroll-style" style={{ height: 220 }}>
          {columns.map((item, key) => {
            const find = links.length ? links.find(link => link.id === item.id) : false;
            var icon = fieldDefault[item.field].icon;
            return (
              <Button key={key} variant="link" className="text-secondary d-flex justify-content-between align-items-center w-100" onClick={() => find ? handleRemove(item) : handleAdd(item)}>
                <span>
                  <Icon icon={icon} /> {item.title}
                </span>
                {find ? <span className="text-primary"><Icon icon={'checkbox'} /></span> : <Icon icon={'square'} />}
              </Button>);
          })}
        </div>
        <Button onClick={() => setColumns([])} icon="chevron-back" title={btnTitle ?? translate('back')} variant="light" />
      </div> :
        <Form.Select options={group} select2 label={translate('selectGroup')} value={groupId} onTextChange={(value) => handleChange(value)} />
      }
    </>
  );
}
