import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Form } from '../../components/Uix';
import Upgrade from '../../components/Upgrade';
import { translate } from '../../services/Language';
import { CreateWorkflow, CreateStage, TaskImport } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { randomNumer, colors } from '../../services/Function';
import { setInboxes, setNotification } from '../../services/Firebase';
import types from '../../data/Type';
import fieldDefault from '../../data/Fields';
import { WORKFLOW_LIMITED } from '../../services/Defines';
/*
 const fields = [
   {id: 'members', title: translate('members'), show: true, index: 0},
   {id: 'tags', title: translate('tags'), show: true, index: 1},
   {id: 'dueDate', title: translate('dueDate'), show: true, index: 2}
 ] */

export default function AddWorkflow({
  isOpen, setIsOpen, fileData, history, setOnEffect, 
  group, isDarkMode, live, setData, workspace_id, users}){

  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  let user_name = localStorage.getItem('user_name');
  let textForm = React.useRef();
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [typeValue, setTypeValue] = useState("items");
  const [schema, setSchema] = useState({});
  const [privacyValue, setPrivacyValue] = useState("private");
  const [createWorkflow, { data }] = useMutation(CreateWorkflow);
  const [taskImport, dataImport] = useMutation(TaskImport);
  const [createStage, dataStage] = useMutation(CreateStage);

  const handleClose = () => {
    setShow(false);
    setIsOpen(false)
    setNameValue("")
  }
  const handleShow = () => setShow(true);

  const handleClick = async() => {
    //var image = `/${images[randomNumer(38)].replace('public','storage')}`;
    var color = colors[randomNumer(8)];
    if(nameValue){
      createWorkflow( {variables: {
        name: nameValue,
        privacy: privacyValue,
        type: typeValue,
        image: null,
        color,
        user_id,
        schema: JSON.stringify(schema),
        app_id,
        workspace_id,
        view: translate('list')
      }})
    }else {
      return;
    }
  };

  useMemo(() => {
    let isMounted = true;
    if(data && isMounted){
      if(data.createWorkflow && data.createWorkflow.id){
        //Process data from Excel Import
        if(fileData && fileData.items){
          taskImport( {variables: {
            stage_name: fileData?.stage_name,
            items: JSON.stringify(fileData?.items),
            app_id,
            user_id,
            workflow_id: data.createWorkflow.id
          }})
          setData()
          setNameValue("")
        }

        setShow(false)
        setOnEffect(true)
        setIsOpen(false)
        //create first stage.
        if(data.createWorkflow.id){
          if(users) {
            let ids = []
            users.map(user => {
              ids.push(user.id)
              setNotification(user, data.createWorkflow.name, data.createWorkflow.id, 'createWorkflow')
            })
            setInboxes(ids, data.createWorkflow.name, data.createWorkflow.id, data.createWorkflow.id, 'createWorkflow')
          }

          createStage( {variables: {
            name: translate('newList'),
            workflow_id: data.createWorkflow.id,
            app_id,
            index: 0,
            open: true,
            type: 'todo',
          }})
        }
        history.push(`/w/a/${data.createWorkflow.id}`)
      }
    }
    return () => { isMounted = false };
  },[data])

  useEffect(() => {
      if(isOpen){
        setShow(true)
        if(live === false){
          let wfs = JSON.parse(localStorage.getItem('wfWorkflows'))
          setLimit(wfs.length)
        }
      }
    },[isOpen]);

  useEffect(() => {
    if(show){
      setTimeout(() => {
        textForm.current?.focus()
      }, 600);
    }
    },[show]);

  useEffect(() => {
      if(fileData){
        setNameValue(fileData.group_name)
        var properties = {}
        var fields = fileData.heading || []
        Object.keys(fields).forEach( (key) => {
          if(fields.hasOwnProperty(key)) {
            var field = fields[key]
            if(field.field || field.id != 'name'){
              var property = Object.assign(field, fieldDefault[field.field])
              property.name = field.field
              property.enabled = true
              properties[property.id] = property
            }
          }
        })
        setSchema(properties)
      }
    },
    [fileData],
  );

  return (
    <>
      <Modal show={show} onClose={handleClose} title={translate('createBoard')}>
        <div className={isDarkMode ? "dark-mode mb-3" : "mb-3"}>
          <form onSubmit={e => { e.preventDefault()}}>

            <Form.Input label={translate('name')} onTextChange={setNameValue} value={nameValue} onKeyPress={handleClick} useRef={textForm} className="mb-3"/>

            <Form.Radio checked={privacyValue === 'private' ? true : false} label={translate('private')} description={translate('privateMore')} onTextChange={setPrivacyValue} value={'private'} name="Privacy"/>

            <Form.Radio checked={privacyValue === 'public' ? true : false} label={translate('public')} description={translate('publicMore')} onTextChange={setPrivacyValue} value={'public'} name="Privacy"/>

            <div className="mt-3">{translate('typeData')}</div>
            <div className="row">
            {types.map((item, key) => <div key={key} className="col-4">
              <Form.OneCheck checked={typeValue === item.name ? true : false} label={translate(item.name)} onTextChange={setTypeValue} value={item.name} name="Type"/>
              </div>
            )}

            </div>
          </form>
        </div>
        <div className={"position-relative"}>
        {limit >= WORKFLOW_LIMITED ? <Upgrade btn={true} text={translate('createList')}/> :
          <Button variant="primary" onClick={handleClick}>
            {translate('createGroup')}
          </Button>}
        </div>
      </Modal>
    </>
  );
}
