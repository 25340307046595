import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function TableOutline({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox="0 0 16 16">
  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
</svg>
 );
}
