import React, { useState , useEffect, useMemo } from "react";
import Icon from '../../components/Icon';
import { Menu, Button, Spinner } from '../../components/Uix';
import { translate } from '../../services/Language';
import Activity from './Activity';
import Thread from './Thread';
import { ActivitiesQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";
import activityList from '../../data/Activity';

export default function Activities({
  workflow_id,
  task_id,
  task_name,
  onActivity,
  setOnActivity,
  setFileResult,
  allowEdit,
  setContent,
  sort
}) {

  const [comments, setComments] = useState([]);
  const [activities, setActivities] = useState([]);
  const [onEvent, setOnEvent] = useState(false);
  const [thread, setThread] = useState(false);
  const [type, setType] = useState('');

  const { loading, refetch, data } = useQuery(ActivitiesQuery, {
    variables: { id: workflow_id, task_id, order: 'DESC' },
  });

  useEffect(() => {
    if(data && data.activities){
      setComments(data.activities)
      setActivities(data.activities)
    }
  },[data])

  useEffect(() => {
      //refetching
      refetch()
      if(onEvent) setOnEvent(false)
      if(onActivity) setOnActivity(false)
    },
    [onEvent, onActivity, task_id],
  );

  useEffect(() => {
    if(type && type.type){
      setActivities(comments.filter(comment => {
          let data = comment.attach ? JSON.parse(comment.attach) : [];
          let row = data[0]?.type;
          if(row === type.type){
            return comment;
          }

      }))
    }
  },[type]);

  return (
    <>
      <div>
        <div className="d-flex justify-content-end">
          <Menu icon={'filter'} title={translate('filter')} placement="right" variant="link">
          {activityList.map((list, key) => {
              let count = 0;
              comments.map(comment => {
                let data = comment.attach ? JSON.parse(comment.attach) : [];
                let row = data[0]?.type;
                if(row === list.type){
                  count += 1;
                }
              })
              return(<Button className="d-flex justify-content-between align-items-center w-100 my-2" variant={list.type === type.type ? "info" : "light"} onClick={() => {
                setType(list);
              }}> <span><Icon icon={list.icon} className={"text-secondary"}/> {translate(list.name)}</span>
                  <span>{count}</span>
             </Button>)
          })}
          </Menu>
        </div>
        <div className="comment-line">
          {loading && <div className="d-flex align-items-center justify-content-center"><Spinner animation="border" variant="warning" /></div>}
          {activities.map((comment, key) => (
            <div key={key} className="d-block w-100">
              <div className="p-2 border rounded-3">
              <Activity comment={comment} setContent={setContent} setOnActivity={setOnActivity} allowEdit={allowEdit}/>
              <Button variant="link" className="p-0 m-0" onClick={() => setThread(thread === comment.id ? null : comment.id)}>
                  <Icon icon={"chatbubble-outline"} size="sm" className={"text-secondary me-1"}/>
                  <span className="small text-secondary">{comment.thread > 0 ? `${comment.thread} ${translate('comment')}`: translate('comment')}</span>
             </Button>
              {thread === comment.id &&
              <div className="border-start border-3 ps-2 ms-1">
                <Thread task_id={task_id} task_name={task_name} workflow_id={workflow_id} user_id={comment.user_id} thread_id={comment.id} setOnEvent={setOnEvent} is_thread={!comment.thread_id} setFileResult={setFileResult}/>
              </div>}
              </div>
              {(key < activities.length - 1) && <div className="border-start mx-auto ms-5" style={{height: 20}}></div>}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
