import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import ItemTab from './ItemTab'

const Accordion = ({ children, activeKey, onToggle }) => {
  const [active, setActive] = useState(activeKey)
  let className = 'btn-group flex-column p-0 w-100';

  useEffect(() => {
    setActive(activeKey)
  },[activeKey])

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -50 },
  }

  return(<>
    <div>
      <ul className={className} role="group">
        {children.map((child, key) => {
          if(child) {
            const { title, text, eventKey, onClick } = child.props;
            return (<motion.div key={key} variants={variants} initial="hidden" animate="visible">
              <ItemTab key={title} eventKey={eventKey} onToggle={onToggle} onClick={onClick} text={text} title={title} active={active} setActive={setActive}/>
              <div>
                 {active === eventKey && <motion.div variants={item}>{child}</motion.div>}
              </div>
            </motion.div>
            )
          }
        })}
      </ul>

    </div>
  </>
)}

export default Accordion;
