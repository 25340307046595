import i18n from 'i18n-js';
import memoize from "lodash.memoize";

const translationGetters = {
  vi: () => require("../data/Vietnamese.json"),
  en: () => require("../data/English.json"),
};

/*
i18n.fallbacks = true;
i18n.missingTranslation = function (string) {
  if(string){
    return string.charAt(0).toUpperCase() +
            string.slice(1)
  }else {
    return 'Missing'
  }
};
*/

function intLanguage(){
  switch (navigator.language) {
    case 'en':
    case 'en-US':
    case 'en-AU':
    case 'es-ES':
    case 'fr-CA':
    case 'zh-SG':
    case 'en-NZ':
    case 'en-IE':
    case 'es-CR':
    case 'fr-CH':
    case 'zh-CN':
    case 'sv-FI':
    case 'it-CH':
    case 'es-MX':
    case 'ms-MY':
    case 'ja-JP':
    case 'da-DK':
    case 'de-DE':
    case 'el-GR':
    case 'fr':
    case 'fr-FR':
    case 'ko':
    case 'ko-KR':
    case 'ru':
    case 'ru-RU':
    case 'sk':
    case 'sk-SK':
    case 'en-GB':
    case 'en':
    case 'en-US':
    case 'de':
    case 'de-CH':
    case 'zh':
    case 'zh-CN':
      return 'en';
      break;
    default:
      return 'vi';
  }
}

export const translate = memoize(
  (key, config) =>
    i18n.t(key, config).includes('missing') ? key.charAt(0).toUpperCase() +
            key.slice(1) : i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key),
);

export const i18nConfig = (langCode = null) => {
  let locale = localStorage.getItem('locale');
  let lang = langCode ?? intLanguage();
  let languageTag = locale ?? lang;

  translate.cache.clear();

  i18n.translations = {
   [languageTag]: translationGetters[languageTag](),
  };

  i18n.locale = languageTag;
}

export default i18n;
