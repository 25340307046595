import React, { useState, useEffect, useReducer } from "react";
import { Button, Form, Toast } from '../../components/Uix';
import Icon from '../../components/Icon';

import { translate } from '../../services/Language';
import { reducer } from '../../reducers/Status';

const initialState = { todos: [], nextLabelId: 0, newLabelTitle: ""};

export default function ItemSelect({
  workflow_id,
  items,
  setItems,
  dispatchField,
  setOnEdit,
  type,
  field
}){
      const listDefaultValue = translate('listDefault')
      const [state, dispatch] = useReducer(reducer, initialState);
      const { todos, newLabelTitle } = state;
      let [show, setShow] = useState(false);
      let [todo, setTodo] = useState();

      let textInput = React.useRef();

      useEffect(() => {
        var data = []
        var lists = null
        if(items){
          lists = items
        }else {
          lists = listDefaultValue.toString().split(',')
        }
        lists.map((t, key) =>{
            data.push({name: t, id: key})
        })
        dispatch({ type: "reset", payload: data, nextLabelId: data.length + 1})
     }, []);

     useEffect(() => {
        var list = []
        if(todos.length) {
          //Need to fixes when change to array.
          todos.map(l => list.push(l.name))
          dispatchField({ type, payload: type === 'editEnum' ? field.id : 'select', enum: list })
          setOnEdit(true)
        }
     }, [todos]);

      //Add or Update tags to workflow
      const handleTagAdd = async() => {
          dispatch({ type: "add" })
      };

      return (
        <>
          <div className="py-2 px-1">
          {todos.map( (todo, key) => (
            <div key={key} className="text-center d-flex justify-content-between align-items-center my-1">
              <input
                onKeyPress={({ key, target }) => {
                  if(key === 'Enter'){
                    if(target.value.length > 0){
                      setTimeout(() => {
                        textInput.current?.focus()
                      }, 600);
                    }else {
                      dispatch({ type: "remove", payload: todo.id })
                    }
                  }
                }}
                onChange={({ target }) => {
                  dispatch({ type: 'editTitle', payload: todo.id, title: target.value })
                }}
                value={todo.name}
                className={"form-control w-75"}
              />
              <Button icon="trash" onClick={() => {
                  setShow(true)
                  setTodo(todo)
              }}/>
            </div>
          ))}

            <div className="text-center d-flex justify-content-between align-items-center my-1">
              <Form.Input className={"w-75 my-2"} placeholder={translate('addNewItem')}
                  value={newLabelTitle}
                  onTextChange={value => dispatch({
                    type: "updateTitle",
                    payload: value,
                  })}
                  focus="true"
                  onKeyPress={handleTagAdd}
                  ref={textInput}
              />
              {(newLabelTitle) ?
                <Icon onClick={handleTagAdd} icon="save" className={"text-primary"}/>:
                <Icon icon="create" className={"text-secondary"}/>
              }
            </div>
          </div>
          {show &&
          <Toast size="md" placement="bottom" show={show} onClose={() => setShow(false)} >
           <div className="p-2 text-center">
              <h4 className="h4">{`${translate('delete')} ${todo.name}?`}</h4>
              <Button
                title={translate('delete')}
                variant="danger"
                onClick={() => {
                  dispatch({
                    type: "remove",
                    payload: todo.id
                  })
                  setShow(false)
              }}
              />
              <Button
                title={translate('close')}
                variant="link"
                onClick={() => setShow(false)}
              />
            </div>
            </Toast>}
        </>
      );
}
