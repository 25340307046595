import React, { useState, useEffect, useMemo } from 'react';
import { Button } from '../../components/Uix';
import { translate } from '../../services/Language';
import { CreateTask } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { sendNotification } from '../../services/Api';
import { setInboxes, setBadges, setNotification } from '../../services/Firebase';
import { automation } from '../../store/automation';

export default function AddLine({
  parent_id,
  stage_id,
  workflow_id,
  setOnTask,
  count,
  inline,
  label,
  users,
  taskDispatch
}) {

  const [onNotify, setOnNotify] = useState();
  const [nameValue, setNameValue] = useState("");

  let taskInput = React.useRef();
  let userName = localStorage.getItem('user_name');
  let user_id = localStorage.getItem('user_id');

  const [createTask, { data, loading, error }] = useMutation(CreateTask);

  const handleClickTask = async () => {
    createTask({
      variables: {
        name: nameValue,
        stage_id,
        user_id,
        workflow_id,
        parent_id,
        index: count + 1,
        status: 'progress',
      }
    })

    setNameValue("")
  };

  useMemo(() => {
    //Process automation
    if (data && data.createTask && data.createTask.id) {
      automation('itemCreated', { id: data.createTask.id, user_id: data.createTask.user_id, stage_id, workflow_id })
      taskDispatch({ type: "addTask", payload: data.createTask })
      setOnNotify({
        id: data.createTask.id,
        name: data.createTask.name,
      })
    }
  }, [data])

  useEffect(() => {
    async function asyncInit() {
      //Add Notify
      if (users) {
        let ids = []
        users.map(user => {
          ids.push(user.email)
          //set badge
          setBadges(user.id, onNotify.id, workflow_id)
          setNotification(user, onNotify.name, onNotify.id, 'createTask')

          if (user.id != user_id) {  
            //Add notify mobile
            var devices = JSON.parse(user.device);
            if (devices && devices.userId) {
              var message = {
                contents: { "en": `${userName} đã thêm nhiệm vụ mới` },
                headings: { "en": onNotify.name },
                include_player_ids: [devices.userId]
              };
              sendNotification(message);
            }
            //End Notify
          }
        })
        setInboxes(ids, onNotify.name, onNotify.id, workflow_id, 'createTask')
      }
    }

    if (onNotify) {
      asyncInit()
      setOnNotify()
    }
  }, [onNotify]);

  var cls = "d-flex align-items-center mt-1 position-relative";

  return (
    <>
      <div className={cls} style={{ marginLeft: inline ? 30 : 0, maxWidth: inline ? 320 : '100%', minWidth: inline ? 320 : '100%' }}>
        <input className={"form-control mb-0 p-1 add-line border-top-0 border-end-0 border-start-0"} type="text"
          value={nameValue}
          placeholder={`+ ${translate('add')} ${translate(label)}`}
          ref={taskInput}
          onChange={e => setNameValue(e.target.value)}
          onKeyPress={({ key, target }) => {
            if (key === 'Enter' && target.value.length > 0) {
              handleClickTask()
            }
          }}
        />
        {nameValue && <Button size="sm" icon="add" variant={nameValue ? "primary" : "link"} className={`mx-2`} onClick={handleClickTask} />}
      </div>
    </>
  );
}
