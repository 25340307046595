import React, { useState, useCallback, useRef } from 'react';
import Overlay from 'react-overlays/Overlay'
import styled from 'styled-components';

const Tooltip = ({children, title, placement}) => {
  const [show, setShow] = useState(false);
  const triggerRef = useRef(null);
  const containerRef = useRef(null);

const Tooltip = styled("div")`
  position: absolute;
  min-width: 100px;
  z-index: 90;
`;

const Arrow = styled("div")`
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background: #454545;
    width: 10px;
    height: 10px;
    top: 0;
    left: 0;
  }

  ${(p) =>
    ({
      left: "right: -4px;",
      right: "left: -4px;",
      top: "bottom: -4px;",
      bottom: "top: -4px;",
    }[p.placement])}
`;

const Body = styled("div")`
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  background-color: #454545;
`;

  return(
      <div ref={containerRef}>
      <div ref={triggerRef} onMouseLeave={() => setShow(false)} onMouseOver={() => setShow(true)}>{children}</div>
      <Overlay
        show={show}
        rootClose
        offset={[0, 10]}
        onHide={() => setShow(false)}
        placement={placement}
        container={containerRef}
        target={triggerRef}
      >
        {({ props, arrowProps, placement }) => (
          <Tooltip {...props} placement={placement}>
           <Arrow
             {...arrowProps}
             placement={placement}
             style={arrowProps.style}
           />
           <Body>{title}</Body>
         </Tooltip>
        )}
      </Overlay>
    </div>
  )
}

export default Tooltip;
