import React,  { useEffect, useState } from "react";
import { Button, Modal } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { random } from '../../services/Function';
const ID_UNSPLASH = process.env.REACT_APP_UNSPLASH;
const lists = ['color-of-water','wallpapers','office','business-work','student','people', 'experimental','architecture','nature','fashion','film','food-drink','health','interiors'];

export default function Unsplash({
  urlValue,
  setUrlValue
}) {

  const [images, setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState("color-of-water");
  const orientation = "landscape";
  const per_page = 30;

  const handleImage = async(image) => {
     try {
       setUrlValue(image.urls.small_s3)
     } catch (error) {
     }
  }

  useEffect(() => {
    fetch(`https://api.unsplash.com/search/photos?page=1&per_page=${per_page}&orientation=${orientation}&query=${query}&client_id=${ID_UNSPLASH}`)
      .then(res => res.json())
      .then(
        (result) => {
          setImages(result.results)
          if(!urlValue){
            setUrlValue(result.results[0].urls.small_s3)
          }
        },
        (error) => {
          //console.log(error)
        }
      )
  },[query])



   return (
     <>

     {lists && lists.map((list, key) => <Button className="p-0 pe-1 pb-1" key={key} variant={"link"} onClick={() => setQuery(list)}>
        <span className={list === query ? "small text-info":"small text-secondary"}>{translate(list)}</span>
       </Button>)}
      <div className="row">
       {images && images.map((image, key) => <Button className="col-4" key={key} variant={"link"} onClick={() => handleImage(image)}>
           <img src={image.urls.thumb} width={90}/>
         </Button>)}
     </div>
    </>
   );
}
