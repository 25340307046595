import React, { useState } from "react";
import { Button } from '../../../components/Uix';
import { translate } from '../../../services/Language';

export default function VideoEditor({confirmBlock}) {

  const [urlValue, setUrlValue] = useState("")

  return (
    <div className="px-4 pb-4">
     <h4>{translate('video')}</h4>
    <input
      className="form-control"
      onChange={(e) => setUrlValue(e.target.value)}
      type="text"
      value={urlValue}
     />
     <Button onClick={() => confirmBlock('VIDEO', urlValue)} icon={'add'} title={translate('add')}/>
    </div>);

}
