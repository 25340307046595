import React, { useEffect, useState, useMemo, useRef } from "react";
import { translate } from '../../services/Language';
import { ProgressBar, Menu, Form, Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import ItemProgress from './ItemProgress';

export default function InputProgress({
  dispatch,
  field,
  onEvent,
  allowEdit,
  schema,
  task,
  setOnEdit,
  setOnEvent,
  ...props
}){

  const [nameValue, setNameValue] = useState("");
  const [option, setOption] = useState('status');
  const [show, setShow] = useState(false);
  const ref = useRef()

  const handleUpdate = async(value) => {
    if(!!field.id && !!value){
      await dispatch({ type: 'updateField', payload: field.id, value })
      setOnEvent(true)
    }
  }

  useMemo(() => {
    handleUpdate(nameValue)
  },[nameValue])

  useEffect(() => {
    if(field && task){
      const collection = task.collection ? JSON.parse(task.collection) : [];
      let config = field.config ? JSON.parse(field.config) : []
      setOption(config.type)
      if(config.type === "fixed"){
        var xPercent = collection[config.xPercent]
        var ofNumber = collection[config.ofNumber]
        if(!!ofNumber && !!xPercent){
          count = Math.round((xPercent / ofNumber)*100);
          setNameValue(count)
        }
      }else if(config.type === "status"){
        if(schema){
          var sum = []
          var columns = []
          var count = 0;
          if(config.status){
            columns = config.status;
          }else {
            columns = Object.values(schema).filter(c => c.name == "status");
          }
          columns.map(column => {
            var col = collection[column.id] ? collection[column.id] : null;
            if(col && col.id === 0){
              sum.push(col)
            }
          })

          if(sum.length && columns.length){
            count = Math.round((sum.length / columns.length)*100);
          }
          setNameValue(count)
        }
      }else {
        setNameValue(field.value)
      }
    }

  },[task])


  return (
    <>
      <div className="w-100 field-progress">
        <Menu disabled={!allowEdit} title={<ProgressBar disabled={!allowEdit} variant="info" now={Number(nameValue)}/>} btnSize="sm" btnStyle={{width: '100%'}} onTogle={() => {
          setTimeout(function () {
            ref.current?.focus()
          }, 1000);
        }}>
            {option === "text" &&
              <div className="border rounded">
                <Form.Input placeholder={field.title}
                   onTextChange={setNameValue}
                   value={nameValue} type="number"
                   ref={ref}
                   className={"input-great text-center"}/>
              </div>
            }
            <Button title={translate('settings')} icon={field.icon} onClick={() =>  setShow(!show)}/>
            {allowEdit && show &&
              <ItemProgress dispatch={dispatch} schema={schema} field={field} type="editConfig" setOnEdit={setOnEdit} setNameValue={setNameValue} nameValue={nameValue}/>
            }
        </Menu>
      </div>
    </>
  )
};
