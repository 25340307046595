import React, { useState } from "react";
import { Modal, Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import Confirm from '../../components/Confirm';
import { translate } from '../../services/Language';
import { imageStorage } from '../../services/Function';

export default function File({
  file,
  onUpload,
  setOnDelete,
  handleFileShow,
  ...props
}){

  let userId = localStorage.getItem('user_id');
  var [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if(!!handleFileShow){
      handleFileShow(file)
    }else {
      setShow(true)
    }
  };

  return (
    <>
        <div key={file.id} className="my-1 w-100">
          {(file.type == 'image/png' || file.type == 'image' || file.type == 'image/jpeg' || file.type == 'image/gif') ?
          <><div className="row">
              <div className="col-7">
                <Button variant="link" className="nav-link p-0 text-break" onClick={handleShow}>
                  <img className="img-fluid rounded" src={imageStorage(file.uri)} />
                </Button>
              </div>
              <div className="col-5">
                <Button variant="link" className="nav-link p-0 text-break text-start text-dark" onClick={handleShow}>
                  {file.name}
                  <div>{file.note} <small>{file.created_at}</small></div>
                </Button>
                {file.user_id === userId && <Confirm type="file" customIcon="ellipsis-horizontal" id={file.id} title={file.name} setOnDelete={setOnDelete} icon={false}/>}
              </div>
            </div>
          </> : <>
          {file.type == 'drive' ? <a target="_blank" className="nav-link text-break" href={`https://drive.google.com/file/w/${file.uri}/view`}>
           <img width="24" className="img-fluid rounded" src={`/assets/google-drive-icon.png`} /> {file.name}
            <div>{file.note} <small>{file.created_at}</small></div>
          </a> : <a target="_blank" className="nav-link text-break" href={imageStorage(file.uri)}>
            <Icon icon="document-attach" /> {file.name}
            <div>{file.note} <small>{file.created_at}</small></div>
          </a>
          }
          </>
        }
        </div>
        <Modal show={show} onClose={handleClose} size="lg">
          <div className={"py-2"}>
            <a target="_blank" className="nav-link text-break" href={imageStorage(file.uri)}>
              <Icon icon="download" /> {file.name}
            </a>
          </div>
          <div>
            <img className="img-fluid w-100" src={imageStorage(file.uri)} />
          </div>
        </Modal>
      </>
    );
}
