import React, { useReducer, useEffect, useState } from "react";
import InputLink from './InputLink';
import InputFile from './InputFile';
import InputSub from './InputSub';
import InputTodo from './InputTodo';
import Icon from '../../components/Icon';
import { UpdateWorkflow, UpdateTask } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { fieldCollection, fieldSchema } from '../../services/Function';
import { reducer, resetData, initialState } from '../../reducers/Actions';

const items = ['todolist', 'link'];

export default function Addon({
  task_id,
  stage_id,
  workflow_id,
  task,
  schema,
  allowEdit,
  setContent,
  setOnTask,
  setOnField,
  setSubVisiable,
  parent_id,
  sheet,
  users
}){

  const [state, dispatch] = useReducer(reducer, initialState);
  const { fields } = state;
  const [onEvent, setOnEvent] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const user_id = localStorage.getItem('user_id');
  const [updateWorkflow] = useMutation(UpdateWorkflow);
  const [updateTask] = useMutation(UpdateTask);

  useEffect(() => {
     if(schema && task_id) {
        let isSubscribed = true
        if(isSubscribed) {
          let collection = task.collection ? JSON.parse(task.collection) : [];
          let { column } = resetData(schema, collection);
          dispatch({ type: "reset", payload: column})
        }
        return () => isSubscribed = false
      }
  }, [schema, task]);

    useEffect(() => {
      if(fields && onEvent === true) {
          let collection = fieldCollection(fields)
          updateTask({variables: {id: task_id, collection }})
          setOnEvent(false)
          //if(setOnTask) setOnTask(true)
      }

    }, [onEvent]);

    useEffect(() => {
      if(fields && onEdit === true) {
        var schema = fieldSchema(fields)
        updateWorkflow({variables: {id: workflow_id, schema }})
        setOnEdit(false)
      }

    }, [onEdit]);

  const makeForm = (field) => {
    switch (field.field) {
      case 'link':
        return <InputLink field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} allowEdit={allowEdit} setContent={setContent} schema={schema} full/>
      case 'file':
        return <InputFile field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      case 'todolist':
        return <InputTodo field={field} workflow_id={workflow_id} task={task} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit} full/>
      case 'subitem':
        return (<InputSub field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit} setSubVisiable={setSubVisiable}/>)
      default:
        return null;
    }
  };

    return (
      <>
       <div className="field-list position-relative mt-2">
          {fields && fields.map(field => {
              if(field.enabled === true && items.includes(field.field)){
                return(
                <div key={field.id} className="w-100 nav-field my-2 pt-3">
                  <div className={"w-100"}>
                    <Icon icon={field.icon} className={"text-secondary me-2"}/> {field.title}
                  </div>
                  <div className={"w-100 border-dotted rounded p-2 mt-2"}>
                   {makeForm(field)}
                  </div>
              </div>
           )}
         })}
      </div>
     </>
    )

};
