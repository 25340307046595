import React, { useEffect, useState } from "react";
import Icon from '../../components/Icon';
import { Dropdown, Button } from '../../components/Uix';
import { translate } from '../../services/Language';
import { automation } from '../../store/automation';
import { Link } from "react-router-dom";
import { jsonParse, text_truncate } from '../../services/Function';

export default function InputUser({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  task,
  users
}){

  let app_id = localStorage.getItem('app_id');

  var [links, setLinks] = useState([]);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if(field.value){
      setLinks(jsonParse(field.value))
    }
  }, [field.value]);

  const handleRemove = (link) => {
    const linksUnique = links.filter(item => item.id !== link.id)
     dispatch({ type: 'updateField', payload: field.id, value: JSON.stringify(linksUnique) })
     setLinks(linksUnique)
     setOnEvent(true)
  }

  const handleClick = async(link) => {
    var newList = links.length ? links : []
    newList.push({id: link.id, name: link.name})
    const linksUnique = Array.from(new Set(newList.map(a => a.id)))
     .map(id => {
       return newList.find(a => a.id === id)
     })

     await dispatch({ type: 'updateField', payload: field.id, value: JSON.stringify(linksUnique) })
     setLinks(linksUnique)
     //Automation
     if(field && link){
       var data = Object.assign({}, task);
       data.field = field.id;
       data.value = link.id;
       automation('columnChanges', data)
     }
     setOnEvent(true)
  }

  return (<div className="field-list d-flex justify-content-center align-items-center on-hover ps-1">
       <Dropdown icon="person" onToggle={setOpen} disabled={!allowEdit} size="sm">
        {users.map(link => {
          const find = links.length ? links.find(item => item.id === link.id) : false;
          return(
            <Button key={link.id} className={find ? "text-primary" : "text-secondary"} variant="link" onClick={() => find ? handleRemove(link) : handleClick(link)}>
             {translate(link.name)} {find && <Icon icon="check" color="blue"/>}
            </Button>
        )})}
      </Dropdown>

      {links.length > 0 &&
      <Dropdown title={links.length && text_truncate(links[0].name, 20)}>
          {links.map(link => (
             <Button key={link.id} title={link.name}/>
          ))}
      </Dropdown>
      }

    </div>
  )
};
