import React, {useContext, useReducer, createContext} from "react";
const GRAPHQL_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_GRAPHQL_LOCAL : process.env.REACT_APP_GRAPHQL;

var UserStateContext = createContext();
var UserDispatchContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("token_id"),
  });
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, syncSpace };


async function loginUser(dispatch, body, history, redirect) {
      let {token, name, id, email, device, photo, appId, spaceId, appName, isRegister} = body;

      if(token) {
        //set token
        localStorage.setItem('token_id', token);
        localStorage.setItem('user_id', id);
        localStorage.setItem('app_id', appId);
        localStorage.setItem('workspace_id', spaceId);
        localStorage.setItem('user_name', name);
        localStorage.setItem('user_email', email);
        localStorage.setItem('user_photo', photo);
        localStorage.setItem('user_device', JSON.stringify(device));
        localStorage.setItem('app_name', appName)

        dispatch({ type: "LOGIN_SUCCESS" });
        if(isRegister){
          sendNotification(id, name, email)
          history.push("/w/initdata")
        }else {
          if(redirect){
            history.push("/w/home");
          }

        }

    }
}

async function syncSpace(dispatch, app_id, user_id, history, setError) {
  if (!!app_id && !!user_id) {
    try {
        localStorage.removeItem('app_id');
        localStorage.setItem('app_id', app_id);
        localStorage.removeItem('currentWorkflow');
        localStorage.removeItem('currentMessage');

        dispatch({ type: "LOGIN_SUCCESS" });
        setError(null);
        history.push("/w/home");

    } catch (error) {
      setError(true)
    }

  } else {
    setError(true)
  }
}

async function sendNotification(user_id, user_name, user_email) {
  await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: `
      mutation {
        notificationRegister(userId: "${user_id}")
      }`
    }),
  });

}

function signOut(dispatch, history) {
  localStorage.removeItem("token_id");
  localStorage.removeItem('user_id');
  localStorage.removeItem('app_id');
  localStorage.removeItem('user_name');
  localStorage.removeItem('user_email');
  localStorage.removeItem('app_name');
  localStorage.removeItem('currentWorkflow');
  localStorage.removeItem('currentMessage');
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  //history.push("/account");
}
