function reducer(state, action) {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        todos: action.payload,
        nextLabelId: action.nextLabelId
      };
    case "add":
      if(state.newLabelTitle.length > 0) {
        return {
          todos: [
            ...state.todos,
            {
              id: state.nextLabelId,
              name: state.newLabelTitle,
              color: state.newLabelColor,
            }
          ],
          nextLabelId: state.todos.length + 1,
          newLabelTitle: "",
        }
      }
    case "remove":
      return {
        ...state,
        todos: state.todos.filter(todo => todo.id !== action.payload)
      };
    case "updateColor":
      return {
        ...state,
        newLabelColor: action.payload,
      };
    case "updateTitle":
      return {
        ...state,
        newLabelTitle: action.payload,
      };
    case 'editTitle':
        return {
          ...state,
          todos: state.todos.map(todo => {
            if(todo.id === action.payload) {
              return {
                ...todo,
                name: action.title
              }
            } else {
              return todo
            }
         })
        };
    case 'toggleShow':
       return {
         ...state,
         todos: state.todos.map(todo =>
            todo.id === action.payload ? { ...todo, show: !todo.show } : todo
          )
    };
    default:
      return state;
  }
}

export {
  reducer
};
