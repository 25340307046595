import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function BulbOutline({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox="0 0 512 512">
<path d='M304 384v-24c0-29 31.54-56.43 52-76 28.84-27.57 44-64.61 44-108 0-80-63.73-144-144-144a143.6 143.6 0 00-144 144c0 41.84 15.81 81.39 44 108 20.35 19.21 52 46.7 52 76v24M224 480h64M208 432h96M256 384V256' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/><path d='M294 240s-21.51 16-38 16-38-16-38-16' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/>
</svg>
 );
}
