import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function CalculatorOutline({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox="0 0 512 512">
   <rect x='112' y='48' width='288' height='416' rx='32' ry='32' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/><path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M160.01 112H352v64H160.01z'/><circle cx='168' cy='248' r='24'/><circle cx='256' cy='248' r='24'/><circle cx='344' cy='248' r='24'/><circle cx='168' cy='328' r='24'/><circle cx='256' cy='328' r='24'/><circle cx='168' cy='408' r='24'/><circle cx='256' cy='408' r='24'/><rect x='320' y='304' width='48' height='128' rx='24' ry='24'/>
</svg>
 );
}
