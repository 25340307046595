import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion'
import Icon from "../Icon";
import Button from './Button';

const Menu = ({children, onToggle, show, onClose, autoHide, onClick, title, icon, className, variant, placement, size, disabled, btnStyle, btnSize, btnCls, position, customWidth, closeBtn, btnBetween}) => {

  const [open, setOpen] = useState(false);
  let ref = React.useRef();

  const handleClose = () => {
    setOpen(false)
    if(onToggle) onToggle(false)
    if(onClose) onClose()
  }

  const handleOpen = () => {
    setOpen(true)
    if(onToggle) onToggle(true)
  }
  let width = 256;
  let classes = 'rounded-3 shadow shadow-sm text-wrap p-2 bg-white';
  if(open) classes += ` position-absolute`;
  if(placement === "bottom"){
    classes += ` start-0 bottom-0`
  }else if(placement === "left"){
    classes += ` start-0 top-0`
  }else {
    classes += ` end-0 top-0`
  }
  if(className) classes += ` ${className}`
  if(size === 'md'){
    width = 512;
  }
  if(size === 'lg'){
    width = 768;
  }
  if(customWidth){
    width = customWidth;
  }

  useEffect(() => {
    if(autoHide) setOpen(false)
  },[autoHide])

  useEffect(() => {
    setTimeout(() => {
      setOpen(show)
    }, 600);
  },[show])

  const escFunction = (event) => {
      if(event.keyCode === 27) {
         handleClose()
      }
    }

  useEffect(() => {
        // Bind the event listener
        document.addEventListener("keydown", escFunction, false);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("keydown", escFunction, false);
        };
    });

  useEffect(() => {
      // Bind the event listener
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside, true);
      };
  });

  const handleClickOutside = (event) => {
     if (ref.current && !ref.current.contains(event.target)) {
        handleClose()
     }
  }

  return(
  <div className={`dropmenu position-relative ${btnBetween?"w-100":""}`}>
  <button disabled={disabled} onClick={open ? handleClose : handleOpen} className={`btn btn-${variant??"link"} btn-${btnSize} ${btnCls}`} type="button" style={btnStyle}>
    {icon && <Icon icon={icon}/>} {title}
  </button>
  {!!open && <motion.div ref={ref} animate={{
       y: 20,
       visible: { opacity: 1 },
       hidden: { opacity: 0 },
     }} transition={{
    type: "spring",
    stiffness: 260,
    damping: 20
  }} className={classes} style={{zIndex: 9909, width, marginTop: 20, display: open ? 'block': 'none'}}>
    {closeBtn && <div class="d-flex justify-content-end"><Button onClick={handleClose} size="sm" icon={'close'} size="lg"/></div>}
    {children}
  </motion.div>}
</div>
)}

export default Menu;
