import React, { useEffect, useState } from "react";
import Icon from '../../components/Icon';
import { Button, Accordion, Portal, Item } from '../../components/Uix';
import { automation } from '../../store/automation';
import { translate } from '../../services/Language';
import { Link } from "react-router-dom";
import { jsonParse } from '../../services/Function';
import ItemStatus from './ItemStatus';

export default function InputStatus({
  dispatch,
  field,
  setOnEvent,
  setOnEdit,
  canEdit,
  allowEdit,
  smartForm,
  task,
  placement,
  ...props
}){

  //Update on each event is changed
  let textInput = React.useRef();
  const [items, setItems] = useState([]);
  const [show, setShow] = useState("link1");
  const [nameValue, setNameValue] = useState("");

  useEffect(() => {
    setNameValue(field.value)
  }, [field.value]);

  useEffect(() => {
    setItems(field.enum)
  }, [field]);

  const handleClick = async(link) => {
     await dispatch({ type: 'updateField', payload: field.id, value: link })
     if(field && link){
       var data = Object.assign({}, task);
       data.field = field.id;
       data.value = link.name;
       automation('columnChanges', data)
     }
     setNameValue(link)
     setOnEvent(true)
  }

  const handleShow = (id) => {
    setShow(id)
  }

  return (
    <div className="field-list d-flex align-items-center smart-form">
      <Portal icon={nameValue ? null : "list"} title={nameValue?.name} btnStyle={{backgroundColor: nameValue?.color, width: '100%', color: smartForm ?'#222' : '#fff', borderRadius:0, display: 'inline-block'}} disabled={!allowEdit} btnSize="sm" placement={placement??"left"} btnBetween>
          <Accordion activeKey={"link1"} onToggle={handleShow}>
              <Item className="py-2" eventKey="link1" title={<><Icon icon="chevron-back" /> {translate('apply')}</>}>
                {items && items.map((link, key) => (
                  <Button icon={nameValue?.color === link.color ? "check" : "list"} className="dropdown-item text-start my-1" key={key} onClick={() => handleClick(link)} title={link.name} style={{backgroundColor: link.color, color: 'white'}}/>
                ))}
              </Item>
              {canEdit &&
              <Item disabled={!canEdit} eventKey="link2" title={<><Icon icon="create" /> {translate('edit')}</>}>
                  <ItemStatus items={items} type={'editEnum'} field={field} dispatchField={dispatch} setOnEdit={setOnEdit}/>
              </Item>}
           </Accordion>
        </Portal>
    </div>
  )
};
