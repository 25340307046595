import React, { useState, useEffect, useReducer, useMemo } from 'react';
import {
  Link, useParams
} from "react-router-dom";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { Menu, Button, Dropdown } from '../../components/Uix';
import NewGroup from './NewGroup';
import { WorkflowsQuery, WorkspacesQuery, DashboardsQuery, UpdateWorkflow, UpdateFolder } from '../../services/GraphqlApi';
import { useQuery, useMutation } from "@apollo/client";
import GroupItem from './GroupItem';
import GroupLink from './GroupLink';
import sections from '../../data/Type';

const initialState = { works: [], newItems: [] };

function reducer(state, action) {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        works: action.payload,
        newItems: action.newItems
      };
    case 'workReorder':
      return {
        works: {
          ...state.works,
          [action.payload]: action.newItems,
        }
      };
    case "filter":
      return {
        ...state,
        works: action.payload
      };
    default:
      return state;
  }
}

export default function BaseWorkflow({ active_link, live, hashTag, history, badges, onEffect, full, setOnEffect, workspace_id }) {

  let { id } = useParams();

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');

  const types = [
    'all',
    'recent',
    'favorite'
  ]

  const [hide, setHide] = useState(false);
  const [origin, setOrigin] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspace, setWorkspace] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [items, setItems] = useState([]);
  const [section, setSection] = useState('');
  const [keyword, setKeyword] = useState("");
  const [workspaceId, setWorkspaceId] = useState(''); //workspace_id

  const [updateWorkflow] = useMutation(UpdateWorkflow);

  const { loading, refetch, data } = useQuery(WorkflowsQuery, {
    variables: { id: app_id, workspace_id: workspaceId, keyword, order: 'DESC' },
  });
  /*
  const folderData = useQuery(FoldersQuery, {
    variables: { id: app_id},
  });*/

  const dashboardData = useQuery(DashboardsQuery, {
    variables: { id: workspaceId },
  });

  const workspaceData = useQuery(WorkspacesQuery, {
    variables: { id: app_id },
  });

  useMemo(() => {
    if (data && data.workflows) {
      var rows = []
      var item = []
      var gr = []
      var fv = []
      data.workflows.map((wf, index) => {
        if (wf.folder_id) {
          rows.push(wf)
        }
        item.push(wf)
      })
      setWorkflows(rows)
      setItems(item)
      setOrigin(item)
      //Save for workflows
      localStorage.setItem('wfWorkflows', JSON.stringify(item))
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [keyword])

  useEffect(() => {
    setTimeout(function () {
      // setWorkspaceId(workspace_id)
      // let ws = workspaces.find(w => w.id === workspace_id);
      // setWorkspace(ws ?? [])
    }, 1000);
  }, [workspaces])

  useEffect(() => {
    if (section) {
      setItems(origin.filter(w => w.type === section))
    } else {
      setItems(origin)
    }
  }, [section])

  /*
  useMemo(() => {
    if(folderData.data && folderData.data.folders){
      setFolders(folderData.data.folders)
    }
  },[folderData.data])

  const folderClick = async(id, open) => {
    updateFolder({variables: {
      open: !open,
      id,
    }})
    folderData.refetch()
    setOnEffect(true)
  };*/

  useMemo(() => {
    if (workspaceData.data && workspaceData.data.workspaces) {
      setWorkspaces(workspaceData.data.workspaces)
    }
  }, [workspaceData.data])

  useMemo(() => {
    if (dashboardData.data && dashboardData.data.dashboards) {
      setDashboards(dashboardData.data.dashboards)
    }
  }, [dashboardData.data])

  const handleClick = (ws) => {
    setWorkspace(ws)
    setWorkspaceId(ws.id)
    setHide(true)
  }

  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result
    // dropped outside the list
    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    if (destination.droppableId === 'all-items') {
      updateWorkflow({ variables: { id: draggableId, index: destination.index } })
    }
    else {
      updateWorkflow({ variables: { id: draggableId, folder_id: destination.droppableId, index: destination.index } })
      //folderClick(destination.droppableId, true)
    }

    setOnEffect(true)
  }

  const itemContent = (wf, index) => {
    if (wf.privacy === 'public') {
      return (<GroupItem key={index} index={index} badges={badges} link={wf} active_link={active_link} />)
    } else {
      let found = (wf.users && wf.users.find(u => u.id === user_id));
      if (found) {
        return (<GroupItem key={index} index={index} badges={badges} link={wf} active_link={active_link} />)
      }
    }
  }

  const linkContent = (wf, index) => {
    if (wf.privacy === 'public') {
      return (<GroupLink key={index} index={index} badges={badges} link={wf} active_link={active_link} />)
    } else {
      let found = (wf.users && wf.users.find(u => u.id === user_id));
      if (found) {
        return (<GroupLink key={index} index={index} badges={badges} link={wf} active_link={active_link} />)
      }
    }
  }

  return (
    <div className="w-100">
      <div className={`position-relative ${full ? "d-flex" : ""}`}>
        <div>
          <Dropdown autoHide={hide} onToggle={() => setHide(false)} placement="left" title={<div className="d-flex justify-content-between align-items-center"><div className="text-start"><div className="fw-bold">{`${(workspace && workspace.name) ? workspace.name : translate('workspace')}`}</div></div>
            <div><Icon icon={'chevron-down'} color="#ccc" /></div></div>}>
            {workspaces && workspaces.map((ws, key) =>
              <Button key={key} onClick={() => handleClick(ws)} className={ws.id === workspace.id ? "text-primary d-block" : "text-dark d-block"}>{ws.name}
              </Button>
            )}
          </Dropdown>
          {/*<div className="input-group ms-2 d-flex justify-content-center align-items-center border-bottom">
            <div className="input-group-prepend">
              <Icon icon={'search'} color="#ccc" />
            </div>
            <input type="text"
              className="form-control input-great border border-light" placeholder={translate('search')}
              onChange={(e) =>
                setKeyword(e.target.value)
              } />
            {/* <Menu icon="option" autoHide={true}>
              {sections.map((item, key) => <div key={key} className="px-2">
                <Button className="d-inline-block text-start w-100" onClick={() => setSection(item.name)}>
                  <Icon icon={item.icon} />
                  <span className="ps-2 text-secondary">{item.name && translate(item.name)}</span>
                </Button>
              </div>
              )}
            </Menu>
          </div>*/}
          {/* {section && <Button className="text-secondary" onClick={() => setSection('')}>
            <span className="pe-2">{section && translate(section)}</span>
            <Icon icon={"remove"} />
          </Button>} */}
        </div>

        {/* <div className="px-2">
      <NewGroup user_id={user_id} app_id={app_id} setOnEffect={setOnEffect} history={history} hashTag={hashTag} live={live} workspace_id={workspaceId}/>
      </div> */}
      </div>
      <div>
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          {items.length > 0 && (<>
            <Droppable droppableId={'all-items'} type="column">
              {(provided, snapshot) => {
                return (
                  <div
                    ref={provided.innerRef}
                    style={{ minHeight: 5, paddingBottom: 0 }}
                  >
                    {items.map((workflow, index) => itemContent(workflow, index))}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable></>)}

          {dashboards && dashboards.map((dashboard, index) => {
            let cls = 'd-flex align-items-center rounded nav-link nav-dash p-2 position-relative';
            if (active_link.slice(6) === dashboard.id) cls += ' bg-light text-primary'
            let clsIcon = 'd-flex border rounded align-items-center justify-content-center text-center';
            if (active_link.slice(6) === dashboard.id) clsIcon += ' text-primary border-primary'

            return (
              <div className="nav-item">
                <Link to={`/w/ab/${dashboard.id}`} className={cls}>
                  <span className={clsIcon} style={{ width: 32, height: 32 }}><Icon icon={"bar-chart"} /></span>
                  <span className="ms-2">{dashboard.name}</span>
                </Link>
              </div>
            )
          })}

          {/*
            {folders.map((folder, key) => {
              return(
                <Droppable droppableId={folder.id} key={folder.id} type="column">
                  {(provided, snapshot) => {
                    var wfs = workflows[folder.id]
                    return(
                      <div
                          ref={provided.innerRef}
                          style={{minHeight: 25, paddingBottom: 10}}
                          >
                          <div style={{minHeight: 45}} className="px-2 d-flex justify-content-between align-items-center on-hover">
                            <Button onClick={() => folderClick(folder.id, folder.open)} className="fw-bold p-0 m-0">
                                <Icon icon={folder.open ? "chevron-down" : "chevron-forward"}/>
                                <span className="fw-bold ps-2">{folder.name}</span>
                            </Button>
                            <EditFolder title={folder.name} id={folder.id} setOnEffect={setOnEffect}/>
                          </div>
                          {folder.open ? <div>
                          {workflows && workflows.map((workflow, index) => {
                            if(workflow.folder_id === folder.id) {
                             return itemContent(workflow, index);
                             }
                           })}
                          {provided.placeholder}
                          </div>: <div></div>}
                       </div>
                   )}}
                </Droppable>
              )})}*/}

        </DragDropContext>
      </div>
    </div>
  );
}
