export default function EditorImage({contentState, block}) {

  const entity = contentState.getEntity(
    block.getEntityAt(0)
  );
  
  const {
    height,
    src,
    width,
  } = entity.getData();

  return (<img src={src} height={height} width={width} className="w-100"/>);

}
