import React, { useEffect, useState, useMemo } from "react";
import Icon from '../../components/Icon';
import { Modal, Toast, Menu, Button, Item } from '../../components/Uix';
import { translate } from '../../services/Language';
import { jsonParse, text_truncate, imageStorage } from '../../services/Function';
import { automation } from '../../store/automation';
import AddFile from '../Media/AddFile';
import Files from "../Media/Files";
import { STR_LIMITED } from '../../services/Defines';

let localUrl = process.env.REACT_APP_API_LOCAL
let proURL = process.env.REACT_APP_API_URL
let mode = process.env.NODE_ENV;
let SERVER_URL = mode === 'development' ? localUrl : proURL

export default function InputFile({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  task
}){

  //Update on each event is changed
  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  let { id, workflow_id } = task;

  var [lists, setLists] = useState([]);
  var [files, setFiles] = useState([]);
  const [hide, setHide] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const [file, setFile] = useState([]);
  const [onActivity, setOnActivity] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [fileAttach, setFileAttach] = useState([]);

  const handleFileShow = (file) => {
      setFile(file)
      setIsFile(true)
      setHide(true)
  };

  useEffect(() => {
    if(fileAttach.length > 0) {
      fileAttach.map(file => {
        handleConfirm(file)
      })
    }
  }, [fileAttach]);

  useEffect(() => {
    if(field.value) {
      setFiles(jsonParse(field.value))
    }
  }, [field.value]);

  useEffect(() => {
    if(onActivity) {
      setHide(true)
    }
  },[onActivity])

  const handleRemove = async(link) => {
     const filesUnique = files.filter(item => item.id !== link.id)
     await dispatch({ type: 'updateField', payload: field.id, value: JSON.stringify(filesUnique) })
     setFiles(filesUnique)
     setOnEvent(true)
  }

  const handleConfirm = async(file) => {
    if(!file) return;
    var newList = files.length ? files : []
    newList.push(file)
    const filesUnique = Array.from(new Set(newList.map(a => a.id)))
     .map(id => {
       return newList.find(a => a.id === id)
     })

     await dispatch({ type: 'updateField', payload: field.id, value: JSON.stringify(filesUnique) })
     setOnEvent(true)
     /*if(field && file){
       var data = Object.assign({}, task);
       data.field = field.id;
       data.value = file.name;
       automation('columnChanges', data)
     }*/
     setFiles(filesUnique)
  }

  //console.log(files)

  return (<>
    <div className="field-list d-flex justify-content-center align-items-start ps-1 position-relative smart-form  text-start">
    <div className={files && files[0] && files[0]?.length > 0 && ""}>
      <Menu icon="attach" btnStyle={{padding:0}} onToggle={() => setHide(false)} autoHide={hide} disabled={!allowEdit} className="d-block btn-sm" placement="right">
        <AddFile task_id={id} workflow_id={workflow_id} user_id={user_id} setOnEvent={setOnEvent} title={'attach'} setOnActivity={setOnActivity} setFileAttach={setFileAttach} />
      </Menu>
    </div>
    {files.length > 0 ?
     <Menu btnSize="sm" btnCls="btn-small p-0" title={files[0] && <div style={{height: 30}}><img className="img-fluid w-75 h-100" src={`${SERVER_URL}/storage${files[0]?.uri}`} /></div>} autoHide={isFile} disabled={!allowEdit} >
     {files.map( (file, key) => (
       <div key={key} className="border-bottom">
          <Files file={file} key={key} setOnDelete={setOnDelete} handleFileShow={handleFileShow}/>
          <Button icon="trash" onClick={() => handleRemove(file)}/>
        </div>
      ))}
    </Menu>:
    <span style={{height: 20, width: 50}}></span>
    }
  </div>
    <Modal show={isFile} onClose={() => setIsFile(false)} size="lg">
      <div className={"py-2"}>
        <a target="_blank" className="nav-link text-break" href={imageStorage(file.uri)}>
          <Icon icon="download" /> {file.name}
        </a>
      </div>
      <div>
        <img className="img-fluid w-100" src={imageStorage(file.uri)} />
      </div>
    </Modal>
    </>
  )
};
