import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function ChevronRight({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox="0 0 512 512">
  <rect x='32' y='144' width='400' height='224' rx='45.7' ry='45.7' fill='none' stroke='currentColor' strokeLinecap='square' strokeMiterlimit='10' strokeWidth='32'/><rect x='85.69' y='198.93' width='154.31' height='114.13' rx='4' ry='4' stroke='currentColor' strokeLinecap='square' strokeMiterlimit='10' strokeWidth='32'/><path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M480 218.67v74.66'/>
</svg>
 );
}
