import React, { useState, useEffect } from "react";
import { Form, Button, Item, Accordion } from '../../components/Uix';
import Icon from '../../components/Icon';
import fieldDefault from '../../data/Fields';
import formulas from '../../data/Formulas';
import { translate } from '../../services/Language';

export default function ItemFormula({
  dispatch,
  field,
  schema,
  type,
  setOnEdit
}){

    const [config, setConfig] = useState("");
    const [columns, setColumns] = useState([]);

    useEffect(() => {
      if(field && field.config) {
        setConfig(field.config)
      }
    },[field]);

    useEffect(() => {
      var ops = [
        {id: 'created_at', field: 'created_at', name: translate('created_at'), icon: 'time'},
        {id: 'user_name', field: 'user_name', name: translate('owner'), icon: 'person'}
      ];
      if(schema) {
        Object.keys(schema).map(key => {
          let row = schema[key];
          let icon = fieldDefault[row.field]?.icon;
          var field = {id: row.id, name: row.title, field: row.field, icon}
          if(row.field === 'integer' || row.field === 'text' || row.field === 'progress' || row.field === 'date' || row.field === 'mirror') {
            ops.push(field)
          }
        })
        setColumns(ops)
      }
    },[schema]);

    const handleUpdate = () => {
      dispatch({
         type,
         payload: type === "editConfig" ? field.id : "config",
         config: config
      })
      if(setOnEdit) setOnEdit(true)
    }

    const handleSelect = (select, formula) => {
      if(select){
        setConfig(config + `{${select}}`)
      }
      if(formula){
        setConfig(config + `${formula}()`)
      }
    }

      return (
        <>
        <div className="text-start flex-start">
          <div className="text-start w-100">
            <div className="d-flex justify-content-between align-items-center">
              <label>{translate('formula')}</label>
              <a target="_blank" href="https://support.microsoft.com/vi-vn/office/c%C3%B4ng-th%E1%BB%A9c-v%C3%A0-h%C3%A0m-294d9486-b332-48ed-b489-abe7d0f9eda9#ID0EBBD=More_functions"><Icon icon="alert-circle"/> {translate('help')}</a>
             </div>
            <textarea className="form-control" onChange={e => setConfig(e.target.value)} value={config}/>
          </div>
          <div className="overflow-auto scroll-style" style={{height: 160}}>
          <Accordion activeKey={"link1"} onToggle={handleUpdate}>
              <Item eventKey="link1" title={<><Icon icon="list" /> {translate('field')}</>}>
              {columns.map((item, key) => {
                return(
                <Button key={key} variant="link" className="text-secondary d-flex justify-content-between align-items-center w-100" onClick={() => handleSelect(item.id, null)}>
                  <span>
                    <Icon icon={item.icon}/> {item.name} {`{${item.id}}`}
                  </span>
                  <Icon icon={'add'}/>
                </Button>);
              })}
              </Item>
              <Item eventKey="link2" title={<><Icon icon="flask" /> {translate('formula')}</>}>
                {formulas.map((item, key) => {
                  return(
                  <Button key={key} variant="link" className="text-secondary d-flex justify-content-between align-items-center w-100" onClick={() => handleSelect(null, item.name)}>
                    <span>
                      {item.name}
                    </span>
                    <Icon icon={'add'}/>
                  </Button>);
                })}
              </Item>
           </Accordion>
          </div>
          <Button onClick={handleUpdate} icon="save" title={translate('update')} variant="light"/>
        </div>
    </>
   );
}
