import React, { useState, useEffect } from 'react';
import { Modal, Tab, Item } from '../../components/Uix';
import Icon from '../../components/Icon';
import { TaskQuery } from '../../services/GraphqlApi';
import { useLazyQuery } from "@apollo/client";
import { translate } from '../../services/Language';
import TaskItems from './TaskItems';
import EditDesc from './EditDesc';
import Column from '../../components/Column';
import TaskHeader from './TaskHeader';
import TaskComment from './TaskComment';
import CRMActivity from './CRMActivity';
import Subitem from '../../components/Stage/Subitem';
import Media from '../../components/Media';
import Addon from '../../components/Column/Addon';

export default function Content({ location, history, content, setContent, setUpdate, setOnTask, onTask, isPage, workflow, app, setOnUpdate, setForward }) {

  const [show, setShow] = useState(false);
  let [task, setTask] = useState([]);
  const handleShow = () => setShow(true);

  const [onEvent, setOnEvent] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [onActivity, setOnActivity] = useState(false);
  const [onField, setOnField] = useState(false);
  //const [formData, setFormData] = useState("");
  const [schema, setSchema] = useState([]);
  //const [statusValue, setStatusValue] = useState("");
  const [eventKey, setEventKey] = useState(content?.eventKey ?? "activities");

  const [subitem, setSubItem] = useState(false);
  const [linkitem, setLinkItem] = useState(false);
  const [isChecklist, setChecklist] = useState(false);
  const [isAttachment, setAttachment] = useState(false);
  const [allowAdd, setAllowAdd] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [subField, setSubField] = useState("");

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  let userName = localStorage.getItem('user_name');
  let hash = location?.hash
  let isInvite = hash?.slice(1, hash?.length)
  let lastScrollY = 0;
  var nav = React.useRef();
  const [dataQuery, { data }] = useLazyQuery(TaskQuery);

  let { group_id, type, columns, permission, users, stages, fields } = workflow;

  useEffect(() => {
    if (data && data.task) {
      setTask(data.task)
      if (data.task.workflow && data.task.workflow.schema) {
        setSchema(JSON.parse(data.task.workflow.schema))
      }
    }
  }, [data]);

  useEffect(() => {
    if (!!content.id) {
      setShow(true)
      dataQuery({ variables: { id: content.id } })
    }
  }, [content]);

  useEffect(() => {
    if (onDelete) {
      setShow(false)
      setOnTask(true)
      setOnDelete(false)
      setContent()
    }
  }, [onDelete]);

  useEffect(() => {
    if (onActivity) {
      setOnTask(true)
    }
  }, [onActivity]);

  useEffect(() => {
    if (onField) {
      setOnUpdate(true)
    }
  }, [onField]);

  //Get task
  useEffect(() => {
    if (schema) {
      Object.values(schema).map(f => {
        if (f.field === 'subitem') {
          setSubItem(true)
          setSubField(f.title)
        }
        if (f.field === 'link') {
          setLinkItem(true)
        }
        if (f.field === 'file') {
          setAttachment(true)
        }
        if (f.field === 'todo') {
          setChecklist(true)
        }
      })
    }
  }, [content, onEvent, onTask]);

  useEffect(() => {
    var isEdit, isAdd;
    if (permission == 'owner') {
      isEdit = true
    } else if (permission == 'anything') {
      isEdit = true
    } else if (permission == 'rows' && (task.users.find(u => u.id === user_id) || task.user_id === user_id)) {
      isEdit = true
    } else if (permission == 'content') {
      isEdit = true
    } else if (permission == 'addRows' || permission == 'viewRows') {
      isAdd = true
    } else if (permission == 'onlyview') {
      isEdit = false
    }
    setAllowEdit(isEdit)
    setAllowAdd(isAdd)
  }, [permission])

  const handleClose = () => {
    setContent()
    setShow(false)
    setOnTask(true)
    if (isPage) {
      history.push(`/w/a/${task.workflow_id}`)
    }
  };

  console.log(workflow.type)

  const headerContainer = (
    <>
      <TaskHeader task={task} setOnTask={setOnTask} setOnDelete={setOnDelete} onTask={onTask} setContent={setContent} setUpdate={setUpdate} allowEdit={allowEdit} history={history} isPage={isPage} setForward={setForward} />
    </>
  )

  const customTitle = (icon, title) => {
    return (<div className={"d-flex justify-content-start align-items-center"}>
      <Icon icon={icon} />
      <span className="ms-2 fw-bold">{translate(title)}</span>
    </div>)
  }

  const bodyContainer = (
    <div className="row">
      <div className="col-6">
      {(fields && task) &&
              <TaskItems task={task} stages={stages} fields={fields} setOnTask={setOnTask} setContent={setContent} allowEdit={allowEdit} onField={onField} users={users} schema={schema} collection={task.collection ? JSON.parse(task.collection) : []} />}

            {(task && schema && !task.parent_id) &&
              <Column workflow_id={task.workflow_id} setOnField={setOnField} onField={onField} task_id={task.id} stage_id={task.stage_id} schema={schema} allowEdit={allowEdit} setContent={setContent} task={task} users={users} stages={stages} />}

            {subitem && <div className="mt-4">
              <Subitem single workflow_id={task.workflow_id} stage_id={task.stage_id} next={null} prev={null} schema={columns} setSchema={[]} setOnTask={setOnTask} setOnEvent={setOnEvent} fields={fields} badges={[]} setUpdate={setUpdate} permission={permission} scrollX={0} setContent={setContent} group_id={group_id} subVisiable={false} stage_index={task.stage?.index} task_id={task.id} subField={subField} type={type} allowEdit={allowEdit} onTask={onTask} users={users} />
            </div>}

            {(task && schema && !task.parent_id) &&
            <Addon workflow_id={task.workflow_id} setOnField={setOnField} onField={onField} task_id={task.id} stage_id={task.stage_id} schema={schema} allowEdit={allowEdit} setContent={setContent} task={task} users={users} stages={stages}/>}

            <div className="form-group my-3">
              <label className="fw-bold text-secondary">{translate('description')}</label>
              <EditDesc task={task} allowEdit={allowEdit} />
            </div>
      </div>
      <div className="col-6">

      <Tab activeKey={eventKey} full>
          <Item title={customTitle('chatbubble', 'comment')} eventKey={'activities'}>
            {task && <TaskComment task={task} setOnTask={setOnTask} onTask={onTask} setContent={setContent} allowEdit={allowEdit} location={location} users={users} workflow_id={task.workflow_id} />}
          </Item>
          <Item title={customTitle('attach', 'files')} eventKey={'files'}>
             <Media task_id={task.id} workflow_id={task.workflow_id} users={users} task_name={task.name} location={location} />
         </Item>
         <Item title={customTitle('pulse', 'crmActivity')} eventKey={'CRMActivity'}>
            {task && <CRMActivity task={task} workflow_id={task.workflow_id} />}
          </Item>
        </Tab>
      </div>
    </div>
  )

  if (isPage) {
    return (
      <div>
        <div>
          {headerContainer}
        </div>
        {bodyContainer}
      </div>
    );
  } else {
    return (
      <>
        <Modal show={show} onClose={handleClose} size={"lg"} className="modal-80w">
          <div>
            <div>
              {headerContainer}
            </div>
            {bodyContainer}
          </div>
        </Modal>
      </>
    );
  }
}