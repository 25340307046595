import React, { useState } from 'react';
import { Button } from '../../components/Uix'
import Icon from '../../components/Icon';
import Upgrade from '../../components/Upgrade';
import { translate } from '../../services/Language';
import XLSX from 'xlsx';
let types = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods"
];
const SheetJSFT = types.map(function(x) { return "." + x; }).join(",");

export default function ExcelReader({setContent, live, label}) {

	let app_id = localStorage.getItem('app_id');
  var [file, setFile] = useState();

  const handleChange = (e) => {
   const files = e.target.files;
   if (files && files[0]) setFile(files[0]);
 };

 const handleFile = () => {
	  if(!file) return;
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      setContent(data);

    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    };
  }

  return (
    <>
      <div className="py-4">
        <input type="file" className="" id="file" accept={SheetJSFT} onChange={handleChange} />
        <div className="my-2 text-secondary">
          {translate('supportFileType')}: <span className="text-wrap">
            {types.map(function(x) { return "." + x; }).join(", ")}
          </span>
        </div>
				{live === false ? <Upgrade btn={true} text={translate('import')}/> :
        <Button variant="primary" onClick={handleFile} className="pb-1 mt-3">
          <Icon icon="upload" className="text-white"/>
          <label className="ms-2 text-white">{label??translate('import')}</label>
        </Button>}
      </div>
    </>
  );
}
