import Icon from "../Icon";

const Button = ({children, onClick, title, icon, className, variant, disabled, size, style, onMouseDown, onToggle, active}) => {
  return(
      <button disabled={disabled} onClick={onClick} onMouseDown={onMouseDown} onToggle={onToggle} className={`btn btn-${variant??"link"} btn-${size} rounded-2 fw-${active?"bold":""} ${className}`} style={style}>
         {children ? children : <> {icon && <Icon icon={icon} size={size}/>} {title} </>}
      </button>
)}

export default Button;
