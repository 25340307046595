import React, { useState, useEffect, useCallback } from "react";
import Icon from "../Icon";

const Dropdown = ({children, drop, show, autoHide, onToggle, title, icon, className, placement, bottom, disabled, size, btnClass, btnBetween}) => {

  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  let ref = React.useRef();

  const handleClose = () => {
    setOpen(false)
    if(onToggle) onToggle(open)
  }
  const handleShow = () => {
    setOpen(true)
    if(onToggle) onToggle(open)
  }

  useEffect(() => {
    setOpen(show)
  },[show])

  useEffect(() => {
    if(autoHide) setOpen(false)
  },[autoHide])

  let classes = 'dropdown-menu border-0 shadow-sm p-2 min-200 text-start overflow-auto scroll-style';
  if(open) classes += ' position-absolute';
  if(placement === "left"){
    classes += ` start-0`
  }else {
    classes += ` end-0`
  }
  if(bottom) classes += ` bottom-0`
  if(className) classes += ` ${className}`

  const escFunction = (event) => {
      if(event.keyCode === 27) {
         handleClose()
      }
    }

  useEffect(() => {
        // Bind the event listener
        document.addEventListener("keydown", escFunction, false);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("keydown", escFunction, false);
        };
    });

  useEffect(() => {
      // Bind the event listener
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        // Unbind the event listener on clean up //mousedown
        document.removeEventListener("click", handleClickOutside, true);
      };
  });

  const handleClickOutside = (event) => {
     if (ref.current && !ref.current.contains(event.target)) {
        handleClose()
     }
  }

  const refOffset = useCallback(
    (el) => {
      if(!el) return;
      let height = el.getBoundingClientRect().height;
      setOffset(height/2)
  },[open]);

  return(
  <div className={`dropdown ${btnBetween?"w-100":""}`} ref={ref}>
  <button onClick={handleShow} disabled={disabled} className={`btn btn-link btn-${size} ${btnClass}`} type="button">
    {icon && <Icon icon={icon}/>} {title}
  </button>
  {!!open && <ul className={classes} style={{zIndex: 9999, maxHeight: 440, marginBottom: bottom ? -offset : 0,  marginLeft: drop === 'left' ? 220: 0,  display: open ? 'block': 'none'}} ref={refOffset}>
    {children.length > 1 ? <> {children.map(child => {
      if(child){
        return (<li className="dropdown-item text-start">{child}</li>)
      }}
    )} </> : <li className="dropdown-item text-start">{children}</li>}
  </ul>}
</div>
)}

export default Dropdown;
