import React, { useState, useEffect, useRef } from "react";
import BoardLink from '../../../pages/workflow/BoardLink';
import BoardView from '../../../pages/workflow/BoardView';
import { Menu, Button } from "../../../components/Uix"
import { translate } from '../../../services/Language';

export default function EditorBoard({contentState, block, blockProps}) {
  const [view, setView] = useState('link');
  const entity = contentState.getEntity(
    block.getEntityAt(0)
  );
  let { board_id } = entity.getData();
  let ref = useRef();

  const handleClick = () => {
    blockProps.onStartEdit(block.getKey());
  }

  const handleClickOutside = (event) => {
     if (ref.current && !ref.current.contains(event.target)) {
        blockProps.onFinishEdit(block.getKey());
     }
  }

  useEffect(() => {
      // Bind the event listener
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside, true);
      };
  },[ref]);

  return (<div ref={ref} onClick={handleClick}><BoardLink onPrint={blockProps.onPrint} board_id={board_id}/></div>);
}
