import Picker, { SKIN_TONE_NEUTRAL }  from 'emoji-picker-react';
export default function EmojiControl({editorState,confirmEmoji}) {

  const onEmojiClick = (event, emojiObject) => {
      confirmEmoji(emojiObject.emoji);
  }

  return (<Picker onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_NEUTRAL}/>);

}
