import React, { useReducer, useEffect, useState } from "react";
import BaseForm from './BaseForm';
import AddColumn from './AddColumn';
import Icon from '../../components/Icon';
import fieldDefault from '../../data/Fields';
import { UpdateWorkflow, UpdateTask } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { fieldCollection, fieldSchema } from '../../services/Function';
import { reducer, resetData, initialState } from '../../reducers/Actions';

export default function Column({
  task_id,
  stage_id,
  workflow_id,
  task,
  schema,
  allowEdit,
  setContent,
  setOnTask,
  setOnField,
  onTask,
  setSubVisiable,
  parent_id,
  sheet,
  users,
  stages
}){

  const [state, dispatch] = useReducer(reducer, initialState);
  const { fields } = state;
  const [onEvent, setOnEvent] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [phases, setPhases] = useState([]);
  const user_id = localStorage.getItem('user_id');
  const [updateWorkflow] = useMutation(UpdateWorkflow);
  const [updateTask] = useMutation(UpdateTask);

  useEffect(() => {
     if(schema && task_id) {
        let isSubscribed = true
        if(isSubscribed) {
          let collection = task.collection ? JSON.parse(task.collection) : [];
          let { column } = resetData(schema, collection);
          dispatch({ type: "reset", payload: column})
        }
        return () => isSubscribed = false
      }
  }, [schema, task]);

    useEffect(() => {
      if(fields && onEvent === true) {
          let collection = fieldCollection(fields)
          updateTask({variables: {id: task_id, collection }})
          setOnEvent(false)
          //if(setOnTask) setOnTask(true)
      }

    }, [onEvent]);

    useEffect(() => {
      if(fields && onEdit === true) {
        var schema = fieldSchema(fields)
        updateWorkflow({variables: {id: workflow_id, schema }})
        setOnEdit(false)
        setOnField(true)
      }

    }, [onEdit]);

    useEffect(() => {
      let af = []
      if(stages){
        stages.map(stage => {
          if(stage.field){
            JSON.parse(stage.field).map(st => {
              af.push(st)
            })
          }
        })
        setPhases(af)
      }
    }, [stages]);

    function availableField(field) {
      let found = phases.find(p=>p.id === field.id)
      if(found){
        if(found.stage_id === stage_id){
          return true;
        }
      }else {
        return true;
      }
      return false;
    }

  if(sheet){
    return (
      <>
        {fields && fields.map((field, key) => {
          if(field.enabled === true){
            return(
            <div key={field.id} className="col nav-item p-0 text-center border-end">
               <BaseForm workflow_id={workflow_id}
               task={task}
               schema={schema}
               allowEdit={allowEdit}
               setOnEdit={setOnEdit}
               onEvent={onEvent}
               setContent={setContent}
               setSubVisiable={setSubVisiable}
               field={field}
               users={users}
               stages={stages}
               dispatch={dispatch}
               setOnEvent={setOnEvent}
               setOnTask={setOnTask}
               onTask={onTask}/>
            </div>
          )}
      })}
      </>
    )
  }else {
    return (
      <>
       <div>
       <div className="field-list position-relative">
          {fields && fields.map(field => {
              if(availableField(field) && field.enabled === true && field.name !== 'subitem' && field.name !== 'todolist'){
                return(
                <div key={field.id} className="w-100 nav-field my-2 d-flex justify-content-start align-items-center">
                  <div className={"w-50"}>
                    <Icon icon={field.icon} className={"text-secondary me-2"}/>
                    {field.title} <span className="text-danger">{field.required&&"*"}</span>
                  </div>
                  <div className={"w-50 h-36 bg-light"}>
                    <BaseForm workflow_id={workflow_id}
                    task={task}
                    schema={schema}
                    allowEdit={allowEdit}
                    setOnEdit={setOnEdit}
                    onEvent={onEvent}
                    setContent={setContent}
                    setSubVisiable={setSubVisiable}
                    field={field}
                    users={users}
                    stages={stages}
                    dispatch={dispatch}
                    setOnEvent={setOnEvent}/>
                  </div>
              </div>
           )}
         })}
      </div>
      {allowEdit && <div className="border-dotted rounded text-center"> <AddColumn workflow_id={workflow_id} dispatch={dispatch} setOnEdit={setOnEdit} fieldDefault={fieldDefault} state={state} isLabel={true}/>
      </div>}
      </div>
     </>
    )
  }
};
