import React, { useEffect, useState} from 'react';
import Icon from '../../components/Icon';
import { Spinner } from '../../components/Uix';
import { CommentSearchQuery, TaskQuery } from '../../services/GraphqlApi';
import { useLazyQuery } from "@apollo/client";
import moment from "moment";

export default function CommentSearch({
  content,
  setContent,
  setComments,
  keyword,
  current,
  ...props
}){

  let app_id = localStorage.getItem('app_id');

  const [commentSearch, { loading, refetch, data }] = useLazyQuery(CommentSearchQuery);
  const [dataQuery, dataTask] = useLazyQuery(TaskQuery);

  useEffect(() => {
    if(data && data.commentSearch) {
      setComments(data.commentSearch);
    }
  },[data])

  useEffect(() => {
    if(keyword){
      commentSearch({ variables: { app_id, keyword } })
    }
  },[keyword, current])

  useEffect(() => {
    if(dataTask && dataTask.data && dataTask.data.task) {
      setContent(dataTask.data.task);
    }
  },[dataTask])

  const handleClick = (id) => {
    dataQuery({ variables: { id } })
  }

  return (
    <>
      {loading && <div className="justify-content-center text-center"><Spinner animation="border" variant="success" /></div>}
      <ul className="nav flex-column">
        {content.map((link, key) => {
          if(link.task_id){
            return(
              <li className="nav-item w-100 text-start" key={key}>
                  <a onClick={() => handleClick(link.task_id)} href="#" className="nav-link d-flex justify-content-start align-items-center py-1 mt-1">
                    <Icon color="darkorchid" icon={"chatbubble"}/>
                    <div className="text-start ms-2">{link.description}</div>
                    <small className="text-secondary ms-2">{moment(Number(link.created_at)).format('lll')}</small>
                  </a>
              </li>
            )
          }
        })}
      </ul>
    </>
  );
}
