import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function ContrastOutline({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns='http://www.w3.org/2000/svg' width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox='0 0 512 512'>
    <circle cx='256' cy='256' r='208' fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32'/><path d='M256 464c-114.88 0-208-93.12-208-208S141.12 48 256 48z'/>
   </svg>
 );
}
