import React, {useState} from "react";
import { Button, Menu } from '../../components/Uix';
import {setNotification} from '../../services/Firebase';
import { translate } from '../../services/Language';
import { DeleteApp,
DeleteUser,
DeleteWorkflow,
DeleteStage,
DeleteTask,
DeleteComment,
DeleteTodo,
DeleteFile,
DeleteView,
DeleteFolder,
DeleteTemplate,
DeleteAutomation,
DeleteWidget,
DeleteWorkspace,
DeleteDashboard,
DeleteDoc,
DeleteEmail,
DeleteGroup } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";

export default function Confirm({
  type,
  id,
  workflow_id,
  title,
  placement,
  customIcon,
  setOnDelete,
  setOnSubTask,
  color,
  button,
  className,
  ...props
}){
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      let app_id = localStorage.getItem('app_id');

      const [deleteUser] = useMutation(DeleteUser);
      const [deleteApp] = useMutation(DeleteApp);
      const [deleteWorkflow] = useMutation(DeleteWorkflow);
      const [deleteStage] = useMutation(DeleteStage);
      const [deleteTask] = useMutation(DeleteTask);
      const [deleteTodo] = useMutation(DeleteTodo);
      const [deleteComment] = useMutation(DeleteComment);
      const [deleteFile] = useMutation(DeleteFile);
      const [deleteView] = useMutation(DeleteView);
      const [deleteTemplate] = useMutation(DeleteTemplate);
      const [deleteGroup] = useMutation(DeleteGroup);
      const [deleteWidget] = useMutation(DeleteWidget);
      const [deleteFolder] = useMutation(DeleteFolder);
      const [deleteAutomation] = useMutation(DeleteAutomation);
      const [deleteDoc] = useMutation(DeleteDoc);
      const [deleteEmail] = useMutation(DeleteEmail);
      const [deleteWorkspace] = useMutation(DeleteWorkspace);
      const [deleteDashboard] = useMutation(DeleteDashboard);

      const handleClick = async() => {
          switch (type) {
            case 'app':
              deleteApp({ variables: { id } })
              break;
            case 'user':
              deleteUser({ variables: { id } })
              break;
            case 'workflow':
              deleteWorkflow({ variables: { id } });
              setNotification([], title, id, 'removeWorkflow');
              break;
            case 'stage':
              deleteStage({ variables: { id } });
              setNotification([], title, id, 'removeStage');
              break;
            case 'task':
              deleteTask({ variables: { id } });
              setNotification([], title, id, 'remove');
              break;
            case 'todo':
              deleteTodo({ variables: { id } })
              break;
            case 'comment':
              deleteComment({ variables: { id } })
              break;
            case 'file':
              deleteFile({ variables: { id } })
              break;
            case 'view':
              deleteView({ variables: { id } })
              break;
            case 'folder':
              deleteFolder({ variables: { id } })
              break;
            case 'group':
              deleteGroup({ variables: { id } })
              break;
            case 'widget':
              deleteWidget({ variables: { id } })
              break;
            case 'template':
              deleteTemplate({ variables: { id } })
              break;
            case 'automation':
              deleteAutomation({ variables: { id } })
              break;
            case 'workspace':
              deleteWorkspace({ variables: { id } })
              break;
            case 'doc':
              deleteDoc({ variables: { id } })
              break;
            case 'email':
              deleteEmail({ variables: { id } })
              break;
            case 'dashboard':
              deleteDashboard({ variables: { id } })
              break;
            default:

          }

          setShow(false)
          setOnDelete(true)
          if(setOnSubTask) setOnSubTask(true)
      }

      return (
        <>
        <Menu title={button?translate('delete'):null} icon={customIcon??"trash"} className="p-0" onClose={handleClose} onToggle={handleShow} placement={placement} autoHide={!show}>
        <div className="w-100 p-2 h4">
             {translate('delete')} {title} <small>?</small>
         </div>
         <div className="p-2 text-dark">
           <p><label className={"fw-bold me-1"}>{title && translate(title)}</label>{translate('confirmDelete')}</p>
           <div className="d-flex">
             <Button variant="danger" size="sm" onClick={handleClick}>
               {translate('delete')}
              </Button>
              <Button size="sm" onClick={handleClose}>
                {translate('close')}
               </Button>
            </div>
          </div>
        </Menu>
        </>
      );
}
