import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
//import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDqO7bzt9PCzSEd4-4QxjzQGOi66DUsP2g",
  authDomain: "workplace-89.firebaseapp.com",
  databaseURL: "https://workplace-89.firebaseio.com",
  projectId: "workplace-89",
  storageBucket: "workplace-89.appspot.com",
  messagingSenderId: "910049251454",
  appId: "1:910049251454:web:3ccfc88f0ae438128fc82f",
  measurementId: "G-7PVMKCVNFM"
};
  // Initialize Firebase
let firebaseApp = firebase.initializeApp(firebaseConfig);
const db =  firebaseApp.firestore();
const storage =  firebaseApp.storage();
const realtime =  firebaseApp.database();
//const auth =  firebaseApp.auth();

export {storage, db, realtime};
