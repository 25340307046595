import axios from 'axios'

let localUrl = process.env.REACT_APP_API_LOCAL
let proURL = process.env.REACT_APP_API_URL
let mode = process.env.NODE_ENV;

// Request interceptor
axios.defaults.baseURL = mode === 'development' ? localUrl : proURL;
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.interceptors.request.use(request => {
  const token = localStorage.getItem('token_id')
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  const locale = 'vi'
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

export default axios;
