import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import { Spinner, Tooltip } from '../../components/Uix';
import TaskWorkflow from '../../components/Workflow/TaskWorkflow';
import { translate } from '../../services/Language';
import { WorkflowQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";
import { Menu, Button } from "../../components/Uix"
import Icon from '../../components/Icon';
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { realtime, db } from '../../services/FirebaseInit';
import types from '../../data/ViewShare';
import viewLists from '../../data/EditorView';

const Table = lazy(() => import("./Table"))
const Board = lazy(() => import("./Board"))
const StageView = lazy(() => import("../../components/Stage/StageView"))


const siteName = process.env.REACT_APP_SITENAME
const siteURL = process.env.REACT_APP_URL;

export default function BoardLink({location, history, board_id, onPrint}) {

  let { id, view } = useParams();
  let [viewId, setView] = useState('table');
  let user_id = localStorage.getItem('user_id');
  const [onEvent, setOnEvent] = useState(false);
  const [onTask, setOnTask] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [workflow, setWorkflow] = useState();
  const [permission, setPermission] = useState("onlyview");
  const [keyword, setKeyword] = useState("");
  const [filters, setFilters] = useState("");
  const [stages, setStages] = useState([]);
  const [notify, setNotify] = useState(false);
  const [count, setCount] = useState(true);
  const [badges, setBadges] = useState([]);
  const [users, setUsers] = useState([]);
  const [views, setViews] = useState([]);
  const [schema, setSchema] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fields, setFields] = useState([]);
  const [token, setToken] = useState('');
  const [workflow_id, setWorkflowId] = useState(board_id??id);

  const [update, setUpdate] = useState('');
  const [content, setContent] = useState('');

  const { loading, refetch, data } = useQuery(WorkflowQuery, {
    variables: { id: workflow_id },
  });

  useMemo(() => {
    function loadPageWorkflow(workflow) {
       //cleanup
       let data = Object.assign({}, workflow);
       delete data.schema;
       delete data.fields;
       delete data.views;
       delete data.tasks;
       delete data.stages;
       delete data.app;
       delete data.users;
       delete data.group;
       setWorkflow(data)
    }
    if(data && data.workflow){
      //permission
      loadPermissions(data.workflow.permissions, data.workflow.user_id)
      loadPageWorkflow(data.workflow)
      //Security view.
      if(data.workflow.schema) { setSchema(JSON.parse(data.workflow.schema)) }
      if(data.workflow.fields) { setFields(JSON.parse(data.workflow.fields)) }
      if(data.workflow.columns) { setColumns(JSON.parse(data.workflow.columns)) }
      if(data.workflow.users) { setUsers(data.workflow.users) }
      if(data.workflow.stages) setStages(data.workflow.stages)

      var links = []
      if(data.workflow.views) {
        data.workflow.views.map(view => {
          var link = types.filter(v => v.type == view.type).shift();
          if(link){
            var property = Object.assign(link, view)
            links.push(property)
          }
        })
        setViews(links)
      }
      if(data.workflow.name) document.title = `${siteName}: ${data.workflow.name}`;
    }
  },[data])

  function loadPermissions(permissions, userId) {
    var perms;
    var isEdit;
    if(permissions){
      perms = JSON.parse(permissions)
      if(userId === user_id){
          setPermission('owner')
          isEdit = true
      }else {
         setPermission(perms.name)
         if(perms.name === 'anything'){
           isEdit = true
         }else {
           isEdit = false
         }
      }
      setAllowEdit(isEdit)
    }else {
      //default
      setAllowEdit(true)
      setPermission('anything')
    }
  }

  var page;
  switch (viewId) {
    case `board`:
      page = <Board id={workflow_id} workflow={workflow} stages={stages} setStages={setStages} onEvent={onEvent} keyword={keyword} filters={filters} setOnEvent={setOnEvent} onTask={onTask} setOnTask={setOnTask} badges={badges} notify={notify} location={location} history={history} setUpdate={setUpdate} setContent={setContent} permission={permission} view={viewId} users={users}/>
      break;
    case 'table':
      page = <StageView users={users} stages={stages} fields={fields} schema={schema} id={workflow_id}/>
      break;
    default:
      page = <Table id={workflow_id} workflow={workflow} schema={schema} setSchema={setSchema} fields={fields} setFields={setFields} stages={stages} setStages={setStages} onEvent={onEvent} keyword={keyword} filters={filters} setOnEvent={setOnEvent} onTask={onTask} setOnTask={setOnTask} badges={badges} notify={notify} setUpdate={setUpdate} setContent={setContent} permission={permission} view={viewId} users={users} columns={columns} isBoardLink/>
  }

  return (
    <>
      <div className={!onPrint && "p-4"}>
        <Suspense fallback={<div className="d-flex justify-content-center align-items-center"><Spinner animation="border" variant="warning" /></div>}>
          {!onPrint &&<div className="w-100 position-relative">
          <div className="d-flex justify-content-between align-items-center border-bottom border-2 mb-3">
            <div className="w-50 d-flex justify-content-start align-items-center">
              <Icon icon={'search'}/>
              <input type="text"
               className={"form-control w-100 input-great border-bottom slideInRight search-hover"} placeholder={translate('search')}
               onChange={(e) =>
                 setKeyword(e.target.value)
               }/>
             </div>
             <div className="w-50 d-flex justify-content-end align-items-center">
               {board_id &&
               <Link target="_blank" to={`/board/${workflow_id}`} className={"btn btn-link"}>
                    <Icon size={'md'} icon="open"/> {translate('open')}
               </Link>}
               <Menu icon="grid" title={translate('views')} placement="right" customWidth={300}>
                 <div className="p-2">
                    {viewLists.map((view,key) => <Button className={`d-block w-100 text-start text-secondary small ${key < viewLists.length - 1 && "border-bottom"}`} key={key} onClick={() => setView(view.type)} size="sm" icon={view.icon} title={translate(view.title)}/>)}
                 </div>
               </Menu>
             </div>
           </div></div>}

          {workflow && page}

          {!onPrint && <>
          {(content || update) && <TaskWorkflow share={true} history={history} location={location} update={update} content={content} setUpdate={setUpdate} setOnTask={setOnTask} setContent={setContent}/>}</>}

        </Suspense>
        </div>
    </>
  )
}
