import React, { useEffect, useState } from "react";
import Icon from '../../components/Icon';
import { Button, Accordion, Portal, Item } from '../../components/Uix';
import { translate } from '../../services/Language';
import { automation } from '../../store/automation';
import { Link } from "react-router-dom";
import { jsonParse, text_truncate } from '../../services/Function';
import ItemSelect from './ItemSelect';
import { STR_LIMITED } from '../../services/Defines';

export default function InputSelect({
  dispatch,
  field,
  setOnEvent,
  setOnEdit,
  canEdit,
  allowEdit,
  placement,
  task,
  ...props
}){

  //Update on each event is changed
  let textInput = React.useRef();
  const [items, setItems] = useState([]);
  const [show, setShow] = useState("link1");
  const [nameValue, setNameValue] = useState("");

  useEffect(() => {
    setNameValue(field.value)
  }, [field.value]);

  useEffect(() => {
    setItems(field.enum)
  }, [field]);

  const handleClick = async(link) => {
     await dispatch({ type: 'updateField', payload: field.id, value: link })
     if(field && link){
       var data = Object.assign({}, task);
       data.field = field.id;
       data.value = link;
       automation('columnChanges', data)
     }
     setNameValue(link)
     setOnEvent(true)
  }

  const handleShow = (id) => {
    setShow(id)
  }

  return (
    <div className="field-list d-flex align-items-center smart-form">
      <Portal icon={"chevron-down"} title={nameValue && text_truncate(nameValue, STR_LIMITED - 2)} disabled={!allowEdit} btnSize="sm" placement={placement??"left"} btnStyle={{width: '100%'}} btnBetween>
          <Accordion activeKey={"link1"} onToggle={handleShow}>
              <Item className="py-2" eventKey="link1" title={<><Icon icon="chevron-back" /> {translate('apply')}</>}>
                {field.enum && field.enum.map((link, key) => (
                  <Button className="dropdown-item" key={key} onClick={() => handleClick(link)} title={translate(link)}/>
                ))}
              </Item>
              {canEdit &&
              <Item eventKey="link2" title={<><Icon icon="create" /> {translate('edit')}</>}>
                 <ItemSelect items={items} type={'editEnum'} field={field} dispatchField={dispatch} setOnEdit={setOnEdit}/>
              </Item>}
           </Accordion>
        </Portal>
    </div>
  )
};
