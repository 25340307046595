import { motion } from 'framer-motion'
const Cols = ({ children, col, className }) => {
  return(
    <div className={`row ${className}`}>
    {children.map(child => {
      return <motion.div className={`col-${col}`} animate="visible" transition={{ ease: "easeOut", duration: 2 }}>
      {child}
     </motion.div>;
    })}
  </div>
)}

export default Cols;
