import { gql } from '@apollo/client';

const AppsQuery = gql`
  query Apps{
   apps{
      id
      name
      phone
      team
      size
      created_at
      user_id
      expire
      live
   }
 }
`;

const AppQuery = gql`
  query App($id: String!){
   app(id: $id){
      id
      name
      phone
      team
      size
      created_at
      user_id
      expire
      live
      user{
        id
        name
        email
        photo
        device
      }
      users{
        id
        name
        email
        photo
      }
      groups{
        id
        name
      }
      workflows{
        id
        name
        privacy
        base
        schema
        users{
          id
          name
          photo
        }
      }
   }
 }
`;


const UserQuery = gql`
  query User($id: String!){
   user(id: $id){
     id
     name
     email
     password
     phone
     location
     photo
     title
     status
     anniversary
     birthday
     timezone
     device
     platform
     notify
     referral
     apps{
       id
       name
       phone
       team
       size
       created_at
       user_id
       expire
       live
     }
   }
 }
`;

const WorkflowsQuery = gql`
  query Workflows($id: String! $keyword: String $order: String $workspace_id: String){
   workflows(app_id: $id keyword: $keyword order: $order workspace_id: $workspace_id){
     id
     name
     description
     type
     color
     privacy
     app_id
     user_id
     folder_id
     workspace_id
     index
     schema
     fields
     columns
     permissions
     favorite
     shared
     users{
       id
       name
       photo
     }
     tasks{
       id
       name
     }
     stages{
       id
       name
     }
     app{
       users{
         id
         name
         email
         photo
         notify
         device
       }
     }
   }
 }
`;

const FavoritesQuery = gql`
  query Favorites($id: String!){
   favorites(app_id: $id){
     id
     name
     type
     privacy
     user_id
     workspace_id
     index
     favorite
     updated_at
     users{
       id
       name
     }
     app{
       users{
         id
         name
       }
     }
   }
 }
`;

const WorkflowQuery = gql`
  query Workflow($id: String!){
   workflow(id: $id){
     id
     name
     description
     type
     color
     privacy
     app_id
     user_id
     group_id
     workspace_id
     index
     schema
     columns
     fields
     permissions
     favorite
     shared
     app{
       id
       live
       name
       user{
         id
         name
       }
       groups{
         id
         name
       }
       users{
         id
         name
         email
         photo
         notify
         device
         groups{
           id
         }
       }
       workflows{
         id
         name
         schema
       }
     }
     group{
       id
       name
     }
     users{
       id
       name
       email
       photo
       device
       notify
     }
     stages{
       id
       name
       index
       open
       field
     }
     views{
       id
       name
       type
       pined
       default
       settings
     }
     automations{
       id
       name
       when
       when_value
       action
       action_value
       condition
       condition_value
     }
   }
 }
`;

const StagesQuery = gql`
  query Stages($id: String!){
   stages(workflow_id: $id){
     id
     name
     color
     field
     tasks{
       id
       name
       created_at
       collection
     }
   }
 }
`;

const TasksQuery = gql`
  query Tasks($id: String $keyword: String $user_id: String){
   tasks(workflow_id: $id user_id: $user_id keyword: $keyword){
     id
     name
     description
     start
     due_date
     created_at
     updated_at
     reminder
     index
     status
     parent_id
     user_id
     stage_id
     workflow_id
     is_private
     is_repeat
     is_template
     collection
     users{
       id
       name
       photo
     }
     comments{
       id
     }
     user{
       id
       name
       phone
     }
     stage{
       name
     }
     todos{
       id
       name
       json
     }
     connectLink{
       id
       name
     }
     connectTask{
       id
       name
     }
   }
 }
`;

const TaskSearchQuery = gql`
  query TaskSearch($app_id: String $keyword: String $limit: Int $offset: Int){
   taskSearch(app_id: $app_id keyword: $keyword limit: $limit offset: $offset){
     id
     name
     description
     start
     due_date
     created_at
     updated_at
     reminder
     index
     status
     parent_id
     user_id
     stage_id
     workflow_id
     is_private
     is_repeat
     is_template
     collection
     users{
       id
       name
       photo
     }
     comments{
       id
     }
     user{
       id
       name
       phone
     }
     stage{
       name
     }
     todos{
       id
       name
       json
     }
   }
 }
`;

const MyWorkQuery = gql`
  query MyWork($app_id: String! $keyword: String $user_id: String $from: String $to: String){
   mywork(app_id: $app_id user_id: $user_id keyword: $keyword from: $from to: $to){
     id
     name
     start
     due_date
     reminder
     created_at
     index
     status
     parent_id
     user_id
     stage_id
     workflow_id
     collection
     users{
       id
       name
       photo
     }
     todos{
       id
       name
       json
     }
     stage{
       id
       name
     }
     workflow{
       id
       name
       type
       privacy
     }
   }
 }
`;

const TaskQuery = gql`
  query Task($id: String!){
   task(id: $id){
     id
     name
     description
     start
     due_date
     created_at
     updated_at
     reminder
     index
     status
     parent_id
     user_id
     stage_id
     workflow_id
     is_private
     is_repeat
     is_template
     collection
     workflow{
       schema
     }
     users{
       id
       name
       photo
     }
     comments{
       id
     }
     user{
       id
       name
       phone
     }
     todos{
       id
       name
       json
     }
     connectLink{
       id
       name
     }
     connectTask{
       id
       name
     }
   }
 }
`;

const SubTasksQuery = gql`
  query SubTasks($id: String!){
   subtasks(parent_id: $id){
     id
     name
     description
     start
     due_date
     reminder
     index
     status
     parent_id
     user_id
     stage_id
     workflow_id
     is_private
     is_repeat
     is_template
     collection
     users{
       id
       name
       photo
     }
     comments{
       id
     }
   }
 }
`;

const CommentsQuery = gql`
  query Comments($id: String $task_id: String $order: String){
   comments(workflow_id: $id task_id: $task_id order: $order){
     id
     name
     description
     task_id
     user_id
     created_at
     updated_at
     thread_id
     workflow_id
     attach
     reaction
     pined
     type
     remind
     completed
   }
 }
`;

const ActivitiesQuery = gql`
  query Activities($id: String $task_id: String $order: String){
   activities(workflow_id: $id task_id: $task_id order: $order){
     id
     name
     description
     task_id
     user_id
     created_at
     updated_at
     thread_id
     workflow_id
     attach
     reaction
     pined
     type
     remind
     completed
   }
 }
`;

const CommentQuery = gql`
  query Comment($id: String!){
   comment(id: $id){
     id
     name
     description
     task_id
     user_id
     thread_id
     workflow_id
     attach
     reaction
     pined
     type
     remind
     completed
   }
 }
`;

const CommentSearchQuery = gql`
  query CommentSearch($app_id: String $keyword: String){
   commentSearch(app_id: $app_id keyword: $keyword){
     id
     name
     description
     task_id
     user_id
     thread_id
     workflow_id
     attach
     reaction
     pined
     completed
     type
     remind
     created_at
   }
 }
`;

const StreamsQuery = gql`
  query Streams($id: String $order: String){
   streams(workflow_id: $id order: $order){
     id
     name
     description
     task_id
     user_id
     created_at
     updated_at
     thread_id
     workflow_id
     attach
     reaction
     pined
     completed
   }
 }
`;

const ThreadsQuery = gql`
  query Threads($thread_id: ID!){
   threads(thread_id: $thread_id){
     id
     name
     description
     task_id
     user_id
     created_at
     updated_at
     thread_id
     workflow_id
     attach
     reaction
     pined
     completed
   }
 }
`;

const FoldersQuery = gql`
  query Folders($id: String!){
   folders(app_id: $id){
     id
     name
     open
     index
   }
 }
`;

const WorkspacesQuery = gql`
  query Workspaces($id: String!){
   workspaces(app_id: $id){
     id
     name
     privacy
     workflows{
       id
       name
       user_id
       privacy
       users{
         id
       }
     }
   }
 }
`;

const DashboardsQuery = gql`
  query Dashboards($id: String){
   dashboards(workspace_id: $id){
     id
     name
     privacy
   }
 }
`;

const FilesQuery = gql`
  query Files($id: String! $keyword: String, $task_id: String){
   files(workflow_id: $id keyword: $keyword task_id: $task_id){
     id
     name
     uri
     type
     note
     shares
     task_id
     user_id
   }
 }
`;

const FileSearchQuery = gql`
  query FileSearch($app_id: String $keyword: String){
   fileSearch(app_id: $app_id keyword: $keyword){
     id
     name
     uri
     type
     note
     shares
     task_id
     user_id
   }
 }
`;

const UserSearchQuery = gql`
  query UserSearch($keyword: String){
   userSearch(keyword: $keyword){
    id
    name
    email
    photo
    notify
    device
   }
 }
`;

const TodoQuery = gql`
    query Todo($id: ID!) {
      todo(id: $id) {
        id
        name
        json
      }
    }
 `;

const TodoSearchQuery = gql`
   query TodoSearch($app_id: String $keyword: String){
    todoSearch(app_id: $app_id keyword: $keyword){
      id
      name
      task_id
      created_at
    }
  }
 `;

const HistoriesQuery = gql`
  query Histories($task_id: String){
   histories(task_id: $task_id){
     id
     name
     created_at
   }
 }
`;

const AutomationsQuery = gql`
  query Automations($workflow_id: String!){
   automations(workflow_id: $workflow_id){
     id
     name
     when
     when_value
     action
     action_value
     condition
     condition_value
     user_id
   }
 }
`;

const WidgetsQuery = gql`
  query Widgets($workflow_id: String $dashboard_id: String){
   widgets(workflow_id: $workflow_id dashboard_id: $dashboard_id){
     id
     name
     type
     chart
     index
     config
     dashboard_id
   }
 }
`;

const TemplatesQuery = gql`
  query Templates($keyword: String, $language: String, $category: String){
   templates(keyword: $keyword, language: $language, category: $category){
     id
     name
     body
     media
     links
     integrate
     like
     used
     category
     language
     enable
     featured
     default
   }
 }
`;

const GroupQuery = gql`
     query Group($id: String!) {
       group(id: $id) {
         id
         name
         app_id
         app{
           id
           name
           live
           users{
             id
             name
             photo
           }
         }
         users{
           id
           name
           photo
         }
       }
     }
  `;

const WorkspaceQuery = gql`
      query Workspace($id: String!) {
        workspace(id: $id) {
          id
          name
          app_id
          user_id
          workflows{
            id
            name
            user_id
            folder_id
            privacy
            type
            favorite
            updated_at
            users{
              id
              name
            }
            tasks{
              id
            }
            app{
              users{
                id
              }
            }
          }
        }
      }
  `;

const DashboardQuery = gql`
        query Dashboard($id: String!) {
          dashboard(id: $id) {
            id
            name
            app_id
            user_id
            workspace_id
            board
            widgets{
              id
              name
            }
          }
        }
     `;

const UserEmailQuery = gql`
      query UserEmail($email: String!) {
        userEmail(email: $email) {
          id
          name
          email
          apps{
            id
          }
        }
      }
   `;

const UserLoginQuery = gql`
       query UserLogin($email: String! $password: String $token: String) {
         userLogin(email: $email password: $password token: $token) {
           id
           name
           email
           photo
           device
           apps{
             id
             name
           }
           token
           message
           isSocial
         }
       }
    `;

const InvitesQuery = gql`
        query Invites($app_id: String!) {
          invites(app_id: $app_id) {
            id
            name
            email
            app_id
            done
          }
        }
     `;

const InviteQuery = gql`
        query Invite($id: String!) {
          invite(id: $id) {
            id
            name
            email
            app_id
            done
          }
        }
     `;

const CollectionsQuery = gql`
       query Collections{
        collections{
          workflow_id
          task_id
          json
        }
      }
     `;

const EmailsQuery = gql`
       query Emails($workflow_id: String!){
        emails(workflow_id: $workflow_id){
          id
          name
          from
          list
          start
          app_id
          user_id
          workflow_id
          status
        }
      }
     `;

const EmailQuery = gql`
       query Email($id: String!){
        email(id: $id){
          id
          name
          body
          data
          from
          list
          start
          status
          app_id
          user_id
          workflow_id
        }
      }
     `;

const DocsQuery = gql`
       query Docs($workflow_id: String!){
        docs(workflow_id: $workflow_id){
          id
          name
          body
          data
          app_id
          user_id
          workflow_id
          workspace_id
          created_at
          status
        }
      }
     `;

const DocQuery = gql`
       query Doc($id: String!){
        doc(id: $id){
          id
          name
          body
          data
          status
          app_id
          user_id
          workflow_id
          workspace_id
        }
      }
     `;

const ViewsQuery = gql`
       query Views($app_id: String! $type: String){
        views(app_id: $app_id type: $type){
          id
          name
          type
          workflow_id
          workflow{
            id
            name
            privacy
            users{
              id
            }
          }
        }
      }
     `;

//Creates
const CreateUser = gql`
    mutation CreateUser(
      $name: String!
      $email: String
      $password: String
      $phone: String
      $location: String
      $photo: String
      $title: String
      $status: String
      $anniversary: String
      $birthday: String
      $timezone: String
      $device: String
      $platform: String
      $team: String
      $size: String
      $company: String
      $workspace: String
      $email_verified_at: String
      $notify: Boolean
      $referral: String
    ) {
      createUser(
        name: $name
        email: $email
        password: $password
        phone: $phone
        location: $location
        photo: $photo
        title: $title
        status: $status
        anniversary: $anniversary
        birthday: $birthday
        timezone: $timezone
        device: $device
        platform: $platform
        workspace: $workspace
        team: $team
        size: $size
        company: $company
        email_verified_at: $email_verified_at
        notify: $notify
        referral: $referral
      ) {
          id
          name
          email
          photo
          email
          token
          appId
          spaceId
          appName
      }
    }
 `;

const CreateApp = gql`
  mutation CreateApp(
    $name: String
    $phone: String
    $team: String
    $size: String
    $created_at: String
    $user_id: String
    $expire: String
    $live: Boolean
    $workspace: String
  ){
   createApp(
     name: $name
     phone: $phone
     team: $team
     size: $size
     created_at: $created_at
     user_id: $user_id
     expire: $expire
     live: $live
     workspace: $workspace
   ){
      id
      name
   }
 }
`;

const CreateWorkflow = gql`
  mutation CreateWorkflow(
    $name: String!
    $description: String
    $type: String
    $color: String
    $privacy: String
    $app_id: String
    $user_id: String
    $folder_id: String
    $group_id: String
    $workspace_id: String
    $index: Int
    $schema: String
    $fields: String
    $permissions: String
    $favorite: Boolean
    $shared: Boolean
    $view: String
    $stage_name: String
    $view_name: String
    $base: String
  ){
   createWorkflow(
     name: $name
     description: $description
     type: $type
     color: $color
     privacy: $privacy
     app_id: $app_id
     user_id: $user_id
     folder_id: $folder_id
     group_id: $group_id
     workspace_id: $workspace_id
     index: $index
     schema: $schema
     fields: $fields
     permissions: $permissions
     favorite: $favorite
     shared: $shared
     view: $view
     stage_name: $stage_name
     view_name: $view_name
     base: $base
   ){
     id
   }
 }
`;

const CreateStage = gql`
  mutation CreateStage(
    $name: String
    $type: String
    $index: Int
    $app_id: String
    $color: String
    $field: String
    $open: Boolean
    $workflow_id: String
  ){
   createStage(
     name: $name
     type: $type
     index: $index
     color: $color
     field: $field
     open: $open
     workflow_id: $workflow_id
     app_id: $app_id
   ){
     id
   }
 }
`;


const CreateTask = gql`
  mutation CreateTask(
    $name: String!
    $description: String
    $start: String
    $due_date: String
    $reminder: String
    $index: Int
    $status: String
    $parent_id: String
    $user_id: String
    $assign_id: String
    $stage_id: String
    $workflow_id: String
    $collection: String
    $todo_name: String
  ){
   createTask(
     name: $name
     description: $description
     start: $start
     due_date: $due_date
     reminder: $reminder
     index: $index
     status: $status
     parent_id: $parent_id
     user_id: $user_id
     assign_id: $assign_id
     stage_id: $stage_id
     workflow_id: $workflow_id
     collection: $collection
     todo_name: $todo_name
   ){
     id
     name
     index
     status
     parent_id
     user_id
     stage_id
     due_date
     created_at
     workflow_id
   }
 }
`;

const CreateTodo = gql`
  mutation CreateTodo(
    $name: String!
    $json: String
    $task_id: String
    $workflow_id: String
  ){
   createTodo(
     name: $name
     json: $json
     task_id: $task_id
     workflow_id: $workflow_id
   ){
     id
     name
   }
 }
`;

const CreateComment = gql`
  mutation CreateComment(
    $name: String!
    $description: String
    $task_id: String
    $user_id: String
    $thread_id: Int
    $workflow_id: String
    $attach: String
    $reaction: String
    $type: String
    $remind: String
    $pined: Boolean
    $completed: Boolean
  ){
   createComment(
     name: $name
     description: $description
     task_id: $task_id
     user_id: $user_id
     thread_id: $thread_id
     workflow_id: $workflow_id
     attach: $attach
     reaction: $reaction
     remind: $remind
     type: $type
     pined: $pined
     completed: $completed
   ){
     id
   }
 }
`;

const CreateFile = gql`
  mutation CreateFile(
    $name: String!
    $uri: String!
    $type: String
    $note: String
    $shares: String
    $task_id: String
    $user_id: String
){
   createFile(
     name: $name
     uri: $uri
     type: $type
     note: $note
     shares: $shares
     task_id: $task_id
     user_id: $user_id
 ){
     uri
   }
 }
`;

const CreateView = gql`
  mutation CreateView(
    $name: String!
    $type: String
    $default: Boolean
    $settings: String
    $pined: Boolean
    $workflow_id: String
  ){
   createView(
     name: $name
     type: $type
     default: $default
     settings: $settings
     pined: $pined
     workflow_id: $workflow_id
   ){
     id
   }
 }
`;

const CreateAutomation = gql`
  mutation CreateAutomation(
    $name: String!
    $when: String
    $when_value: String
    $action: String
    $action_value: String
    $condition: String
    $condition_value: String
    $active: Boolean
    $user_id: String
    $app_id: String
    $workflow_id: String
  ){
   createAutomation(
     name: $name
     when: $when
     when_value: $when_value
     action: $action
     action_value: $action_value
     condition: $condition
     condition_value: $condition_value
     active: $active
     user_id: $user_id
     app_id: $app_id
     workflow_id: $workflow_id
   ){
     id
   }
 }
`;

const CreateWidget = gql`
  mutation CreateWidget(
    $name: String!
    $type: String
    $chart: String
    $index: Int
    $config: String
    $workflow_id: String
    $dashboard_id: String
  ){
   createWidget(
     name: $name
     type: $type
     chart: $chart
     index: $index
     config: $config
     workflow_id: $workflow_id
     dashboard_id: $dashboard_id
   ){
     id
   }
 }
`;

const CreateHistory = gql`
  mutation CreateHistory(
    $name: String!
    $description: String
    $task_id: String
    $user_id: String
    $workflow_id: String
  ){
   createHistory(
     name: $name
     description: $description
     task_id: $task_id
     user_id: $user_id
     workflow_id: $workflow_id
   ){
     id
   }
 }
`;

const CreateExport = gql`
  mutation CreateExport(
    $only_field: Boolean
    $workflow_id: String
    $user_id: String
    $app_id: String
  ){
   createExport(
     only_field: $only_field
     workflow_id: $workflow_id
     user_id: $user_id
     app_id: $app_id
   ){
     id
   }
 }
`;

const CreateInvite = gql`
  mutation CreateInvite(
    $name: String!
    $email: String
    $app_id: String
    $done: Boolean
  ){
   createInvite(
     name: $name
     email: $email
     app_id: $app_id
     done: $done
   ){
     id
   }
 }
`;

const CreateFolder = gql`
  mutation CreateFolder($name: String! $index: Int $open: Boolean $app_id: String){
   createFolder(
     name: $name
     index: $index
     open: $open
     app_id: $app_id
   ){
     id
   }
 }
`;

const CreateTemplate = gql`
  mutation CreateTemplate(
    $name: String!
    $body: String
    $media: String
    $links: String
    $integrate: String
    $like: Int
    $used: Int
    $category: String
    $language: String
    $keyword: String
    $enable: Boolean
    $featured: Boolean
    $default: Boolean
  ){
   createTemplate(
     name: $name
     body: $body
     media: $media
     links: $links
     integrate: $integrate
     like: $like
     used: $used
     category: $category
     language: $language
     keyword: $keyword
     enable: $enable
     featured: $featured
     default: $default
   ){
     id
   }
 }
`;

const CreateGroup = gql`
     mutation CreateGroup($name: String! $app_id: String) {
       createGroup(name: $name app_id: $app_id) {
         id
       }
     }
  `;

const CreateWorkspace = gql`
      mutation CreateWorkspace($name: String! $app_id: String, $user_id: String, $privacy: String $icon: String) {
        createWorkspace(name: $name app_id: $app_id user_id: $user_id privacy: $privacy icon: $icon) {
          id
        }
      }
   `;

const CreateDashboard = gql`
       mutation CreateDashboard($name: String! $app_id: String, $user_id: String, $workspace_id: String, $privacy: String $type: String $board: String) {
         createDashboard(name: $name app_id: $app_id user_id: $user_id workspace_id: $workspace_id privacy: $privacy type: $type board: $board) {
           id
         }
       }
    `;

const CreateEmail = gql`
    mutation CreateEmail(
      $name: String!
      $body: String
      $from: String
      $status: String
      $data: String
      $list: String
      $start: String
      $user_id: String
      $app_id: String
      $workflow_id: String
    ){
     createEmail(
       name: $name
       body: $body
       from: $from
       data: $data
       list: $list
       start: $start
       status: $status
       user_id: $user_id
       app_id: $app_id
       workflow_id: $workflow_id
     ){
       id
       name
     }
   }
  `;


const CreateDoc = gql`
      mutation CreateDoc(
        $name: String!
        $body: String
        $status: String
        $data: String
        $user_id: String
        $app_id: String
        $workflow_id: String
        $workspace_id: String
      ){
       createDoc(
         name: $name
         body: $body
         data: $data
         status: $status
         user_id: $user_id
         app_id: $app_id
         workflow_id: $workflow_id
         workspace_id: $workspace_id
       ){
         id
         name
         workflow_id
       }
     }
    `;

const UserJoin = gql`
    mutation UserJoin(
      $name: String
      $email: String
      $password: String
      $app_id: String
      $app_name: String
      $existApp: Boolean
    ){
     userJoin(
       name: $name
       email: $email
       password: $password
       app_id: $app_id
       app_name: $app_name
       existApp: $existApp
     ){
        id
        name
        email
        token
        appName
        appId
        message
        device
        photo
     }
   }
  `;

const TaskImport = gql`
      mutation TaskImport(
        $stage_name: String
        $items: String
        $user_id: String
        $workflow_id: String
        $app_id: String
      ) {
        taskImport(
          stage_name: $stage_name
          items: $items
          app_id: $app_id
          user_id: $user_id
          workflow_id: $workflow_id
        )
      }
   `;


const TaskCopy = gql`
       mutation TaskCopy(
         $id: String
       ) {
         taskCopy(
           id: $id
         ){
           id
           name
         }
       }
    `;

const TaskClone = gql`
       mutation TaskClone(
         $id: String
         $name: String
         $privacy: String
         $app_id: String
         $user_id: String
         $workspace_id: String
         $connect: Boolean
         $task: Boolean
         $task_user: Boolean
         $user: Boolean
         $collection: Boolean
         $due_date: Boolean
       ) {
         taskClone(
           id: $id
           name: $name
           privacy: $privacy
           app_id: $app_id
           user_id: $user_id
           workspace_id: $workspace_id
           connect: $connect
           task: $task
           task_user: $task_user
           user: $user
           due_date: $due_date
           collection: $collection
         ){
           id
           name
         }
       }
    `;
const UserMigrate = gql`
        mutation UserMigrate(
          $name: String
          $email: String
          $password: String
          $phone: String
          $photo: String
          $platform: String
          $company: String
          $salt: String
          $tasks: String
          $last_signed: String
          $created_at: String
        ) {
          userMigrate(
            name: $name
            email: $email
            password: $password
            phone: $phone
            photo: $photo
            platform: $platform
            company: $company
            salt: $salt
            tasks: $tasks
            last_signed: $last_signed
            created_at: $created_at
          )
        }
     `;
const NoteMigrate = gql`
         mutation NoteMigrate(
           $name: String
           $note: String
           $user: String
           $app_id: String
         ) {
           noteMigrate(
             name: $name
             note: $note
             user: $user
             app_id: $app_id
           )
         }
      `;
//Updates
const UpdateUser = gql`
    mutation UpdateUser(
      $id: String!
      $name: String
      $email: String
      $password: String
      $phone: String
      $location: String
      $photo: String
      $title: String
      $status: String
      $anniversary: String
      $birthday: String
      $timezone: String
      $device: String
      $platform: String
      $email_verified_at: String
      $notify: Boolean
      $referral: String
    ) {
      updateUser(
        id: $id
        name: $name
        email: $email
        password: $password
        phone: $phone
        location: $location
        photo: $photo
        title: $title
        status: $status
        anniversary: $anniversary
        birthday: $birthday
        timezone: $timezone
        device: $device
        platform: $platform
        email_verified_at: $email_verified_at
        notify: $notify
        referral: $referral
      )
    }
 `;

const ChangePassword = gql`
     mutation ChangePassword(
       $id: String!
       $password: String!
     ) {
       changePassword(
         id: $id
         password: $password
       )
     }
  `;

const UpdateApp = gql`
  mutation UpdateApp(
    $id: String!
    $name: String
    $phone: String
    $team: String
    $size: String
    $created_at: String
    $user_id: String
    $expire: String
    $live: Boolean
  ){
   updateApp(
     id: $id
     name: $name
     phone: $phone
     team: $team
     size: $size
     created_at: $created_at
     user_id: $user_id
     expire: $expire
     live: $live
   )
 }
`;

const UpdateWorkflow = gql`
  mutation UpdateWorkflow(
    $id: String!
    $name: String
    $description: String
    $type: String
    $color: String
    $privacy: String
    $app_id: String
    $user_id: String
    $folder_id: String
    $group_id: String
    $workspace_id: String
    $index: Int
    $schema: String
    $columns: String
    $fields: String
    $permissions: String
    $favorite: Boolean
    $shared: Boolean
    $image: String
  ){
   updateWorkflow(
     id: $id
     name: $name
     description: $description
     type: $type
     color: $color
     privacy: $privacy
     app_id: $app_id
     user_id: $user_id
     folder_id: $folder_id
     group_id: $group_id
     workspace_id: $workspace_id
     index: $index
     schema: $schema
     columns: $columns
     fields: $fields
     permissions: $permissions
     favorite: $favorite
     shared: $shared
     image: $image
   )
 }
`;

const UpdateStage = gql`
  mutation UpdateStage(
    $id: String!
    $name: String
    $type: String
    $color: String
    $field: String
    $index: Int
    $open: Boolean
  ){
   updateStage(
     id: $id
     name: $name
     type: $type
     index: $index
     color: $color
     field: $field
     open: $open
   )
 }
`;

const UpdateTask = gql`
  mutation UpdateTask(
    $id: String!
    $name: String
    $description: String
    $start: String
    $due_date: String
    $reminder: String
    $index: Int
    $status: String
    $parent_id: String
    $user_id: String
    $stage_id: String
    $workflow_id: String
    $collection: String
  ){
   updateTask(
     id: $id
     name: $name
     description: $description
     start: $start
     due_date: $due_date
     reminder: $reminder
     index: $index
     status: $status
     parent_id: $parent_id
     user_id: $user_id
     stage_id: $stage_id
     workflow_id: $workflow_id
     collection: $collection
   )
 }
`;

const UpdateComment = gql`
  mutation UpdateComment(
    $id: ID!
    $name: String
    $description: String
    $task_id: String
    $user_id: String
    $updated_at: String
    $updated_at: String
    $thread_id: String
    $workflow_id: String
    $attach: String
    $reaction: String
    $type: String
    $remind: String
    $pined: Boolean
    $completed: Boolean
  ){
   updateComment(
     id: $id
     name: $name
     description: $description
     task_id: $task_id
     user_id: $user_id
     updated_at: $updated_at
     updated_at: $updated_at
     thread_id: $thread_id
     workflow_id: $workflow_id
     attach: $attach
     type: $type
     remind: $remind
     reaction: $reaction
     pined: $pined
     completed: $completed
   )
 }
`;

const UpdateFile = gql`
  mutation UpdateFile(
    $id: String!
    $name: String
    $uri: String
    $type: String
    $note: String
    $shares: String
    $task_id: String
    $user_id: String
){
   updateFile(
     id: $id
     name: $name
     uri: $uri
     type: $type
     note: $note
     shares: $shares
     task_id: $task_id
     user_id: $user_id
 )
 }
`;

const UpdateTodo = gql`
  mutation UpdateTodo(
    $id: ID!
    $name: String
    $json: String
  ){
   updateTodo(
     id: $id
     name: $name
     json: $json
   )
 }
`;

const UpdateEmail = gql`
  mutation UpdateEmail(
    $id: String!
    $name: String
    $body: String
    $from: String
    $list: String
    $start: String
    $data: String
    $status: String
  ){
   updateEmail(
     id: $id
     name: $name
     body: $body
     from: $from
     list: $list
     start: $start
     data: $data
     status: $status
   )
 }
`;


const UpdateDoc = gql`
  mutation UpdateDoc(
    $id: String!
    $name: String
    $body: String
    $data: String
    $status: String
  ){
   updateDoc(
     id: $id
     name: $name
     body: $body
     data: $data
     status: $status
   )
 }
`;

const UpdateView = gql`
  mutation UpdateView(
    $id: ID!
    $name: String
    $type: String
    $default: Boolean
    $settings: String
    $pined: Boolean
    $workflow_id: String
  ){
   updateView(
     id: $id
     name: $name
     type: $type
     default: $default
     settings: $settings
     pined: $pined
     workflow_id: $workflow_id
   )
 }
`;

const UpdateAutomation = gql`
  mutation UpdateAutomation(
    $id: Id!
    $name: String!
    $when: String
    $when_value: String
    $action: String
    $action_value: String
    $condition: String
    $condition_value: String
    $user_id: String
    $app_id: String
    $workflow_id: String
  ){
   updateAutomation(
     id: $id
     name: $name
     when: $when
     when_value: $when_value
     action: $action
     action_value: $action_value
     condition: $condition
     condition_value: $condition_value
     user_id: $user_id
     app_id: $app_id
     workflow_id: $workflow_id
   )
 }
`;

const UpdateWidget = gql`
  mutation UpdateWidget(
    $id: ID!
    $name: String!
    $type: String
    $chart: String
    $index: Int
    $config: String
    $workflow_id: String
    $dashboard_id: String
  ){
   updateWidget(
     id: $id
     name: $name
     type: $type
     chart: $chart
     index: $index
     config: $config
     dashboard_id: $dashboard_id
     workflow_id: $workflow_id
   )
 }
`;

const UpdateHistory = gql`
  mutation UpdateHistory(
    $id: ID!
    $name: String
    $description: String
    $task_id: String
    $user_id: String
    $workflow_id: String
  ){
   updateHistory(
     id: $id
     name: $name
     description: $description
     task_id: $task_id
     user_id: $user_id
     workflow_id: $workflow_id
   )
 }
`;

const UpdateInvite = gql`
  mutation UpdateInvite(
    $id: String!
    $name: String
    $email: String
    $app_id: String
    $done: Boolean
  ){
   updateInvite(
     id: $id
     name: $name
     email: $email
     app_id: $app_id
     done: $done
   )
 }
`;

const UpdateFolder = gql`
  mutation UpdateFolder($id: String! $name: String $open: Boolean $index: Int){
   updateFolder(
     id: $id
     name: $name
     open: $open
     index: $index
   )
 }
`;

const UpdateTemplate = gql`
  mutation UpdateTemplate(
    $id: ID!
    $name: String
    $body: String
    $media: String
    $links: String
    $integrate: String
    $like: Int
    $used: Int
    $category: String
    $language: String
    $keyword: String
    $enable: Boolean
    $featured: Boolean
    $default: Boolean
  ){
   updateTemplate(
     id: $id
     name: $name
     body: $body
     media: $media
     links: $links
     integrate: $integrate
     like: $like
     used: $used
     category: $category
     language: $language
     keyword: $keyword
     enable: $enable
     featured: $featured
     default: $default
   )
 }
`;

const UpdateGroup = gql`
     mutation UpdateGroup($id: String! $name: String $app_id: String) {
       updateGroup(id: $id name: $name app_id: $app_id)
     }
  `;


const UpdateWorkspace = gql`
      mutation UpdateWorkspace($id: String! $name: String $app_id: String $user_id: String $privacy: String $icon: String) {
        updateWorkspace(id: $id name: $name app_id: $app_id user_id: $user_id privacy: $privacy icon: $icon)
      }
   `;

const UpdateDashboard = gql`
       mutation UpdateDashboard($id: String! $name: String $app_id: String $user_id: String $workspace_id: String, $privacy: String $type: String $board: String) {
         updateDashboard(id: $id name: $name app_id: $app_id user_id: $user_id workspace_id: $workspace_id privacy: $privacy type: $type board: $board)
       }
    `;

//Assigns User
const AppUser = gql`
    mutation AppUser($app_id: String! $user_id: String! $role: String $type: String){
     appUser(
       app_id: $app_id
       user_id: $user_id
       role: $role
       type: $type
     )
   }
  `;

const TaskUser = gql`
    mutation TaskUser($task_id: String! $user_id: String!){
     taskUser(
       task_id: $task_id
       user_id: $user_id
     )
   }
  `;

const WorkflowUser = gql`
    mutation WorkflowUser($workflow_id: String! $user_id: String!){
     workflowUser(
       workflow_id: $workflow_id
       user_id: $user_id
     )
   }
  `;

const GroupUser = gql`
    mutation GroupUser($group_id: String! $user_id: String!){
     groupUser(
       group_id: $group_id
       user_id: $user_id
     )
   }
  `;

const SendEmail = gql`
mutation SendEmail($email: String, $subject: String, $message: String) {
  sendEmail(email: $email, subject: $subject, message: $message)
}
`;

const NotificationRegister = gql`
mutation NotificationRegister($userId: String, $appName: String) {
  notificationRegister(userid: $userid, appName: $appName) {
    userid
    appName
  }
}
`;

const NotificationEmail = gql`
mutation NotificationEmail(
  $user_id: String
  $email: String
  $send_name: String
  $user_name: String
  $name: String
  $type: String
  $model_id: String
) {
  notificationEmail(
    user_id: $user_id
    email: $email
    send_name: $send_name
    user_name: $user_name
    name: $name
    type: $type
    model_id: $model_id
  )
}
`;

const WorkspaceUser = gql`
    mutation WorkspaceUser($workspace_id: String! $user_id: String!){
     workspaceUser(
       workspace_id: $workspace_id
       user_id: $user_id
     )
   }
  `;

const TaskConnect = gql`
    mutation TaskConnect($task_id: String! $link_id: String! $field: String! $type: String){
     taskConnect(
       task_id: $task_id
       link_id: $link_id
       field: $field
       type: $type
     )
   }
  `;

//Deletes
const DeleteUser = gql`
      mutation DeleteUser($id: String!) {
        deleteUser(id: $id)
      }
   `;

const DeleteApp = gql`
    mutation DeleteApp($id: String!){
     deleteApp(id: $id)
   }
  `;

const DeleteWorkflow = gql`
    mutation DeleteWorkflow($id: String!){
     deleteWorkflow(id: $id)
   }
  `;

const DeleteStage = gql`
    mutation DeleteStage($id: String!){
     deleteStage(id: $id)
   }
  `;

const DeleteTask = gql`
    mutation DeleteTask($id: String!){
     deleteTask(id: $id)
   }
  `;

const DeleteTodo = gql`
    mutation DeleteTodo($id: ID!){
     deleteTodo(id: $id)
   }
  `;

const DeleteComment = gql`
    mutation DeleteComment($id: ID!){
     deleteComment(id: $id)
   }
  `;

const DeleteFile = gql`
    mutation DeleteFile($id: String!){
     deleteFile(id: $id)
   }
  `;

const DeleteCollection = gql`
    mutation DeleteCollection($task_id: String $workflow_id: String){
     deleteCollection(task_id: $task_id workflow_id: $workflow_id)
   }
  `;

const DeleteView = gql`
    mutation DeleteView($id: ID!){
     deleteView(id: $id)
   }
  `;

const DeleteAutomation = gql`
    mutation DeleteAutomation($id: ID!){
     deleteAutomation(id: $id)
   }
  `;

const DeleteWidget = gql`
    mutation DeleteWidget($id: ID!){
     deleteWidget(id: $id)
   }
  `;

const DeleteHistory = gql`
    mutation DeleteHistory($id: ID!){
     deleteHistory(id: $id)
   }
  `;

const DeleteInvite = gql`
    mutation DeleteInvite($id: String!){
     deleteInvite(id: $id)
   }
  `;

const DeleteFolder = gql`
    mutation DeleteFolder($id: ID!){
     deleteFolder(id: $id)
   }
  `;

const DeleteTemplate = gql`
    mutation DeleteTemplate($id: ID!){
     deleteTemplate(id: $id)
   }
  `;

const DeleteEmail = gql`
    mutation DeleteEmail($id: ID!){
     deleteEmail(id: $id)
   }
  `;

const DeleteDoc = gql`
    mutation DeleteDoc($id: ID!){
     deleteDoc(id: $id)
   }
  `;

const DeleteGroup = gql`
       mutation DeleteGroup($id: String!) {
         deleteGroup(id: $id)
       }
  `;

const DeleteWorkspace = gql`
      mutation DeleteWorkspace($id: String!) {
        deleteWorkspace(id: $id)
      }
 `;

const DeleteDashboard = gql`
     mutation DeleteDashboard($id: String!) {
       deleteDashboard(id: $id)
     }
`;

export {
  AppQuery,
  UserQuery,
  WorkflowsQuery,
  WorkflowQuery,
  StagesQuery,
  TasksQuery,
  WorkspacesQuery,
  DashboardsQuery,
  TaskQuery,
  MyWorkQuery,
  GroupQuery,
  WorkspaceQuery,
  DashboardQuery,
  FoldersQuery,
  FilesQuery,
  TodoQuery,
  HistoriesQuery,
  TemplatesQuery,
  SubTasksQuery,
  AutomationsQuery,
  ActivitiesQuery,
  CommentsQuery,
  CommentQuery,
  StreamsQuery,
  ThreadsQuery,
  UserEmailQuery,
  UserLoginQuery,
  InviteQuery,
  InvitesQuery,
  EmailQuery,
  EmailsQuery,
  DocQuery,
  DocsQuery,
  CollectionsQuery,
  TaskSearchQuery,
  FileSearchQuery,
  UserSearchQuery,
  CommentSearchQuery,
  TodoSearchQuery,
  FavoritesQuery,
  WidgetsQuery,
  ViewsQuery,
  UserJoin,
  CreateUser,
  CreateWorkflow,
  CreateStage,
  CreateTask,
  CreateComment,
  CreateTodo,
  CreateFile,
  CreateView,
  CreateAutomation,
  CreateFolder,
  CreateHistory,
  CreateGroup,
  CreateWorkspace,
  CreateDashboard,
  CreateWidget,
  CreateInvite,
  CreateEmail,
  CreateTemplate,
  CreateDoc,
  TaskImport,
  TaskClone,
  TaskCopy,
  UpdateApp,
  UpdateUser,
  UpdateWorkflow,
  UpdateStage,
  UpdateTask,
  UpdateComment,
  UpdateTodo,
  UpdateFile,
  UpdateView,
  UpdateAutomation,
  UpdateFolder,
  UpdateGroup,
  UpdateWorkspace,
  UpdateDashboard,
  UpdateTemplate,
  UpdateWidget,
  UpdateEmail,
  UpdateDoc,
  AppUser,
  TaskUser,
  GroupUser,
  NotificationEmail,
  NotificationRegister,
  SendEmail,
  TaskConnect,
  ChangePassword,
  UserMigrate,
  NoteMigrate,
  WorkflowUser,
  DeleteApp,
  DeleteUser,
  DeleteWorkflow,
  DeleteStage,
  DeleteTask,
  DeleteComment,
  DeleteTodo,
  DeleteFile,
  DeleteCollection,
  DeleteView,
  DeleteAutomation,
  DeleteWidget,
  DeleteFolder,
  DeleteEmail,
  DeleteDoc,
  DeleteTemplate,
  DeleteGroup,
  DeleteWorkspace,
  DeleteDashboard
}
