import React, {useEffect} from 'react';
import Icon from '../../components/Icon';
import { Spinner } from '../../components/Uix';
import moment from "moment";
import { TaskSearchQuery } from '../../services/GraphqlApi';
import { useLazyQuery } from "@apollo/client";

export default function TaskSearch({
  content,
  keyword,
  setContent,
  setTasks,
  current
}){
  let app_id = localStorage.getItem('app_id');

  const [taskSearch, { loading, refetch, data }] = useLazyQuery(TaskSearchQuery);

  useEffect(() => {
    if(data && data.taskSearch) {
      setTasks(data.taskSearch);
    }
  },[data])

  useEffect(() => {
    if(keyword){
      taskSearch({ variables: { app_id, keyword } })
    }
  },[keyword, current])

  return (
    <>
     {loading && <div className="justify-content-center text-center"><Spinner animation="border" variant="success" /></div>}
      <ul className="nav flex-column">
        {content.map((link, key) => (
          <li className="nav-item w-100 text-start" key={key}>
              <a onClick={() => setContent(link)} href="#" className="nav-link d-flex justify-content-start align-items-center py-1 mt-1">
                <Icon icon={'check'}/>
                <div className="text-start ms-2">{link.name}</div>
                {link.due_date ? <small className="text-danger ms-2">{moment(Number(link.due_date)).format('lll')}</small> :
                 <small className="text-secondary ms-2">{moment(Number(link.created_at)).format('lll')}</small>}
              </a>
          </li>
        ))}
      </ul>
    </>
  );
}
