import { Button } from '../../../components/Uix';
import Icon from '../../../components/Icon';
export default function ColorControl({editorState,onColorToggle}) {

  let COLORS = [
     {label: 'Red', style: 'red'},
     {label: 'Orange', style: 'orange'},
     {label: 'Yellow', style: 'yellow'},
     {label: 'Green', style: 'green'},
     {label: 'Blue', style: 'blue'},
     {label: 'Indigo', style: 'indigo'},
     {label: 'Violet', style: 'violet'}
   ];

  let currentStyle = editorState.getCurrentInlineStyle();

  return (<div className="color-control">
    {COLORS.map(type =>
      <Button
        size={24}
        key={type.label}
        active={currentStyle.has(type.style)}
        title={<><span className="pe-2" style={{color: type.style}}><Icon icon="text"/></span>{type.label}</>}
        onClick={() => onColorToggle(type.style)}
      />
    )}
  </div>);

}
