import React, { useEffect, useState } from "react";
import { translate } from '../../services/Language';
import { automation } from '../../store/automation';
import { v4 as uuidv4 } from 'uuid';
import copy from 'copy-to-clipboard';
import { Button } from '../../components/Uix';

export default function InputIID({
  dispatch,
  field,
  setOnEvent,
  task,
  ...props
}){

    const [nameValue, setNameValue] = useState("");

    useEffect(() => {

        let unique;
        if(field.value){
          unique = field.value
        }else {
          setTimeout(function () {
            unique = uuidv4().split('-')[0];
            dispatch({ type: 'updateField', payload: field.id, value: unique })
            //Automation
            if(field && unique){
              var data = Object.assign({}, task);
              data.field = field.id;
              data.value = unique;
              automation('columnChanges', data)
            }
            setOnEvent(true)
          }, 7000);
        }
        if(unique) setNameValue(unique)
    }, [field.value]);

  return (<>
    <Button variant="link" title={nameValue} onClick={() => {
      copy(nameValue)
    }} className="in-hover text-center"/>
  </>)
};
