import Image from './Image';
import Iframe from './Iframe';
import Board from './Board';
import Table from './Table';

export default function EditorBlock({contentState, block, blockProps}) {

  if(!block.getEntityAt(0)) return null;

  const entity = contentState.getEntity(
    block.getEntityAt(0)
  );

  const type = entity.getType();

  switch (type) {
    case 'IMAGE':
      return <Image contentState={contentState} block={block}/>
    case 'VIDEO':
      return <Iframe contentState={contentState} block={block}/>
    case 'BOARD':
      return <Board contentState={contentState} blockProps={blockProps} block={block}/>
    case 'TABLE':
      return <Table contentState={contentState} blockProps={blockProps} block={block}/>
    default:
      return null;
  }
}
