import React, { useState, useEffect } from 'react';
import { db } from '../../services/FirebaseInit';

export default function GetNotification({setBadge, badge, ...props}){

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');

  useEffect(() => {
    if(!!app_id && user_id) {
      db.collection('notifications').doc(app_id).collection(user_id).where('read', '==',  0).onSnapshot(snapshot => {
        const Items = [];
        snapshot.forEach(doc => {
          Items.push({
            id: doc.data().id,
          });
        });

        if(Items.length){
          setBadge(Items.length)
        }

      },(error) => {
        
      });
    }

    },[]);

  return (
    <>
    {badge > 0 && <span className="rounded-pill notify" style={{top:3, right:3}}>
      <i className="sr-only-0">{badge}</i></span>
    }</>
  );
}
