import React, { useState } from 'react';
import Icon from '../../components/Icon';
import { Button, Menu } from '../../components/Uix';
import { translate } from '../../services/Language';

export default function InputName({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  fullOption,
  ...props
}){

  const [optionShow, setOptionShow] = useState(false);
  const [nameValue, setNameValue] = useState(field.title);
  const [show, setShow] = useState(false);

  const handleChange = () => {
    if(typeof nameValue !== 'undefined'){
      dispatch({ type: 'editField', payload: field.id, title: nameValue })
      setOnEvent(true)
    }
  }

  return (
    <>
      {show === true ? <input
          type="text"
          value={nameValue}
          onBlur={handleChange}
          onMouseLeave={() => {handleChange(); setShow(false)}}
          className="form-control input-great py-1 px-2 border-hover"
          onChange={e => setNameValue(e.target.value)}
      /> :
      <div className={fullOption ? "d-flex align-items-center justify-content-start on-hover" : "d-flex align-items-center justify-content-center on-hover"}>
        <div className="py-1 px-2 text-truncate text-secondary fw-bold" onClick={(e) => allowEdit ? setShow(true) : e.preventDefault()}><Icon icon={field.id === "dueDate" ? "time":"person"}/> {nameValue}</div>
         <Menu icon={'chevron-down'} disabled={!allowEdit} btnStyle={{padding: 0}}>
          <div className="w-100 p-3">
            <div className="mt-2">
              <Button
                className={"p-2"}
                variant="link"
                title={field.show===true?translate('show'):translate('hidden')}
                icon={field.show===true?"eye":"eye-off"}
                onClick={() => {
                  dispatch({
                    type: "toggleField",
                    payload: field.id
                  })
                  setOnEvent(true)
                }}
              />
              <div className="border-bottom"/>
              <Button
                className={"p-2"}
                variant="link"
                title={field.required===true?translate('required'):translate('notRequired')}
                icon={"option"}
                onClick={() => {
                  dispatch({
                    type: "required",
                    payload: field.id
                  })
                  setOnEvent(true)
                }}
              />
            </div>
          </div>
        </Menu>
      </div>
      }
    </>
  );
}
