import React, { useState , useEffect, useMemo, useRef} from "react";
import { Menu, Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import Picker, { SKIN_TONE_NEUTRAL }  from 'emoji-picker-react';
import { MentionsInput, Mention } from 'react-mentions';
import { translate } from '../../services/Language';
import { sendNotification } from '../../services/Api';
import { CreateComment, FilesQuery } from '../../services/GraphqlApi';
import { useQuery, useMutation } from "@apollo/client";
import { notificationAdd } from '../../store/notification';
import AddFile from '../Media/AddFile';
import TodoAdd from '../Todo/TodoAdd';
import { db } from '../../services/FirebaseInit';
import moment from "moment"

let MIN_HEIGHT = 60;

export default function AddComment({
  simple,
  workflow_id,
  task_id,
  task_name,
  fixed,
  setOnActivity,
  setFileResult,
  setOnEvent,
  allowEdit,
  thread_id,
  location,
  teamUsers
}) {

  const [commentValue, setCommentValue] = useState("");
  const [nameValue, setNameValue] = useState();
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileAttach, setFileAttach] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [attach, setAttach] = useState([]);
  const [autoHide, setAutoHide] = useState(false);
  const [height, setHeight] = useState(MIN_HEIGHT);
  const [onTodo, setOnTodo] = useState();

  const app_id = localStorage.getItem('app_id');
  const user_id = localStorage.getItem('user_id');
  const userName = localStorage.getItem('user_name');
  const [createComment] = useMutation(CreateComment);

  const { data } = useQuery(FilesQuery, {
    variables: { id: workflow_id, keyword: "" },
  });

  useMemo(() => {
     if(data && data.files){
       let fileList = []
       data.files.map(f => {
         fileList.push({
           id: f.id,
           display: f.name
         })
       })
       setFiles(fileList)
     }
    },
    [data],
  );

  const ref = useRef()
  setTimeout(function(){
    if(thread_id) ref.current?.focus()
  }, 600)

  useEffect(() => {
        //get users
        let usersList = []
        if(teamUsers){
          teamUsers.map(r => {
            usersList.push({id: r.id, display: r.name})
          })
          setUsers(usersList)
        }
    },
    [teamUsers],
  );

  useEffect(() => {
        //get Tasks
          let tasks = JSON.parse(localStorage.getItem('wfTasks'))
          let taskList = []
          if(tasks) {
            tasks.map(r => {
              taskList.push({id: r.id, display: r.name})
            })
            setTasks(taskList)
         }
    },
    [],
  );

  const handleUpdate = async () => {
    if(commentValue !== '') {
      createComment( {variables: {
        name: userName,
        description: commentValue,
        attach: JSON.stringify(attach),
        thread_id: thread_id ? Number(thread_id): null,
        type: 'update',
        workflow_id,
        task_id,
        user_id
      }})

      //Add notify
      mentions.forEach( async({id, name}) => {
        //Add notify
        if(id != user_id){
          var user = teamUsers.find(u => u.id === id)
          if(user.notify === true) {
            sendNotification(user,task_name,'mentioned');
          }
          //End Notify

          let notify_id = moment().valueOf().toString();
          var notification = {
              id: task_id,
              name: task_name,
              user_id: id,
              user_name: name,
              email: user.email,
              send_name: userName,
              send_id: user_id,
              type: 'mention',
              read: 0,
              created_at: moment().valueOf()
            };
          db.collection('notifications').doc(app_id).collection(id).doc(notify_id).set(notification);

          if(user.notify === 1) {
            //Send email
            notificationAdd({
             id,
             app_id,
             task_id,
             user_name: name,
             send_name: userName,
             name: task_name,
             email: user.email,
             type: 'mention'
           });
         }
        }
      })

      setCommentValue("")
      setNameValue("")
      setOnActivity(true)
      setMentions([])
      setHeight(MIN_HEIGHT)
    }
  };

  const addMention = async (id, display) => {
    var obj = mentions
    var data = {
      id,
      name: display,
      type: 'user'
    }
    var list = []
    list.push(data)
    obj.forEach((value, key) => {
      if(value.id != id) {
        list.push(value)
      }
    })
    setMentions(list)

    var obj2 = attach
    var list2 = []
    obj2.forEach((value, key) => {
      if(value.id != id) {
        list2.push(value)
      }
    })
    setAttach(list2)
  }

  const addTask = async (id, display) => {
    var obj = attach
    var data = {
      id,
      name: display,
      type: 'task'
    }
    var list = []
    list.push(data)
    obj.forEach((value, key) => {
      if(value.id != id) {
        list.push(value)
      }
    })
    setAttach(list)
  }

  const addFile = async (id, display) => {
    var obj = attach
    var file = files.filter(f => f.id === id)
    var data = {
      id,
      name: display,
      uri: file[0].uri,
      type: file[0].type,
      user_id: file[0].user_id,
      created_at: file[0].created_at,
      updated_at: file[0].updated_at,
    }
    var list = []
    list.push(data)
    obj.forEach((value, key) => {
      if(value.id != id) {
        list.push(value)
      }
    })
    setAttach(list)
  }

  useEffect(() => {
     if(chosenEmoji){
       setCommentValue(commentValue + chosenEmoji.emoji)
     }
    },
    [chosenEmoji],
  );

  const onEmojiClick = (event, emojiObject) => {
      setChosenEmoji(emojiObject);
      setAutoHide(true)
  }

  const handleChange = (value) => {
    setCommentValue(value)
    setHeight(ref.current ? ref.current.scrollHeight: height)
  }

  useEffect(() => {
    if(fileAttach && fileAttach[0]){
      createComment( {variables: {
       name: userName,
       description: fileAttach[0].note ? fileAttach[0].note : fileAttach[0].name,
       attach: JSON.stringify(fileAttach),
       workflow_id,
       task_id,
       type: 'update',
       thread_id: thread_id ? Number(thread_id): null,
       user_id,
     }})

     setOnActivity(true)
     setFileAttach(false)
    }
  },[fileAttach])

  useEffect(() => {
    if(onTodo){
      let attach = []
      attach.push({
        id: onTodo.id,
        name: onTodo.name,
        type: 'todo'
      })
      createComment( {variables: {
       name: userName,
       description: onTodo.name,
       attach: JSON.stringify(attach),
       type: 'update',
       workflow_id,
       task_id,
       user_id,
     }})
     setOnActivity(true)
     setOnTodo()
    }
  },[onTodo])

  return (
    <>
     <div style={{minHeight: 60}} className="position-relative">
       <MentionsInput
        inputRef={ref}
        style={{minHeight: MIN_HEIGHT, height: height}}
        singleLine={false}
        placeholder={translate('comment') + ', @ # !'}
        className={thread_id ? "form-control w-100 mb-0 mentions" : "form-control w-100 mb-0 bg-great mentions"}
        value={commentValue}
        onChange={e => handleChange(e.target.value)}
        onKeyPress={(event) => {
         let { key, target, shiftKey, altKey, handleKeyPress } = event
         if(key === 'Enter' && target.value.length > 0){
           if(!shiftKey){
             handleUpdate()
           }
         }
       }}>
         <Mention
           trigger="@"
           data={users}
           appendSpaceOnAdd={true}
           onAdd={addMention}
           markup='<div>@__display__</div>'
           renderSuggestion={(suggestion, search, highlightedDisplay) => (
             <div className="list-item">{highlightedDisplay}</div>
           )}
         />
         <Mention
           trigger="#"
           data={tasks}
           onAdd={addTask}
           markup='<div>__display__</div>'
           renderSuggestion={(suggestion, search, highlightedDisplay) => (
             <div className="list-item">{highlightedDisplay}</div>
           )}
         />
         <Mention
           trigger="!"
           data={files}
           onAdd={addFile}
           markup='<div>__display__</div>'
           renderSuggestion={(suggestion, search, highlightedDisplay) => (
             <div className="list-item">{highlightedDisplay}</div>
           )}
         />
       </MentionsInput>
       <div className="position-absolute end-0 top-0 d-flex justify-content-end align-items-center">
       {!simple && <div className="d-flex justify-content-start align-items-center">
         <Menu btnSize="sm" icon={'happy'} placement="left" variant="link" onClose={() => {
           setTimeout(function(){
             if(thread_id) ref.current?.focus()
           }, 600)
         }} onToggle={setAutoHide}>
           <Picker onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_NEUTRAL}/>
         </Menu>
         <Button className="px-1" variant="link" onClick={() => {
           setCommentValue(commentValue + " @");
           setTimeout(() => {
             ref.current?.focus()
           }, 600);
         }}>
             <Icon icon={"person"} className={"text-secondary"}/>
          </Button>
          {!thread_id &&
          <Button className="px-1" variant="link" onClick={() => {
            setCommentValue(commentValue + " #");
            setTimeout(() => {
              ref.current?.focus()
            }, 600);
          }}>
              <Icon icon={"hash"} className={"text-secondary"}/>
           </Button>}

           <Button className="px-1" variant="link" onClick={() => {
             setCommentValue(commentValue + " !");
             setTimeout(() => {
               ref.current?.focus()
             }, 600);
           }}>
               <Icon icon={"attach"} className={"text-secondary"}/>
          </Button>

          <Menu btnSize="sm" icon={'upload'} onToggle={setOnActivity}>
            <AddFile task_id={task_id} workflow_id={workflow_id} user_id={user_id} setOnEvent={setOnEvent} title={task_name} setFileResult={setFileResult} setOnActivity={setOnActivity} setFileAttach={setFileAttach} location={location} users={teamUsers}/>
          </Menu>
          {(allowEdit && !thread_id) &&
            <Menu btnSize="sm" icon={'check-circle'}>
              <TodoAdd setOnTodo={setOnTodo} setOnEvent={setOnEvent} task_id={task_id} workflow_id={workflow_id}/>
            </Menu>
          }
          </div>}
          <Button variant="link" size={'lg'} className={commentValue?"text-primary":"text-secondary"} onClick={() => commentValue ? handleUpdate() : null}>
            <Icon icon="paper-plane" size={'lg'}/>
          </Button>
        </div>
     </div>
    </>
  );
}
