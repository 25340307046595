import React, { useState, useEffect, useReducer } from 'react';
import { Form, Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';

 const initialState = { items: [], nextitemId: 0, newTitle: ""};

 function reducer(state, action) {
   switch (action.type) {
     case "reset":
       return {
         ...state,
         items: action.payload,
         nextitemId: action.nextitemId
       };
     case "add":
       if(state.newTitle.length > 0) {
         return {
           items: [
             ...state.items,
             {
               id: state.nextitemId,
               item: state.newTitle,
             }
           ],
           nextitemId: state.items.length + 1,
           newTitle: "",
         }
       }
    case 'editItem':
           return {
             ...state,
             items: state.items.map(item => {
               if(item.id === action.payload) {
                 return {
                   ...item,
                   item: action.title
                 }
               } else {
                 return item
               }
            })
     };
     case "remove":
       return {
         ...state,
         items: state.items.filter(item => item.id !== action.payload)
       };
     case "updateTitle":
       return {
         ...state,
         newTitle: action.payload,
       };
     default:
       return state;
   }
 }

export default function MultiItem({
  value,
  onTextChange,
  label,
  type,
  uppercase,
  ...props
}) {

  const [invalid, setInvalid] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { items, newTitle } = state;

  useEffect(() => {
    if(value.length){
      dispatch({ type: "reset", payload: value, nextitemId: value.length + 1})
    }
  },[value])

  useEffect(() => {
    onTextChange(items)
  },[items])

  let textInput = React.useRef();

  return (
    <>
      <div className="p-2 border my-2">
      {label  &&
        <label className={uppercase? "text-uppercase text-dark" : "text-dark"}>{translate(label)}:</label>}
        {items && items.map((item, key) => (
          <div key={key} className="w-100 d-flex justify-content-between align-items-center my-1">
            <Form.Input
              onTextChange={(value) => dispatch({ type: 'editItem', payload: item.id, title: value })}
              value={item.item}
              className={"form-control border-bottom Form.Input-great"}
            />
            <Button variant="link" size="sm" className="text-start ms-2 ps-0 text-secondary"
            onClick={() => dispatch({ type: "remove", payload: item.id })}>
              <Icon size="sm" icon="trash"/>
            </Button>
          </div>
        ))}
        <div className="w-100 d-flex justify-content-between align-items-center my-1">
          <Form.Input
            type="text"
            value={newTitle}
            style={{color: invalid ? 'red' : 'gray'}}
            className="border-0 border-bottom"
            onTextChange={(value) =>{
              dispatch({
                type: "updateTitle",
                payload: value,
              })
            }}
          />
          <Button variant="link" size="sm" className="text-start ms-2 ps-0 text-secondary"
          onClick={() => {
            if(newTitle){
              dispatch({ type: "add" })
              setInvalid(false)
            }else {
              setInvalid(true)
            }
          }}>
            <Icon size="sm" icon="add-outline"/>
          </Button>
        </div>
        </div>
    </>
  );
}
