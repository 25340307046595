export default function EditorFrame({contentState, block}) {

  const entity = contentState.getEntity(
    block.getEntityAt(0)
  );
  const {
    height,
    src,
    width,
  } = entity.getData();

  return (<iframe height={height??600} width={width??"100%"} src={src} frameBorder="0" allowFullScreen />);

}
