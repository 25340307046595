import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function TodayOutline({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox="0 0 512 512">
<rect fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32' x='48' y='80' width='416' height='384' rx='48'/><path fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32' strokeLinecap='round' d='M128 48v32M384 48v32'/><rect fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32' strokeLinecap='round' x='112' y='224' width='96' height='96' rx='13'/><path fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32' strokeLinecap='round' d='M464 160H48'/>
</svg>
 );
}
