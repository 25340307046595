import React, { useState, useEffect } from 'react';
import Icon from '../../components/Icon';
import { Button } from '../../components/Uix';
import { UpdateTask } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import User from '../../components/User';

export default function EditTask({
  task,
  workflow_id,
  group_id,
  inline,
  badge,
  color,
  setUpdate,
  setContent,
  setOnTask,
  allowEdit,
  single,
  subitem,
  taskDispatch,
  users
}) {

  let textInput = React.useRef();
  const [isEdit, setIsEdit] = useState(false);
  const [nameValue, setNameValue] = useState(task.name);
  let user_id = localStorage.getItem('user_id');
  const [updateTask] = useMutation(UpdateTask);

  const handleUpdate = () => {
    updateTask({ variables: { id: task.id, name: nameValue } })
    taskDispatch({ type: "updateTitle", payload: task.id, title: nameValue })
    setIsEdit(false)
  }

  const handleClose = () => {
    setIsEdit(false)
  }

  const handleContent = () => {
    if (single || subitem) {
      setContent(task)
    } else {
      if (inline) {
        //Fix shared
        if (user_id) {
          setUpdate(task)
        } else {
          setContent(task)
        }
      } else {
        setContent(task)
      }
    }
  }

  var todos = []
  var checklist = []
  if (task.todo) {
    task.todo.map(todo => {
      var items = JSON.parse(todo.json)
      if (items) {
        items.map(item => {
          todos.push(item)
        })
      }
    })
  }

  checklist = todos.filter(item => item.done === true);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleClickOutside = (event) => {
    if (textInput.current && !textInput.current.contains(event.target)) {
      handleClose()
      handleUpdate()
    }
  }

  return (
    <>
      <div className={`${inline && `item-inline`} d-flex justify-content-between align-items-center can-edit on-hover position-relative`}
        onDoubleClick={(e) => allowEdit ? setIsEdit(true) : e.preventDefault()}>

        {isEdit ? <> <input
          ref={textInput}
          type="text" placeholder="Task, Plan, Deal"
          value={nameValue}
          onMouseOver={() => {
            setTimeout(function () {
              handleClose()
            }, 9000);
          }}
          onBlur={handleUpdate}
          className="form-control border-0 border-hover fw-bold p-1"
          onChange={e => setNameValue(e.target.value)}
          style={{ maxWidth: 220 }}
        /> <Button variant="link" size={'sm'} icon="save" onClick={handleUpdate} /> </> : <div className="text-start d-flex justify-content-start align-items-center">
          <a className={`nav-link ${task.status === 'done' ? `text-secondary` : `text-black`} ${!inline && `py-2`} text-truncate d-inline-block p-0 item-name`} onClick={handleContent}>{nameValue} {badge && <span className="rounded-pill badge-dot shadow" />}</a>
          {allowEdit && !inline &&
          <Button variant="link" size={'sm'} className="text-dark-70 p-0 m-0 btn-edit ms-1" onClick={() => {
            setIsEdit(true)
            setTimeout(() => {
              textInput.current?.focus()
            }, 60);
          }}>
            <Icon icon="create" />
          </Button>}
        </div>
        }

        {inline ?
          <>{user_id ?
            <Button variant="link" size={'sm'} className={"p-0 mx-1 ms-0 pe1"} onClick={() => {
              let content = Object.assign({}, task)
              content.eventKey = "activities";
              if (subitem) {
                setContent(content)
              } else {
                setUpdate(content)
              }
            }}>
              <Icon icon={"chatbubble"} color={(task.comments && task.comments.length > 0) ? "#007bff" : "#ccc"} />
              {(task.comments && task.comments.length > 0) && <span className="small rounded-pill notify bg-primary text-white">{task.comments.length}</span>}
            </Button> : ''} </> :
          <User task_id={task.id} workflow_id={workflow_id} users={users} members={task.users} fixed={true} task_name={task.name} setUserEvent={setOnTask} allowEdit={allowEdit} group_id={group_id} />}
        {allowEdit && inline && !isEdit &&
          <Button variant="link" size={'sm'} className="text-dark-70 p-0 m-0 btn-edit ms-1" onClick={() => {
            setIsEdit(true)
            setTimeout(() => {
              textInput.current?.focus()
            }, 60);
          }}>
            <Icon icon="create" />
          </Button>}
      </div>
    </>
  );
}
