import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Accordion, Item, Spinner } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import categories from '../../data/Category';
import { jsonParse } from '../../services/Function';
import Template from '../../components/Template';
import { TemplatesQuery, UpdateTemplate } from '../../services/GraphqlApi';
import { useLazyQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";

export default function GroupTemplate({isTemplate, setIsTemplate, history, setIds, setGroupValue, isDarkMode, live, defaultId, setIsVisible, ...props}){

  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  const [show, setShow] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [category, setCategory] = useState("all");
  const [select, setSelect] = useState();
  const [useItem, setUse] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [keyword, setKeyword] = useState("");
  const language = translate("locale");

  const [dataQuery, { data }] = useLazyQuery(TemplatesQuery);
  const [updateTemplate] = useMutation(UpdateTemplate);

  useMemo(() => {
    if(data && data.templates){
      let featured = []
      data.templates.map(template => {
        if(template.featured){
          featured.push(template)
        }
      })
      if(!keyword && category === 'all'){
        setOrigin(data.templates)
        setTemplates(featured)
      }else {
        setTemplates(data.templates)
      }
      setSelect(data.templates[0])

    }
  },[data])

  const handleClose = () => {
    setShow(false);
    setIsTemplate(false)
    setUse([])
    if(setIsVisible){
      setIsVisible(false)
    }
  }
  const handleShow = () => setShow(true);

  useEffect(() => {
    if(show) {
      if(category && category != "all"){
        dataQuery({
          variables: { keyword, language, category },
        })
      }else {
        dataQuery({
          variables: { keyword, language },
        })
      }
      if(keyword){
        setCategory("all")
      }
    }
  }, [show, keyword, category]);

  useEffect(() => {
      if(isTemplate){
        setShow(true)
      }
    },
    [isTemplate],
  );

  useEffect(() => {
      if(defaultId){
        setTimeout(function () {
          setSelect(templates.find(t=>t.id===defaultId))
        }, 1000);
      }
    },
    [defaultId],
  );

  useEffect(() => {
    if(useItem.links){
      let ids = jsonParse(useItem.links)
      if(ids.length){
        setIds(ids)
        setGroupValue(useItem.name)
        setShow(false);
        setIsTemplate(false)
        setUse([])
        //Update Template count
        updateTemplate({variables: {id: useItem.id, used: useItem.used+1}})
      }
    }
    },[useItem]);

  const handleClick = (item) => {
    if(item === 'all'){
      setCategory(null)
    }else {
      setCategory(item.id)
    }
  }

  const customTitle = (name, index) => {
    if(index ===0) {
      return keyword ? translate('results') : translate(name);
    }else {
      return translate(name)
    }
  }

  return (
    <>
      <Modal show={show} onClose={handleClose} size={"xl"} title={translate('templateCenter')} className={isDarkMode ? "dark-mode" : ""}>
        <Item>
          <div className="my-auto">
            <div className="row overflow-y">
              <div className="col-md-3">
              <div className="input-group mb-2 d-flex justify-content-center align-items-center border-bottom">
                 <div className="input-group-prepend">
                   <Icon icon={'search'} color="#ccc"/>
                 </div>
                 <input type="text"
                  className="form-control input-great border-bottom border-0" placeholder={translate('search')}
                  onChange={(e) =>
                    setKeyword(e.target.value)
                  }/>
               </div>
               <div>
                 {keyword ? `${translate('keyword')}: ${keyword}` : ''}
               </div>
               <Accordion activeKey={category}>
                 {categories.map((item, key) => {
                   var found = origin.find(t => t.category == item.id);
                   if(found || (key === 0 && item.id === "all")){
                     return(
                      <Item key={key} eventKey={item.id} title={customTitle(item.name, key)} onClick={() => handleClick(item)}>
                        <div className="py-2">
                          {templates.map((template, key) => (
                            <Button key={key} onClick={() => setSelect(template)} size="sm" variant="link nav-link text-start text-dark">{template.name}</Button>
                          ))}
                        </div>
                     </Item>)
                  }else {
                    return;
                  }
                }
                )}
               </Accordion>
              </div>
              <div className="col-md-9">
               {select &&
               <Template select={select} setSelect={setSelect} setUse={setUse} live={live}/>}
              {useItem.length > 0 && <Spinner animation="grow" variant="success"/>}
             </div>
            </div>
           </div>
        </Item>
      </Modal>
    </>
  );
}
