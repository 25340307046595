import { Button } from '../../../components/Uix';
import { translate } from '../../../services/Language';

export default function BoardControl({confirmBlock, boards}) {

  return (
    <div className="px-4 pb-4">
      <h3 className="px-2">{translate('boards')}</h3>
      <div className="d-flex flex-column">
       {boards && boards.map((item, key) => <Button key={key} variant="light" className="text-start w-100 my-1" onClick={(e) => {
         confirmBlock('BOARD', item.id, item.name)
       }} title={item.name}/>)}
       </div>
     </div>);

}
