import React, { useState , useEffect } from "react";
import Icon from '../../components/Icon';
import { Button, Spinner } from '../../components/Uix';
import { translate } from '../../services/Language';
import Comment from './Comment';
import Thread from './Thread';
import { CommentsQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";

export default function Comments({
  simple,
  workflow_id,
  task_id,
  task_name,
  onActivity,
  setOnActivity,
  setFileResult,
  allowEdit,
  setContent,
  sort
}) {

  const [comments, setComments] = useState([]);
  const [onEvent, setOnEvent] = useState(false);
  const [thread, setThread] = useState(false);

  const { loading, refetch, data } = useQuery(CommentsQuery, {
    variables: { id: workflow_id, task_id, order: 'DESC' },
    pollInterval: 100,
    //partialRefetch: true,
  });

  useEffect(() => {
    if(data && data.comments){
      setComments(data.comments)
    }
  },[data])

  useEffect(() => {
      //refetching
      refetch()
      console.log(comments, workflow_id, task_id)
      if(onEvent) setOnEvent(false)
      if(onActivity) setOnActivity(false)
    },
    [onEvent, onActivity, task_id],
  );

  return (
    <>
      <div className="form-group mt-3">
        <div className="comment-line">
          {loading && <div className="d-flex align-items-center justify-content-center"><Spinner animation="border" variant="warning" /></div>}
          {comments.map((comment, key) => (
            <div key={key} className="d-block rounded-2 shadow-sm my-2 p-3 w-100">
              <Comment comment={comment} setContent={setContent} setOnActivity={setOnActivity} allowEdit={allowEdit} simple={simple}/>
              {!simple && <Button variant="link" className="p-0 m-0" onClick={() => setThread(thread === comment.id ? null : comment.id)}>
                  <Icon icon={"chatbubble-outline"} size="sm" className={"text-secondary me-1"}/>
                  <span className="small text-secondary">{comment.thread > 0 ? `${comment.thread} ${translate('comment')}`: translate('comment')}</span>
             </Button>}
              {thread === comment.id &&
              <div className="border-start border-3 ps-2 ms-1">
                <Thread task_id={task_id} task_name={task_name} workflow_id={workflow_id} user_id={comment.user_id} thread_id={comment.id} setOnEvent={setOnEvent} is_thread={!comment.thread_id} setFileResult={setFileResult}/>
              </div>}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
