import React, { useEffect, useState } from "react";
import Icon from '../../components/Icon';
import { Toast, Button } from '../../components/Uix';
import moment from "moment";
import { automation } from '../../store/automation';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';
registerLocale('vi', vi)

export default function InputDate({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  isForm,
  task,
  ...props
}){

  const [startDate, setStartDate] = useState(moment.now());
  const [dateValue, setDateValue] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    let default_value = field.value
    setDateValue(default_value)
    setStartDate(default_value ? moment(default_value).toDate() : moment().toDate())
  }, [field.value]);


  const handleClick = (text) => {
     const date = new Date(text);
     var dt = moment(date)
     var dv = dt.format('YYYY-MM-DD HH:mm')
     dispatch({ type: 'updateField', payload: field.id, value: dv })
     if(field && text){
       setTimeout(function () {
         var data = Object.assign({}, task);
         data.field = field.id;
         data.value = dv;
         automation('columnChanges', data)
       }, 5000);
     }
     setDateValue(dv)
     setOnEvent(true)
  }

  const handleRemove = () => {
     dispatch({ type: 'updateField', payload: field.id, value: null })
     setDateValue()
  }

  const handleClose = () => {
    setShow(false)
  };

    return (
      <>
      <div className="field-list d-flex justify-content-center align-items-center on-hover">
        <Button disabled={!allowEdit} size="sm" variant={"link"} className={"rounded-4 fs-7 px-1"} onClick={() => setShow(true)}>
          <Icon color={"white"} icon="time"/>
          {dateValue && moment(dateValue).format('DD/MM H:m')}
        </Button>

        {dateValue && <Button variant="link" onClick={handleRemove} className="in-hover">
          <Icon icon={"trash"} size="sm"/>
        </Button>}
    </div>
    <Toast show={show} onClose={handleClose} placement="fixed" customWidth={380}>
      <DatePicker
        selected={startDate}
        onChange={date => handleClick(date)}
        showTimeSelect={true}
        showPopperArrow={false}
        locale="vi"
        inline
        timeFormat="HH:mm"
        dateFormat="d MMMM, yyyy"
      />
    </Toast>
    </>
  )
};
