import React, { useState,  useEffect, useMemo } from 'react';
import { Button, Modal, Form } from '../../components/Uix';
import { CreateDoc } from '../../services/GraphqlApi';
import { translate } from '../../services/Language';
import { useMutation } from "@apollo/client";

export default function DocCreate({history, workflow_id, workspace_id, auto, isOpen, setIsOpen, isDarkMode}){

  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  const [nameValue, setNameValue] = useState("");
  const [privacyValue, setPrivacyValue] = useState("private");
  const textForm = React.useRef();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setIsOpen(false)
    setNameValue("")
  }
  const handleShow = () => setShow(true);

  const [createDoc, { data }] = useMutation(CreateDoc);

  const handleClick = async() => {
    if(nameValue){
      createDoc( {variables: {
        name: nameValue,
        status: 'draft',
        user_id,
        app_id,
        workflow_id,
        workspace_id,
      }})
    }
  };

  useEffect(() => {
      if(isOpen){
        setShow(true)
        setTimeout(() => {
          textForm.current?.focus()
        }, 600);
      }
    },[isOpen]);

  useMemo(() => {
    let isMounted = true;
    if(data && isMounted){
      if(data.createDoc && data.createDoc.id){
       //console.log('Doci',data.createDoc)
       history.push(`/w/doc/${data.createDoc.workflow_id}`)
      }
    }
    return () => { isMounted = false };
  },[data])


  return (
    <>
    {!auto && <Button variant="primary" onClick={handleClick}>
      {translate('createDoc')}
    </Button>}
    <Modal show={show} onClose={handleClose} title={translate('createDoc')}>
      <div className={isDarkMode ? "dark-mode mb-3" : "mb-3"}>
        <form onSubmit={e => { e.preventDefault()}}>

          <Form.Input label={translate('name')} onTextChange={setNameValue} value={nameValue} onKeyPress={handleClick} useRef={textForm} className="mb-3"/>

          <Form.Radio checked={privacyValue === 'private' ? true : false} label={translate('private')} description={translate('privateMore')} onTextChange={setPrivacyValue} value={'private'} name="Privacy"/>

          <Form.Radio checked={privacyValue === 'public' ? true : false} label={translate('public')} description={translate('publicMore')} onTextChange={setPrivacyValue} value={'public'} name="Privacy"/>

        </form>
      </div>
      <div className={"position-relative"}>
        <Button variant="primary" onClick={handleClick}>
          {translate('createDoc')}
        </Button>
      </div>
    </Modal>
    </>
  );
}
