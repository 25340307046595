import React, { useReducer, useEffect, useState } from "react";
import AddColumn from './AddColumn';
import NameHead from './NameHead';
import { UpdateWorkflow } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import fieldDefault from '../../data/Fields';
import { fieldSchema } from '../../services/Function';
import { reducer, resetData, initialState } from '../../reducers/Actions';

export default function ColumnHead({
  workflow_id,
  setSchema,
  schema,
  allowEdit,
  isSubItem
}){

  const [state, dispatch] = useReducer(reducer, initialState);
  const { fields } = state;
  const [onEdit, setOnEdit] = useState(false);
  const [updateWorkflow] = useMutation(UpdateWorkflow);

  useEffect(() => {
    let { column } = resetData(schema, []);
    dispatch({ type: "reset", payload: column})
  }, [schema]);

  useEffect(() => {
    if(fields && onEdit) {
      var schema = fieldSchema(fields)
      if(isSubItem){
        updateWorkflow({variables: {id: workflow_id, columns: schema }})
      }else {
        updateWorkflow({variables: {id: workflow_id, schema }})
      }
      setSchema(JSON.parse(schema))
      setOnEdit(false)
    }
  }, [onEdit]);

  return (<>
        {fields && fields.map(field => {
            if(field.enabled === true){
              return(
            <div key={field.id} className="col nav-item p-0 border-end">
              <NameHead field={field} dispatch={dispatch} schema={schema} setOnEdit={setOnEdit} allowEdit={allowEdit} workflow_id={workflow_id}/>
            </div>
          )}
        })}
        {allowEdit && <AddColumn workflow_id={workflow_id} dispatch={dispatch} setOnEdit={setOnEdit} fieldDefault={fieldDefault} state={state} isLabel={false} schema={schema}/>}
    </>
  )
};
