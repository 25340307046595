import React, { useEffect, useReducer, useState } from 'react';
import InputName from './InputName';
import { translate } from '../../services/Language';
import { UpdateWorkflow } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";

var initialField = [
  {id: 'members', title: translate('members'), icon: 'person', show: true, index: 0},
  {id: 'dueDate', title: translate('dueDate'), icon: 'time', show: true, index: 1}
]

const initialState = { todos: null }

function reducer(state, action) {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        todos: action.payload.filter(t => t.id != 'tags')
      };
    case 'editField':
        return {
          ...state,
          todos: state.todos.map(field => {
            if(field.id === action.payload) {
              return {
                ...field,
                title: action.title
              }
            } else {
              return field
            }
         })
        };
    case 'toggleField':
      return {
        ...state,
        todos: state.todos.map(field =>
          field.id === action.payload ? { ...field, show: !field.show } : field
        )
    };
    case 'required':
      return {
        ...state,
        todos: state.todos.map(field =>
          field.id === action.payload ? { ...field, required: !field.required } : field
        )
    };
    default:
      return state;
  }
}

export default function HeadingStage({
  workflow_id,
  fields,
  allowEdit,
  onEvent,
  setOnEvent,
  view
}){

  const [state, dispatch] = useReducer(reducer, initialState);
  const { todos } = state;
  const [onUpdate, setOnUpdate] = useState(false);
  const [updateWorkflow] = useMutation(UpdateWorkflow);

 useEffect(() => {
   let isSubscribed = true

   if(fields.length){
     if(isSubscribed) {
       dispatch({ type: "reset", payload: fields})
     }
   }else {
     if(isSubscribed) {
       dispatch({ type: "reset", payload: initialField})
     }
   }

    return () => isSubscribed = false

  }, [workflow_id, onEvent]);

  useEffect(() => {
    if(onUpdate && todos) {
      updateWorkflow({variables: {id: workflow_id, fields: JSON.stringify(todos) }})
      setOnUpdate(false)
      setOnEvent(true)
   }
 }, [onUpdate]);

  let lineCls = 'col nav-item p-0 text-center';

  return (
    <>
      {todos && todos.map(field => {
        if(field.show) {
          return(
          <div key={field.id} className={lineCls}>
            <InputName field={field} dispatch={dispatch} setOnEvent={setOnUpdate} allowEdit={allowEdit}/>
          </div>
        )}
      })}
    </>
  );
}
