import React, { useState } from "react";
import { Button } from '../../../components/Uix';
import { translate } from '../../../services/Language';

export default function LinkControl({confirmLink,removeLink}) {
  const [urlValue, setUrlValue] = useState("")

  return (
    <div className="px-4 pb-4">
    <h3>{translate('link')}</h3>
    <input
      className="form-control"
      onChange={(e) => setUrlValue(e.target.value)}
      type="text"
      value={urlValue}
      onKeyDown={(e) => {
        if (e.which === 13) {
         confirmLink(e);
       }
      }}
    />
    <Button onClick={confirmLink} icon={'add'} title={translate('add')} size={24}/>
    {urlValue && <Button onMouseDown={removeLink} icon="trash" title={translate('remove')}/>}
  </div>);

}
