import React, { useState, useEffect } from "react";
import Icon from '../../components/Icon';
import { Modal, Button, Dropdown } from "../../components/Uix";
import AddWorkflow from './AddWorkflow';
import AddDashboard from '../Dashboard/AddDashboard';
import AddForm from '../Form/AddForm';
import DocCreate from '../Doc/DocCreate';
import GroupTemplate from './GroupTemplate';
import ImportExcel from './ImportExcel';
import { CreateFolder, TaskClone } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { useModeState } from "../../context/ModeContext";
import { translate } from '../../services/Language';
import { setInboxes, setNotification } from '../../services/Firebase';

export default function NewGroup({ history, setOnEffect, hashTag, live, workspace_id}){

  let app_id = localStorage.getItem('app_id');
  let cacheUsers = localStorage.getItem('wfUsers');
  let users = JSON.parse(cacheUsers)
  var { isDarkMode } = useModeState();

  let textInput = React.useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const [isFolder, setIsFolder] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isDoc, setIsDoc] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [groupValue, setGroupValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [fileData, setData] = useState(null);
  const [ids, setIds] = useState(null);
  const [createFolder] = useMutation(CreateFolder);
  const [taskClone, {data, error}] = useMutation(TaskClone);

  const links = [
    { name: translate('blankBoard'), id: "blankBoard", hash: 'profile', icon: "open"},
    { name: translate('chooseFromtemplate'), id: 'chooseFromtemplate', icon: "grid"},
    { name: translate('importExcel'), id: 'import', icon: "cloud-upload"},
    { name: translate('newDoc'), id: 'newDoc', icon: "document-text"},
    { name: translate('newForm'), id: 'newForm', icon: "text"},
    { name: translate('newFolder'), id: 'newFolder', icon: "folder"},
    { name: translate('dashboard'), id: 'dashboard', icon: "pie-chart"},
  ];

  const handleClick = (id) => {
    if(id === 'blankBoard'){
      setIsOpen(true)
    }else if(id === 'chooseFromtemplate') {
      setIsTemplate(true)
    }else if(id === 'import') {
      setIsImport(true)
    }else if(id === 'dashboard') {
      setIsDashboard(true)
    }else if(id === 'newDoc') {
      setIsDoc(true)
    }else if(id === 'newForm') {
      setIsForm(true)
    }else {
      setIsFolder(true)
      setNameValue(translate('nameFolder'))
      setTimeout(() => {
        textInput.current?.focus()
      }, 600)
    }
    //Empty data.
    setData()
  }

  const folderClick = async() => {
    createFolder( {variables: {
      name: nameValue,
      app_id
    }})
    setOnEffect(true)
    setIsFolder(false)
  };

  useEffect(() => {
      if(fileData){
        //Open popup
        setIsOpen(true)
      }
    },
    [fileData],
  );

  useEffect(() => {
    if(hashTag === 'init'){
      setIsTemplate(true)
    }
    },
    [hashTag],
  );

  useEffect(() => {
    let isMounted = true;
    if(ids && isMounted){
      ids.map(id => {
        taskClone( {variables: {
          id: id.item,
          privacy: 'public',
          task: true,
          user: false,
          todo: true,
          task_user: false,
          collection: true,
          task_date: true,
          workspace_id,
          app_id
        }})
      })
    }
    return () => { isMounted = false };
  },[ids]);

  useEffect(() => {
    let isMounted = true;
    if(data && data.taskClone && isMounted){
      if(data.taskClone.id){
        setOnEffect(true)
        setIsOpen(false)
        setIds()

        if(users) {
          let ids = []
          users.map(user => {
            ids.push(user.id)
            setNotification(user, data.taskClone.name, data.taskClone.id, 'createWorkflow')
          })
          setInboxes(ids, data.taskClone.name, data.taskClone.id, data.taskClone.id, 'createWorkflow')
        }
        history.push(`/w/a/${data.taskClone.id}`)
      }
    }
    return () => { isMounted = false };
  },[data])

  return (
    <>
     <Dropdown icon="add-outline" btnClass="btn btn-outline-primary text-body w-100" autoHide={isFolder||isImport||isTemplate||isOpen} title={translate('new')}>
        {links.map((link, key) => (
          <div key={link.id} onClick={() => handleClick(link.id)}>
            <Button variant="link" className={key ===0 ? "ps-0" : "ps-0"}><Icon icon={link.icon} color="grey"/> {link.name}</Button>
          </div>
        ))}
      </Dropdown>

      <AddWorkflow isOpen={isOpen} setIsOpen={setIsOpen} users={users} history={history} setOnEffect={setOnEffect} fileData={fileData} setData={setData} group={groupValue} isDarkMode={isDarkMode} live={live} workspace_id={workspace_id}/>

      <AddForm isOpen={isForm} setIsOpen={setIsForm} history={history} setOnEffect={setOnEffect} fileData={fileData} isDarkMode={isDarkMode} live={live} workspace_id={workspace_id}/>

      <GroupTemplate isTemplate={isTemplate} setIsTemplate={setIsTemplate} setIds={setIds} setGroupValue={setGroupValue} isDarkMode={isDarkMode} live={live}/>

      <AddDashboard isOpen={isDashboard} setIsOpen={setIsDashboard} history={history} setOnEffect={setOnEffect} isDarkMode={isDarkMode} live={live} workspace_id={workspace_id}/>

      <DocCreate history={history} workspace_id={workspace_id} isOpen={isDoc} setIsOpen={setIsDoc} auto/>

      <Modal show={isImport} onClose={setIsImport} title={translate('importExcel')}>
          <ImportExcel setIsImport={setIsImport} setData={setData} setGroupValue={setGroupValue} isDarkMode={isDarkMode} live={live}/>
      </Modal>

      {isFolder &&
      <div className="mx-3">
        <input
          type="text"
          value={nameValue}
          ref={textInput}
          className="form-control fw-bold px-2 mb-2"
          placeholder={translate('newFolder')}
          onChange={e => setNameValue(e.target.value)}
          onKeyPress={({ key, target }) => {
            if(key === 'Enter' && target.value.length > 0){
              folderClick()
            }
          }}
        />
        <Button variant="link" size={'sm'} className={"text-secondary"} onClick={()=>setIsFolder(false)}>
          <Icon icon="trash" /> {translate('cancel')}
        </Button>
        <Button variant="primary" size={'sm'} onClick={folderClick}>
          {translate('add')}
        </Button>
      </div>}
    </>
  );

}
