import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function TrendingUpOutline({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox="0 0 512 512">
  <path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M352 144h112v112'/><path d='M48 368l121.37-121.37a32 32 0 0145.26 0l50.74 50.74a32 32 0 0045.26 0L448 160' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/>
</svg>
 );
}
