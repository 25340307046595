import React from "react";
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { random } from '../../services/Function';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ImageUpload({
  label,
  setUrlValue
}) {

  const handleUpload = async({target}) => {
     try {
         let file = target.files[0];
         let file_type = file.name.split('.').pop();
         const body = new FormData();
         let image_name = `${random(8)}-${file.name}`;
         body.append('id', image_name)
         body.append("file", file);
         const res = await fetch(`${SERVER_URL}/upload/file`, {
           method: "POST",
           body
         });
         if (!res.ok) {
           throw new Error(res.status)
         }else {
           setUrlValue(`${SERVER_URL}/files_blog/${image_name}`)
         }

     } catch (error) {
       //console.log(error)
     }
  }

   return (<div>
     {label && <label>{label}</label>}
     <input className="form-control" name="image_url" onChange={handleUpload} accept="image/*" type="file" id="formFile"/>
   </div>);
}
