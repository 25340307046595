import React, { useEffect, useState } from "react";
import { translate } from '../../services/Language';
import { automation } from '../../store/automation';
import { Form } from '../../components/Uix';

export default function InputText({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  isLabel,
  task,
  ...props
}){

  const [nameValue, setNameValue] = useState("");
  const [initValue, setInitValue] = useState("");

  useEffect(() => {
    setNameValue(field.value)
    setInitValue(field.value)
  }, [field.value]);

  const handleChange = (text) => {
     dispatch({ type: 'updateField', payload: field.id, value: text })
     setNameValue(text)
     setOnEvent(true)
  }

 const handleUpdate = () => {
    setTimeout(function () {
      if(field && nameValue && !initValue){
        var data = Object.assign({}, task);
        data.field = field.id;
        data.value = nameValue;
        automation('columnChanges', data)
      }
    }, 5000);
  }


  const inputContent = (mode) => {
    return(
      <Form.Input placeholder={field.title}
         onTextChange={handleChange}
         onMouseOut={handleUpdate}
         disabled={!mode} value={nameValue} type="text"
         className={"input-great text-center"}/>
    )
  }

  return (<>{inputContent(allowEdit)}</>)
};
