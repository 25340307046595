import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import Icon from '../../components/Icon';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Modal, ButtonGroup, Button, Menu, Item } from '../../components/Uix';
import { imageStorage } from '../../services/Function';
import { motion } from 'framer-motion';
import Workspace from '../Workspace';
import { translate } from '../../services/Language';


export default function Apps({ location, history, live, placement}){

  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  let appName = localStorage.getItem('app_name');
  let locale = localStorage.getItem('locale');
  var [user, setUser] = useState([]);
  const [show, setShow] = useState(false);

  const apps = [
    { name: translate('messenger'), link: '/w/messenger', icon: "messenger"},
    { name: translate('calendar'), link: "/w/calendar", icon: "calendar"},
    //{ name: translate('form'), link: "/w/workspaces", icon: "form"},
    //{ name: translate('docs'), link: '/w/workspaces', icon: "doc"},
    { name: translate('sheets'), link: "/w/workspaces", icon: "sheet"},
    //{ name: translate('sequences'), link: "/w/sequences", icon: "sequence"},
    //{ name: translate('notes'), link: "/w/reports", icon: "notes"},
    { name: translate('todo'), link: "/w/todos", icon: "todo"},
    { name: translate('dashboard'), link: "/w/reports", icon: "chart"},
    { name: translate('team'), link: "/w/teams", icon: "team"},
  ];

  const handleClick = async() => {

  }

  const customTitle = (
    <img src="/assets/apps.svg"/>
  )
  return (
    <>
      <Menu title={customTitle} placement={placement??"left"} position="bottom" customWidth={360}>
        <div className="w-100">
          <div className="px-3 border-bottom py-2 mb-3">
            <div className="text-secondary">{`Apps`}</div>
          </div>
          <div className="row row-cols-1 row-cols-md-3 g-3">
            {apps.map(link => (<div className="col">
              <Item key={link.id}>
                <Link className="btn btn-link text-center d-block text-body" target="_blank" to={{pathname: link.link, hash: link.hash}}>
                  <img src={`/assets/${link.icon}.svg`} width={32} height={32}/>
                  <div className="text-center">{link.name}</div>
                </Link>
              </Item>
          </div>))}
        </div>
        </div>
      </Menu>
    </>
  );

}
