import React, { useState, useEffect } from 'react';
import { Button, Title } from '../../components/Uix';
import { translate } from '../../services/Language';
import { UserQuery } from '../../services/GraphqlApi';
import { useUserDispatch, syncSpace } from "../../context/UserContext";
import { useModeState } from "../../context/ModeContext";
import colors from '../../data/BgColor';
import { useQuery } from "@apollo/client";

export default function Workspace({show, history}){

  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');

  const [workspace, setWorkspace] = useState([]);
  const [error, setError] = useState(false);
  const {data} = useQuery(UserQuery, {
    variables: {id: user_id},
  });

  var userDispatch = useUserDispatch();
  var { isDarkMode } = useModeState();

  useEffect(() => {
    if (data && data.user && data.user.apps) {
      setWorkspace(data.user.apps);
    }
  }, [data]);

  async function handleClick(app_id) {
      syncSpace(userDispatch, app_id, user_id, history, setError)
  };

  return (
    <>
      <div>
        <Title className={isDarkMode ? "dark-mode" : ""}>{translate('workspace')}</Title>
        <div className={isDarkMode ? "dark-mode" : ""}>
          <div className="row">
            {workspace && workspace.map( (link, key) => (
                 <div className="col-4" style={{backgroundColor: colors[key]}}>
                  <Button className={`my-2 ${app_id === link.id ? "fw-bold" : ""}`} size="sm"
                  onClick={() => handleClick(link.id)} title={link.name} icon={app_id === link.id ? "open":"download"}/>
                  </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
