import React from "react";
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';

export default function fileUpload({
  setFileUpload,
  label,
  icon,
  ...props
}) {

    const onFileChange = async(e) => {
          var files = e.target.files || e.dataTransfer.files;
          if (!files.length) return;
          setFileUpload(Array.from(files))
    }

   return (
    <>
      <input
       accept="*/*"
       className={"d-none"}
       id="contained-button-file"
       multiple
       type="file"
       onChange={e => onFileChange(e)}
     />
     <label htmlFor="contained-button-file" className="ms-0 d-block fw-bold text-primary cursor nav-link">
         <Icon icon={icon ? icon : "cloud-upload"} className="text-secondary" size="sm"/> {label ? label : translate('fromComputer')}
     </label>
    </>
  );
}
