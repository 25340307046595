import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import { Button, Tooltip } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { jsonParse } from '../../services/Function';

let gmapAPI = process.env.REACT_APP_GMAP_API

export default function InputLocation({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  isLabel,
  ...props
}){

  const [address, setAddress] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    var place = jsonParse(field.value ? field.value : []);
    setNameValue(place.label ? place.label: '')
  }, [field.value]);

  const handleSelect = async({value}) => {
     const results = await geocodeByAddress(value.description);
     const latLng = await getLatLng(results[0]);
     var place = {
       label: value.description,
       latLng,
     }
     setAddress(value)
     setNameValue(value.description)
     await dispatch({ type: 'updateField', payload: field.id, value: JSON.stringify(place)})
     setOnEvent(true)
     setShow(false)
  }

  const handleRemove = () => {
     setNameValue()
     dispatch({ type: 'updateField', payload: field.id, value: null})
     setOnEvent(true)
  }

  //old api AIzaSyAizZkdoxN73w1vzM8coYGynhIMU472Y48

  const inputContent = (mode) => {
    return(
    <>
    <div className="position-relative">
      {show ?<div className="d-flex justify-content-center align-items-center">
        <div className="w-100">
        <GooglePlacesAutocomplete
        apiKey={gmapAPI}
        selectProps={{
          value: address,
          onChange: handleSelect,
          styles: {padding: 0}
        }}
      /></div>
      <Button variant="link" className="text-light on-hover btn-sm bg-white" style={{marginLeft: -35, zIndex: 9}} onClick={handleClose}>
        <Icon size="sm" icon="close"/>
      </Button>
      </div>:
      <>
      <div className="d-flex align-items-center on-hover">
        <Tooltip
          placement="top"
          title={nameValue}
          >
          <Button variant="link" onClick={(e) => allowEdit ? setShow(true) : e.preventDefault()} style={{width: 150}}>
             {nameValue ? <span className="text-truncate d-block" style={{width: 130}}>{nameValue}</span> : <span className="text-secondary h-36" style={{width: 140}}></span>}
          </Button>
        </Tooltip>
        {nameValue && <Button variant="link" onDoubleClick={handleRemove} className="in-hover">
          <Icon icon="trash" size="sm"/>
        </Button>}
        </div>
        </>
      }
    </div>
    </>
    )
  }

  return (<>{inputContent(allowEdit)}</>)
};
