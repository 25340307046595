import React, { useEffect, useState} from 'react';
import Icon from '../../components/Icon';
import { Spinner } from '../../components/Uix';
import { FileSearchQuery } from '../../services/GraphqlApi';
import Files from "../Media/Files";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";

export default function FileSearch({
  content,
  setUpdate,
  setFiles,
  keyword,
  current,
  ...props
}){

  let app_id = localStorage.getItem('app_id');
  const [onActivity, setOnActivity] = useState(false);

  const [fileSearch, { loading, refetch, data }] = useLazyQuery(FileSearchQuery);

  useEffect(() => {
    if(data && data.fileSearch) {
      setFiles(data.fileSearch);
    }
  },[data])

  useEffect(() => {
    if(keyword){
      fileSearch({ variables: { app_id, keyword } })
    }
  },[keyword, current])

  return (
    <>
      {loading && <div className="justify-content-center text-center"><Spinner animation="border" variant="success" /></div>}
      <ul className="nav flex-column">
        {content.map((link, key) => (
          <li className="nav-item w-100 text-start">
            <Files file={link} key={key} setOnDelete={setOnActivity}/>
          </li>
        ))}
      </ul>
    </>
  );
}
