import React, { useState, useEffect } from 'react';
import { Button, Toast } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { Link } from "react-router-dom";

export default function Upgrade({
  placement,
  text,
  icon,
  btn
}){

  const [show, setShow] = useState(false);
  const target = React.useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let app_id = localStorage.getItem('app_id');

  return (
    <>
    <div className="position-relative" ref={target}>
      <Button className={btn ? "mt-1" : "mt-0 p-0"} variant={btn ? "primary" : "link"} onClick={handleShow}>
        {icon && <Icon icon={icon}/>}  { text }
      </Button>
      <Toast show={show} onClose={handleClose} placement={placement?placement:"top"}>
        <div className="text-center p-3" style={{width: 200}}>
          <div className="mt-1 lead fw-bold text-truncate d-inline-block w-100" style={{maxWidth: 205}}>{ translate('upgrade') }</div>
          <p>{ translate('upgradeInfo') }</p>
          <Link to={{pathname: `/apps/${app_id}`, hash: 'st1'}} className="btn btn-primary">
            <Icon icon="rocket" /> { translate('upgrade') }
          </Link>
        </div>
      </Toast>
      </div>
    </>
  );
}
