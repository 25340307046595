import slugify from 'slugify';
import fieldDefault from '../data/Fields';
const initialState = { fields: null };

function reducer(state, action) {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        fields: action.payload
      };
    case "add":
      if (state.newFieldTitle.length > 0) {
        return {
          fields: [
            ...state.fields,
            {
              id: slugify(state.newFieldTitle, {replacement: '_', locale: 'vi'}),
              title: state.newFieldTitle,
              name: state.newFieldName,
              field: state.newFieldName,
              enum: state.newFieldEnum,
              link: state.newFieldLink,
              connect: state.newFieldConnect,
              ref: state.newFieldRef,
              icon: state.newFieldIcon,
              config: state.newFieldConfig,
              enabled: true,
            }
          ],
          nextFieldId: null,
          newFieldTitle: "",
          newFieldName: state.newFieldName,
        }
      };
    break;
    case "updateTitle":
      return {
        ...state,
        newFieldTitle: action.payload,
        newFieldIcon: action.icon,
      };
    case "updateType":
      return {
        ...state,
        newFieldName: action.payload,
        newFieldEnum: action.enum,
      };
    case "updateLink":
      return {
        ...state,
        newFieldName: action.payload,
        newFieldLink: action.link,
      };
    case "updateConnect":
      return {
        ...state,
        newFieldConnect: action.connect,
    };
    case "updateMirror":
      return {
        ...state,
        newFieldName: action.payload,
        newFieldRef: action.ref,
        newFieldConnect: action.connect,
    };
    case "updateConfig":
      return {
        ...state,
        newFieldConfig: action.config,
    };
    case "updateField":
        return {
          ...state,
          fields: state.fields.map(field => {
            if (field.id === action.payload) {
              return {
                ...field,
                value: action.value
              }
            } else {
              return field
            }
          })
        };
    case 'editEnum':
      return {
        ...state,
        fields: state.fields.map(field => {
          if (field.id === action.payload) {
            return {
              ...field,
              enum: action.enum
            }
          } else {
            return field
          }
        })
      };
    case 'editLink':
      return {
        ...state,
        fields: state.fields.map(field => {
          if (field.id === action.payload) {
            return {
              ...field,
              link: action.link
            }
          } else {
            return field
          }
        })
      };
    case 'editConnect':
        return {
          ...state,
          fields: state.fields.map(field => {
            if (field.id === action.payload) {
              return {
                ...field,
                connect: action.connect
              }
            } else {
              return field
            }
          })
      };
    case 'editField':
      return {
        ...state,
        fields: state.fields.map(field => {
          if(field.id === action.payload) {
            return {
              ...field,
              title: action.title
            }
          } else {
            return field
          }
       })
     };
     case 'editConfig':
       return {
         ...state,
         fields: state.fields.map(field => {
           if (field.id === action.payload) {
             return {
               ...field,
               config: action.config
             }
           } else {
             return field
           }
         })
       };
   case "reorder":
       return {
         ...state,
         fields: state.fields.map(field =>
            field.id === action.payload ? { ...field, index: action.index } : field
          )
       };
    case "enabled":
      return {
        ...state,
        fields: state.fields.map(field =>
          field.id === action.payload ? { ...field,
            enabled: !field.enabled
          } : field
        )
      };
      case "required":
        return {
          ...state,
          fields: state.fields.map(field =>
            field.id === action.payload ? { ...field,
              required: !field.required
            } : field
          )
        };
      case "formshow":
        return {
          ...state,
          fields: state.fields.map(field =>
            field.id === action.payload ? { ...field,
              formshow: action.formshow
            } : field
          )
        };
    case "remove":
      return {
        ...state,
        fields: state.fields.filter(field => field.id !== action.payload)
      };

    default:
      return state;
  }
}

function resetData(schema, collection) {
  var column = []
  var data = []
  var index = 0;
  Object.keys(schema).forEach( (key) => {
   if(schema.hasOwnProperty(key)) {
     var field = {};
     field.value = collection[key];
     field.icon = fieldDefault[schema[key].name]?.icon;
     field.index = index;
     if(schema[key].value !== 'undefined' || schema[key].value !== null) {
       delete schema[key].value
     }
     var property = Object.assign(field, schema[key])
     data.push(property)
     index ++;
   }
 })

  if(data) {
    column = data.sort(function(obj1, obj2) {
      if (obj1.index > obj2.index) return 1;
      if (obj2.index > obj1.index) return -1;
      return 0;
    });
  }
  return { column }
}

export {
  reducer,
  resetData,
  initialState
};
