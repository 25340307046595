import InputLink from './InputLink';
import InputFile from './InputFile';
import InputUser from './InputUser';
import InputDate from './InputDate';
import InputSelect from './InputSelect';
import InputCheck from './InputCheck';
import InputText from './InputText';
import InputNumber from './InputNumber';
import InputEmail from './InputEmail';
import InputPhone from './InputPhone';
import InputLocation from './InputLocation';
import InputProgress from './InputProgress';
import InputStatus from './InputStatus';
import InputRating from './InputRating';
import InputColor from './InputColor';
import InputURL from './InputURL';
import InputTimer from './InputTimer';
import InputSub from './InputSub';
import InputIID from './InputIID';
import InputFormula from './InputFormula';
import InputMirror from './InputMirror';
import InputTodo from './InputTodo';
import InputArea from './InputArea';

export default function BaseForm({
  workflow_id,
  task,
  schema,
  allowEdit,
  setContent,
  setSubVisiable,
  field,
  users,
  stages,
  dispatch,
  onEvent,
  setOnEvent,
  setOnEdit,
  setOnTask,
  onTask
}){

    switch (field.field) {
      case 'link':
        return <InputLink field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} allowEdit={allowEdit} setContent={setContent} schema={schema} task_id={task.id} connectLink={task.connectLink} connectTask={task.connectTask}/>
      case 'file':
        return <InputFile field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      case 'user':
        return <InputUser field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task} users={users}/>
      case 'date':
         return <InputDate field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      case 'select':
        return <InputSelect field={field} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit} task={task}/>
      case 'checkbox':
        return <InputCheck field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      case 'integer':
        return <InputNumber field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task} setOnTask={setOnTask}/>
       case 'email':
       return <InputEmail field={field} dispatch={dispatch} setOnEvent={setOnEvent} isEmail={true} allowEdit={allowEdit} task={task}/>
      case 'phone':
        return <InputPhone field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit}/>
      case 'location':
        return <InputLocation field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit}/>
      case 'progress':
        return <InputProgress field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} schema={schema} task={task} onEvent={onEvent} setOnEdit={setOnEdit}/>
      case 'status':
        return <InputStatus field={field} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit} task={task}/>
      case 'rating':
        return <InputRating field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit}/>
      case 'color':
        return <InputColor field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit}/>
      case 'iid':
          return <InputIID field={field} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} task={task}/>
      case 'url':
        return <InputURL field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit}/>
      case 'todolist':
        return <InputTodo field={field} workflow_id={workflow_id} task={task} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit}/>
      case 'subitem':
        return (<InputSub field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit} setSubVisiable={setSubVisiable}/>)
     case 'formula':
        return <InputFormula field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} schema={schema} task={task} onEvent={onEvent} onTask={onTask} setOnEdit={setOnEdit} task={task}/>
      case 'mirror':
        return <InputMirror field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit} schema={schema} task={task} users={users}/>
      case 'timer':
        return <InputTimer field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit}/>
      case 'textarea':
        return <InputArea field={field} workflow_id={workflow_id} dispatch={dispatch} setOnEvent={setOnEvent} setOnEdit={setOnEdit} canEdit={true} allowEdit={allowEdit}/>
      default:
        return <InputText field={field} dispatch={dispatch} setOnEvent={setOnEvent}  allowEdit={allowEdit} task={task}/>
    }
};
