import React, { useEffect, useState } from "react";
import Icon from '../../components/Icon';
import { Form, Menu, Button } from '../../components/Uix';
import { translate } from '../../services/Language';
import { Link } from "react-router-dom";
import { jsonParse } from '../../services/Function';
import ItemLink from './ItemLink';

export default function InputURL({
  workflow_id,
  dispatch,
  field,
  setOnEvent,
  setOnEdit,
  allowEdit,
  isLabel,
  ...props
}){

  var [link, setLink] = useState("");
  var [text, setText] = useState("");
  var [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(field.value) setLink(field.value.link)
    if(field.value) setText(field.value.text)
  }, [field.value]);

  const handleChange = () => {
    if(!!link){
     var value = {
       link,
       text
     }
     dispatch({ type: 'updateField', payload: field.id, value})
     setLink(link)
     setText(text)
     setOnEvent(true)
   }
  }

  const handleRemove = () => {
     var value = null
     dispatch({ type: 'updateField', payload: field.id, value})
     setLink()
     setText()
     setOnEvent(true)
  }

  return (
    <div>
    {isLabel && <label className="mb-1">
      {field.title}
    </label>}
    <div className="field-list d-flex justify-content-center align-items-center on-hover ps-1 h-34">
     <Menu icon={link ? "create" : "add"} show={isOpen} onToggle={e => setIsOpen(e)} disabled={!allowEdit} btnSize="sm" btnBetween>
        <div className="p-3">
          <Form.Input
            value={link}
            className={"border-hover"}
            placeholder={`${translate('url')}`}
            onMouseOut={handleChange}
            onTextChange={value => setLink(value)}
          />
          <Form.Input
            value={text}
            className={"border-hover mt-3"}
            placeholder={`${translate('text')}`}
            onMouseOut={handleChange}
            onTextChange={value => setText(value)}
          />
        </div>
      </Menu>
       {link && <a className="w-75" href={link} target="_blank">{text ? text : link}</a>}
       {link && <Button variant="link" onClick={handleRemove} className="in-hover">
         <Icon icon="trash" size="sm"/>
       </Button>}
    </div>
  </div>
  )
};
