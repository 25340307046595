import React, { useState } from "react";
import { ButtonGroup, Button, Menu } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { formatPrice, median } from '../../services/Function';

const funcs = [
  'none', 'sum', 'average', 'median', 'min', 'max', 'count'
]

export default function SumFunc({
  tasks,
  isButton
}){

    const [type, setType] = useState('sum');

    const handleClick = async(fun) => {
      setType(fun)
    }

    let count = 0;

    switch (type) {
      case 'sum':
        count = tasks.reduce((a, b) => a + b, 0)
        break;
      case 'average':
        var average = tasks.reduce((a, b) => a + b, 0)
          count = Math.fround(average / tasks.length)
        break;
      case 'median':
        count = median(tasks)
        break;
      case 'min':
          count = Math.min.apply(null, tasks)
        break;
      case 'max':
        count = Math.max.apply(null, tasks)
        break;
      case 'count':
          count =  tasks.length
        break;
      default:
      count = 0;
    }

    const customTitle = (
      <div className="d-inline">
        <span className="d-block">{formatPrice(count)}</span>
        <span className="d-block small"><Icon size="sm" icon="calculator"/> {translate(type)}</span>
      </div>
    )
    if(count > 0 ){
    return (
        <>
        {isButton ?
        <Menu title={customTitle} size="md" btnStyle={{padding: 2}}>
        <div>
         <ButtonGroup className="p-2 text-dark">
            {funcs.map((fun, key) =>
              <Button size="sm" key={key} title={translate(fun)} onClick={() => handleClick(fun)}/>
            )}
          </ButtonGroup>
          </div>
        </Menu>:customTitle}
        </>
    )
  }else {
    return null;
  }
};
