import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withRouter } from "react-router-dom";
import Icon from '../../components/Icon';
import Workflow from '../Workflow';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Button, Modal, Tooltip } from '../../components/Uix'
import Search from '../Search';
import Invite from '../Invite';
import Notification from '../Notification';
import GetNotification from "../Notification/GetNotification";
import Profile from '../Profile';
import { AppQuery } from '../../services/GraphqlApi';
import { useQuery, gql } from "@apollo/client";
import { realtime } from '../../services/FirebaseInit';
import homeList from '../../data/Home';
import colors from '../../data/Colors';
import { motion } from 'framer-motion';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { translate } from '../../services/Language';

let mId = localStorage.getItem('currentMessage');
let wId = localStorage.getItem('currentWorkflow');
let app_id = localStorage.getItem('app_id');
var color = "#6c757d";


const WorkflowQuery = gql`
  query Workflow($id: String!){
   workflow(id: $id){
     workspace_id
   }
 }
`;

function Sidebar({ location, history, id, ...props }) {

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var [app, setApp] = useState([]);
  var [badges, setBadges] = useState([]);
  var [folders, setFolders] = useState([]);
  var [workflow, setWorkflow] = useState([]);
  var [reverseMessage, setReverseMessage] = useState([]);
  var [layoutHeight, setLayoutHeight] = useState();
  var [appName, setAppName] = useState("");
  var [badge, setBadge] = useState();
  const [show, setShow] = useState(false);
  const [link, setLink] = useState("");
  const [workspace_id, setWorkspaceId] = useState("");
  const [onEvent, setOnEvent] = useState(false);
  const [onEffect, setOnEffect] = useState(false);
  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');

  var currentPath = location.pathname;
  var isMessagePath = location.pathname.slice(3)[0];
  var boardPath = location.pathname.slice(3)[0];
  var homePath = location.pathname.split('/')[2];

  let hash = location.hash
  let hashTag = hash.slice(1, hash.length)

  const { data } = useQuery(AppQuery, {
    variables: { id: app_id },
  });

  const dataWorkflow = useQuery(WorkflowQuery, {
    variables: { id },
  });

  useMemo(() => {
    if (data && data.app) {
      setAppName(data.app.name)
      setApp(data.app)
      //Save for users
      localStorage.setItem('wfUsers', JSON.stringify(data.app.users))
      //Save for groups
      localStorage.setItem('wfGroups', JSON.stringify(data.app.groups))
      localStorage.setItem('wfWorkflows', JSON.stringify(data.app.workflows))
      setWorkflow(data.app.workflows[0] ?? [])
    }
  }, [data])

  useMemo(() => {
    if (dataWorkflow && dataWorkflow.data && dataWorkflow.data.workflow) {
      setWorkspaceId(dataWorkflow.data.workflow?.workspace_id)
    }
  }, [dataWorkflow.data])

  useMemo(() => {
    setOnEffect(true)
  },
    [currentPath], //badge
  );

  useEffect(() => {
    realtime.ref(`badges/${user_id}`).on('value', function (snapshot) {
      let dataItems = [];
      snapshot.forEach((childSnapshot) => {
        var sub = []
        childSnapshot.forEach((subSnapshot) => {
          sub.push(subSnapshot.val())
        })
        dataItems[childSnapshot.key] = sub
      })
      setBadges(dataItems)
    });
  },
    [user_id]
  );

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });


  var expire = moment(app.expire)
  var live = app.live
  var timediff = expire.diff(moment(), 'days')
  let active_link = location.pathname;
  let w_link = 'a'

  if (timediff < 0) {
    history.push({
      pathname: '/apps/' + app_id,
      hash: 'st1'
    })
  }

  const refPosition = useCallback(
    (el) => {
      if (!el) return;
      let height = el.getBoundingClientRect().height;
      setLayoutHeight({ height });
    }, []);

  let startLink = `/w/templates`;
  if (workflow.id) {
    startLink = `/w/a/${workflow?.id}`;
  }

  let structure = [
    { name: 'home', link: '/w/workspaces', icon: "house" },
    { name: 'tasks', link: '/w/mywork', icon: "today" },
    //{ name: 'board', link: wId ? `/w/a/${wId}` : startLink, icon: "grid" },
    { name: 'team', link: '/w/team', icon: "people" },
    //{ name: 'notification', link: null, icon: "notifications" },
    //{ name: 'search', link: null, icon: "search" }
  ];

  let bottomLinks = [
    { name: 'team', link: '/w/team', icon: "people" },
    { name: 'support', link: "/w/support", icon: "alert-circle" }
  ];

  if (!app.live) {
    structure.push({ name: 'upgrade', link: `/apps/${app_id}`, icon: "rocket" })
  }

  let homeLinks = [
    { name: 'workspaces', link: 'workspaces', icon: "project", title: translate('workspace') },
    { name: 'favorites', link: 'favorites', icon: "bookmark", title: translate('favorite') },
    { name: 'news', link: 'news', icon: "chatbubbles", title: translate('chat') },
    { name: 'calendar', link: 'calendar', icon: "calendar", title: translate('calendar') },
    { name: 'forms', link: 'forms', icon: "text", title: translate('form') },
    { name: 'emails', link: "emails", icon: "mail", title: translate('emails') },
    { name: 'docs', link: 'docs', icon: "document-text", title: `${translate('docs')}` },
    { name: 'files', link: 'files', icon: "upload", title: translate('files') }
  ];

  let currentHome = homeList.find(h => h === homePath) ?? true;

  const textMotion = {
    rest: {
      width: 52,
      transition: {
        duration: 0.1,
        type: "tween",
        ease: "easeIn"
      }
    },
    hover: {
      width: 190,
      tansition: {
        duration: 0.1,
        type: "tween",
        ease: "easeOut"
      }
    }
  };

  const slashMotion = {
    rest: { opacity: 0, ease: "easeOut", duration: 0.1, type: "tween", width: 0, height: 24 },
    hover: {
      width: 90,
      opacity: 1,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn"
      }
    }
  };

  return (
    <>
      <nav className={"navbarfex d-flex"} ref={refPosition}>
        <div className="nav-right bg-light overflow-auto scroll-style">
          <Button
            className="text-secondary position-absolute text-center align-items-center d-block p-0"
            style={{ left: isSidebarOpened ? 210 : 0, top: 10, zIndex: 999 }}
            onClick={() => toggleSidebar(layoutDispatch, isSidebarOpened)}
          >
            <span className="p-1"><Icon icon={isSidebarOpened ? "chevron-back" : "chevron-forward"} /></span>
          </Button>
          <ul className="nav flex-column border-bottom">
            {structure.map((link, key) => (
              <li className={"nav-item px-0"} key={key}>
                {link.link ? <Link to={link.link ? link.link : '#'} className={`d-flex nav-link p-2 position-relative ${(active_link === link.link || (boardPath === "a" && link.name === "board")) ? "bg-dark-80 rounded-2 text-primary" : "text-dark"}`}>
                  <div className="text-center" style={{ width: 30 }}>
                    <Icon icon={link.icon} size="md" color={color} />
                  </div>
                  <div variants={slashMotion} className="ms-1 text-nowrap">
                    {translate(link.name)}
                  </div>
                  {(link.name === 'message' && badge > 0) && <span className="rounded-pill notify"><i className="sr-only-0">{badge}</i></span>}
                </Link> : <Button onClick={() => setLink(link.name)} className={`d-flex nav-link p-2 position-relative text-start`}>
                  <div className="text-center" style={{ width: 30 }}>
                    <Icon icon={link.icon} size="md" color={color} />
                  </div>
                  <div variants={slashMotion} className="ms-1 text-nowrap">{translate(link.name)}</div>
                  {link.name === "notification" && <GetNotification setBadge={setBadge} badge={badge} />}
                </Button>}
              </li>
            ))}
          </ul>
          <Workflow workspace_id={workspace_id} active_link={active_link} live={app.live} hashTag={hashTag} history={history} badges={badges} onEffect={onEffect} setOnEffect={setOnEffect} />
        </div>
      </nav>
      <Modal show={show} onClose={() => setShow(false)}>
        <Invite />
      </Modal>
    </>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = 768;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
    //Get height
    var windowHeight = window.innerHeight;
    setLayoutHeight(windowHeight)
  }
}

export default withRouter(Sidebar);
