import React, {useState, useMemo} from "react";
import { Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { CreateTodo } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";

export default function AddTodo({
  workflow_id,
  task_id,
  setOnEvent,
  setOnTodo,
  ...props
}){
      const [nameValue, setNameValue] = useState(translate('checkList'));
      const [createTodo, { data, loading, error }] = useMutation(CreateTodo);
      let taskInput = React.useRef();

      setTimeout(() => {
        taskInput.current?.focus()
      }, 400)

      const handleClick = async() => {
          createTodo( {variables: {name: nameValue, task_id, workflow_id}})
          setOnEvent(true)
      }

      useMemo(() => {
        if(data && data.createTodo) {
          setOnTodo(data.createTodo)
        }
      },[data])

      return (
        <>
          <div className="p-4">
            <input className={"form-control mt-2 mb-3 w-100"} type="text" placeholder="Todo"
                value={nameValue}
                ref={taskInput}
                onChange={e => setNameValue(e.target.value)}
                onKeyPress={({ key, target }) => {
                  if(key === 'Enter' && target.value.length > 0){
                    handleClick()
                  }
                }}
            />
            <Button variant="primary" onClick={handleClick}>
              <Icon icon="add" className="me1" color="white"/>
              {translate('create')}
            </Button>
          </div>
        </>

      );
}
