import React, { useReducer, useMemo, useState, useEffect, useRef } from "react";
import UseApi from '../../services/UseApi';
import { TodoQuery, UpdateTodo } from '../../services/GraphqlApi';
import { useQuery, useMutation } from "@apollo/client";
import Icon from '../../components/Icon';
import { Button, Toast, ProgressBar} from '../../components/Uix';
import { translate } from '../../services/Language';
import { notificationAdd } from '../../store/notification';
import { historyAdd } from '../../store/history';
import { random } from '../../services/Function';
import { MentionsInput, Mention } from 'react-mentions';
import { sendNotification } from '../../services/Api';
import { db } from '../../services/FirebaseInit';
import moment from "moment"

const initialState = { todos: null, nextTodoId: random(8), newTodoLabel: ""};

function reducer(state, action) {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        todos: action.payload,
        nextTodoId: action.nextTodoId
      };
    case "add":
      if(state.newTodoLabel.length > 0) {
        return {
          todos: [
            ...state.todos,
            {
              id: state.nextTodoId,
              label: state.newTodoLabel,
              done: false,
            }
          ],
          nextTodoId: random(8),
          newTodoLabel: ""
        }
      }
    case "remove":
      return {
        ...state,
        todos: state.todos.filter(todo => todo.id !== action.payload)
      };
    case 'toggleTodo':
      return {
        ...state,
        todos: state.todos.map(todo =>
          todo.id === action.payload ? { ...todo, done: !todo.done } : todo
        )
      };
    case "updateLabel":
      return {
        ...state,
        newTodoLabel: action.payload
      };
    case 'editTodo':
        return {
          ...state,
          todos: state.todos.map(todo => {
            if(todo.id === action.payload) {
              return {
                ...todo,
                label: action.label
              }
            } else {
              return todo
            }
         })
        };
     case 'toggleShow':
       return {
         ...state,
         todos: state.todos.map(todo =>
           todo.id === action.payload ? { ...todo, show: !todo.show } : todo
         )
       };
      case 'toggleEdit':
        return {
          ...state,
          todos: state.todos.map(todo =>
             todo.id === action.payload ? { ...todo, edit: !todo.edit } : todo
          )
        };
    default:
      return state;
  }
}

export default function TodoList({
  todo_id,
  checkname,
  workflow_id,
  task_id,
  setOnActivity,
  allowEdit,
  simple,
  users
}){
  const [state, dispatch] = useReducer(reducer, initialState);
  const { todos, newTodoLabel, editTodoLabel } = state;
  let [mentions, setMentions] = useState([]);
  let [show, setShow] = useState(false);
  let [onEvent, setOnEvent] = useState(false);
  let [todo, setTodo] = useState();
  let refs = useRef([])
  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  let userName = localStorage.getItem('user_name');

  const [updateTodo] = useMutation(UpdateTodo);
  const { loading, refetch, data } = useQuery(TodoQuery, {
    variables: { id: Number(todo_id) },
  });

  useMemo(() => {
     let todos = []
     if(data && data.todo){
        todos = data.todo.json ? JSON.parse(data.todo.json) : []
     }
     dispatch({ type: "reset", payload: Object.values(todos), nextTodoId: random(8)})
    },[data]);

  useEffect(() => {
      if(todos && onEvent){
         updateTodo({variables: {id: Number(todo_id), json: JSON.stringify(todos)}})
         setOnEvent(false)
      }
  }, [onEvent]);

  useEffect(() => {
    refetch()
  },[todo_id])

  const handlePress = (value) => {
    //Add notify
    mentions.forEach( async({id, display}) => {
      if(id != user_id){
        //Add notify
        var user = users.find(u => u.id === id);
        if(user.notify === true) {
          sendNotification(user,userName,'mentioned');
        }
        //End Notify

        let notify_id = random(10);
        var notification = {
            id: task_id,
            name: value,
            user_id: id,
            user_name: display,
            email: user.email,
            send_name: userName,
            send_id: user_id,
            type: 'mention',
            read: 0,
            created_at: moment().valueOf()
          };
        db.collection('notifications').doc(app_id).collection(id).doc(notify_id).set(notification);
        if(user.notify === 1) {
          //Send email
          notificationAdd({
           id,
           app_id,
           task_id,
           user_name: display,
           send_name: userName,
           name: value,
           email: user.email,
           type: 'mention'
         })
       }
      }
    })

    historyAdd({
      name: userName,
      description: `${checkname}: ${translate('added')}, ${value}`,
      workflow_id,
      task_id,
      user_id
    })
    if(setOnActivity){
        setOnActivity(true)
    }
  }

  const addMention = async (id, display) => {
    var obj = mentions
    var data = {id, display}
    var list = []
    list.push(data)
    obj.forEach((value, key) => {
      if(value.id != id) {
        list.push(value)
      }
    })
    setMentions(list)
  }

  const removeTodo = async (todo) => {
    historyAdd({
      name: userName,
      description: `${checkname}: ${translate('removed')}, ${todo.label}`,
      workflow_id,
      task_id,
      user_id
    })
    dispatch({
      type: "remove",
      payload: todo.id
    })
    setOnActivity(true)
    setShow(false)
  }

  //Update on each event is changed
  let textInput = React.useRef();
  let textBtn = React.useRef();
  var percent = 0;

  if(todos && todos.length > 0){
    const result = todos.filter(todo => todo.done === true);
    percent = Math.round((result.length / todos.length) * 100, 0);
  }

  const todoCreate = () => {
    dispatch({ type: "add" })
    setOnEvent(true)
  }

  const todoEdit = (id, value) => {
    dispatch({ type: 'editTodo', payload: id, label: value })
    setOnEvent(true)
  }

  const todoToggle = (id) => {
    dispatch({
      type: "toggleTodo",
      payload: id
    })
    setOnEvent(true)
  }

  return (<div className="todo-list">
      {!simple && <ProgressBar striped variant="primary" now={percent} label={Math.round(percent) + "%"}/>}
      <ul className="nav mt-2">
        {todos && todos.map((todo, key) => (
          <li key={key} className="w-100 nav-todo nav-link py-1 px-0 d-flex justify-content-between align-items-center can-edit text-hover rounded">
            <Button disabled={!allowEdit} className="text-dark" size="sm" onClick={() => todoToggle(todo.id)}>
              <Icon className={todo.done ? "text-success" : "text-secondary"} icon={todo.done ? "check-circle" : "circle"} />
            </Button>
            {todo.edit ? <MentionsInput
              onKeyPress={({ key, target }) => {
                if(key === 'Enter'){
                  if(target.value.length == 0){
                    dispatch({ type: "remove", payload: todo.id })
                  }
                  dispatch({
                    type: "toggleEdit",
                    payload: todo.id
                  })
                  handlePress(target.value)
                }
              }}
              onChange={({ target }) => todoEdit(todo.id, target.value)}
              value={todo.label}
              ref={ref => refs.current[key] = ref}
              singleLine={true}
              className={"form-control input-great bg-white mentions p-0 mx-2 todo"}>
              <Mention
                trigger="@"
                data={users}
                markup='@__display__'
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div className="list-item">{highlightedDisplay}</div>
                )}
              />
            </MentionsInput> :
            <div onClick={() => {
              //Edit button
              if(allowEdit){
                dispatch({ type: "toggleEdit", payload: todo.id })
              }
            }} className={todo.done ? "w-100 text-start mx-2 great-done text-secondary" : "w-100 text-start mx-2 text-secondary"} dangerouslySetInnerHTML={{__html: todo.label}}></div>
            }
            {todo.edit && <Button size="sm" variant="link" size={'sm'} className="text-dark btn-edit" onClick={() =>{
              if(todo.label.length == 0){
                dispatch({ type: "remove", payload: todo.id })
              }
              dispatch({
                type: "toggleEdit",
                payload: todo.id
              })
              handlePress(todo.label)
            }
            }>
              <Icon icon={ "save" } className={"text-primary"}/>
            </Button>}

            <Button size="sm" variant="link" size={'sm'} className="text-dark btn-edit" onClick={() => {
              if(allowEdit){
                if(todo.label.length == 0){
                  dispatch({ type: "remove", payload: todo.id })
                }
                dispatch({ type: "toggleEdit", payload: todo.id })
              }
            }
            }>
              <Icon icon={todo.edit ?  "close" : "create"}/>
            </Button>

            <Button
              size="sm"
              className="arrow"
              onClick={() => {
                if(allowEdit){
                  setShow(true)
                  setTodo(todo)
                }
              }}
            >
              <Icon icon="ellipsis-horizontal" className={"text-secondary"}/>
            </Button>

          </li>
        ))}
      </ul>
     {allowEdit &&
      <div className="w-100 nav-link p-0 d-flex justify-content-between align-items-center">
        <MentionsInput
          singleLine={true}
          placeholder={`${translate('addNewItem')}, @`}
          onChange={({ target }) => {
            dispatch({
              type: "updateLabel",
              payload: target.value
            })
            }
          }
          onKeyPress={({ key, target }) => {
            if(key === 'Enter' && target.value.length > 0){
              todoCreate()
              handlePress(target.value)
            }
          }}
          ref={textInput}
          value={newTodoLabel}
          className={"form-control input-great mentions p-0 todo border-bottom"}>
          <Mention
            trigger="@"
            onAdd={addMention}
            data={users}
            markup='@__display__'
            renderSuggestion={(suggestion, search, highlightedDisplay) => (
              <div className="list-item">{highlightedDisplay}</div>
            )}
          />
        </MentionsInput>
        {newTodoLabel &&
        <Button size="sm" ref={textBtn} className={"text-secondary"} variant="link" onClick={() => {
            if(newTodoLabel.length > 0){
              todoCreate()
              handlePress(newTodoLabel)
            }
            setTimeout(() => {
              textInput.current?.focus()
            }, 600)
          }}>{translate('add')}</Button>
        }
      </div>}
      {show &&
      <Toast placement="right" show={show} onClose={() => setShow(false)}>
       <span className="w-100">{`${translate('delete')} ${todo.label}`}</span>
       <div className="p-2 text-center">
          <Button
            size="sm"
            onClick={() => removeTodo(todo)}
            variant="danger"
          >
            {translate('delete')}
          </Button>
        </div>
    </Toast>}
    </div>
  )
};
