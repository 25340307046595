import React, { useState } from 'react';
import Comment from '../Comment';
import AddComment from '../Comment/AddComment';

export default function TaskComment({task, users, setContent, allowEdit, location, workflow_id}){

  const [onEvent, setOnEvent] = useState(false);
  const [onActivity, setOnActivity] = useState(false);
  const [fileResult, setFileResult] = useState("")
  console.log(onActivity);
    return (
      <>
        <AddComment task_id={task.id} task_name={task.name} workflow_id={workflow_id} user_id={task.user_id} fixed={true} setOnActivity={setOnActivity} setFileResult={setFileResult} setOnEvent={setOnEvent} allowEdit={allowEdit} location={location} teamUsers={users}/>
        <Comment task_id={task.id} task_name={task.name} workflow_id={workflow_id} user_id={task.user_id} fixed={true} onActivity={onActivity} setOnActivity={setOnActivity} allowEdit={allowEdit} setFileResult={setFileResult} setContent={setContent}/>
      </>
   );

}
