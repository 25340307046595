import React, { useState, useEffect } from 'react';
import moment from "moment";
import Icon from '../../components/Icon';
import { Modal  } from '../../components/Uix';
import { translate } from '../../services/Language';
import { db } from '../../services/FirebaseInit';


import {
  Link
} from "react-router-dom";

export default function Notifications({badge, setBadge, location, setLink, ...props}){

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  var [data, setData] = useState([]);
  var currentPath = location?.pathname;

  const ref = React.useRef(null);
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false)
    setLink('')
  }
  const handleShow = () => setShow(true);
  const [onEvent, setOnEvent] = useState(true);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if(!!app_id && !!user_id) {
      db.collection('notifications').doc(app_id).collection(user_id).orderBy('created_at', 'desc').limit(50).onSnapshot(snapshot => {
        const Items = [];
        snapshot.forEach(doc => {
          Items.push({
            id: doc.data().id,
            name: doc.data().name,
            send_name: doc.data().send_name,
            send_id: doc.data().send_id,
            email: doc.data().email,
            user_name: doc.data().user_name,
            user_id: doc.data().user_id,
            type: doc.data().type,
            created_at: doc.data().created_at
          });
          db.collection('notifications').doc(app_id).collection(user_id).doc(doc.id).update({read: true});
        });

        setData(Items)

      },(error) => {
        //console.log('Error getting documents', error);
      });
    }
  },[]);

  return (
    <div className="position-relative">
    <Modal show={show} onClose={handleClose} placement="top" sideLeft={80} sideTop={-225} size="md">
      <div className="overflow-y" style={{height: 500}}>
      <ul className="nav flex-column">
          {data.map((link, key) => {
            var type = link.type
            var url = `/w/t/${link.id}`;
            var icon = "check";
            var color = "#FFCC67";
            var reminder = "";
            var title = translate('added');

            if(type === 'workflow'){
                url = `/w/a/${link.id}`;
                icon = "hashtag";
                color = "#1FB7F0";
            }
            if(type === 'reminder'){
                title = translate('closeExpire');
            }
            if(type === 'mention'){
                title = translate('mention')
            }
            if(type === 'createTask'){
                title = translate('createTask')
            }

            return(
            <li className={link.read ? "nav-item px-0 text-start bg-light mb-1 rounded" : "nav-item text-start mb-1 rounded bg-active px-0"} key={key}>
                <Link to={url} onClick={() => setShow(false)} className="nav-link px-2 d-flex justify-content-start align-items-center">
                  {link.send_name && <div style={{position: 'relative'}}>
                    <span className={"bg-secondary avatar text-light primary rounded-pill"}>
                      {link.send_name.toString().charAt(0)}
                    </span><Icon icon={icon} color={color} style={{position: 'absolute',top: 5, left: 30}}/></div>}
                  <div>
                    <label className="text-start ms-4">
                      <span className="fw-bold">
                       {link.send_name}
                       </span> {title}
                    </label>
                    <div className="ms-4">
                        <div className="fw-bold">{link.name}</div>
                        <small className="text-secondary ms-2">{moment(link.created_at).fromNow()}</small>
                     </div>
                  </div>
                </Link>
            </li>
          )}
        )}
      </ul>
      </div>
    </Modal>
    </div>
  );
}
