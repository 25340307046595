import React, { useState } from "react";
import Icon from '../../components/Icon';
import { Button } from '../../components/Uix';
import Confirm from '../Confirm';
import colors from "../../data/Colors"
import moment from "moment"

export default function Comment({
  comment,
  allowEdit,
  setContent,
  setOnActivity,
  ...props
}) {

  let userId = localStorage.getItem('user_id');
  const [show, setShow] = useState(true);
  const renderAttach = ({attach, workflow_id, task_id}) => {
    let data = attach ? JSON.parse(attach) : []
    if(data){
      return(
        <>
          {Array.isArray(data) && data.map((row, key) => {
            switch (row.type) {
              case 'mail':
                 return (<span key={key} className="fw-bold small"><Icon color={colors[0]} icon="mail"/> {row.name}</span>)
              case 'call':
                 return (<span key={key} className="fw-bold small"><Icon color={colors[1]} icon="call"/> {row.name}</span>)
              case 'meet':
                 return (<span key={key} className="fw-bold small"><Icon color={colors[2]} icon="calendar"/> {row.name}</span>)
              case 'note':
                 return (<span key={key} className="fw-bold small"><Icon color={colors[3]} icon="document-text"/> {row.name}</span>)
             case 'chat':
                return (<span key={key} className="fw-bold small"><Icon color={colors[4]} icon="chatbubbles"/> {row.name}</span>)
              default:
                return (<span key={key} className="fw-bold small"> {row.name}</span>);
            }
          })}
        </>
      )
    }
  }

  return (
    <>
    <div className="d-flex justify-content-between align-items-center can-edit">
      <div>

      <Button variant="link" className="p-0 m-0 d-flex text-secondary align-items-center" onClick={() => setShow(!show)}>
          {renderAttach(comment)}
          <Icon icon={show ? "chevron-down" : "chevron-up"} size="sm" className={"text-secondary ms-1"}/>
     </Button>
    {show &&
     <>
      {comment.description && <div className="small" dangerouslySetInnerHTML={{__html: comment.description.replace(/\n\r?/g, '<br />')}}></div>}
      {comment.remind && <div className="small"><Icon icon="time"/>{moment(Number(comment.remind)).calendar()}</div>}
     </>}

      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex text-secondary align-items-center">
          <div className="avatar rounded-pill d-inline-block bg-light">{comment.name.split(' ').slice(-1)[0].toString().charAt(0)}</div>
          {/*<span className="small ms-2">{moment(Number(comment.created_at)).fromNow()}</span>*/}
        </div>
        <div>
        {comment.user_id === userId &&
           <Confirm type="comment" id={comment.id} title={comment.name} setOnDelete={setOnActivity} icon={true} customIcon="ellipsis-horizontal" placement="right"/>}
        </div>
      </div>

    </div>

    </>
  );
}
