import React, { useState } from "react";
import { Button } from '../../../components/Uix';
import ImageUpload from '../../../components/Media/Image';
import Unsplash from '../../../components/Media/Unsplash';
import { translate } from '../../../services/Language';

export default function ImageControl({confirmBlock}) {

  const [urlValue, setUrlValue] = useState("")

  return (
  <div className="px-4 pb-4">
    <h4>{translate('image')}</h4>
    <label>{translate('url')}</label>
   <input
     className="form-control"
     onChange={(e) => setUrlValue(e.target.value)}
     type="text"
     value={urlValue}
    />
    <div className="my-3">
      <ImageUpload setUrlValue={setUrlValue} label={translate('fromComputer')}/>
    </div>
    <div className="my-3">
      <Unsplash setUrlValue={setUrlValue} urlValue={urlValue}/>
    </div>
    <Button onClick={() => confirmBlock('IMAGE', urlValue)} variant={urlValue?"primary":"light"} icon={'add'} title={translate('insertImage')} size={24}/>
   </div>);

}
