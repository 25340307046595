import React, { useState , useEffect, useMemo, useRef} from "react";
import { Menu, Dropdown, Button } from '../../components/Uix';
import Editor from '../../components/Editor';
import Icon from '../../components/Icon';
import MailSend from '../../components/Email/MailSend';
import { translate } from '../../services/Language';
import { sendNotification } from '../../services/Api';
import { CreateComment } from '../../services/GraphqlApi';
import { useQuery, useMutation } from "@apollo/client";
import { notificationAdd } from '../../store/notification';
import Confirm from '../Confirm';
import DatePicker from "react-datepicker";
import { db } from '../../services/FirebaseInit';
import activityList from '../../data/Activity';
import moment from "moment"

export default function AddActivity({
  workflow_id,
  task_id,
  task_name,
  setOnActivity,
  setFileResult,
  setOnEvent,
  allowEdit,
  thread_id
}) {

  const [commentValue, setCommentValue] = useState("");
  const [nameValue, setNameValue] = useState();
  const [onDelete, setOnDelete] = useState(false);
  const [bodyValue, setBodyValue] = useState("");
  const [dataValue, setDataValue] = useState([]);
  const [type, setType] = useState('');
  const [dateField, setDateField] = useState();
  const [remind, setRemind] = useState();
  const [mailData, setMailData] = useState();

  const app_id = localStorage.getItem('app_id');
  const user_id = localStorage.getItem('user_id');
  const userName = localStorage.getItem('user_name');
  const [createComment] = useMutation(CreateComment);

  const handleUpdate = async (type, content) => {
    if(commentValue !== '' || content) {
      let attach = []
      attach.push({
        id: type.type,
        name: translate(type.name),
        type: type.type
      })
      createComment( {variables: {
        name: userName,
        description: commentValue || content,
        attach: JSON.stringify(attach),
        type: 'activity',
        remind,
        workflow_id,
        task_id,
        user_id
      }})

      setCommentValue("")
      setNameValue("")
      setOnActivity(true)
      setType('')
    }
  };

  useEffect(() => {
    if(mailData){
      handleUpdate({type: 'mail', name: 'emails'}, mailData)
    }
  },[mailData])

  return (
    <>
     <div className="position-relative mb-3">
       <Menu icon={'add'} title={translate('addNew')} placement="left" variant="primary" customWidth={616}>
       {activityList.map((list, key) =>
         <Button variant="link" onClick={() => {
           setType(list);
         }}>
             <Icon icon={list.icon} className={"text-secondary"}/>
             <span className={type === list.type ? "ms-1 text-primary fw-bold":"ms-1"}>{translate(list.name)}</span>
        </Button>
       )}
       </Menu>
       {type && <div className="bg-light rounded border p-3 my-3">
        <div className="py-2 fw-bold"><Icon icon={type.icon} size="md"/> {translate(type.name)}</div>
       {(type && type.type !== "mail") && <> <Editor fields={[]} setData={setDataValue} setBody={setCommentValue} data={null}/>

      <div className="d-flex justify-content-end align-items-center py-2">
       <Menu icon="time" title={remind??translate('remind')} placement="right" customWidth={360}>
         <div className="justify-content-center text-center">
         <div className="lead my-2 ps-2 text-start w-100">{translate('date')}</div>
         <DatePicker
           selected={dateField}
           onChange={date => {
             var dt = moment(new Date(date))
             setRemind(dt.format('YYYY-MM-DD HH:mm'))
             setDateField(date)
           }}
           showTimeSelect
           showPopperArrow={false}
           locale="vi"
           inline
           timeFormat="HH:mm"
           dateFormat="d MMMM, yyyy H:mm aa"
         />
         </div>
       </Menu>
       <Button variant={"secondary"} className="mx-3" onClick={() => setType('')}>
         {translate('cancel')}
       </Button>
       <Button variant={commentValue.length > 7?"primary":"light"} onClick={() => commentValue.length > 7 ? handleUpdate(type, commentValue): null}>
         <Icon icon="paper-plane" size={'md'}/> {translate('send')}
       </Button>
       </div>
       </>}
       {(type && type.type === "mail") && <MailSend emailValue="" setData={setMailData} setCancel={setType}/>}
       </div>}
     </div>
    </>
  );
}
