import React, { useState, useMemo } from "react";
import InputFile from './InputFile';
import InputText from './InputText';
import InputArea from './InputArea';
import InputNumber from './InputNumber';
import InputSelect from './InputSelect';
import InputStatus from './InputStatus';
import InputEmail from './InputEmail';
import InputPhone from './InputPhone';
import InputDate from './InputDate';
import InputColor from './InputColor';
import InputURL from './InputURL';
import InputUser from './InputUser';
import ViewMirror from './ViewMirror';

export default function InputMirror({
  workflow_id,
  dispatch,
  field,
  schema,
  setOnEvent,
  allowEdit,
  task,
  users
}){

  const [type, setType] = useState("");

  useMemo(() => {
    let column = Object.values(schema).find(c => c.id == field.connect);
    let connect = (column && column.connect) ? JSON.parse(column.connect) : []
    let type = connect.find(c => c.id === field.ref)
    setType(type)
  },[schema])

  switch (type?.field) {
    case 'user':
      return <InputUser field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task} users={users}/>
      break;
    case 'url':
      return <InputURL field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'color':
      return <InputColor field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'email':
      return <InputEmail field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'phone':
      return <InputPhone field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'date':
      return <InputDate field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'select':
      field.enum = type?.enum;
      return <InputSelect field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'status':
      field.enum = type?.enum;
      return <InputStatus field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'text':
      return <InputText field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'integer':
      return <InputNumber field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'textarea':
      return <InputArea field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    case 'file':
      return <InputFile field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit} task={task}/>
      break;
    default:
     return <ViewMirror field={field} dispatch={dispatch} setOnEvent={setOnEvent} allowEdit={allowEdit}/>
  }

};
