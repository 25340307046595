
function reducer(state, action) {
  switch (action.type) {
     case "reset":
      return {
        ...state,
        groups: action.payload
      };
      case 'reorder':
      return {
        ...state,
        groups: state.groups.map(group =>
           group.id === action.payload ? { ...group, index: action.index } : group
         )
      };
      case 'collapse':
        return {
        ...state,
        groups: state.groups.map(group =>
          group.id === action.payload ? { ...group, open: action.open } : group
        )
      };
      case 'collapseAll':
        return {
        ...state,
        groups: state.groups.map(group => ({ ...group, open: action.payload }))
      };
    default:
      return state;
  }
}

function reducerTask(state, action) {
  switch (action.type) {
     case "reset":
      return {
        ...state,
        tasks: action.payload
      };
      case 'reorder':
      return {
        ...state,
        tasks: state.tasks.map(task =>
           task.id === action.payload ? { ...task, index: action.index } : task
         )
      };
      case 'updateTitle':
      return {
        ...state,
        tasks: state.tasks.map(task =>
           task.id === action.payload ? { ...task, name: action.title } : task
         )
      };
      case 'updateDate':
      return {
        ...state,
        tasks: state.tasks.map(task =>
           task.id === action.payload ? { ...task, due_date: action.date } : task
         )
      };
      case 'updateStatus':
      return {
        ...state,
        tasks: state.tasks.map(task =>
           task.id === action.payload ? { ...task, status: action.status } : task
         )
      };
      case 'addTask':
      if(action.payload.id && action.payload?.name != state.tasks[state.tasks.length-1]?.name) {
        return {
          tasks: [
            ...state.tasks,
            action.payload,
          ]
        };
      }
      case 'removeTask':
        return {
          tasks: state.tasks.filter(t => t.id != action.payload)
        };
      case 'move':
      return {
        ...state,
        tasks: state.tasks.map(task =>
           task.id === action.payload ? { ...task, stage_id: action.stage_id, index: action.index } : task
         )
      };
    default:
      return state;
  }
}

export {
  reducerTask,
  reducer
};
