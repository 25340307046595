import axios from "axios";
import qs from 'qs';

let localUrl = process.env.REACT_APP_SERVER_LOCAL
let proURL = process.env.REACT_APP_SERVER_URL
let mode = process.env.NODE_ENV;

const googleDrive = async (code) => {
  let response = await axios({
      baseURL: mode === 'development' ?  localUrl : proURL,
      url: `/drive/list`,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: qs.stringify({
        code
      })
   });
   return await response.data;
}


export { googleDrive }
