import React, { useState , useEffect } from "react";
import { EditorState, convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';

import BoardView from '../../pages/workflow/BoardView';
import { translate } from '../../services/Language';
const APP_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_URL : process.env.REACT_APP_SITE_URL;

export function useEditorView(data) {

  const { useConvertToHTML } = useEditor()
  const [content, setContent] = useState(null);

    useEffect(() => {
      function dataChange(data) {
        if(data && data.blocks){
          const blocks = convertFromRaw(data);
          let editorState = EditorState.createWithContent(blocks)
          setContent(convertToHTML(useConvertToHTML)(editorState.getCurrentContent()))
        }else {
          return null;
        }
      }

      dataChange(data);
      return () => dataChange;
    },[data]);

  return content;

}

export function useEditor() {
  const findLinkEntities = (contentBlock, callback, contentState) => {
     contentBlock.findEntityRanges(
       (character) => {
         const entityKey = character.getEntity();
         return (
           entityKey !== null &&
           contentState.getEntity(entityKey).getType() === 'LINK'
         );
       },
       callback
     );
   }

   const promptForLink = (editorState) => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      return url;
    }
  }

   const findImageEntities = (contentBlock, callback, contentState) => {
     contentBlock.findEntityRanges(
       (character) => {
         const entityKey = character.getEntity();
         return (
           entityKey !== null &&
           contentState.getEntity(entityKey).getType() === 'IMAGE'
         );
       },
       callback
     );
   }

   const findIframeEntities = (contentBlock, callback, contentState) => {
     contentBlock.findEntityRanges(
       (character) => {
         const entityKey = character.getEntity();
         return (
           entityKey !== null &&
           contentState.getEntity(entityKey).getType() === 'VIDEO'
         );
       },
       callback
     );
   }

   const findBoardEntities = (contentBlock, callback, contentState) => {
     contentBlock.findEntityRanges(
       (character) => {
         const entityKey = character.getEntity();
         return (
           entityKey !== null &&
           contentState.getEntity(entityKey).getType() === 'BOARD'
         );
       },
       callback
     );
   }

   const findTableEntities = (contentBlock, callback, contentState) => {
     contentBlock.findEntityRanges(
       (character) => {
         const entityKey = character.getEntity();
         return (
           entityKey !== null &&
           contentState.getEntity(entityKey).getType() === 'TABLE'
         );
       },
       callback
     );
   }

  const findHashtagEntities = (contentBlock, callback) => {
    let text = contentBlock.getText();
    let startIndex = 0;
    let counter = 0;
    let hashCharacter = "#"
    let separator = " ";

    for (; text.length > 0 && startIndex >= 0; ) {
      if (text[0] === hashCharacter) {
        startIndex = 0;
        counter = 0;
        text = text.substr(hashCharacter.length);
      } else {
        startIndex = text.indexOf(separator + hashCharacter);
        if (startIndex >= 0) {
          text = text.substr(
            startIndex + (separator + hashCharacter).length
          );
          counter += startIndex + separator.length;
        }
      }
      if (startIndex >= 0) {
        const endIndex =
          text.indexOf(separator) >= 0
            ? text.indexOf(separator)
            : text.length;
        const hashtagText = text.substr(0, endIndex);
        if (hashtagText && hashtagText.length > 0) {
          callback(
            counter,
            counter + hashtagText.length + hashCharacter.length
          );
          counter += hashCharacter.length;
        }
      }
    }
  };

  const styleMap = {
     'STRIKETHROUGH': {
       textDecoration: 'line-through',
     },
     'red': {
        color: 'rgba(255, 0, 0, 1.0)',
      },
      'orange': {
        color: 'rgba(255, 127, 0, 1.0)',
      },
      'yellow': {
        color: 'rgba(180, 180, 0, 1.0)',
      },
      'green': {
        color: 'rgba(0, 180, 0, 1.0)',
      },
      'blue': {
        color: 'rgba(0, 0, 255, 1.0)',
      },
      'indigo': {
        color: 'rgba(75, 0, 130, 1.0)',
      },
      'violet': {
        color: 'rgba(127, 0, 255, 1.0)',
      }
  };

   const useConvertToHTML = {
      styleToHTML: (style) => {
        if (style === 'BOLD') {
          return <span style={{fontWeight: 'bold'}} />;
        }
        if (style === 'ITALIC'){
          return <span style={{fontStyle: 'italic'}} />;
        }
        if (style === 'UNDERLINE') {
          return <span style={{textDecoration: 'underline'}} />;
        }
        if (style === 'STRIKETHROUGH') {
          return <span style={{textDecoration: 'line-through'}} />;
        }
        if (style === 'red') {
          return <span style={{color: 'rgba(255, 0, 0, 1.0)'}} />;
        }
        if (style === 'green') {
          return <span style={{color: 'rgba(0, 180, 0, 1.0)'}} />;
        }
      },
      blockToHTML: (block) => {
        const blockAlignment = block?.data && block.data?.textAlign;
        if (block.type === 'PARAGRAPH') {
          return <p />;
        }
        if (block.type === 'header-one') {
          return <h1 />;
        }
        if (block.type === 'header-two') {
          return <h2 />;
        }
        if (block.type === 'header-three') {
          return <h3 />;
        }
        if (block.type === 'unordered-list-item') {
          return {
            start: '<li>',
            end: '</li>',
            nestStart: '<ul>',
            nestEnd: '</ul>',
          }
        }
        if (block.type === 'ordered-list-item') {
          return {
            start: '<li>',
            end: '</li>',
            nestStart: '<ol>',
            nestEnd: '</ol>',
          }
        }
        if (blockAlignment === 'center') {
          return {
            start: '<div style="text-align: center;">',
            end: '</div>'
          }
        }
        if (blockAlignment === 'right') {
          return {
            start: '<div style="text-align: right;">',
            end: '</div>'
          }
        }
        if (blockAlignment === 'left') {
          return {
            start: '<div style="text-align: left;">',
            end: '</div>'
          }
        }
        if (block.type === 'atomic') {
          return {
            start: '',
            end: ''
          }
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url} className="link">
            {originalText}
          </a>;
        }
        if (entity.type === 'IMAGE') {
          return <img src={entity.data.src} className="img-fluid"/>;
        }
        if (entity.type === 'VIDEO') {
          return <iframe src={entity.data.src} frameBorder="0" allowFullScreen width={'100%'} height={600}/>;
        }
        if (entity.type === 'BOARD') {
          const shareLink = `${APP_URL}/view/${entity.data.board_id}#iframe`;
          return <iframe src={shareLink} frameBorder="0" width={"100%"} height={900} allowFullScreen/>;
        }
        return originalText;
      }
   }

  return {
    styleMap,
    promptForLink,
    findBoardEntities,
    findLinkEntities,
    findImageEntities,
    findIframeEntities,
    findTableEntities
  };

}
