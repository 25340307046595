import React, { useEffect, useState } from "react";
import { translate } from '../../services/Language';
import {Form} from '../../components/Uix';

export default function InputPhone({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  ...props
}){

  const [nameValue, setNameValue] = useState("");

  useEffect(() => {
    setNameValue(field.value)
  }, [field.value]);

  const handleChange = (phone) => {
     setNameValue(phone)
     dispatch({ type: 'updateField', payload: field.id, value: phone })
     setOnEvent(true)
  }

 const inputContent = (mode) => {
   return(
     <div className="w-100">
       <Form.Phone
         className={"input-great w-100"}
         country={'vn'}
         value={nameValue}
         onTextChange={phone => handleChange(phone)}
         disabled={!mode}
      />
     </div>
   )
 }

  return (<>{inputContent(allowEdit)}</>)
};
