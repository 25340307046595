import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import Icon from '../../components/Icon';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Modal, ButtonGroup, Button, Menu, Item } from '../../components/Uix';
import { UserQuery } from '../../services/GraphqlApi';
import { imageStorage } from '../../services/Function';
import { useQuery } from "@apollo/client";
import { motion } from 'framer-motion';
import Workspace from '../Workspace';
import Photo from './Photo';

// context
import { useUserDispatch, signOut } from "../../context/UserContext";
import { useModeState, useModeDispatch, toggleMode } from "../../context/ModeContext";
import { translate, i18nConfig } from '../../services/Language';

const locales = [
  {key: 'vi', name: 'Vietnamese'},
  {key: 'en', name: 'English'}
]

let localUrl = process.env.REACT_APP_API_LOCAL
let proURL = process.env.REACT_APP_API_URL
let mode = process.env.NODE_ENV;
let SERVER_URL = mode === 'development' ? localUrl : proURL

export default function Profile({ location, history, app, full, color, live, placement, slashMotion, ...props}){

  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  let appName = localStorage.getItem('app_name');
  let locale = localStorage.getItem('locale');
  var [user, setUser] = useState([]);
  const [show, setShow] = useState(false);

  var userDispatch = useUserDispatch();
  var modeDispatch = useModeDispatch();
  var { isDarkMode } = useModeState();

  const { data } = useQuery(UserQuery, {
    variables: { id: user_id},
  });

  useMemo(() => {
    if(data && data.user){
      setUser(data.user)
    }
  },[data])

  const profiles = [
    //{ id: 0, name: `${appName} - ${translate('workspace')}`, link: "/w/workspace", icon: "home"},
    { id: 1, name: translate('profile'), link: "/w/settings", hash: 'profile', icon: "person"},
    //{ id: 2, name: translate('team'), link: '/w/team', icon: "users"},
    //{ id: 3, name: translate('data'), link: "/w/setting", hash: 'data', icon: "cloud"},
    { id: 4, name: live ? translate('billing') : translate('upgrade'), link: `/apps/${app_id}`, icon: "rocket", hash: 'st1'}
  ];

  const handleClick = async(locale) => {
    localStorage.setItem('locale', locale);
    i18nConfig(locale)
    window.location.reload();
  }

  const customTitle = (
    <div className="justify-content-center align-items-center">
      {(user && user.photo) ? <div className="d-flex align-items-center"><img className="avatar-md rounded-pill d-inline-block" src={imageStorage(user.photo)}/> <motion.div variants={slashMotion} className="ms-1 text-nowrap text-start">{user.name}</motion.div></div>: <div className="d-flex align-items-center"><div className="avatar-md rounded-pill d-inline-block bg-light"><Icon icon="person" size="md" color={color} className="rounded-circle profile-photo"/></div><motion.div variants={slashMotion} className="ms-1 text-nowrap text-start">{user.name}</motion.div></div>}
      {full && <span className="ms-2">{app.name}</span>}
    </div>
  )
  return (
    <>
      <Menu title={customTitle} placement={placement??"left"} customWidth={220} btnStyle={{width: '100%'}}>
        <div className="w-100 fw-bold">
          <div className="px-3 border-bottom py-2">
            <div className="text-secondary">{`${user.name}`}</div>
          </div>
          <div className="">
            <Button icon="house" className="d-block w-100" onClick={() => setShow(true)}>
              <div className="d-flex justify-content-between align-items-center">
                <span><Icon icon="house"/> {appName??'Workspace'}</span>
                <Icon icon={'chevron-forward'}/>
              </div>
            </Button>

            {profiles.map(link => (
              <Item key={link.id}>
                <Link className="btn btn-link" to={{pathname: link.link, hash: link.hash}}>
                <Icon icon={link.icon}/> {link.name}</Link>
              </Item>
            ))}

            <Button icon={isDarkMode ? "moon-stars-fill" : "moon-stars"} onClick={() => {
              toggleMode(modeDispatch, isDarkMode)
            }} title={translate('darkMode')} />


            <ButtonGroup>
              {locales.map((l, key) =>
                <Button className={locale === l.key ? "text-dark" : "text-secondary"} key={key} onClick={() => handleClick(l.key)} variant="light">{l.name}</Button>
              )}
            </ButtonGroup>
          </div>
          <Button title={translate('logout')} icon="logout" className="border-top mt-2 w-100 text-start" onClick={() => signOut(userDispatch, props.history)} />
        </div>
      </Menu>

      <Modal show={show} onClose={() => setShow(false)}>
        <Workspace history={history} show={show} history={history}/>
        <Button variant="danger" onClick={() => setShow(false)}>
            {translate('cancel')}
        </Button>
      </Modal>
      <Photo user={app.user}/>
    </>
  );

}
