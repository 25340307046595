import React, { useEffect, useState } from "react";
import { Button, Modal, Menu, Toast } from '../../../components/Uix';
import Icon from '../../../components/Icon';
import Image from './Image';
import Video from './Video';
import Color from './Color';
import Link from './Link';
import Table from './Table';
import Board from './Board';
import Field from './Field';
import Emoji from './Emoji';
import { translate } from '../../../services/Language';
import items from '../../../data/Editor';

export default function AddEditor({confirmBlock, confirmField, confirmEmoji, editorState, onColorToggle, confirmLink, removeLink, onTypeToggle, boards, fields, addButton}) {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [item, setItem] = useState(null);

  useEffect(() => {
    if(addButton) {
      setOpen(true)
    }
    return () => setOpen(false)
  },[addButton])

  const ModalContent = () => {
    switch (item) {
      case "link":
        return <Link confirmLink={confirmLink} removeLink={removeLink}/>
      case "color":
        return <Color confirmBlock={confirmBlock} editorState={editorState} onColorToggle={onColorToggle}/>
      case "image":
        return <Image confirmBlock={confirmBlock}/>
      case "video":
        return <Video confirmBlock={confirmBlock}/>
      case "board":
        return <Board confirmBlock={confirmBlock} boards={boards}/>
      case "field":
        return <Field confirmField={confirmField} fields={fields}/>
      case "table":
        return <Table confirmBlock={confirmBlock}/>
      case "emoji":
        return <Emoji confirmEmoji={confirmEmoji}/>
      default:
      return;
    }
  }
  return (<>
    <Menu icon="add" title={null} placement="left" onToggle={()=>setHide(false)} show={open} autoHide={show||hide}>
      <div style={{height: 350}} className="overflow-auto scroll-style">
      {items.map((item, key) => <Button icon={item.icon} className={`d-block w-100 text-start my-1 ${key < items.length-1 && "border-bottom"}`} onClick={() => {
        if(item.type === "toggle"){
          onTypeToggle(item.name);
          setHide(true)
        }else {
          setShow(true);
          setItem(item.name)
        }
      }} title={translate(item.title)}/>)}
      </div>
    </Menu>
    <Modal show={show} backdrop={false} center onClose={() => setShow(false)}>
      {<ModalContent />}
    </Modal>
  </>);

}
