import React from "react";
import { translate } from '../../services/Language';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Form = ({
  children,
  onSubmit,
  method,
  action
}) => {

   return (
    <form onSubmit={onSubmit} action={action} method={method} className="mb-3">
      {children}
    </form>
  );
}

const FormInput = ({useRef, value, onTextChange, onTextPress, label, description, disabled, placeholder, maxlength, type, onMouseOut, className,readOnly, name, required}) => {
  return (
    <div>
       {label && <label htmlFor={`input${label}`} className="form-label">{label}</label>}
       <input ref={useRef}
       type={type??"text"}
       value={value}
       name={name}
       readOnly={readOnly}
       placeholder={placeholder}
       maxLength={maxlength}
       disabled={disabled}
       required={required}
       className={`form-control ${className}`}
       onMouseOut={() => {
         if(onMouseOut) onMouseOut()
       }}
       onChange={({target}) => {
         if(onTextChange) onTextChange(target.value)
       }}
       onKeyPress={({ key, target }) => {
         if(key === 'Enter' && target.value.length > 0){
           if(onTextPress) onTextPress()
         }
       }}/>
       {description && <div className="form-text">{description}</div>}
     </div>
  )
}

const FormTextarea = ({ref, rows, value, onTextChange, onKeyPress, label, description, disabled, placeholder, maxlength, className}) => {
  return (
    <div>
       {label && <label htmlFor={`input${label}`} className="form-label">{label}</label>}
       <textarea ref={ref}
       type={"textarea"}
       value={value}
       placeholder={placeholder}
       maxLength={maxlength}
       disabled={disabled}
       className={`form-control ${className}`}
       rows={rows??3}
       onChange={({target}) => {
         if(onTextChange) onTextChange(target.value)
       }}
       onKeyPress={({ key, target }) => {
         if(key === 'Enter' && target.value.length > 0){
           if(onKeyPress) onKeyPress()
         }
       }}/>
       {description && <div className="form-text">{description}</div>}
     </div>
  )
}

const FormCheck = ({checked, onTextChange, label, disabled}) => {
  return (
    <div className="form-check d-flex align-items-center" style={{lineHight: 44}}>
       <input type="checkbox"
       id={`check${label}`}
       className="form-check-input"
       disabled={disabled}
       value={checked}
       onChange={({target}) => {
         if(onTextChange) onTextChange(target.checked?true:false)
       }} checked={checked}/>
       {label && <label className="form-check-label ms-2" htmlFor={`check${label}`}>{label}</label>}
     </div>
  )
}

const FormOneCheck = ({checked, onTextChange, label, value, disabled}) => {
  return (
    <div className="form-check d-flex align-items-center" style={{lineHight: 44}}>
       <input type="checkbox"
       id={`check${label}`}
       className="form-check-input"
       disabled={disabled}
       value={value}
       onChange={({target}) => {
         if(onTextChange) onTextChange(target.value)
       }} checked={checked}/>
       {label && <label className="form-check-label ms-2" htmlFor={`check${label}`}>{label}</label>}
     </div>
  )
}

const FormRadio = ({checked, onTextChange, label, value, disabled, description, name}) => {
  return (
    <div className="form-check">
        <input checked={checked} disabled={disabled} onChange={({target}) => onTextChange(target.value)} value={value} name={name} className="form-check-input" type="radio" name="flexRadioDefault" id={`radios-${label}`} />
        <label className="form-check-label" htmlFor={`radios-${label}`}>
          {label}
        </label>
        {description && <div className="form-text">{description}</div>}
      </div>
  )
}

const FormSelect = ({options, onTextChange, label, value, select2}) => {
  if(select2) {
    return (
      <div className="mb-3">
         {label && <label htmlFor={`input${label}`} className="form-label">{label}</label>}
         <select className="form-select" onChange={({target}) => onTextChange(target.value)}>
            <option value="">----</option>
           {options && options.map((item, key) => <option key={key} selected={item.id === value} value={item.id} defaultValue={value}>{item.name}</option>)}
         </select>
      </div>
    )
  }else {
    return (
      <div className="mb-3">
        <label htmlFor={`input${label}`} className="form-label">{label}</label>
        <select className="form-select" onChange={({target}) => onTextChange(target.value)}>
           {options && options.map((item, key) => <option key={key} selected={item === value} value={item} defaultValue={value}>{item}</option>)}
         </select>
      </div>
    )
  }
}

const FormPhone = ({ref, value, onTextChange, onKeyPress, label, description, disabled, placeholder, country, className}) => {
  return (
    <div className="w-100">
       {label && <label htmlFor={`input${label}`} className="form-label">{label}</label>}
       <PhoneInput
       ref={ref}
       value={value ? value.toString() : null}
       country={country??'vi'}
       placeholder={placeholder}
       disabled={disabled}
       inputClass={`form-control border-0 ${className}`}
       rows={3}
       onChange={phone => {
         if(onTextChange) onTextChange(phone)
       }}
       onKeyPress={({ key, target }) => {
         if(key === 'Enter' && target.value.length > 0){
           if(onKeyPress) onKeyPress()
         }
       }}/>
       {description && <div className="form-text">{description}</div>}
     </div>
  )
}

Form.Input = FormInput;
Form.Select = FormSelect;
Form.Check = FormCheck;
Form.OneCheck = FormOneCheck;
Form.Radio = FormRadio;
Form.Textarea = FormTextarea;
Form.Phone = FormPhone;

export default Form;
