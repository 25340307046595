import React, { useState, useEffect } from 'react';
import { Form, Button, Tooltip, Modal, Tab, Item, Spinner } from '../../components/Uix';
import Icon from '../../components/Icon';
import TaskWorkflow from '../../components/Workflow/TaskWorkflow';
import { translate } from '../../services/Language';
import Comment from './Comment';
import File from './File';
import Task from './Task';
import Todo from './Todo';
import { useModeState } from "../../context/ModeContext";

export default function Search({
  setLink,
  location,
  history,
  color,
}){

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  let app_name = localStorage.getItem('app_name');
  var { isDarkMode } = useModeState();

  const [show, setShow] = useState(true);
  const [current, setCurrent] = useState("tasks");
  const [onTask, setOnTask] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [content, setContent] = useState();
  const [update, setUpdate] = useState();
  var [tasks, setTasks] = useState([]);
  var [files, setFiles] = useState([]);
  var [comments, setComments] = useState([]);
  var [todos, setTodos] = useState([]);

  const handleClose = () => {
    setShow(false)
    setLink('')
  }
  const handleShow = () => setShow(true);

  const ref = React.useRef(null);

  useEffect(() => {
    if(show){
      setTimeout(() => {
        ref.current?.focus()
      }, 600);
    }
  },[show]);

  const lists = [
    { name: 'tasks', component: <Task content={tasks} setTasks={setTasks} current={current} keyword={keyword} setContent={setContent}/>},
    { name: 'files', component: <File content={files} setFiles={setFiles} current={current} keyword={keyword} setUpdate={setUpdate}/>},
    { name: 'comment', component: <Comment content={comments} setComments={setComments} current={current} keyword={keyword} setContent={setContent}/>},
    { name: 'checkList', component: <Todo content={todos} setTodos={setTodos} current={current} keyword={keyword} setContent={setContent}/>}
  ];

  return (
    <>
        <Modal show={show} onClose={handleClose} size={"lg"} className={"main-search"}>
          <div  className={isDarkMode ? "pt-2 pb-0 border-0 dark-mode" : "pt-2 pb-0 border-0"}>
           <div className="row d-flex align-items-center">
              <div className="col-11">
               <Form.Input
                useRef={ref}
                className="border-0 border-bottom p-3" placeholder={`${translate('search')} ${app_name}`}
                onTextChange={(value) => setKeyword(value)}/></div>
               <div className="col-1"><Icon icon={'search'} size="md"/></div>
             </div>
           </div>
          <div className={isDarkMode ? "dark-mode" : ""}>
          <Tab activeKey={'tasks'} onToggle={setCurrent}>
            {lists.map((list, key) =>(
                <Item key={key} eventKey={list.name} title={<span className="lead">{translate(list.name)}</span>}>
                  {list.component}
                </Item>
            ))}
          </Tab>
          </div>
        </Modal>

      {(content || update) && <TaskWorkflow history={history} location={location} update={update} content={content} setUpdate={setUpdate} setContent={setContent} setOnTask={setOnTask}/>}
    </>
  );
}
