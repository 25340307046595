import React, { useState, useEffect, useMemo } from 'react';
import Icon from '../../components/Icon';
import { Spinner, Menu } from '../../components/Uix';
import { translate } from '../../services/Language';
import { FilesQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";
import Files from "./Files";
import AddFile from './AddFile';
export default function FileList({workflow_id, task_id, task_name, location, users}) {

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');

  const [keyword, setKeyword] = useState("");
  const [filelist, setFiles] = useState([]);
  const [onDelete, setOnDelete] = useState(false);
  const [onEvent, setOnEvent] = useState(false);

  const { loading, refetch, data } = useQuery(FilesQuery, {
    variables: { id: workflow_id, task_id, keyword },
    pollInterval: 100,
  });

  useMemo(() => {
     if(data && data.files){
       setFiles(data.files)
     }
    },
    [data],
  );

  useEffect(() => {
       refetch()
       if(onEvent) setOnEvent(false)
       if(onDelete) setOnDelete(false)
    },
    [keyword, onDelete, onEvent],
  );

  return (
    <>
    {loading && <Spinner animation="border" variant="warning" />}
    <div className="container">
      <div className="d-flex justify-content-end position-relative">
      <Menu icon="upload" title={translate('attach')}>
        <AddFile task_id={task_id} workflow_id={workflow_id} user_id={user_id} setOnEvent={setOnEvent} title={task_name} location={location} users={users}/>
      </Menu></div>
      <div className="px-4 py-2">
        <input type="text"
           className="form-control border-bottom w-100 py-1 rounded-pill" placeholder={translate('search')+"..."}
           onChange={(e) => setKeyword(e.target.value)}/>
           <div className="nav flex-column mt-2">
            <div className="row">
              {filelist.map( (file, key) => (
                  <div className="col-6" key={key}> <Files file={file} setOnDelete={setOnDelete}/> </div>
               ))}
            </div>
           </div>
      </div>
     </div>
    </>
  );
}
