import React from 'react';
import SumFunc from '../../components/Column/SumFunc'

export default function TableViewSum({col, rows}) {
  let tasks = []
  rows.map(row => {
    if(row.collection) {
      let collection = row.collection ? JSON.parse(row.collection) : [];
      var task = collection[`${col.id}`];
      if(!isNaN(task)){
        tasks.push(parseInt(task));
      }
    }
  })
  return <SumFunc tasks={tasks}/>;
}
