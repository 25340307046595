import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function LogoutOutline({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns='http://www.w3.org/2000/svg' width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox='0 0 512 512'>
    ><path d='M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32'/>
   </svg>
 );
}
