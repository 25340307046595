import React, { useState, useEffect, useReducer, useMemo } from 'react';
import {
  Link, useParams
} from "react-router-dom";
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { Menu, Button, Accordion, Dropdown, Item } from '../../components/Uix';
import { useQuery, gql } from "@apollo/client";
import { colors } from '../../services/Function';
import moment from 'moment';

const WorkflowsQuery = gql`
  query Workflows($id: String! $keyword: String $order: String $workspace_id: String){
   workflows(app_id: $id keyword: $keyword order: $order workspace_id: $workspace_id){
     id
     name
     type
     privacy
     user_id
     workspace_id
     index
     base
     updated_at
     users{
       id
     }
   }
 }
`;

const sections = [
  { name: translate('messenger'), type: 'message', icon: "messenger"},
  { name: translate('forms'), type: "form", icon: "form"},
  { name: translate('docs'), type: 'doc', icon: "doc"},
  { name: translate('sheets'), type: "sheet", icon: "sheet"}
];

export default function QuickSearch({history, location}) {

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  let app_name = localStorage.getItem('app_name');

  let ref = React.useRef();

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [section, setSection] = useState();
  const [origin, setOrigin] = useState([]);

  const { loading, refetch, data } = useQuery(WorkflowsQuery, {
    variables: { id: app_id, workspace_id: null, keyword, order: 'DESC' },
  });

  useMemo(() => {
    if(data && data.workflows){
      setItems(data.workflows.filter((w,i)=>i<=10))
      setOrigin(data.workflows)
    }
  },[data])

  useEffect(() => {
    refetch()
  },[keyword])

  useEffect(() => {
    if(section){
      setItems(origin.filter((w,i) => w.base === section))
    }else {
      setItems(origin.filter((w,i)=>i<=10))
    }
  },[section])

  useEffect(() => {
        // Bind the event listener
        document.addEventListener("keydown", escFunction, false);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("keydown", escFunction, false);
        };
    });

  useEffect(() => {
      // Bind the event listener
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside, true);
      };
  },[ref]);

  const escFunction = (event) => {
      if(event.keyCode === 27) {
        setOpen(false)
      }
  }

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
       setOpen(false)
    }
  }

  const LinkContent = ({wf}) => {
    var iconAvatar = "sheet.svg"
    var linkTo = "/w/a"
    if(wf.base === "doc") {
      iconAvatar = "doc.svg";
      linkTo = "/w/doc"
    }
    if(wf.base === "todo") {
      iconAvatar = "todo.svg";
      linkTo = "/w/todos"
    }
    if(wf.base === "message") {
      iconAvatar = "messenger.svg";
      linkTo = "/w/messenger"
    }
    if(wf.base === "form") {
      iconAvatar = "form.svg"
      linkTo = "/w/form"
    };
    return (<Link className="py-2 rounded dropdown-item d-block text-start small" target="_blank" to={`${linkTo}/${wf.id}`}>
      <div>
      <span style={{width: 18, height: 18}}><img width={18} src={`/assets/${iconAvatar}`}/></span> {wf.name}
      </div>
      <span>{moment(Number(wf.updated_at)).fromNow()}</span>
    </Link>)
  }

  return (
    <div ref={ref} className="w-100 position-relative">
      <div className="input-group d-flex justify-content-center align-items-center bg-light-80 rounded-3 px-3 py-1">
        <div className="input-group-prepend">
          <Icon icon={'search'} color="#ccc"/>
        </div>
        <input type="text"
         className="form-control border-0 bg-transparent" placeholder={`${translate('search')} ${app_name}`}
         onFocus={()=>setOpen(true)}
         onChange={(e) => {
           setKeyword(e.target.value)
           setOpen(true)
         }
         }/>
         <Menu icon="option" customWidth={300} onToggle={() => setOpen(true)}>
         {sections.map((item, key) =>
           <Button key={key} className="text-start small text-secondary d-block" onClick={() => setSection(item.type)}>
             <img src={`/assets/${item.icon}.svg`} width={18}/>
             <span className="ps-2">{item.name}</span>
           </Button>
         )}
         </Menu>
     </div>
     {open &&
      <div className="shadow-sm rounded p-4 position-absolute w-100 bg-white start-0" style={{zIndex: 999}}>
       {items.length > 0 ? <>
        <div className="px-3 text-dark-70">{translate('recently')}</div>
       {items.map((item, key) => {
         if(item.privacy === 'public'){
           return <LinkContent wf={item} key={key} />
         }else {
           let found = (item.users && item.users.find(u => u.id === user_id));
           if(found) {
             return <LinkContent wf={item} key={key} />
           }
         }
       }
     )}</>: <div className="">
       <Icon icon={'alert-circle'} color="#ccc"/> {translate('pageNotFound')}
     </div>}
      </div>}
   </div>
  );
}
