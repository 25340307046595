import React, { useState, useEffect } from 'react';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { UpdateTask } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { Menu, Button } from '../../components/Uix';
import { historyAdd } from '../../store/history';
import colors from "../../data/Colors"
import { resetData } from '../../reducers/Actions';
import { toast } from 'react-toastify'

export default function GetStage({
  workflow_id,
  collection,
  schema,
  task_id,
  stage_id,
  stageName,
  setOnTask,
  allowEdit,
  fields,
  task,
  users,
  stages,
  islabel,
  icon
}){

  var [stageId, setStageId] = useState("");
  var [stageName, setStageName] = useState(islabel ? translate('list') : "");
  let userName = localStorage.getItem('user_name');
  let user_id = localStorage.getItem('user_id');
  const [updateTask] = useMutation(UpdateTask);

  function checkRequired(task_id, collection){
      let seen = new Set("");
      let value = task_id;
      if(collection){
        if(schema){
          Object.keys(schema).map(key => {
            let col = schema[key];
            let content = collection[col.id];
            if(!content && col.required){
              seen.add(value)
            }
          })
        }
      }
      if(fields){
        fields.map(col => {
          if(col.required && col.id === "dueDate"){
            if(!task.due_date){
              seen.add(value)
            }
          }
          if(col.required && col.id === "members"){
            if(users.length===0){
              seen.add(value)
            }
          }
        })
      }
     return seen.has(task_id);
  }

  const handleClick = async(stage, type) => {
    let taskRequired = checkRequired(task_id, collection);
    if(taskRequired){
      toast.warn(`${translate('enter')} ${translate('required')}`)
      return;
    }
    updateTask({variables: {id: task_id, stage_id: stage.id}})
    //taskProgress(task_id, stage_id, stage.id, type)
    //setOnTask(true)
    setStageId(stage.id)
    //Add activity
    historyAdd({
      name: userName,
      description: `${translate('move')} ${stageName} ${translate('into')} ${stage.name}`,
      workflow_id,
      task_id,
      user_id,
    })
  };

  useEffect(() => {
    setStageId(stage_id)
  },[stage_id])

  useEffect(() => {
    let stage = stages.find(s => s.id === stageId)
    if(stage) setStageName(stage.name)
  },[stageId])

  var index = 0;

  return (
    <>
        <Menu title={stageName} disabled={!allowEdit} icon={icon}>
          {stages.map((link, key) => {
            if(link.id == stageId){
              index = key;
            }
            let color = colors[key]
            return(
              <Button className={"nav-item d-block text-white w-100 my-2"} style={{backgroundColor: color}} key={link.id} onClick={(e) => allowEdit ? handleClick(link, key > index ? 'next' : 'prev') : e.preventDefault()}>
                {link.id == stageId && <Icon icon="check" />} {link.name}
              </Button>
            )
          }
          )}
        </Menu>
    </>
  );
}
