import React  from 'react';
import { collectionValue } from '../../services/Function';
let localUrl = process.env.REACT_APP_API_LOCAL
let proURL = process.env.REACT_APP_API_URL
let mode = process.env.NODE_ENV;
let SERVER_URL = mode === 'development' ? localUrl : proURL

export default function TableView({stages, users, fields, task}) {
 let collection = task.collection ? JSON.parse(task.collection) : [];

  return (
    <>
    <tr>
      <td scope="row">{task.name}</td>
      {fields && fields.map((field, key) => {
        if(field.enabled === true){
          let text;
          let value = collectionValue(field, collection, null);
          try {
              let json = JSON.parse(value);
              text = json[0] ? json[0].name : value;
              if(json && json[0] && json[0].uri){
                text = `<img style="width: 100%;" src="${SERVER_URL}/storage${json[0]?.uri}"/>`;
              }
          } catch (e) {
              text = value;
          }
          return(
          <td key={key} scope="row" style={{maxWidth: 260}}><span dangerouslySetInnerHTML={{__html: text}}/></td>
        )}
    })}
    </tr>

    </>
  );
}
