import React, { useState, useEffect } from 'react';
import { Button } from "../../components/Uix";
import { translate } from '../../services/Language';
import { automation } from '../../store/automation';
import { Draggable } from 'react-beautiful-dnd';
import Subitem from '../Stage/Subitem';
import { motion } from "framer-motion";
import ContextMenu from './ContextMenu';
import EditTask from './EditTask';
import DateTime from '../DateTime';
//import TaskStatus from './TaskStatus';
import User from '../User';
import Column from '../Column/Column';
import moment from "moment"
const dateNow = moment.utc()

export default function Task({
  workflow_id,
  group_id,
  app_id,
  stage_id,
  task_id,
  schema,
  fields,
  task,
  index,
  next,
  prev,
  setOnEvent,
  setOnTask,
  setOnField,
  onTask,
  badges,
  setUpdate,
  setContent,
  setOnSubTask,
  permission,
  scrollX,
  stage_index,
  subitem,
  color,
  last,
  type,
  view,
  single,
  stages,
  setColumns,
  columns,
  users,
  setForward,
  taskDispatch
}){

  let user_id = localStorage.getItem('user_id');
  const [show, setShow] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [badge, setBadge] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [subVisiable, setSubVisiable] = useState(false);
  const [subField, setSubField] = useState("");
  const [taskMenu, setTaskMenu] = useState([]);

  const handleRightClick = (e, task) => {
    e.preventDefault()
    if(e.type === 'contextmenu') {
      setTaskMenu(task)
      setShow(true)
    }
  }

  const handleClick = (task) => {
      setTaskMenu(task)
      setShow(true)
  }

  const handleClickOutside = (e) => {
     setShow(false)
  }

  useEffect(() => {
      let collect = task.collection ? JSON.parse(task.collection) : [];
      let field = Object.values(schema).find(f => f.field === 'subitem');
      if(!!field && collect[field.id]){
        setSubVisiable(true)
        setSubField(field.title)
      }else {
        setSubVisiable(false)
      }
      let due_date = moment.utc(Number(task.due_date));
      if(due_date.isSameOrAfter(dateNow, 'hour')){
          automation('dateArrives', {id: task.id, stage_id: task.stage_id, workflow_id: task.workflow_id, user_id: task.user_id, collection: collect})
      }

    },
    [task],
  );

  function checkPrivate(task){
       var taskShow = false
       if(task.user_id === user_id){
         taskShow = true
       }else if(task.reporter_id === user_id){
         taskShow =  true
       }else if(task.users.find(u => u.id === user_id)){
         taskShow =  true
       }
       return taskShow
  }

  useEffect(() => {
    var found = badges.find(b => b[0] == task_id)
    if(found) setBadge(true);
  }, [badges])

  useEffect(() => {
    var isEdit;
    if(permission == 'owner'){
      isEdit = true
    }else if(permission == 'anything'){
      isEdit = true
    }else if(permission == 'rows' && (task.user_id == user_id || task.users.find(u => u.id == user_id))){
      isEdit = true
    }else if(permission == 'addRows' && (task.user_id == user_id || task.users.find(u => u.id == user_id))){
      isEdit = true
    }else if(permission == 'content'){
      isEdit = true
    }else if(permission == 'onlyview'){
      isEdit = false
    }
    setAllowEdit(isEdit)
  }, [permission])

  const userContainer = (
      <User task_id={task.id} workflow_id={workflow_id} app_id={app_id} users={users} members={task.users} task_name={task.name} setUserEvent={setOnEvent} allowEdit={allowEdit} group_id={group_id}/>
  )

  const dateContainer = (
     <DateTime task_id={task.id} start={task.start} due_date={task.due_date} allowEdit={allowEdit} done={task.status === "done"} inline/>
  )

  var lineCls = 'col nav-item p-0 text-center border-end';
  let className = 'col nav-item title p-0 cell-name ps-2 bg-white fadeInRight border-end';

  if(subitem){
    className += ' subitem'
  }
  let rowClass = 'row g-0 w-100 task-list task-row align-items-center align-sefl-center position-relative task-row border-start-0';

  const taskContainer = (task, mode) => {
    return(
    <Draggable
        key={task.id}
        draggableId={task.id}
        isDragDisabled={!mode}
        index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="row-task"
                >
                <div className="d-flex align-items-center">

                <motion.div className="item-drop on-hover bg-white" style={{x: scrollX, width: 32, height: 40, zIndex: 9}}>
                  <Button className="in-hover" icon="arrows-move" size="sm" color="grey" onClick={() => handleClick(task)}/>
                </motion.div>

                <div contextMenu="none" className={rowClass} onContextMenu={(e) => handleRightClick(e, task)}>
                  <motion.div className={className} style={{x: scrollX, borderLeftColor: color, borderLeftStyle: 'solid', borderLeftWidth: 4}}>
                   <div className="d-flex align-items-center">
                    {/*view === "list" && <TaskStatus task={task} setOnTask={setOnTask} allowEdit={allowEdit} taskDispatch={taskDispatch}/>*/}
                    <EditTask task={task} workflow_id={workflow_id} inline={true} badge={badge} setUpdate={setUpdate} setContent={setContent} allowEdit={allowEdit} single={single} users={users} taskDispatch={taskDispatch} subitem={subitem}/>
                    </div>
                  </motion.div>

                  {fields.length ?
                    <>
                    {fields.map((field, key) => {
                      if(field.id === 'members' && field.show){
                        return (
                          <div key={key} className={lineCls}>
                           {userContainer}
                          </div>
                        )
                      }
                      if(field.id.toLowerCase() === 'duedate' && field.show){
                        return (
                          <div key={key} className={lineCls}>
                             {dateContainer}
                          </div>
                        )
                      }
                    })}
                    </> :
                    <>
                      <div className={lineCls}>
                         {userContainer}
                       </div>
                      <div className={lineCls}>
                         {dateContainer}
                      </div>
                    </>
                   }

                   {task &&
                   <Column sheet task_id={task_id} workflow_id={workflow_id} stage_id={stage_id} schema={schema} allowEdit={allowEdit} setContent={setContent} setOnTask={setOnTask} setSubVisiable={setSubVisiable} parent_id={task.parent_id} task={task} setOnField={setOnField} users={users} onTask={onTask}/>}

                  <div className={"col nav-item add"}></div>
                 </div>
                 </div>
                 {subVisiable &&
                  <div className="scroll-style overflow-auto" style={{maxHeight: 140}}>
                 <Subitem workflow_id={workflow_id} stage_id={stage_id} next={next} prev={prev} schema={columns} setSchema={setColumns} setOnTask={setOnTask} setOnEvent={setOnEvent} fields={fields} badges={badges} setUpdate={setUpdate} permission={permission} scrollX={scrollX} setContent={setContent} group_id={group_id} subVisiable={subVisiable} stage_index={index} task_id={task_id} subField={subField} type={type} allowEdit={allowEdit} onTask={onTask} users={users} taskDispatch={taskDispatch}/>
                 </div>}

                 {(allowEdit && show ) && <ContextMenu stages={stages} open={show} stage_id={stage_id} task={taskMenu} next={next} prev={prev} setOnTask={setOnTask} setOnEvent={setOnEvent} setOnSubTask={setOnSubTask} setIsComplete={setIsComplete} scrollX={scrollX} stage_index={stage_index} onClose={() => setShow(false)} last={last} taskDispatch={taskDispatch} setForward={setForward}/>}
            </div>
        )}
    </Draggable>
  )};

  const privateContainer = (task) => {
    return(
    <Draggable
        key={task.id}
        draggableId={task.id}
        index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                >
                <div contextMenu="none" className={"d-flex square justify-content-between align-items-center my-0 taskline"}>
                  <div className="row w-100 task-list justify-content-between align-items-center p-0 border-bottom">
                    {translate('youCantSee')}
                  </div>
              </div>
            </div>
        )}
    </Draggable>
  )};

  return (
    <>
    {task && taskContainer(task, allowEdit)}
    </>
  );
}
