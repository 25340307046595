const APP_URL = process.env.REACT_APP_API_URL
const SERVER_URL = process.env.REACT_APP_SERVER_URL
const validate = (email) => {
    const expression = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    return expression.test(String(email).toLowerCase())
}

const phoneNumber = (phone) => {
  var regexp = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{8,16})$/
  return regexp.test(phone)
}

const isDateFormat = (date) => {
  return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

function random(length) {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

function randomNumer(max){
  return Math.floor(Math.random() *  Math.floor(max))
}

// a little function to help us with reordering the result
function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function fieldSchema(fields) {
  var properties = {}
  Object.keys(fields).forEach( (key) => {
    if(fields.hasOwnProperty(key)) {
      var property = fields[key]
      var item = Object.assign({}, property)
      if(item.value) delete item.value
      properties[property.id] = item
    }
  })
  return JSON.stringify(properties);
}

function fieldCollection(fields) {
  var values = {}
  fields.map( property => {
    if(property.value) {
       values[property.id] = property.value
    }
  })
  return JSON.stringify(values);
}

function collectionValue(field, collection) {
  let value;
  switch (field.field) {
    case "integer":
    case "text":
    case "textarea":
    case "checkbox":
    case "color":
    case "email":
    case "phone":
    case "url":
    case "date":
    case "status":
    case "select":
    case "mirror":
    case "formula":
      value = collection[field.id];
      if(!isNaN(value)){
        value = formatPrice(value)
      }
    break;
    case "link":
    case "user":
      let list = collection[field.id] ? jsonParse(collection[field.id]) : null;
      if(list){
        value = list[0]?.name;
      }
    break;
    case "file":
      let file = collection[field.id] ? jsonParse(collection[field.id]) : null;
      if(file){
        value = file[0]?.name;
      }
    break;
    default:
    value = null;
  }
  return value;
}


/**
 * Moves an item from one list to another list.
 */
function move(source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
};

function text_truncate(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

 function formatPrice(value) {
    let val = (value/1).toFixed(0).replace(',', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  function parse(text) {
      var str = text
      str = str.replace(/%40/g,"@");
      str = str.trim();
      return str;
  }

  function jsonParse(str) {
    try {
      var json = JSON.parse(str);
      if (json && typeof json === "object") {
         return json;
      }
    } catch (e) {
        return false;
    }
    return true;
 }

 function search(tasks, query){
   tasks.filter(task => {
    if (query === "") {
      //if query is empty
      return task;
    } else if (task.name.toLowerCase().includes(query.toLowerCase())) {
      //returns filtered array
      return task;
    }
  });
 }

 const imageStorage = (src) => {
   if(!src) return;
   return `${SERVER_URL}/fileswork/${src}`;
 }

 function urlForMedia(pastedData) {
   var youtube_id;
   var vimeo_id;
   if (pastedData.match('//(www.)?youtube|youtu\.be')) {
       if (pastedData.match('embed')) {
         youtube_id = pastedData.split(/embed\//)[1].split('"')[0]; }
       else { youtube_id = pastedData.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/)[0]; }
       return `https://www.youtube.com/embed/${youtube_id}`;
   }
   else if (pastedData.match('//(player.)?vimeo\.com')) {
       vimeo_id = pastedData.split(/video\/|https:\/\/vimeo\.com\//)[1].split(/[?&]/)[0];
       return `https://player.vimeo.com/video/${vimeo_id}`;
   }
   return pastedData;
 }

 const cities = [
   {name: "Thành phố *"},
   {name: "Hà Nội"},
   {name: "Hồ Chí Minh"},
   {name: "Đà Nẵng"},
   {name: "Bình Dương"},
   {name: "Bắc Ninh"},
   {name: "Đồng Nai"},
   {name: "Hưng Yên"},
   {name: "Hải Dương"},
   {name: "Hải Phòng"},
   {name: "An Giang"},
   {name: "Bà Rịa-Vũng Tàu"},
   {name: "Bắc Giang"},
   {name: "Bắc Kạn"},
   {name: "Bạc Liêu"},
   {name: "Bến Tre"},
   {name: "Bình Định"},
   {name: "Bình Phước"},
   {name: "Bình Thuận"},
   {name: "Cà Mau"},
   {name: "Cần Thơ"},
   {name: "Cao Bằng"},
   {name: "Cửu Long"},
   {name: "Đắc Lắc"},
   {name: "Đắc Nông"},
   {name: "Điện Biên"},
   {name: "Đồng Tháp"},
   {name: "Gia Lai"},
   {name: "Hà Giang"},
   {name: "Hà Nam"},
   {name: "Hà Tĩnh"},
   {name: "Hậu Giang"},
   {name: "Hoà Bình"},
   {name: "Khánh Hoà"},
   {name: "Kiên Giang"},
   {name: "Kon Tum"},
   {name: "Lai Châu"},
   {name: "Lâm Đồng"},
   {name: "Lạng Sơn"},
   {name: "Lào Cai"},
   {name: "Long An"},
   {name: "Miền Bắc"},
   {name: "Miền Nam"},
   {name: "Miền Trung"},
   {name: "Nam Định"},
   {name: "Nghệ An"},
   {name: "Ninh Bình"},
   {name: "Ninh Thuận"},
   {name: "Phú Thọ"},
   {name: "Phú Yên"},
   {name: "Quảng Bình"},
   {name: "Quảng Nam"},
   {name: "Quảng Ngãi"},
   {name: "Quảng Ninh"},
   {name: "Quảng Trị"},
   {name: "Sóc Trăng"},
   {name: "Sơn La"},
   {name: "Tây Ninh"},
   {name: "Thái Bình"},
   {name: "Thái Nguyên"},
   {name: "Thanh Hoá"},
   {name: "Thừa Thiên Huế"},
   {name: "Tiền Giang"},
   {name: "Toàn Quốc"},
   {name: "Trà Vinh"},
   {name: "Tuyên Quang"},
   {name: "Vĩnh Long"},
   {name: "Vĩnh Phúc"},
   {name: "Yên Bái"},
 ];

 let colors =[
   'ffadad', 'ffd6a5', 'fdffb6', 'caffbf', '9bf6ff', 'a0c4ff', 'bdb2ff', 'ffc6ff', '80ed99', 'caf0f8'
 ]

 function median(values){
   if(values.length ===0) return 0;

   values.sort(function(a,b){
     return a-b;
   });

   var half = Math.floor(values.length / 2);

   if (values.length % 2)
     return values[half];

   return (values[half - 1] + values[half]) / 2.0;
 }


export { random, reorder, move, validate, text_truncate, formatPrice, parse, jsonParse, cities, phoneNumber, randomNumer, colors, median, fieldCollection, collectionValue, fieldSchema, urlForMedia, isDateFormat, imageStorage}
