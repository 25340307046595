import React, { useState, useEffect } from 'react';
import { translate } from '../../services/Language';
import { phoneNumber } from '../../services/Function';
import slugify from 'slugify';
import ExcelReader from '../../components/ExcelReader';
import * as EmailValidator from 'email-validator';

export default function ImportExcel({setData, setIsImport, history, setGroupValue, live, label}){

  const [content, setContent] = useState();

  useEffect(() => {
      if(content && content[1]){
      var heading = [];
      var body = [];
      var row = Object.values(content[1]);

      Object.keys(content[0]).map((item, key) => {
        var field = "text";
        var id;
        if(EmailValidator.validate(row[key])){
          field = "email";
        }
        if(phoneNumber(row[key])){
          field = "phone";
        }
        if(key === 0){
          id = "name"
        }else {
          id = slugify(item)
        }
        heading.push({"title": item, "id": id, "field": field, "name": field})
      })

      content.map(row => {
        var items = {}
        var item = Object.values(row)
        heading.map((col, index) => {
            items[col.id] = item[index]
        })

        body.push(items)
      })

      const mapping =
        {
          "group_name": translate("import"),
          "private": false,
          "heading": heading,
          "stage_name": translate("new"),
          "items": body
       };
        setData(mapping)
        if(setGroupValue) {
          setGroupValue('import') }
        if(setIsImport){
          setIsImport(false) }
      }
    },
    [content],
  );

  return ( <ExcelReader setContent={setContent} live={live} label={label}/> );
}
