import { Button } from '../../../components/Uix';
import { translate } from '../../../services/Language';

export default function FieldControl({confirmField, fields}) {

  return (
    <div className="px-4 pb-4">
      <h4 className="px-2">{translate('customField')}</h4>
      <div className="d-flex flex-column">
       {fields && fields.map((field, key) => <Button key={key} className="text-start" onClick={(e) => confirmField(e, field.id)} title={translate(field.name)}/>)}
       </div>
     </div>);

}
