import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from '../../components/Uix';
import Editor from '../../components/Editor';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { SendEmail } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";

export default function EmailSend({ emailValue, setData, setCancel, title, body }){
      const [isLoading, setLoading] = useState(false);
      const [subject, setSubject] = useState(title);
      const [message, setMessage] = useState('');
      const [email, setEmail] = useState("");
      const [dataValue, setDataValue] = useState([]);
      let name = localStorage.getItem('user_name');
      let from = localStorage.getItem('user_email');
      let app_name = localStorage.getItem('app_name');

      const [sendEmail] = useMutation(SendEmail);

      const handleMail = async() => {
        if(!subject && !message && !email){
          return;
        }
        setLoading(true)
        let body = `<h3>${subject}</h3>` + message;
        sendEmail({
          variables: {
            subject: subject,
            message: body,
            email: email,
            from_name: from,
            to_name: name
          },
        });
        if(setData && !!body){
          setData(body)
        }
        setLoading(false)
      }

      useEffect(() => {
        setEmail(emailValue)
      },[emailValue])

      const handleCancel = () => {
        if(setCancel){
          setCancel(null)
        }
      }

      return (
        <>
            <div>
              <Form.Input value={email} onTextChange={setEmail} label={translate('sendTo')} type="email" border="border-0" inline/>
              <div className="my-2">
                <Form.Input value={subject} onTextChange={setSubject} label={translate('subject')} border="border-0" inline/>
              </div>
              <Editor fields={[]} setData={setDataValue} setBody={setMessage} data={body}/>
              <div className="text-end mt-3">
                <Button variant={"secondary"} className="mx-3" onClick={handleCancel}>
                  {translate('cancel')}
                </Button>
                <Button variant={(!!subject && message.length > 7 && !!email) ? "success" : "light"} onClick={handleMail}>
                  {isLoading && <Spinner size="sm" animation="border" variant="light" />} <Icon icon="paper-plane"/> {translate('send')}
                </Button>
              </div>
            </div>
        </>

      );
}
