const Alert = ({children, variant, show, className}) => {

  return(
    <>
    {show ? <div className={`alert alert-${variant} ${className}`}>
        {children}
      </div>: null}
    </>
)}

export default Alert;
