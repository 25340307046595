import React from 'react';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import GetStage from '../../components/Stage/GetStage';
import DateTime from '../../components/DateTime';
import User from '../../components/User';

export default function TaskItems({task, users, stages, fields, setOnTask, allowEdit, schema, collection}){

    let tools = [{
        name: 'stages',
        icon: 'grid',
        component: <GetStage task={task} task_id={task.id} stage_id={task.stage_id} workflow_id={task.workflow_id} stages={stages} allowEdit={allowEdit} setOnTask={setOnTask} fields={fields} schema={schema} collection={collection} users={task.users}/>
      },
      {
      name: 'members',
      icon: 'person',
      component: <User task_id={task.id} users={users} workflow_id={task.workflow_id} members={task.users} fixed={true} islabel={true} task_name={task.name} setOnTask={setOnTask} allowEdit={allowEdit} group_id={task.workflow_id}/>
    },
    {
      name: 'dueDate',
      icon: 'time',
      component: <DateTime task_id={task.id} workflow_id={task.workflow_id} start={task.start} due_date={task.due_date} islabel={true} done={task.status === "done"} allowEdit={allowEdit} position="top"/>
    }]

      return (
        <>
        <div className="px-0">
            {fields.length ?
              <>
              {!task.parent_id &&
              <div className="w-100 nav-field my-2 d-flex bg-great justify-content-start align-items-center">
                 <div className={"w-50"}>
                   <Icon icon={tools[0].icon} className={"text-secondary me-2"}/> {translate(tools[0].name)}
                 </div>
                 <div className={"w-50 bg-light h-36"}>
                  {tools[0].component}
                 </div>
               </div>}
              {fields.map((field, key) => {
                if(field.id === 'members' && field.show){
                  var item= tools[1]
                  return (
                    <div key={key} className="w-100 nav-field my-2 d-flex bg-great justify-content-start align-items-center">
                      <div className={"w-50"}>
                        <Icon icon={item.icon} className={"text-secondary me-2"}/>
                        {field.title} <span className="text-danger">{field.required&&"*"}</span>
                      </div>
                      <div className={"w-50 bg-light h-36"}>
                       {item.component}
                      </div>
                    </div>
                  )
                }
                if(field.id === 'dueDate' && field.show){
                  var item= tools[2]
                  return (
                    <div key={key} className="w-100 nav-field my-2 d-flex bg-great justify-content-start align-items-center">
                      <div className={"w-50"}>
                        <Icon icon={item.icon} className={"text-secondary me-2"}/>
                        {field.title} <span className="text-danger">{field.required&&"*"}</span>
                      </div>
                      <div className={"w-50 bg-light h-36"}>
                       {item.component}
                      </div>
                    </div>
                  )
                }
              })}
              </> :
              <>
                {tools.map((item, key) => {
                  if(task.parent_id && item.name === "stages"){
                    return;
                  }else {
                    return(
                      <div key={key} className="w-100 nav-field my-2 d-flex bg-great justify-content-start align-items-center">
                        <div className={"w-40"}>
                          <Icon icon={item.icon} className={"text-secondary me-2"}/> {translate(item.name)}
                        </div>
                        <div className={"w-60 bg-light h-36"}>
                         {item.component}
                        </div>
                      </div>
                    )
                  }
                })}
              </>
             }
        </div>
        </>
      );

}
