import React, { useState, useEffect, useRef, useCallback } from "react";
import Icon from "../Icon";
import {Button} from "../Uix";
import Overlay from 'react-overlays/Overlay'
import { motion } from 'framer-motion'

export default function Toast({ children, custom, sideLeft, sideTop, title, show, onClose, placement, size, customWidth, position}){

const [open, setOpen] = useState(false);
const [offset, setOffset] = useState({});
let containerRef = React.useRef();
const triggerRef = useRef(null);

const handleClose = () => {
  setOpen(false)
  if(onClose) onClose()
}

useEffect(() => {
  setOpen(show)
},[show])

const escFunction = (event) => {
    if(event.keyCode === 27) {
       handleClose()
    }
  }

useEffect(() => {
      // Bind the event listener
      document.addEventListener("keydown", escFunction, false);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("keydown", escFunction, false);
      };
  });

useEffect(() => {
    // Bind the event listener
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside, true);
    };
},[containerRef]);

const handleClickOutside = (event) => {
   if (containerRef.current && !containerRef.current.contains(event.target)) {
      handleClose()
   }
}

let winHeight = window.innerHeight;

let width = 256;
if(size === 'md'){
  width = 512;
}
if(size === 'lg'){
  width = 768;
}
if(customWidth){
  width = customWidth;
}

let side;
if(placement === "right"){
  side = "end-0"
}else if (placement === "left") {
  side = "start-0"
}else if (placement === "bottom") {
  side = "bottom-0"
}else{
  side = "top-0"
}

const refOffset = useCallback(
  (el) => {
    if(!el) return;
    let top = el.getBoundingClientRect().top;
    let left = el.getBoundingClientRect().left;
    setOffset({top, left})
},[]);

let {top, left} = offset;

return (
  <div className="position-relative" style={{zIndex:9999}}>
  <div ref={refOffset}/>
  <Overlay
    show={open}
    rootClose
    offset={[0, 10]}
    onHide={() => setOpen(false)}
    placement={placement}
    target={triggerRef}
  >
    {({ props, arrowProps, placement }) => (
        <motion.div animate={{
             y: 20,
             visible: { opacity: 1 },
             hidden: { opacity: 0 },
           }} transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }} className={`rounded shadow bg-white position-absolute`} ref={containerRef} style={{zIndex:9999, top: 100, left: placement === "right" ? 'inherit' : left, right: placement === "right" ? 0: 'inherit', width, marginLeft: sideLeft??0, marginTop: sideTop??0}}>
          <div className="d-flex flex-row-revers">
            <Button icon="close" onClick={handleClose}/>
          </div>
          <div className="px-3 text-wrap text-start">
            {children}
          </div>
      </motion.div>
    )}
  </Overlay>
    </div>
  );
}
