import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Toast, Title } from "../../components/Uix";
import Icon from '../../components/Icon';
import Confirm from '../../components/Confirm';
import { translate } from '../../services/Language';
import { sendNotification } from '../../services/Api';
import { automation } from '../../store/automation';
import { UpdateTask, TaskCopy } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";

export default function ContextMenu({
  stage_id,
  task,
  app_id,
  setOnTask,
  setOnSubTask,
  setIsComplete,
  next,
  prev,
  btn,
  open,
  onClose,
  scrollX,
  stage_index,
  last,
  stages,
  isSubItem,
  setForward,
  taskDispatch
}){

  const [show, setShow] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const target = React.useRef(null);
  const [updateTask] = useMutation(UpdateTask);
  const [taskCopy] = useMutation(TaskCopy);
  let user_id = localStorage.getItem('user_id');

  const handleClose = () => {
    setShow(false);
    if(onClose) onClose()
  }
  const handleShow = () => setShow(!show);

  useEffect(() => {
    if(open){
      setShow(open)
    }
  },[open])

  useEffect(() => {
    if(isDelete){
      taskDispatch({ type: "removeTask", payload: task.id})
    }
  },[isDelete])

  const handleComplete = async() => {
    var status = task.status === 'done' ? 'progress' : 'done';
    updateTask({variables: {id: task.id, status}})

    //Process automation
    let reresh = automation('statusChanges', {id: task.id, name: task.name, status, user_id: task.user_id, stage_id, workflow_id: task.workflow_id, collection: task.collection})
    setShow(false)
    //setOnTask(true)
    if(setIsComplete) setIsComplete(true)
    if(status === 'done'){
      task.users.map(user => {
        if(user.notify === true && user.id != user_id) {
          sendNotification(user,task.name,'completed');
        }
      })
      taskDispatch({ type: "updateStatus", payload: task.id, status: "done"})
    }else {
      taskDispatch({ type: "updateStatus", payload: task.id, status: "progress"})
    }
  };

  const handleMove = async(move, type) => {
    updateTask({variables: {id: task.id, stage_id: move.id}})
    automation('itemMoved', {id: task.id, name: task.name, status: task.status, user_id: task.user_id, stage_id: move.id, workflow_id: task.workflow_id, collection: task.collection})
    //taskProgress(task.id, stage_id, move.id, type)
    setShow(false)
    taskDispatch({ type: "move", payload: task.id, stage_id: move.id, index: task.index})
    //setOnTask(true)
  };

  const handleClone = async() => {
      taskCopy({variables: {id: task.id}})
      setShow(false)
      setOnTask(true)
  };
  //placement={last?"bottom":"left"}
  return (
    <>
    {scrollX > 0 && <div className="pre-menu"></div>}
      <div className="context-menu" style={{zIndex: 10}}>
        <Toast onClose={handleClose} show={show} placement="left">
             <div className="text-start">
               <Title variant="5">{task.name}</Title>
               <Button icon="check-circle" title={task.status === 'done' ? translate('reOpen') : translate('complete')} className="d-block" onClick={handleComplete}/>

               <Button icon="copy" title={translate('clone')} className="d-block" onClick={handleClone}/>
               {!isSubItem && <>
              {prev &&  <Button icon="chevron-back" title={translate('prev')} className="d-block" onClick={() => handleMove(prev, 'prev')}/>}

              {next &&  <Button icon="chevron-forward" title={translate('next')} className="d-block" onClick={() => handleMove(next, 'next')}/>}

              <Button className="d-block" variant="link" onClick={() => setForward(task)}>
                <Icon icon="sync-circle"/> {translate('forward')}
              </Button>

               <Dropdown icon="repeat" title={translate('move')} bottom>
                 {stages && stages.map((link, key) => <Button key={key} className={link.id == stage_id ? "text-primary d-block" : "d-block"}
                       onClick={(e) => handleMove(link, key > stage_index ? 'next' : 'prev')}>
                       {link.name}
                    </Button>)}
               </Dropdown>
               </>}
               <Confirm type="task" id={task.id} title={task.name} button setOnDelete={setIsDelete} setOnSubTask={setOnSubTask} placement={'bottom'} customIcon="trash" color={true}/>
          </div>
       </Toast>
      </div>
     </>
  );
}
