async function sendNotificationApi(data) {
  var headers = {
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": "Basic ZGFhNzlhMGYtMWIzYy00NDU4LWI1NjQtYjc1ZTEwNjUyZjVh"
  };

  data.app_id = "d6f2b6c3-9220-49dc-9306-1d0a34ed5c21";

  const response = await fetch('https://onesignal.com/api/v1/notifications', {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data), 
  });
  return response.json();

};

const sendNotification = async (user, content, type) => {
  if(!!user && !!content) {
    let viHeading = "", enHeading = "";
    let userName = localStorage.getItem('user_name');
    switch (type) {
      case "upgrade":
          viHeading = `${userName} đã nâng cấp`;
          enHeading = `${userName} has been upgraded`;
        break;
      case "completed":
          viHeading = `${userName} đã hoàn thành`;
          enHeading = `${userName} has been completed`;
        break;
      case "stageChanged":
          viHeading = `${userName} đã cập nhật`;
          enHeading = `${userName} stage changed to`;
        break;
      case "upload":
          viHeading = `${userName} đã cập nhật tệp tin`;
          enHeading = `${userName} attached file to`;
        break;
      case "mentioned":
          viHeading = `${userName} đã đề cập đến`;
          enHeading = `${userName} mentioned you to`;
        break;
      case "subscribe":
          viHeading = `${userName} đã gán bạn vào nhóm`;
          enHeading = `${userName} assigned you to group`;
        break;
      case "subscribeUser":
          viHeading = `${userName} đã gán công việc`;
          enHeading = `${userName} assigned you to`;
        break;
      default:

    }

    var devices = JSON.parse(user.device);
    if(!!devices){
      let include_player_ids = []
      if(devices.userId != null){
        include_player_ids.push(devices.userId)
      }
      if(devices.webId != null && devices.webId != 'null'){
        include_player_ids.push(devices.webId)
      }

      var message = {
        headings: {"en": enHeading, "vi": viHeading},
        contents: {"en": content, "vi": content},
        include_player_ids
      };
      await sendNotificationApi(message);
    }
  }
}

export { sendNotification }
