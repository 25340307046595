import React, { useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Link } from "react-router-dom";
import NewGroup from '../../components/Workflow/NewGroup';
import QuickSearch from '../../components/Workflow/QuickSearch';
import Notification from '../Notification';
import GetNotification from "../Notification/GetNotification";
import Search from '../Search';
import Profile from '../Profile';
import Apps from '../App/Apps';
import { AppQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";
import { Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
var color = "#6c757d";

function Navbar({ location, history, id }) {

  // local
  var [app, setApp] = useState([]);
  const [onEvent, setOnEvent] = useState(false);
  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  const [link, setLink] = useState("");
  var [badge, setBadge] = useState();
  const { data } = useQuery(AppQuery, {
    variables: { id: app_id },
  });

  useMemo(() => {
    if (data && data.app) {
      setApp(data.app)
      //Save for users
      localStorage.setItem('wfUsers', JSON.stringify(data.app.users))
      //Save for groups
      localStorage.setItem('wfGroups', JSON.stringify(data.app.groups))
      let wTodo = data.app.workflows.filter(w => w.base === "todo");
      localStorage.setItem('worktodo_id', (wTodo && wTodo[0]) ? wTodo[0].id : "");
    }
  }, [data])

  var expire = moment(app.expire)
  var live = app.live;

  return (
    <>
      <nav className={"navbar-app bg-light"}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="ps-3" style={{ width: 316 }}>
            <Link className="btn p-0" to="/"><img src="/assets/logo.png" width={32} height={32} /> {"Taggo"}</Link>
          </div>
          <div className="d-flex justify-content-between align-items-center w-75">
            <div className="w-75">
              <QuickSearch history={history} location={location} />
            </div>
            <NewGroup user_id={user_id} app_id={app_id} setOnEffect={setOnEvent} history={history} hashTag={''} live={true} workspace_id={id} />
          </div>
          <div className="d-flex justify-content-end align-items-center w-25" style={{ width: 168 }}>
            <Button onClick={() => setLink('notification')} className={`d-flex nav-link p-2 position-relative text-start`}>
              <div className="text-center" style={{ width: 30 }}>
                <Icon icon={'notifications'} size="md" />
              </div>
              {/* <div className="ms-1 text-nowrap">{translate('notification')}</div> */}
              <GetNotification setBadge={setBadge} badge={badge} />
            </Button>
            <Button onClick={() => setLink('search')} className={`d-flex nav-link p-2 position-relative text-start`}>
              <div className="text-center" style={{ width: 30 }}>
                <Icon icon={'search'} size="md" />
              </div>
              {/* <div className="ms-1 text-nowrap">{translate('search')}</div> */}
            </Button>
            {link === "notification" && <Notification setBadge={setBadge} badge={badge} setLink={setLink} />}
            {link === "search" && <Search history={history} location={location} color={color} setLink={setLink} />}
            <Apps history={history} placement="right" />
            <Profile history={history} color={color} app={app} live={live} placement="right" />
          </div>
        </div>
      </nav>
    </>
  );
}

export default withRouter(Navbar);
