import React, { useState, useEffect, useCallback } from "react";
import { motion } from 'framer-motion'
import Overlay from 'react-overlays/Overlay'
import Icon from "../Icon";
import Button from './Button'

const Portal = ({children, onToggle, show, onClose, autoHide, onClick, title, icon, className, variant, placement, size, disabled, btnStyle, btnSize, btnCls, position, customWidth, closeBtn, btnBetween}) => {

  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState({});
  let ref = React.useRef();

  const handleClose = () => {
    setOpen(false)
    if(onToggle) onToggle(false)
    if(onClose) onClose()
  }

  const handleOpen = () => {
    setOpen(true)
    if(onToggle) onToggle(true)
  }
  let width = 256;
  let classes = 'rounded-3 shadow shadow-sm text-wrap p-2 bg-white';
  if(open) classes += ` position-absolute`;
  if(placement === "bottom"){
    classes += ` start-0 bottom-0`
  }else if(placement === "left"){
    classes += ` start-0 top-0`
  }else {
    classes += ` end-0 top-0`
  }
  if(className) classes += ` ${className}`
  if(size === 'md'){
    width = 512;
  }
  if(size === 'lg'){
    width = 768;
  }
  if(customWidth){
    width = customWidth;
  }

  useEffect(() => {
    if(autoHide) setOpen(false)
  },[autoHide])

  useEffect(() => {
    setTimeout(() => {
      setOpen(show)
    }, 600);
  },[show])

  const escFunction = (event) => {
      if(event.keyCode === 27) {
         handleClose()
      }
    }

  useEffect(() => {
        // Bind the event listener
        document.addEventListener("keydown", escFunction, false);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("keydown", escFunction, false);
        };
    });

  useEffect(() => {
      // Bind the event listener
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside, true);
      };
  });

  const handleClickOutside = (event) => {
     if (ref.current && !ref.current.contains(event.target)) {
        handleClose()
     }
  }


  const refOffset = useCallback(
    (el) => {
      if(!el) return;
      let top = el.getBoundingClientRect().top;
      let left = el.getBoundingClientRect().left;
      setOffset({top, left})
  },[]);

  let {top, left} = offset;

  return(
  <div className="position-relative" style={{zIndex:9999}}>
  <button ref={refOffset} disabled={disabled} onClick={open ? handleClose : handleOpen} className={`btn btn-${variant??"link"} btn-${btnSize} ${btnCls}`} type="button" style={btnStyle}>
    {icon && <Icon icon={icon}/>} {title}
  </button>
  <Overlay
    show={open}
    rootClose
    offset={[0, 10]}
    onHide={() => setOpen(false)}
    placement={placement}
  >
    {({ props, arrowProps, placement }) => (
        <motion.div animate={{
             y: 20,
             visible: { opacity: 1 },
             hidden: { opacity: 0 },
           }} transition={{
          type: "spring",
          stiffness: 260,
          damping: 20
        }} className={`rounded shadow bg-white position-absolute`} ref={ref} style={{zIndex:9999, top: 100, left: placement === "right" ? 'inherit' : left, right: placement === "right" ? 0: 'inherit', width}}>
          <div className="d-flex flex-row-revers">
            <Button icon="close" onClick={handleClose}/>
          </div>
          <div className="px-3 text-wrap text-start">
            {children}
          </div>
      </motion.div>
    )}
  </Overlay>
</div>
)}

export default Portal;
