import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import { Spinner } from '../../components/Uix';
import { translate } from '../../services/Language';
import { WorkflowQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";
import { Menu, Button } from "../../components/Uix"
import Icon from '../../components/Icon';
import StageView from '../../components/Stage/StageView';
import { useParams } from "react-router-dom";

const types = [{type: 'list', icon: 'project'},{type: 'board', icon: 'list'}]

export default function BoardView({board_id, onEvent}) {

  const [stages, setStages] = useState([]);
  const [users, setUsers] = useState([]);
  const [views, setViews] = useState([]);
  const [fields, setFields] = useState([]);
  const [schema, setSchema] = useState([]);

  const { loading, refetch, data } = useQuery(WorkflowQuery, {
    variables: { id: board_id },
  });

  useMemo(() => {
    if(data && data.workflow){
      if(data.workflow.schema) { setSchema(JSON.parse(data.workflow.schema)) }
      if(data.workflow.fields) { setFields(JSON.parse(data.workflow.fields)) }
      if(data.workflow.users) { setUsers(data.workflow.users) }
      if(data.workflow.stages) setStages(data.workflow.stages)
    }
  },[data])

  useEffect(() => {
    //refetch()
  },[onEvent])

  return (
    <>
      <div className={"my-4 board-view"}>
        <Suspense fallback={<div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="warning" /></div>}>
          <div>
             <StageView users={users} stages={stages} fields={fields} schema={schema} id={board_id}/>
          </div>
        </Suspense>
        </div>
    </>
  )
}
