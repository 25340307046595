import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function ToggleOutline({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox="0 0 512 512">
   <circle cx='368' cy='256' r='128' fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32'/><rect x='16' y='128' width='480' height='256' rx='128' ry='128' fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32'/>
</svg>
 );
}
