import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion'
import {Button} from "../Uix";

export default function Pagesheet({
  children,
  title,
  label,
  icon,
  size,
  color,
  show,
  onClose,
  ...props
}){

  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(true);
  const handleClose = () => {
    setOpen(false)
    if(onClose) onClose()
  }

  useEffect(() => {
    // Bind the event listener
    //document.addEventListener("click", handleClose, true);
    return () => {
      // Unbind the event listener on clean up
      //document.removeEventListener("click", handleClose, true);
    };
  });

  useEffect(() => {
    setOpen(show)
  },[show])

return (
  <>
    {open && <>
      <motion.div animate={{
       y: 0,
       visible: { opacity: 1 },
       hidden: { opacity: 0 },
     }} transition={{
    type: "spring",
    stiffness: 260,
    damping: 20
  }}
  onMouseLeave={() => {
    setTimeout( () => {
      setBackdrop(false)
    }, 60);
  }} onMouseOver={() => {
    setTimeout( () => {
      setBackdrop(true)
    }, 60);
  }}
  className="shadow h-100 overflow scroll-style bg-white position-fixed end-0 top-0" style={{display: 'block', zIndex: 9999, width: 680}}>
      <div className={`sheet-${size}`}>
        <div className="sheet-content">
          <div className="d-flex flex-row">
            <Button icon="close" onClick={handleClose}/>
          </div>
          <div className="p-4">
            {children}
          </div>
        </div>
      </div>
    </motion.div>
    {backdrop && <div className="modal-backdrop fade show"></div>}
    </>}
  </>
);
}
