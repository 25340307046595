import React, { useEffect, useState } from "react";
import Icon from '../../components/Icon';
import { Button } from '../../components/Uix';
import { translate } from '../../services/Language';

export default function InputTimer({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  isLabel,
  ...props
}){

  const [timeValue, setTimeValue] = useState();

  const handleClick = (time) => {
     dispatch({ type: 'updateField', payload: field.id, value: time })
     setOnEvent(true)
  }

  const handleRemove = () => {
     dispatch({ type: 'updateField', payload: field.id, value: null })
     setTimeValue()
  }

  const inputContent = (mode, value) => {
    return(
      <div className="field-list d-flex justify-content-start align-items-center on-hover">
            <div>{ timeValue }</div>
            {timeValue && <Button variant="link" onClick={handleRemove} className="in-hover">
              <Icon icon="trash" size="sm"/>
            </Button>}
        </div>
    )
  }

  return (<>{inputContent(allowEdit, timeValue)}</>)
};
