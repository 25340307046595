import React, { useState, useEffect } from 'react';
import { Button, Item} from '../../components/Uix';
import Icon from '../../components/Icon';
import Upgrade from '../../components/Upgrade';
import { translate } from '../../services/Language';
import { jsonParse, imageStorage } from '../../services/Function';
import { WORKFLOW_LIMITED } from '../../services/Defines';


export default function Template({select, setUse, setGroupValue, live, ...props}) {

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  const [limit, setLimit] = useState(false);
  let [medias, setMedias] = useState([])

  const handleClick = (item) => {
    setUse(item)
  }

  useEffect(() => {
    var media = select.media ? jsonParse(select.media) : []
    setMedias(media)
  },[select])

  useEffect(() => {
    if(live === false){
      let wfs = JSON.parse(localStorage.getItem('wfWorkflows'))
      setLimit(wfs.length)
    }
  },[]);

  const mediaView = (img) => {
      if(img.type == "video/mp4"){
       return(
       <video width="100" controls>
         <source src={imageStorage(img.uri)} type="video/mp4" />
         Your browser does not support HTML video.
       </video>)
      }else {
         return(<img src={imageStorage(img.uri)} className="d-block w-100"/>)
      }
  }

  return (
    <>
    <div className="mb-3 border-bottom">
       <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
         <h3>{select.name}</h3>
       </div>
       <div className="row mt-2">
         <div className="col-md-9">

            {medias && medias.map(img => (<Item>
              {mediaView(img)} </Item>)
            )}

          <div className="my-3">{select.body}</div>
         </div>
         <div className="col-md-3">
           <div className="w-100">
             <div>{select.used > 0 &&  <> <Icon icon="cafe" size="sm" className="text-secondary"/> {select.used} {translate('team')} {translate('used')}</>}</div>
             <div className="my-3">
               {limit >= WORKFLOW_LIMITED ? <Upgrade btn={true} text={translate('use')}/> : <Button variant="primary" className="w-100" onClick={() => handleClick(select)}>
                 {translate('use')}
               </Button>}
             </div>
           </div>
         </div>
        </div>
      </div>
    </>
  );
}
