import React, { useEffect, useState, useMemo } from "react";
import Icon from '../../components/Icon';
import { Dropdown, Button, Accordion, Portal, Item } from '../../components/Uix';
import { translate } from '../../services/Language';
import { TaskConnect } from '../../services/GraphqlApi';
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { jsonParse, text_truncate } from '../../services/Function';
import { STR_LIMITED } from '../../services/Defines';
import ItemLink from './ItemLink';

export default function InputLink({
  connectLink,
  connectTask,
  task_id,
  workflow_id,
  dispatch,
  field,
  schema,
  setOnEvent,
  setOnEdit,
  setOnField,
  allowEdit,
  setContent,
  full,
}) {

  var [links, setLinks] = useState([]);
  const [stages, setStages] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [show, setShow] = useState('link1');
  const [keyword, setKeyword] = useState('');
  let group_id = field.link ? field.link : workflow_id;
  const [taskConnect] = useMutation(TaskConnect);

  const TaskFastQuery = gql`
    query Tasks($id: String $keyword: String){
     tasks(workflow_id: $id keyword: $keyword){
       id
       name
       created_at
       collection
       stage_id
     }
   }
  `;

  const StagesQuery = gql`
    query Stages($id: String!){
     stages(workflow_id: $id){
       id
       name
       color
     }
   }
  `;

  const [dataQuery, { loading, data }] = useLazyQuery(TaskFastQuery);
  const [stageQuery, dataStage] = useLazyQuery(StagesQuery);

  useMemo(() => {
    if (dataStage && dataStage.data && dataStage.data.stages) {
      setStages(dataStage.data.stages)
    }
  }, [dataStage]);

  useMemo(() => {
    if (data && data.tasks) {
      setTasks(data.tasks)
    }
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      //Only feching when opened popup.
      stageQuery({
        variables: { id: group_id },
      })
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      dataQuery({
        variables: { id: group_id, keyword, user_id: null },
      })
    }
  }, [isOpen, keyword]);

  useEffect(() => {
    if (connectLink) {
      //setLinks(jsonParse(field.value))
      setLinks(connectLink ?? [])
    }
  }, [connectLink]);

  const handleRemove = (link) => {
    taskConnect({
      variables: {
        task_id,
        link_id: link.id,
        field: field.id,
        type: 'remove'
      }
    })
    const linksUnique = links.filter(item => item.id !== link.id)
    dispatch({ type: 'updateField', payload: field.id, value: JSON.stringify(linksUnique) })
    setLinks(linksUnique)
    setOnEvent(true)
    if (setOnField) setOnField(true)
  }

  const handleClick = async (link) => {
    connectMirror(link)
    var newList = links.length ? links : []
    newList.push({ id: link.id, name: link.name })
    const linksUnique = Array.from(new Set(newList.map(a => a.id)))
      .map(id => {
        return newList.find(a => a.id === id)
      })
    taskConnect({
      variables: {
        task_id,
        link_id: link.id,
        field: field.id,
        type: 'add'
      }
    })
    await dispatch({ type: 'updateField', payload: field.id, value: JSON.stringify(linksUnique) })
    setLinks(linksUnique)
    setOnEvent(true)
  }

  //Copy data from connect board
  function connectMirror(task) {
    let collection = task.collection ? jsonParse(task.collection) : [];
    Object.keys(schema).map(key => {
      var column = schema[key];
      if (column.field === 'mirror') {
        if (collection[column.ref]) {
          dispatch({ type: 'updateField', payload: column.id, value: collection[column.ref] })
          setOnEvent(true)
        }
      }
    })
    if (setOnField) setOnField(true)
  }

  const menu = (title, icon, size) => {
    return (
      <Portal icon={icon ?? "link"} title={title} disabled={!allowEdit} onToggle={setOpen} size={"md"} btnSize={"sm"} btnCls="btn-small p-0" placement="left">
        <Accordion activeKey={"link1"} onToggle={setShow}>
          <Item eventKey="link1" title={<><Icon icon="chevron-back" /> {translate('apply')}</>}>
            <div className="">
              {links.length > 0 && links.map((link, key) => <Button key={key} variant="link" className={"text-primary text-start"} onClick={() => handleRemove(link)}>
                {translate(link.name)} <Icon icon={"remove"} color="blue" />
              </Button>)}
            </div>
            <div>
              <div className="px-3">
                <input type="text"
                  className={"form-control slideInRight w-100"} placeholder={translate('search')}
                  onChange={(e) =>
                    setKeyword(e.target.value)
                  } />
              </div>
              {stages && stages.map((stage, key) => {
                let newTasks = tasks.filter(t => t.stage_id === stage.id);
                return (<div key={key}>
                  {newTasks.length > 0 && <div className="px-3 fw-bold">{stage.name}</div>}
                  {newTasks.length > 0 && newTasks.map(link => {
                    const find = links.length ? links.find(item => item.id === link.id) : false;
                    if (!find) {
                      return (
                        <Button variant="link" className={"text-dark text-start"} onClick={() => handleClick(link)}>
                          {translate(link.name)} <Icon icon={"add"} color="blue" />
                        </Button>
                      )
                    }
                  }
                  )}
                </div>)
              })}
            </div>
          </Item>
          <Item eventKey="link2" title={<><Icon icon="create" /> {translate('edit')}</>}>
            <ItemLink dispatch={dispatch} type={'editLink'} field={field} setOnEdit={setOnEdit} setIsReset={setIsReset} />
          </Item>
        </Accordion>
      </Portal>
    )
  }

  if (full) {
    return (
      <div>
        {links.length > 0 &&
          <div className="field-list">
            {links.map(link => (
              <Button icon="open" title={link.name} key={link.id} onClick={() => setContent(link)} />
            ))}
          </div>}
        {menu(translate('addLink'), 'add', 'md')}
      </div>
    )
  } else {
    return (<div className="field-list d-flex align-items-center justify-content-start text-start">
      {<div>{menu('', 'add')}</div>}
      {links.length > 0 ?
        <>
          <Button className="text-start p-0" title={links.length && text_truncate(links[0].name, STR_LIMITED)} onClick={() => setContent(links[0])} />
          {links.length > 1 &&
            <Dropdown btnClass="btn-small text-start p-0 small text-secondary" title={`+${links.length - 1}`}>
              {links.map(link => (
                <Button icon="open" title={link.name} key={link.id} onClick={() => setContent(link)} />
              ))}
            </Dropdown>}
        </> : <span className="p-2"></span>
      }
    </div>
    )
  }
};
