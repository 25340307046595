const ProgressBar = ({children, now, striped, variant, className, disabled}) => {
  let cls = `progress-bar bg-${variant} ${className}`;
  if(striped) cls += ' progress-bar-striped';

  return(
    <div className="progress">
  <div className={cls} disabled={disabled} role="progressbar" style={{width: `${now}%`}} aria-valuenow={now} aria-valuemin="0" aria-valuemax="100">{`${now}%`}</div>
</div>
)}

export default ProgressBar;
