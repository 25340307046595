import { sendNotification } from '../services/Api';
import moment from "moment";
const GRAPHQL_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_GRAPHQL_LOCAL : process.env.REACT_APP_GRAPHQL;

async function updateStatus(id, status) {
  const res = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: `
      mutation {
        updateTask(id: "${id}", status: "${status}")
      }`
    }),
  });
}

async function updateStage(id, stage_id, workflow_id) {
  const res = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: `
      mutation {
        updateTask(id: "${id}", stage_id: "${stage_id}", workflow_id: "${workflow_id}")
      }`
    }),
  });
}

function createLabel(action, task) {
  let { color, name } = action;
  let { id , workflow_id } = task;
  //create to group
  let tags = []
}

async function addNotify(user, task) {
  //Add notify
  var devices;
  let person;
  if(user.id === "owner"){
    const res = await fetch(GRAPHQL_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: `
        query {
          user(id: "${task.user_id}") {
            id
            name
            email
            device
          }
        }`
      }),
    });
    const result = await res.json();
    if(result && result.data && result.data.user){
      person = result.data.user;
    }
  }else {
    person = user;
  }
  if(person){
    sendNotification(person, task.name, 'mentioned');
  }
  //End Notify
}

async function sendEmail(subject, body, mail, task) {
  let name = localStorage.getItem('user_name');
  let from = localStorage.getItem('user_email');
  let app_name = localStorage.getItem('app_name');
  var email;

  let collection = task.collection ? JSON.parse(task.collection) : [];

  if(mail.field === 'email' && collection){
    email = collection[mail.id] ? collection[mail.id] : '';
  }else if(mail === 'owner'){
    email = task.value
  }else {
    email = mail.email
  }

  let str = body.replace(/(\r\n|\n|\r)/gm, "<br>");

  function changeStr(_, p1) {
    if(!collection) return;
    return collection[p1] ? collection[p1] : p1;
  }

  let message = str.replace(/\[(.*?)\]/g, changeStr);

  const res = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: `
      mutation {
        sendEmail(email: "${email}", subject: "${subject}", message: "${message}", from_name: "${from}", to_name: "${name}")
      }`
    }),
  });

}

async function addTaskItem(name, {user_id, stage_id, workflow_id}, parent_id) {
  const res = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: `
      mutation {
        createTask(name: "${name}", stage_id: "${stage_id}", workflow_id: "${workflow_id}", user_id: "${user_id}", parent_id: "${parent_id}", status: "progress"){
          id
          name
        }
      }`
    }),
  });
  const result = await res.json();
  if(result && result.data && result.data.createTask){
    return true;
  }
}

async function addStage(name, workflow_id) {
  let app_id = localStorage.getItem('app_id');
  const res = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: `
      mutation {
        createStage(name: "${name}", app_id: "${app_id}", workflow_id: "${workflow_id}", type: "todo"){
          id
          name
        }
      }`
    }),
  });
}

async function addUserTask(task, id, user_name) {
  //Fix owner.
  let type = "add";
  if(id === 'owner'){
    id = task.user_id
  }
  const res = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query: `
      mutation {
        taskUser(task_id: "${task.id}", user_id: "${id}")
      }`
    }),
  });
}

async function updateTaskDate(due_date, id) {
  try {
    if(due_date === "currentDay"){
      due_date = moment().format('YYYY-MM-DD HH:mm');
    }
    const res = await fetch(GRAPHQL_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: `
        mutation {
          updateTask(id: "${id}", due_date: "${due_date}")
        }`
      }),
    });

  } catch (error) {
    console.log(error);
  }
}

function automationProcess(action, actionValue, task) {
  switch (action) {
    case "changeStatus":
      updateStatus(task.id, actionValue.name)
      break;
    case "moveItem":
      updateStage(task.id, actionValue.id, task.workflow_id)
      break;
    case "assignePerson":
      addUserTask(task, actionValue.id, actionValue.name)
      break;
    case "createItem":
        addTaskItem(actionValue.name, task)
      break;
    case "createSubItem":
        addTaskItem(actionValue.name, task, task.id)
      break;
    case "createGroup":
          addStage(actionValue.name, task.workflow_id)
      break;
    case "createLabel":
          createLabel(actionValue, task)
        break;
    case "setDate":
          updateTaskDate(actionValue.name, task.id)
          break;
    case "notify":
        addNotify(actionValue, task)
      break;
    case "mail":
        sendEmail(actionValue.name, actionValue.body, actionValue.mail, task)
      break;
    default:
  }
}

async function automation(dispatch, task) {
    try {
      const res = await fetch(GRAPHQL_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: `
          query {
            automations(workflow_id: "${task.workflow_id}") {
              id
              name
              when
              when_value
              action
              action_value
              condition
              condition_value
              user_id
            }
          }`
        }),
      });

      const result = await res.json();
      if(result.data && result.data.automations){
        result.data.automations.map(automate => {
          if(automate.when === dispatch){
            let { when, when_value, action, action_value, condition, condition_value} = automate;
            let { id, name } = when_value ? JSON.parse(when_value) : []
            let actionValue = action_value ? JSON.parse(action_value) : []
            let conditionValue = condition_value ? JSON.parse(condition_value) : []

            if(when === "statusChanges" && name === task.status){
                automationProcess(action, actionValue, task)
            }

            if(when === "itemMoved" && id === task.stage_id){
              automationProcess(action, actionValue, task)
            }

            if(when === "itemCreated"){
              automationProcess(action, actionValue, task)
            }

            if(when === "personAssigned" && id === task.assign_id){
              automationProcess(action, actionValue, task)
            }

            if(when === "dateArrives"){
              automationProcess(action, actionValue, task)
            }

            if(when === "columnChanges" && id === task.field){
              var result = false;
              if(condition) {
                if(condition === '=' && task.value === conditionValue) {
                  result = true;
                }else if(condition === '<>' && task.value !== conditionValue){
                  result = true;
                }
                else if(condition === 'value' && task.value){
                  result = true;
                }
                else if(condition === 'empty' && !task.value){
                  result = true;
                }
              }else {
                result = true;
              }

              if(result) automationProcess(action, actionValue, task);

            }
          }
        })
      }

    } catch (error) {
      console.log(error);
    }
}

export { automation }
