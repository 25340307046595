import React, { useEffect, Suspense, lazy } from "react";
import {
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import { Spinner } from '../../components/Uix';
import { isMobile, isIOS } from 'react-device-detect';
// context
import { useLayoutState } from "../../context/LayoutContext";
import { useModeState } from "../../context/ModeContext";
import homeList from '../../data/Home';
import 'react-toastify/dist/ReactToastify.css';
// components
import Navbar from "../Sidebar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { ToastContainer } from 'react-toastify'

// pages

const Notification = lazy(() => import("../../pages/notification"))
const Error = lazy(() => import("../../pages/error"))
const Messenger = lazy(() => import("../../pages/messenger"))
const Dashboard = lazy(() => import("../../pages/dashboard"))
const Account = lazy(() => import("../../pages/setting/Account"))
const Home = lazy(() => import("../../pages/dashboard/Home"))
const Team = lazy(() => import("../../pages/team"))
const Group = lazy(() => import("../../pages/team/Group"))
const Profile = lazy(() => import("../../pages/team/Profile"))
const Invites = lazy(() => import("../../pages/team/Invites"))
const Support = lazy(() => import("../../pages/support"))
const Todos = lazy(() => import("../../pages/tasks/Todos"))
const Reports = lazy(() => import("../../pages/reports/Reports"))
const SaleReports = lazy(() => import("../../pages/reports/SaleReports"))
const Messengers = lazy(() => import("../../pages/messenger/Messengers"))
const Workspaces = lazy(() => import("../../pages/workspace/Workspaces"))
const WorkspacesConfig = lazy(() => import("../../pages/workspace/Configs"))
const Workspace = lazy(() => import("../../pages/workspace"))
const Calendar = lazy(() => import("../../pages/home/Calendar"))
const Docs = lazy(() => import("../../pages/home/Docs"))
const Forms = lazy(() => import("../../pages/form/Forms"))
const Favorites = lazy(() => import("../../pages/home/Favorites"))
const Sequences = lazy(() => import("../../pages/email/Sequences"))
const Doc = lazy(() => import("../../pages/docs"))
const Email = lazy(() => import("../../pages/email"))
const Task = lazy(() => import("../../pages/tasks/Task"))
const MyWork = lazy(() => import("../../pages/tasks/MyWork"))
const Workflow = lazy(() => import("../../pages/workflow"))
const TeamImport = lazy(() => import("../../pages/team/Import"))
const InitData = lazy(() => import("../../pages/setting/InitData"))
const Templates = lazy(() => import("../../pages/template"))
const Export = lazy(() => import("../../pages/export"))
 

function mobileRedirect () {
  if(isIOS) {
    window.location.href = 'https://apps.apple.com/vn/app/workflow/id1471537598?l=vi'
  } else {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.nic.workflow'
  }
}



function Layout({location, history}) {
  // global
  var { isSidebarOpened } = useLayoutState();
  var { isDarkMode } = useModeState();

  useEffect(() => {
      if(isMobile) mobileRedirect()
    },
    [isMobile],
  );
  //bg-light-primary
  var clss = 'main-layout bg-light toggleClass open'

  if(isDarkMode){
    clss += ' dark-mode';
  }

  let width = window.innerWidth;
  let boardPath = location.pathname.slice(3)[0];
  let basePath = location.pathname.split('/');
  let homePath = basePath[2];
  let idPath = basePath[3];
  let navW = (boardPath === "a" || homeList.find(h => h === homePath)) ? 256: 48;
  let redirect = localStorage.getItem('lg_redirect');

  return (
        <>
          <div className={clss}>
            <Navbar history={history} id={boardPath==='w'?idPath:null}/>
            <div className="d-flex">
              <div className="md-with" style={{width: isSidebarOpened ? 256 : 0}}>
                <Sidebar id={idPath} history={history}/>
              </div>
              <div className="md-main shadow-sm rounded-3 pt-4 ps-4 bg-white">
                <Suspense fallback={<div><Spinner animation="grow" variant="success" /></div>}>
                  <Switch>
                    <Route path="/w/home" component={Home} />
                    <Route path="/w/workspaces" component={Workspaces} />
                    <Route path="/w/workspace/order" component={WorkspacesConfig} />
                    <Route path="/w/workspace/:id" component={Workspace} />
                    <Route path="/w/mywork" component={MyWork} />
                    <Route path="/w/salereports" component={SaleReports} />
                    <Route path="/w/dashboard/:id" component={Dashboard} />
                    <Route path="/w/calendar" component={Calendar} />
                    <Route path="/w/form/:id" component={Forms} />
                    <Route path="/w/doc/:id" component={Doc} />
                    <Route path="/w/todos" component={Todos} />
                    <Route path="/w/favorites" component={Favorites} />
                    <Route path="/w/teams" component={Team} />
                    <Route path="/w/group/:id" component={Group} />
                    <Route path="/w/invites" component={Invites} />
                    <Route path="/w/a/:id" component={Workflow} />
                    <Route path="/w/mi/:id" component={Messenger} />
                    <Route path="/w/t/:id" component={Task} />
                    <Route path="/w/team/:id" component={Profile} />
                    <Route path="/w/account/:id" component={Account} />
                    <Route path="/w/team_import/:id" component={TeamImport} />
                    <Route path="/w/team" component={Team} />
                    <Route path="/w/g/:id" component={Group} />
                    <Route path="/w/e/:id" component={Email} />
                    <Route path="/w/ab/:id" component={Dashboard} />
                    <Route path="/w/setting" component={Account} />
                    <Route path="/w/settings" component={Account} />
                    <Route path="/w/notification" component={Notification} />
                    <Route path="/w/support" component={Support} />
                    <Route path="/w/reports" component={Reports} />
                    <Route path="/w/salereports" component={SaleReports} />
                    <Route path="/w/templates" component={Templates} />
                    <Route path="/w/initdata" component={InitData} />
                    <Route path="/w/export_csv/:app_id/:id" component={Export} />
                    <Route path="/w/404" component={Error} />
                    <Route path="/w/calendar" component={Calendar} />
                    <Route path="/w/f/:id" component={Forms} />
                    <Route path="/w/emails" component={Forms} />
                    <Route path="/w/docs" component={Docs} />
                    <Route path="/w/sequences" component={Sequences} />
                    <Route path="/w/sequence/:id" component={Email} />
                    <Route path="/w/messenger/:id" component={Messenger} />
                    <Route path="/w/messenger" component={Messengers} />
                    <Route path="/w/files" component={Forms} />
                    <Route path="/w/favorites" component={Favorites} />
                  </Switch>
                </Suspense>
                <ToastContainer
                  position="top-center"
                  autoClose={1000}
                  hideProgressBar={true}
                  newestOnTop={true}
                />
              </div>
            </div>
          </div>
        </>
  );
}

export default withRouter(Layout);
