import React, { useEffect, useState } from "react";
import { translate } from '../../services/Language';
import { text_truncate } from '../../services/Function';
import { automation } from '../../store/automation';
import { Form, Menu } from '../../components/Uix';
import { STR_LIMITED } from '../../services/Defines';

export default function InputText({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  isLabel,
  task,
  ...props
}){

  const [nameValue, setNameValue] = useState("");
  const [initValue, setInitValue] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    setNameValue(field.value)
    setInitValue(field.value)
  }, [field.value]);

  const handleChange = (text) => {
     dispatch({ type: 'updateField', payload: field.id, value: text })
     setNameValue(text)
     setOnEvent(true)
  }

 const handleUpdate = () => {
    setTimeout(function () {
      if(field && nameValue && !initValue){
        var data = Object.assign({}, task);
        data.field = field.id;
        data.value = nameValue;
        automation('columnChanges', data)
      }
    }, 5000);
  }

  const inputContent = (mode) => {
    return(
      <Menu icon={!nameValue ? "text" : null} title={nameValue && text_truncate(nameValue, STR_LIMITED)}>
        <Form.Textarea placeholder={field.title}
           onTextChange={handleChange}
           onMouseOut={handleUpdate}
           disabled={!mode} value={nameValue} type="textarea"
           className={"input-great"}/>
      </Menu>
    )
  }

  return (<>{inputContent(allowEdit)}</>)
};
