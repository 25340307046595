import React, { useEffect, useState } from "react";
import { translate } from '../../services/Language';
import Rating from "react-rating";
import Icon from '../../components/Icon';
import { Button } from '../../components/Uix';

export default function InputRating({
  workflow_id,
  dispatch,
  field,
  setOnEvent,
  setOnEdit,
  allowEdit,
  ...props
}){

  var [nameValue, setNameValue] = useState(0);

  useEffect(() => {
    setNameValue(field.value)
  }, [field.value]);

  const ratingChanged = (count) => {
     dispatch({ type: 'updateField', payload: field.id, value: count })
     setOnEvent(true)
     setNameValue(count)
  }

  const handleRemove = () => {
     dispatch({ type: 'updateField', payload: field.id, value: null })
     setOnEvent(true)
     setNameValue()
  }

  const inputContent = (mode, value) => {
    return(
      <div className="justify-content-center align-items-center text-center on-hover" style={{maxWidth: 140}}>
        <Rating
          initialRating={value}
          onChange={ratingChanged}
          readonly={!mode}
          emptySymbol={<Icon icon={"star"}/>}
          fullSymbol={<Icon icon="star" color="#ffd166"/>}
      />
      {value && <Button variant="link" onClick={handleRemove} className="in-hover">
        <Icon icon="trash" size="sm"/>
      </Button>}
      </div>
    )
  }

  return (<>{inputContent(allowEdit, nameValue)}</>)

};
