import React, { useEffect, useState } from "react";
import { SketchPicker } from 'react-color';
import { Menu, Button, Item } from '../../components/Uix';
import Icon from '../../components/Icon';

export default function InputColor({
  workflow_id,
  dispatch,
  field,
  setOnEvent,
  setOnEdit,
  allowEdit,
  ...props
}){

  var [nameValue, setNameValue] = useState(0);

  useEffect(() => {
    setNameValue(field.value)
  }, [field.value]);

  const handleChangeComplete = (value) => {
     dispatch({ type: 'updateField', payload: field.id, value })
     setOnEvent(true)
     setNameValue(value)
  }

  const handleRemove = () => {
     dispatch({ type: 'updateField', payload: field.id, value: null })
     setOnEvent(true)
     setNameValue()
  }

  const customTitle = (value) => {
    return(<Item>{value ? <div className="d-flex align-items-center"> <span className="rounded-3 p-2 d-block me-1" style={{backgroundColor: value.hex, width: 10, height: 10}}></span> {value.hex} </div>: <span className="p-2 d-block" style={{width: 120, height: 14}}></span>}</Item>)
  }

  const inputContent = (mode, value) => {
    return(
      <div className="field-list d-flex justify-content-center align-items-center on-hover">
      <Menu title={customTitle(value)} >
        <SketchPicker
          color={ value }
          onChangeComplete={ handleChangeComplete}
        />
      </Menu>
      {value && <Button variant="link" onClick={handleRemove} className="in-hover">
        <Icon icon={"trash"} size="sm"/>
      </Button>}
    </div>
    )
  }

  return (<>{inputContent(allowEdit, nameValue)}</>)

};
