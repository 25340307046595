import React, { useState } from "react";
import Icon from '../../components/Icon';
import { Menu, Item, Button, Toast } from '../../components/Uix';
import { translate } from '../../services/Language';
import slugify from 'slugify';
import ItemLink from './ItemLink';
import ItemSelect from './ItemSelect';
import ItemStatus from './ItemStatus';
import ItemProgress from './ItemProgress';
import ItemFormula from './ItemFormula';

export default function AddColumn({
  workflow_id,
  setOnEdit,
  dispatch,
  isLabel,
  state,
  listValue,
  fieldDefault,
  placement,
  variant,
  schema
}){

  let textInput = React.useRef();
  let textBtn = React.useRef();
  let textOption = React.useRef();
  const [select, setSellect] = useState("");
  const [connect, setConnect] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const { newFieldTitle, newFieldName } = state;

  //Add conect to field link
  const addConnect = () => {
    connect.map(link => {
      dispatch({
        type: "updateTitle",
        payload: translate(link.title),
      })
      dispatch({
        type: "updateMirror",
        payload: 'mirror',
        ref: link.id,
        connect: slugify(newFieldTitle, {replacement: '_', locale: 'vi'}),
      })
      dispatch({ type: "add" })
      setOnEdit(true)
    })
    setConnect([])
  }

    var fields = Object.values(fieldDefault);
    //var newfields = Object.values(fields).slice(0, showMore ? fields.length : fields.length / 2)
    var newfields = Object.values(fields).filter(f => f.field !== 'mirror');

    return (<div className="col field-list position-relative">
      <Menu title={isLabel && translate('addField')} icon="add" size="md" placement={placement??"right"} autoHide={hide} onClose={() => setHide(false)} variant={variant}>
          <div className="px-4 py-2 mb-2 text-secondary fw-bold border-bottom text-start">{translate('customField')}</div>
          <div className="text-start">
          {newfields.map((link, key) => (
            <Item key={link.field} className={showMore ? "w-50 d-inline-block px-3" : "px-3"}>
            <Button variant="link" onClick={() => {
              dispatch({
                type: "updateTitle",
                payload: translate(link.field),
                icon: link.icon,
              })
              dispatch({
                  type: "updateType",
                  payload: link.field
              })
              //open popup
              //Show all options (=== 'status' || link.field === 'select' || link.field === 'link')
              if(link.field){
                setSellect(link.field)
                setIsOpen(true)
                setTimeout(() => {
                  textInput.current?.focus()
                }, 300);
              }else {
                dispatch({ type: "add" })
                setHide(true)
                setOnEdit(true)
              }
            }}>
              <Icon icon={link.icon} /> {translate(link.field)}
            </Button>
            </Item>
          ))}
          </div>
          {!showMore && <Button className="nav-link small border-top w-100 pt-2" variant="link" onClick={() => setShowMore(!showMore)}>{showMore ? translate('showLess') : translate('showMore')}</Button>}
        </Menu>

        <Toast title={translate('customField')} show={isOpen} onClose={() => setIsOpen(false)} placement="right" size={(newFieldName === 'status' || newFieldName === 'formula' || newFieldName === 'link') ? "md" : "sm"}>
          <div className="w-100 p-3">
          <input
            type="text"
            value={newFieldTitle}
            className={"form-control"}
            placeholder={translate('fieldName')}
            onChange={({ target }) =>{
              dispatch({
                type: "updateTitle",
                payload: target.value,
              })
            }}
            onKeyPress={({ key, target }) => {
              if(key === 'Enter' && target.value.length > 0){
                if(newFieldName === 'select') {
                  if(listValue) {
                    dispatch({ type: "add" })
                  }else {
                    setTimeout(() => {
                      textOption.current?.focus()
                    }, 600);
                  }
                }else{
                  dispatch({ type: "add" })
                }
                setIsOpen(false)
              }
            }}
            ref={textInput}
          />

          {select === 'select' && <ItemSelect dispatchField={dispatch} type={'updateType'} setOnEdit={setOnEdit}/>}
          {select === 'link' && <ItemLink dispatch={dispatch} type={'updateLink'} workflow_id={workflow_id} setOnEdit={setOnEdit} setConnect={setConnect}/>}
          {select === 'status' && <ItemStatus dispatchField={dispatch} type={'updateType'} workflow_id={workflow_id} setOnEdit={setOnEdit}/>}
          {select === 'progress' && <ItemProgress dispatch={dispatch} type={'updateConfig'} setOnEdit={setOnEdit} schema={schema}/>}
          {select === 'formula' && <ItemFormula dispatch={dispatch} type={'updateConfig'} setOnEdit={setOnEdit} schema={schema}/>}

           <div className="text-end">
            <Button ref={textBtn} className="mt-1" variant="primary" onClick={() => {
               if(textInput.current.value.length > 0){
                 //Add link connect
                 dispatch({ type: "add" })
                 if(select === 'link'){
                   addConnect()
                 }
                 setIsOpen(false)
                 setOnEdit(true)
                 setSellect("")
               }
             }}>{translate('add')}</Button>
            </div>
          </div>
        </Toast>
    </div>
  )
};
