import React, { useState, useEffect } from 'react';
import { Menu, Button } from "../../components/Uix";
import Icon from '../../components/Icon';
import { Link } from "react-router-dom";
import { UpdateTask, TaskCopy } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { realtime } from '../../services/FirebaseInit';
import { automation } from '../../store/automation';
import { historyAdd } from '../../store/history';
import { translate } from '../../services/Language';
import { sendNotification } from '../../services/Api';
import Confirm from '../Confirm';
import EditName from '../Task/EditName';

export default function TaskHeader({task, setUpdate, setOnDelete, setOnTask, onTask, setContent, allowEdit,  history, isPage, setForward}){

  const [onEvent, setOnEvent] = useState(false);
  const [onActivity, setOnActivity] = useState(false);

  const [statusValue, setStatusValue] = useState("");

  const [subVisiable, setSubVisiable] = useState(true);
  const [subField, setSubField] = useState("");

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  let userName = localStorage.getItem('user_name');
  const [updateTask] = useMutation(UpdateTask);
  const [taskCopy] = useMutation(TaskCopy);

  useEffect(() => {
      if(onActivity) {
        setOnTask(true)
      }
  }, [onActivity]);

  //Get task
  useEffect(() => {
      //badge remove
      realtime.ref(`badges/${user_id}/${task.workflow_id}`).on('value', function(snapshot) {
          snapshot.forEach((childSnapshot) => {
            childSnapshot.forEach((subSnapshot) => {
              if(childSnapshot.val().id == task.id){
                realtime.ref(`badges/${user_id}/${task.workflow_id}/${childSnapshot.key}`).remove()
              }
            })
          })
      });
      //End badge

  }, [task]);


  const handleClose = () => {
      setUpdate('')
      if(isPage){
        history.push(`/w/a/${task.workflow_id}`)
      }
  };

  const handleClone = async() => {
      taskCopy({variables: {id: task.id}})
      setOnTask(true)
  };

  const handleStatus = async(item) => {
    var status = item === 'progress' ? 'done' : 'progress'
    setStatusValue(status)
    updateTask({variables: {id: task.id, status}})
    //Process automation
    automation('statusChanges', {id: task.id, name: task.name, status, user_id: task.user_id, stage_id: task.stage_id, workflow_id: task.workflow_id, collection: task.collection})
    //Add activity
    historyAdd({
      name: userName,
      description: status === 'done' ? translate('completedTheTask') : translate('openedTheTask'),
      workflow_id: task.workflow_id,
      task_id: task.id,
      user_id,
    })
    if(status === 'done'){
      task.users.map(user => {
        if(user.notify === true) {
          sendNotification(user,task.name,'completed');
        }
      })
    }
    setOnActivity(true)
  }

    return (
       <>
       <div className="d-flex justify-content-between align-items-center w-100">
         {!isPage && <> <div className="d-flex align-items-center">
           <Button variant="link" className="p-0 m-0 text-dark" onClick={(e) => allowEdit ? handleStatus(statusValue) : e.preventDefault()}>
             <Icon icon={statusValue === 'done' ? 'check-circle' : 'circle'}
             className={statusValue === 'done' ? "text-primary" : "text-secondary"}
              />
           </Button>
            <EditName task={task} setOnTask={setOnTask} allowEdit={allowEdit}/>
         </div>
         <div className="d-flex align-items-center">
         <Menu icon={'ellipsis-horizontal'} disabled={!allowEdit}>
           <div className="h4">{translate('option')}</div>
           <Link className="d-block btn btn-link text-start" to={`/w/t/${task.id}`} target="_blank">
             <Icon icon="link"/> {translate('openLink')}
           </Link>
           <Button className="d-block" variant="link" onClick={handleClone}>
             <Icon icon="copy"/> {translate('clone')}
           </Button>
           {setForward &&
           <Button className="d-block" variant="link" onClick={() => setForward(task)}>
             <Icon icon="sync-circle"/> {translate('forward')}
           </Button>}
           <Confirm type="task" id={task.id} workflow_id={task.workflow_id} title={task.name} setOnDelete={setOnDelete} placement="bottom"/>
         </Menu>
         </div></>}
       </div>
       </>
   );

}
