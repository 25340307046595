import React, { useState , useEffect, useRef} from "react";
import {Editor, EditorState, RichUtils, AtomicBlockUtils, ContentState, CompositeDecorator, Modifier, getDefaultKeyBinding, convertFromHTML, convertFromRaw, convertToRaw} from 'draft-js';
import {useEditor} from './Hooks';
import { urlForMedia, random } from '../../services/Function';
import { translate } from '../../services/Language';
import 'draft-js/dist/Draft.css';
import './style.css';
import { Button, Dropdown, Menu, Tooltip } from '../../components/Uix';
import Icon from '../../components/Icon';
import {Map} from 'immutable';
import Link from './Blocks/Link';
import Image from './Blocks/Image';
import Block from './Blocks/Block';
import Table from './Blocks/Table';
import Board from './Blocks/Board';
import Iframe from './Blocks/Iframe';
import Add from './Controls/Add';
import BlockStyleControls from './Controls/Style';
import InlineStyleControls from './Controls/Inline';


export default function RichEditor({fields, boards, setData, setBody, data, onPrint, simple, placeholder}) {
  const refUrl = useRef()
  const refLink = useRef()
  const editor = useRef()
  const [urlValue, setUrlValue] = useState("")
  const [urlType, setUrlType] = useState("")
  const [textValue, setTextValue] = useState("")
  const [addButton, setAddButton] = useState(false)
  const [currentTextAlignment, setCurrentTextAlignment] = useState('left')
  const [tableEdits, setTableEdits] = useState(Map());

    const { findLinkEntities, findImageEntities, findIframeEntities, findBoardEntities, findTableEntities, styleMap, promptForLink} = useEditor()

    const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
        {
          strategy: findImageEntities,
          component: Image,
        },
        {
          strategy: findBoardEntities,
          component: Board,
        },
        {
          strategy: findIframeEntities,
          component: Iframe,
        },
        /*{
          strategy: findTableEntities,
          component: Table,
          props: {
            onStartEdit: blockKey => {
              //setTableEdits(tableEdits.set(blockKey, true));
            },
            onFinishEdit: blockKey => {
              //setTableEdits(tableEdits.remove(blockKey));
            },
          },
        }*/
      ]);

   const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));

     const _promptForLink = () => {
        setUrlValue(promptForLink(editorState))
        setTimeout(() => refLink.current?.focus(), 220);
    }

     const confirmLink = (e) => {
         e.preventDefault();
         const contentState = editorState.getCurrentContent();
         const contentStateWithEntity = contentState.createEntity(
           'LINK',
           'MUTABLE',
           {url: urlValue}
         );
         const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
         const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
         setEditorState(RichUtils.toggleLink(
               newEditorState,
               newEditorState.getSelection(),
               entityKey,
        ))

         setTimeout(() => editor.current?.focus(), 0);
         setUrlValue('')
      }

      const removeLink = (e) => {
         e.preventDefault();
         const selection = editorState.getSelection();
         if (!selection.isCollapsed()) {
           setEditorState(RichUtils.toggleLink(editorState, selection, null))
         }
       }


     const confirmBlock = (type, value, title) => {
          const contentState = editorState.getCurrentContent();
          let entityURL;
          let mutable;
          switch (type) {
            case "BOARD":
              entityURL = {board_id: value, title}
              break;
            case "VIDEO":
              entityURL = {src: urlForMedia(value)}
              break;
            case "TABLE":
              entityURL = value;
              mutable = 'IMMUTABLE';
              break;
            default:
              entityURL = {src: value}
              mutable = 'UNMUTABLE';
          }
          const contentStateWithEntity = contentState.createEntity(
            type,
            mutable,
            entityURL
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(
            editorState,
            {currentContent: contentStateWithEntity}
          );

          setEditorState(AtomicBlockUtils.insertAtomicBlock(
                newEditorState,
                entityKey,
                ' '
         ))
          setUrlValue('')
      }

      const onURLInputKeyDown = (e) => {
          if (e.which === 13) {
            confirmBlock('LINK');
          }
        }

      const promptForMedia = (type) => {
          setUrlValue('')
          setUrlType(type)
          setTimeout(() => refUrl.current?.focus(), 200)
      }

  //Import Data from DB.
  useEffect(() => {
    if(!!data){
      if(data.blocks){
        const blocks = convertFromRaw(data);
        setEditorState(EditorState.createWithContent(blocks, decorator))
      }else {
        //Only html.
        const blocksFromHTML = convertFromHTML(data.length > 3 ? data.replace(/(\r\n|\n|\r)/gm, "<br>") : '<p></p>');
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        setEditorState(EditorState.createWithContent(state, decorator))
      }
    }
  },[data])

  //Export Editor to HTML & JSON
  useEffect(() => {
     const content = editorState.getCurrentContent();
     setData(convertToRaw(content))
     setBody(editor.current.editor?.innerHTML);
  },[editorState])

  useEffect(() => {
    function onChange(){
      const content = editorState.getCurrentContent();
      setEditorState( EditorState.createWithContent(content, decorator));
    }
    if(onPrint) onChange();
    return () => onChange;
  },[onPrint])

  const handleKeyCommand = (command) => {
   const newState = RichUtils.handleKeyCommand(editorState, command);
   if (newState) {
     setEditorState(newState);
     return true;
   }
   return false;
 };

 const mapKeyToEditorCommand = (e) => {
   if (e.keyCode === 9 /* TAB */) {
     const newEditorState = RichUtils.onTab(
       e,
       editorState,
       4, /* maxDepth */
     );
     if (newEditorState !== editorState) {
       setEditorState(newEditorState);
     }
     return;
   }
   if (e.keyCode === 191 /* slack / */) {
     setAddButton(true);
   }else {
     setAddButton(false);
   }
   return getDefaultKeyBinding(e);
}

const toggleBlockType = (blockType) => {
     setEditorState(RichUtils.toggleBlockType(editorState, blockType));
};

const toggleInlineStyle = (inlineStyle) => {
     setEditorState(
       RichUtils.toggleInlineStyle(editorState, inlineStyle)
     );
  };

const toggleBlockColor = (toggledColor) => {

    const selection = editorState.getSelection();
    //const contentState = editorState.getCurrentContent();

    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(styleMap)
      .reduce((contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color)
      }, editorState.getCurrentContent());

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style'
    );

    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledColor
      );
    }

    setEditorState(nextEditorState);
 }

function getBlockStyle(block) {
  const blockAlignment = block.getData() && block.getData().get('textAlign');
  if (blockAlignment) {
    return `aligned-block text-${blockAlignment}`;
  }
  if(block.getType() === "blockquote"){
    return "blockquote";
  }
  if(block.getType() === "divider"){
    return "border-bottom";
  }
  return null;
}

function getNextAlignment(currentAlignment) {
    switch (currentAlignment) {
        case 'right':
            return 'left';
        case 'left':
            return 'center';
        case 'center':
            return 'right';
        default:
            return 'left';
    }
}

const addBlockAlignmentData = () => {
    let value = getNextAlignment(currentTextAlignment);
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const newContentState = Modifier.setBlockData(
     contentState,
     selection,
     { 'textAlign': value }
   );
   setEditorState(EditorState.push(editorState, newContentState, 'change-block-data'))
   setCurrentTextAlignment(value)
 };

function componentBlockRenderer(block) {
  if (block.getType() === 'atomic') {
    return {
      component: Block,
      editable: false,
      props: {
        onPrint: onPrint,
        onStartEdit: blockKey => {
          setTableEdits(tableEdits.set(blockKey, true));
        },
        onFinishEdit: blockKey => {
          setTableEdits(tableEdits.remove(blockKey));
        },
      },
    };
  }
  return null;
}

const confirmField = (e, id) => {
  e.preventDefault();
  let text = `[${id}]`; //field define
  let nextEditorState = EditorState.createEmpty();
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const nextContentState = Modifier.insertText(contentState, selection, text);

  setEditorState(EditorState.push(
    editorState,
    nextContentState,
    'insert-characters'
  ))
}

const confirmEmoji = (emoji) => {
  let nextEditorState = EditorState.createEmpty();
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const nextContentState = Modifier.insertText(contentState, selection, emoji);

  setEditorState(EditorState.push(
    editorState,
    nextContentState,
    'insert-characters'
  ))
}

const handleUndo = () => {
  setEditorState(EditorState.undo(editorState))
}

const handleRedo = () => {
  setEditorState(EditorState.redo(editorState))
}

const focusEditor = () => {
    editor.current.focus();
};

  return (
    <>
    <div className={`editor ${!simple && "border rounded shadow-sm"}`}>
      {!simple &&
      <div className="p-2 d-flex align-items-center">

      <div className="border-end">
        <Add confirmBlock={confirmBlock}
          confirmField={confirmField}
          confirmEmoji={confirmEmoji}
          editorState={editorState}
          onColorToggle={toggleBlockColor}
          confirmLink={confirmLink}
          removeLink={removeLink}
          onTypeToggle={toggleBlockType}
          boards={boards}
          fields={fields}
          addButton={addButton}
        />
      </div>

      <Tooltip placement="top" title={translate('text')}>
        <Menu icon="document-text" placement="left">
          <BlockStyleControls
            editorState={editorState}
            onTypeToggle={toggleBlockType}
          />
        </Menu>
      </Tooltip>

      <Tooltip placement="top" title={translate('alignment')}>
        <Button onClick={() => addBlockAlignmentData()} icon={`align-${currentTextAlignment}`} />
      </Tooltip>

      <div className="border-start border-end">
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
      </div>

      <Tooltip placement="top" title={translate('undo')}>
        <Button onClick={handleUndo} icon="undo"/>
      </Tooltip>
      <Tooltip placement="top" title={translate('redo')}>
        <Button onClick={handleRedo} icon="redo"/>
      </Tooltip>
      </div>}
      <div className={`editor ${!simple && "border-top"} p-2`} onClick={focusEditor}>
        <Editor
          ref={editor}
          customStyleMap={styleMap}
          blockRendererFn={componentBlockRenderer}
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={setEditorState}
          placeholder={placeholder ?? `${translate('enter')} /`}
          readOnly={tableEdits.count()}
        />
      </div>
     </div>
    </>
  );

}
