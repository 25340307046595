import React, { useState, useEffect } from 'react';
import Icon from '../../components/Icon';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from "react-router-dom";
import { randomNumer, colors } from '../../services/Function';
import types from '../../data/Type';

export default function GroupItem({
  link,
  active_link,
  badges,
  rules,
  index,
  ...props
}){
  let w_link = 'a';
  let cls = 'd-flex align-items-center rounded nav-link p-2 position-relative';
  if(active_link.slice(5) === link.id) cls += ' bg-light text-primary'
  let clsIcon = 'd-flex border rounded align-items-center justify-content-center text-center';
  if(active_link.slice(5) === link.id) clsIcon += ' text-primary border-primary'
  var badge = badges[link.id] ? badges[link.id].length : null
  var iconAvatar = types.find(t => t.name === link.type)?.icon;

  return (
    <>
    <Draggable
        key={link.id}
        draggableId={link.id}
        index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                >
                  <div className="nav-item" {...provided.dragHandleProps}>
                    <Link to={`/w/${w_link}/${link.id}`} className={cls}>
                      <span className={clsIcon} style={{width: 32, height: 32}}><Icon icon={iconAvatar??"grid"}/></span>
                      <span className="ms-2">{link.name}</span>
                      {badge && <span className="small text-white rounded-pill notify" style={{top:0, right:3}}>
                        <i class="sr-only-0">{badge}</i></span>}
                    </Link>
                </div>
            </div>
        )}
    </Draggable>
    </>
  );
}
