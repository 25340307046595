import React, { useState, useMemo, useEffect } from 'react';
import { Dropdown, Button, Item } from '../../components/Uix';
import Icon from '../../components/Icon';
import TaskView from '../../components/Task/TaskView';
import TaskViewSum from '../../components/Task/TaskViewSum';
import { TasksQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";
import { translate } from '../../services/Language';
import { resetData } from '../../reducers/Actions';
import colors from "../../data/Colors"

  export default function StageView({stages, users, columns, schema, id}){

  const [items, setItems] = useState([]);
  const [fields, setFields] = useState([]);

  const { loading, refetch, data } = useQuery(TasksQuery, {
    variables: { id, user_id: null, keyword: '' },
  });

  useMemo(() => {
    if(data && data.tasks){
      setItems(data.tasks)
    }
  },[data])

  useEffect(() => {
     if(schema) {
        let { column } = resetData(schema, []);
        setFields(column)
      }
  }, [schema]);

  let sortedStages = Object.values(stages).sort(function(obj1, obj2) {
    if (obj1.index > obj2.index) return 1;
    if (obj2.index > obj1.index) return -1;
    return 0;
  });

  return (
    <>
    <table className="table table-hover">
    {sortedStages && sortedStages.map((stage, key) => {
      let tasks = items.filter(t=>t.stage_id===stage.id);
      return(
        <tbody key={key}>
        <tr style={{backgroundColor: colors[key]}} className="border-bottom">
          <td scope="col">{stage.name}</td>
          {fields && fields.map((field, key) => {
            if(field.enabled === true){
              return (<td key={key} scope="col">{field.title}</td>)
            }
          })}
        </tr>
        {tasks && tasks.map((task, key) => <TaskView key={key} users={users} stages={stages} fields={fields} task={task}/>)}
        <tr key={key}>
          <td scope="col"></td>
          {fields && fields.map((col, key) => {
            if(col.enabled === true) {
            if(col.field === 'integer' || col.field === 'progress' || col.field === 'mirror' || col.field === 'formula'){
              return <td key={key}><TaskViewSum col={col} rows={tasks}/></td>
            }else {
              return <td key={key}></td>
            }
           }
          })}
        </tr>
        </tbody>
      )}
    )}
    </table>
    </>
  );
}
