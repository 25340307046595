import React, { useState, useEffect, useRef, useMemo } from 'react';
import { UpdateTask } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { translate } from '../../services/Language';
import Icon from '../../components/Icon';
import { Button } from '../../components/Uix';
import Editor from '../../components/Editor';

let MIN_HEIGHT = 64;

export default function EditName({
  task,
  allowEdit,
  simple
}){
  let { id, description, workflow_id } = task
  const [nameValue, setNameValue] = useState();
  const [height, setHeight] = useState(MIN_HEIGHT);
  const [show, setShow] = useState(false);
  const [dataValue, setDataValue] = useState([]);
  let textInput = useRef();
  const [updateTask] = useMutation(UpdateTask);

  const getHeight = () => {
    setHeight(textInput.current ? textInput.current.scrollHeight: height)
  }

  useEffect(() => {
    setNameValue(description)
  }, [id]);

  useMemo(() => {
    if(typeof nameValue !== 'undefined'){
      updateTask({variables: {id, description: nameValue}})
    }
  },[nameValue])

  return (
    <div>
      <div style={{minHeight: height}}>
        <Editor fields={[]} setData={setDataValue} setBody={setNameValue} data={description} simple={simple} placeholder={translate('description')}/>
      </div>
      {height > MIN_HEIGHT && <Button variant="link" onClick={() => setHeight(MIN_HEIGHT)}>
         <Icon icon={"chevron-up"} className={"text-primary"}/>
      </Button>}
    </div>
  );
}
