import React, { useState } from "react";
import { Button, Form } from '../../../components/Uix';
import { translate } from '../../../services/Language';
export default function TableControl({confirmBlock}) {
  const [row, setRow] = useState(3)
  const [column, setColumn] = useState(3)
  return (
    <>
    <Form.Input placeholder={translate('row')}
       label={translate('row')}
       onTextChange={setRow} value={row} type="number"
       className={"mb-3"}/>
    <Form.Input placeholder={translate('column')}
          onTextChange={setColumn}
          label={translate('column')}
          value={column} type="number"
          className={"mb-3"}/>
    <Button title={translate('add')} onClick={() => {
    const cell = Object.fromEntries(
      Array.from(
        {length: row},
        (_, i) => [i, Array.from({length: column}, (_, j) => ` `)],
      ),
    );
    confirmBlock('TABLE', {row, column, cell, caption: ''})
  }} icon={'add'}/></>);

}
