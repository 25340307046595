import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Button, Toast, Form, Title} from '../../components/Uix';
import Icon from '../../components/Icon';
import { UpdateTask } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { translate } from '../../services/Language';
import moment from "moment";

export default function DateTime({
  task_id,
  workflow_id,
  due_date,
  done,
  start,
  allowEdit,
  inline,
  position,
  islabel,
  taskDispatch,
}){
      const [show, setShow] = useState(false);
      const [check, setCheck] = useState(false);
      const [startDate, setStartDate] = useState();
      const [endDate, setEndDate] = useState();
      const [dateDisplay, setDateDisplay] = useState("");
      const [dateLabel, setDateLabel] = useState("");
      const [timediff, setTimediff] = useState(0);
      const [reminder, setReminder] = useState("5");
      const [updateTask] = useMutation(UpdateTask);

      const handleShow = () => setShow(true);

      let options = [
        {id:"1",name: translate('time1')},
        {id:"0",name: translate('time0')},
        {id:"5",name: translate('time5')},
        {id:"10",name: translate('time10')},
        {id:"15",name: translate('time15')},
        {id:"20",name: translate('time20')},
        {id:"30",name: translate('time30')},
        {id:"60",name: translate('time60')},
        {id:"120",name: translate('time120')},
        {id:"1440",name: translate('time1440')},
        {id:"2880",name: translate('time20')}
      ]

      const handleClose = () => {
        setShow(false)
      };

      const handleClick = async() => {
        var start,due_date,rem = null;
        if(startDate){
          const date = new Date(startDate);
          var start_date = moment(date).format('YYYY-MM-DD HH:mm')
        }

        if(endDate){
            const date2 = new Date(endDate);
            var dt = moment(date2)
            var due_date = dt.format('YYYY-MM-DD HH:mm')
            var rem = dt.subtract(reminder, 'm').format('YYYY-MM-DD HH:mm')
        }

        if(check && startDate) {
           start = start_date
        }

        updateTask({variables: {
          id: task_id,
          due_date,
          start,
          reminder: reminder === "1" ? null : rem
        }})
        setShow(false)
        if(taskDispatch){
          taskDispatch({ type: "updateDate", payload: task_id, date: moment(due_date).valueOf()})
        }
      };

      const handleRemove = () => {
        updateTask({variables: {
          id: task_id,
          due_date: null,
          start: null,
          reminder: null
        }})
        setDateLabel("")
        setDateDisplay("")
        setTimediff()
      };

      const showDisplay = (start, due_date) => {
          var diff = null;
          if(due_date) diff = moment().isAfter( moment(due_date), 'days')

          var start_day = start ? moment.utc(Number(start)) : moment();
          var end_day = due_date ? moment.utc(Number(due_date)): moment();

          var display = "";

          if(start && due_date){
            if(start_day.format('MMM') == end_day.format('MMM')){
              display = `${start_day.format('DD')} - ${end_day.format('DD')} ${end_day.format('MMM')}`;
            }
            else {
              display = `${start_day.format('DD MMM')} - ${end_day.format('DD MMM')}`;
            }
          }else if (due_date) {
             display = `${end_day.format('DD MMM')}`;
          }
          else {
            display = islabel ? translate("date") : null;
          }
          setDateLabel(due_date ? moment.utc(due_date).format('ll') : "")
          setDateDisplay(display)
          setTimediff(diff)
      }

      useEffect(() => {
          if(start) setStartDate(moment.utc(Number(start)).toDate())
          if(due_date) setEndDate(moment.utc(Number(due_date)).toDate())
          if(start && !check) setCheck(true)
          showDisplay(start, due_date)
        },
        [start, due_date, task_id],
      );

      useEffect(() => {
          let start = startDate ? new Date(startDate) : null;
          let end = endDate ? new Date(endDate) : null;
          showDisplay(start, end)
        },
        [startDate, endDate],
      );

      let variant = "link"
      if(done) {
        variant = "success"
      }else if(timediff){
        variant = "danger"
      }else {
        variant = "light"
      }

      return (
        <>
          <div>
                <div className="field-list d-flex justify-content-start align-items-center on-hover">
                  <Button disabled={!allowEdit} size="sm" variant={variant} className={"rounded-4 fs-7 px-1"} onClick={handleShow}>
                    <Icon color={"white"} icon="time"/>
                    {dateDisplay}
                  </Button>
                  {due_date && <Button disabled={!allowEdit} size="sm" variant="link" onClick={handleRemove} className="in-hover text-secondary">
                    <Icon icon="trash" size="sm"/>
                  </Button>}
                </div>

              <Toast show={show} onClose={handleClose} placement="fixed" customWidth={check?710:380} position={position}>
              <div className="d-flex justify-content-between align-items-center">
                  <Form.Check label={`${translate('addStartDay')}?`} checked={check}
                  onTextChange={(e) => setCheck(e)}/>
                 <small className="ms-2">{dateLabel}</small>
               </div>
                <div className="p-2 my-picker">
                  <div className="d-flex justify-content-start align-items-center">
                    {check && <div>
                    <label>{translate('startDay')}</label>
                    <DatePicker
                      selected={startDate??null}
                      onChange={date => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      minDate={moment().subtract(5, 'years').valueOf()}
                      maxDate={moment().add(5, 'years').valueOf()}
                      inline
                      showTimeSelect
                      showYearDropdown
                      locale="vi"
                      timeFormat="HH:mm"
                      dateFormat="d MMMM, yyyy H:mm aa"
                    />
                    </div>}
                    <div>
                      {check && <label>{translate('endDay')}</label>}
                      <DatePicker
                        selected={endDate??null}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={moment.utc(startDate).valueOf()}
                        endDate={endDate}
                        minDate={moment().subtract(5, 'years').valueOf()}
                        maxDate={moment().add(5, 'years').valueOf()}
                        inline
                        showTimeSelect
                        showPopperArrow={false}
                        showYearDropdown
                        locale="vi"
                        timeFormat="HH:mm"
                        dateFormat="d MMMM, yyyy H:mm aa"
                      />
                    </div>
                  </div>
                  <div className="form-group" style={{maxWidth: 320}}>
                    <label>{translate('setupRemider')}</label>
                    <Form.Select select2 options={options} value={reminder} onTextChange={e => setReminder(e)} />
                  </div>
                  <Button variant="success" size="sm" onClick={handleClick}>
                    {translate('save')}
                  </Button>
                  <Button variant="link" size="sm" className={"text-secondary"} onClick={handleClose}>
                    <Icon icon="trash" /> {translate('cancel')}
                  </Button>
                </div>
              </Toast>
          </div>
        </>

      );
}
