import React, { useState , useEffect} from "react";
import { Button } from '../../components/Uix';
import Icon from '../../components/Icon';
import Drive from "../Media/Drive";
import Upload from "./Upload";
import { translate } from '../../services/Language';
import { CreateFile, CreateHistory } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";
import { random } from '../../services/Function';
import { sendNotification } from '../../services/Api';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function AddFile({
  app_id,
  workflow_id,
  task_id,
  fixed,
  title,
  setOnEvent,
  setFileResult,
  setFileAttach,
  setOnActivity,
  location,
  variant,
  users
}) {

  var [completed, setCompleteValue] = useState(0);
  var [noteValue, setNoteValue] = useState("");
  var [urlValue, setUrlValue] = useState("");
  var [typeValue, setTypeValue] = useState("");
  var [driveUrl, setDriveUrl] = useState("");
  var [fileUpload, setFileUpload] = useState([]);
  var [filesValue, setFilesValue] = useState([]);
  var [dataValue, setDataValue] = useState();
  const [onOpen, setOnOpen] = useState(false);
  const [createFile, { data, loading, error }] = useMutation(CreateFile);
  const [createHistory] = useMutation(CreateHistory);

  let user_id = localStorage.getItem('user_id');
  let userName = localStorage.getItem('user_name');
  //Google Drive
  useEffect(() => {
      if(driveUrl){
        let fileUp = []
        driveUrl.map(fileRow => {
          var {name, id} = fileRow
          var file = `https://drive.google.com/file/w/${id}/view`;
          fileUp.push({type: 'drive', name, uri: id, file })
          setUrlValue(file)
          setTypeValue('drive')
        })
        setFilesValue(fileUp)
        setOnOpen(true)
      }
    },
    [driveUrl],
  );

 //Upload from Computer
  useEffect(() => {
     function asyncInit() {
        let fileUp = []
        fileUpload.map( async(fileRow) => {
          let id = `${random(8)}-${fileRow.name}`;
          var {name, type} = fileRow;
          var fileData = new FormData()
          fileData.append("file", fileRow)
          fileData.append("name", name)
          fileData.append("id", id)
          const res = await fetch(`${SERVER_URL}/cloud/file`, {
            method: "POST",
            body: fileData
          });
          var file = URL.createObjectURL(fileRow)
          fileUp.push({type, name, uri: id, file })
          setUrlValue(file)
          setTypeValue(type)
        })

        setFilesValue(fileUp)
    }

     if(fileUpload.length){
      asyncInit()
      setOnOpen(true)
     }
    },
    [fileUpload],
  );

  const handleFileUpload = async () => {
        if(filesValue.length) {
          filesValue.map( async(fileRow) => {
            createFile( {variables: {
              name: fileRow.name,
              uri:  fileRow.uri,
              type: fileRow.type,
              note: noteValue,
              workflow_id,
              task_id,
              user_id,
            }})

            if(task_id){
              //Add activity
              createHistory( {variables: {
                name: userName,
                description: `${translate('uploadFile')} ${fileRow.name}`,
                workflow_id,
                task_id,
                user_id
              }})
            }
          })

          if(task_id && users){
            let lists = []
            users.map(user => {
              if(user.notify === true){
                sendNotification(user,title,'upload');
              }
            })
          }


          let fileResults = []
          filesValue.map(r => {
            fileResults.push({
              name: r.name,
              uri:  r.uri,
              type: r.type,
              note: noteValue
            })
          })

          if(setFileResult) setFileResult(fileResults)
          if(setFileAttach) setFileAttach(fileResults)
          if(setOnEvent) setOnEvent(true)
          if(setOnActivity) setOnActivity(true)

          setFilesValue()
          setOnOpen(false)
        }
    };

  return (
    <>
     <div className={"p-2 mb-3 text-start"}>
      <Drive setFile={setDriveUrl} location={location}/>
      <Upload setFileUpload={setFileUpload}/>

      {onOpen &&
       <div>
        <div className="d-flex justify-content-center align-items-center">
           <div className="col-4">
           {(typeValue == 'image/png' || typeValue == 'image/jpeg' || typeValue == 'image/gif') ?
           <img className="img-fluid rounded" src={urlValue}/> :
            <img className="img-fluid rounded" src={"/assets/document-500.png"}  />
           }
           </div>
           <div>
           <span>{filesValue[0]?.name}</span>
            <textarea className={"form-control w-100 mb-0"} type="text" placeholder="Note, Comment..."
               value={noteValue}
               onChange={e => setNoteValue(e.target.value)}
               />
           </div>
         </div>
         <Button variant="primary" className={"w-100 pb-0 mt-3"} onClick={handleFileUpload}>
           <Icon icon="cloud-upload" /> {translate('upload')}
         </Button>
        </div>}
     </div>
    </>
  );
}
