import React from "react";
import { ICON_WIDTH, ICON_HEIGHT, ICON_COLOR } from '../../services/Defines';

export default function Form({
  color,
  width,
  height,
  onClick,
  className,
  ...props
}) {
   return (<svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width={width??ICON_WIDTH} height={height??ICON_HEIGHT} fill={color??ICON_COLOR} className={className} viewBox="0 0 60 60">
   <rect width="60" height="60" rx="8" fill="#7849CD"/>
   <path d="M12 21C14.2091 21 16 19.2091 16 17C16 14.7909 14.2091 13 12 13C9.79086 13 8 14.7909 8 17C8 19.2091 9.79086 21 12 21Z" fill="white"/>
   <path d="M52 14.3333H22.6667V19.6667H52V14.3333Z" fill="white"/>
   <path d="M52 27.6667H22.6667V33H52V27.6667Z" fill="white"/>
   <path d="M22.6667 41H52V46.3333H22.6667V41Z" fill="white"/>
   <path d="M16 30.3333C16 32.5425 14.2091 34.3333 12 34.3333C9.79086 34.3333 8 32.5425 8 30.3333C8 28.1242 9.79086 26.3333 12 26.3333C14.2091 26.3333 16 28.1242 16 30.3333Z" fill="white"/>
   <path d="M12 47.6667C14.2091 47.6667 16 45.8758 16 43.6667C16 41.4575 14.2091 39.6667 12 39.6667C9.79086 39.6667 8 41.4575 8 43.6667C8 45.8758 9.79086 47.6667 12 47.6667Z" fill="white"/>
</svg>
 );
}
