import { Button } from '../../../components/Uix';
import { translate } from '../../../services/Language';
export default function BlockStyleControls({editorState, onTypeToggle}) {

  const BLOCK_TYPES = [
          {label: translate('normalText'), style: 'PARAGRAPH', class: '', icon: 'text'},
          {label: translate('title'), style: 'header-one', class: 'fs-1', icon: 'type-h1'},
          {label: translate('heading2'), style: 'header-two', class: 'fs-2', icon: 'type-h2'},
          {label: translate('heading3'), style: 'header-three', class: 'fs-3', icon: 'type-h3'},
          {label: translate('bulletedList'), style: 'unordered-list-item', class: '', icon: 'list-ul'},
          {label: translate('numberedList'), style: 'ordered-list-item', class: '', icon: 'list-ol'},
        ];

    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();


  return (<div className="d-flex flex-column align-items-start">
    {BLOCK_TYPES.map((type,key) => (
      <Button
        icon={type.icon}
        className={`text-start w-100 ${type.class}`}
        key={key}
        active={type.style === blockType}
        title={type.label}
        onClick={() => onTypeToggle(type.style)}
        size={24}
      />
    ))}
  </div>);

}
