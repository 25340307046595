import AddOutline from "./AddOutline"
import AlertCircleOutline from "./AlertCircleOutline"
import ArrowUpRightSquare from "./ArrowUpRightSquare"
import AtOutline from "./AtOutline"
import AttachOutline from "./AttachOutline"
import ArrowsMove from "./ArrowsMove"
import ArrowsCollapse from "./ArrowsCollapse"
import ArrowsExpand from "./ArrowsExpand"
import AlignLeft from "./AlignLeft"
import AlignCenter from "./AlignCenter"
import AlignRight from "./AlignRight"
import BarChartOutline from "./BarChartOutline"
import BatteryHalfOutline from "./BatteryHalfOutline"
import BookmarkOutline from "./BookmarkOutline"
import BulbOutline from "./BulbOutline"
import BriefcaseOutline from "./BriefcaseOutline"
import CafeOutline from "./CafeOutline"
import CashOutline from "./CashOutline"
import CalculatorOutline from "./CalculatorOutline"
import CalendarOutline from "./CalendarOutline"
import CallOutline from "./CallOutline"
import CubeOutline from "./CubeOutline"
import ChatbubbleOutline from "./ChatbubbleOutline"
import ChatbubblesOutline from "./ChatbubblesOutline"
import CheckboxOutline from "./CheckboxOutline"
import ChevronBackOutline from "./ChevronBackOutline"
import ChevronDownOutline from "./ChevronDownOutline"
import ChevronForwardOutline from "./ChevronForwardOutline"
import ChevronUpOutline from "./ChevronUpOutline"
import CloseOutline from "./CloseOutline"
import ContrastOutline from "./ContrastOutline"
import CopyOutline from "./CopyOutline"
import ColorPaletteOutline from "./ColorPaletteOutline"
import CreateOutline from "./CreateOutline"
import Circle from "./Circle"
import CheckCircle from "./CheckCircle"
import CheckOutline from "./CheckOutline"
import CloudUpload from "./CloudUpload"
import DocumentAttachOutline from "./DocumentAttachOutline"
import DocumentTextOutline from "./DocumentTextOutline"
import DownloadOutline from "./DownloadOutline"
import DriveOutline from "./DriveOutline"
import EllipsisHorizontalOutline from "./EllipsisHorizontalOutline"
import ExitOutline from "./ExitOutline"
import ExpandOutline from "./ExpandOutline"
import EyeOffOutline from "./EyeOffOutline"
import EyeOutline from "./EyeOutline"
import FilterOutline from "./FilterOutline"
import FlaskOutline from "./FlaskOutline"
import FunnelOutline from "./FunnelOutline"
import FolderOutline from "./FolderOutline"
import FolderOpenOutline from "./FolderOpenOutline"
import ShieldCheck from "./ShieldCheck"
import GitMergeOutline from "./GitMergeOutline"
import GridOutline from "./GridOutline"
import GearOutline from "./GearOutline"
import GolfOutline from "./GolfOutline"
import HappyOutline from "./HappyOutline"
import HouseOutline from "./HouseOutline"
import ImageOutline from "./ImageOutline"
import InfiniteOutline from "./InfiniteOutline"
import KanbanOutline from "./KanbanOutline"
import LinkOutline from "./LinkOutline"
import ListOutline from "./ListOutline"
import ListCircleOutline from "./ListCircleOutline"
import LockOutline from "./LockOutline"
import LockOpenOutline from "./LockOpenOutline"
import LogoutOutline from "./LogoutOutline"
import LocationOutline from "./LocationOutline"
import MagnetOutline from "./MagnetOutline"
import MailOutline from "./MailOutline"
import MoonStars from "./MoonStars"
import MoonStarsFill from "./MoonStarsFill"
import NotificationsOutline from "./NotificationsOutline"
import OpenOutline from "./OpenOutline"
import OptionOutline from "./OptionOutline"
import QRCodeOutline from "./QRCodeOutline"
import PaperPlaneOutline from "./PaperPlaneOutline"
import PieChartOutline from "./PieChartOutline"
import PersonOutline from "./PersonOutline"
import PersonCircleOutline from "./PersonCircleOutline"
import PersonPlus from "./PersonPlus"
import PulseOutline from "./PulseOutline"
import PinOutline from "./PinOutline"
import ProjectOutline from "./ProjectOutline"
import PrintOutline from "./PrintOutline"
import PeopleOutline from "./PeopleOutline"
import RadioButtonOffOutline from "./RadioButtonOffOutline"
import RefreshOutline from "./RefreshOutline"
import RepeatOutline from "./RepeatOutline"
import RedoOutline from "./RedoOutline"
import RocketOutline from "./RocketOutline"
import RobotOutline from "./RobotOutline"
import ReplyOutline from "./ReplyOutline"
import ReceiptOutline from "./ReceiptOutline"
import RemoveCircleOutline from "./RemoveCircleOutline"
import SaveOutline from "./SaveOutline"
import ShieldCheckmarkOutline from "./ShieldCheckmarkOutline"
import Star from "./Star"
import StarOutline from "./StarOutline"
import SadOutline from "./SadOutline"
import ShapesOutline from "./ShapesOutline"
import SearchOutline from "./SearchOutline"
import ShareOutline from "./ShareOutline"
import SyncCircleOutline from "./SyncCircleOutline"
import Square from "./Square"
import SwapHorizontalOutline from "./SwapHorizontalOutline"
import TableOutline from "./TableOutline"
import TextOutline from "./TextOutline"
import TimeOutline from "./TimeOutline"
import ToggleOutline from "./ToggleOutline"
import TodayOutline from "./TodayOutline"
import TrashOutline from "./TrashOutline"
import TrendingUpOutline from "./TrendingUpOutline"
import HashOutline from "./HashOutline"
import UploadOutline from "./UploadOutline"
import UndoOutline from "./UndoOutline"
import VideoOutline from "./VideoOutline"
import Bold from "./Bold"
import Italic from "./Italic"
import Underline from "./Underline"
import Strikethrough from "./Strikethrough"

import Typeh1 from "./Typeh1"
import Typeh2 from "./Typeh2"
import Typeh3 from "./Typeh3"
import ListUL from "./ListUL"
import ListOL from "./ListOL"
import LineOutline from "./LineOutline"
import QuoteOutline from "./QuoteOutline"
import CodeOutline from "./CodeOutline"

import Form from "./Form"

const Icon = ({icon, size, color, onClick}) => {
  let width = null; let height = null;
  if(size === 'sm'){
    width = 16; height = 16;
  }
  if(size === 'md'){
    width = 24; height = 24;
  }
  if(size === 'lg'){
    width = 36; height = 36;
  }
  switch (icon) {
    case "alert-circle":
    case "alert-circle-outline":
      return <AlertCircleOutline onClick={onClick} width={width} height={height} color={color}/>
    case "arrow-up-right-square":
      return <ArrowUpRightSquare onClick={onClick} width={width} height={height} color={color}/>
    case "at":
    case "at-outline":
      return <AtOutline onClick={onClick} width={width} height={height} color={color}/>
    case "add":
    case "add-outline":
      return <AddOutline onClick={onClick} width={width} height={height} color={color}/>
    case "attach":
    case "attach-outline":
      return <AttachOutline onClick={onClick} width={width} height={height} color={color}/>
    case "arrows-move":
      return <ArrowsMove onClick={onClick} width={width} height={height} color={color}/>
    case "arrows-collapse":
      return <ArrowsCollapse onClick={onClick} width={width} height={height} color={color}/>
    case "arrows-expand":
        return <ArrowsExpand onClick={onClick} width={width} height={height} color={color}/>
    case "align-left":
        return <AlignLeft onClick={onClick} width={width} height={height} color={color}/>
    case "align-right":
        return <AlignRight onClick={onClick} width={width} height={height} color={color}/>
    case "align-center":
        return <AlignCenter onClick={onClick} width={width} height={height} color={color}/>
    case "bar-chart":
    case "bar-chart-outline":
      return <BarChartOutline onClick={onClick} width={width} height={height} color={color}/>
    case "battery-half":
    case "battery-half-outline":
      return <BatteryHalfOutline onClick={onClick} width={width} height={height} color={color}/>
    case "bookmark":
      return <BookmarkOutline onClick={onClick} width={width} height={height} color={color}/>
    case "bulb":
      return <BulbOutline onClick={onClick} width={width} height={height} color={color}/>
    case "briefcase":
      return <BriefcaseOutline onClick={onClick} width={width} height={height} color={color}/>
    case "bold":
      return <Bold onClick={onClick} width={width} height={height} color={color}/>
    case "cafe":
      return <CafeOutline onClick={onClick} width={width} height={height} color={color}/>
    case "cash":
        return <CashOutline onClick={onClick} width={width} height={height} color={color}/>
    case "calculator":
    case "calculator-outline":
      return <CalculatorOutline onClick={onClick} width={width} height={height} color={color}/>
    case "calendar":
      return <CalendarOutline onClick={onClick} width={width} height={height} color={color}/>
    case "call":
      return <CallOutline onClick={onClick} width={width} height={height} color={color}/>
    case "chatbubble":
    case "chatbubble-outline":
      return <ChatbubbleOutline onClick={onClick} width={width} height={height} color={color}/>
    case "chatbubbles":
        return <ChatbubblesOutline onClick={onClick} width={width} height={height} color={color}/>
    case "checkbox":
    case "checkbox-outline":
      return <CheckboxOutline onClick={onClick} width={width} height={height} color={color}/>
    case "chevron-back":
      return <ChevronBackOutline onClick={onClick} width={width} height={height} color={color}/>
    case "chevron-down":
      return <ChevronDownOutline onClick={onClick} width={width} height={height} color={color}/>
    case "chevron-forward":
      return <ChevronForwardOutline onClick={onClick} width={width} height={height} color={color}/>
    case "chevron-up":
      return <ChevronUpOutline onClick={onClick} width={width} height={height} color={color}/>
    case "close":
      return <CloseOutline onClick={onClick} width={width} height={height} color={color}/>
    case "contrast":
    case "contrast-outline":
      return <ContrastOutline onClick={onClick} width={width} height={height} color={color}/>
    case "code":
        return <CodeOutline onClick={onClick} width={width} height={height} color={color}/>
    case "copy":
      return <CopyOutline onClick={onClick} width={width} height={height} color={color}/>
    case "color":
      return <ColorPaletteOutline onClick={onClick} width={width} height={height} color={color}/>
    case "create":
      return <CreateOutline onClick={onClick} width={width} height={height} color={color}/>
    case "circle":
      return <Circle onClick={onClick} width={width} height={height} color={color}/>
    case "cube":
      return <CubeOutline onClick={onClick} width={width} height={height} color={color}/>
    case "check-circle":
      return <CheckCircle onClick={onClick} width={width} height={height} color={color}/>
    case "check":
        return <CheckOutline onClick={onClick} width={width} height={height} color={color}/>
    case "cloud-upload":
      return <CloudUpload onClick={onClick} width={width} height={height} color={color}/>
    case "document-attach":
    case "document-attach-outline":
      return <DocumentAttachOutline onClick={onClick} width={width} height={height} color={color}/>
    case "document-text":
    case "document-text-outline":
      return <DocumentTextOutline onClick={onClick} width={width} height={height} color={color}/>
    case "drive":
      return <DriveOutline onClick={onClick} width={width} height={height} color={color}/>
    case "download":
      return <DownloadOutline onClick={onClick} width={width} height={height} color={color}/>
    case "ellipsis-horizontal":
      return <EllipsisHorizontalOutline onClick={onClick} width={width} height={height} color={color}/>
    case "exit":
      return <ExitOutline onClick={onClick} width={width} height={height} color={color}/>
    case "expand":
    case "expand-outline":
      return <ExpandOutline onClick={onClick} width={width} height={height} color={color}/>
    case "eye-off":
      return <EyeOffOutline onClick={onClick} width={width} height={height} color={color}/>
    case "eye":
      return <EyeOutline onClick={onClick} width={width} height={height} color={color}/>
    case "flask":
      return <FlaskOutline onClick={onClick} width={width} height={height} color={color}/>
    case "filter":
      return <FilterOutline onClick={onClick} width={width} height={height} color={color}/>
    case "funnel":
      return <FunnelOutline onClick={onClick} width={width} height={height} color={color}/>
    case "folder":
      return <FolderOutline onClick={onClick} width={width} height={height} color={color}/>
    case "folder-open":
      return <FolderOpenOutline onClick={onClick} width={width} height={height} color={color}/>
    case "save":
      return <SaveOutline onClick={onClick} width={width} height={height} color={color}/>
    case "share":
        return <ShareOutline onClick={onClick} width={width} height={height} color={color}/>
    case "shield-check":
      return <ShieldCheck onClick={onClick} width={width} height={height} color={color}/>
    case "git-merge":
    case "git-merge-outline":
      return <GitMergeOutline onClick={onClick} width={width} height={height} color={color}/>
    case "grid":
      return <GridOutline onClick={onClick} width={width} height={height} color={color}/>
    case "gear":
      return <GearOutline onClick={onClick} width={width} height={height} color={color}/>
    case "golf":
      return <GolfOutline onClick={onClick} width={width} height={height} color={color}/>
    case "happy":
      return <HappyOutline onClick={onClick} width={width} height={height} color={color}/>
    case "house":
        return <HouseOutline onClick={onClick} width={width} height={height} color={color}/>
    case "hash":
      return <HashOutline onClick={onClick} width={width} height={height} color={color}/>
    case "kanban":
      return <KanbanOutline onClick={onClick} width={width} height={height} color={color}/>
    case "infinite":
      return <InfiniteOutline onClick={onClick} width={width} height={height} color={color}/>
    case "image":
        return <ImageOutline onClick={onClick} width={width} height={height} color={color}/>
    case "italic":
        return <Italic onClick={onClick} width={width} height={height} color={color}/>
    case "link":
    case "link-outline":
     return <LinkOutline onClick={onClick} width={width} height={height} color={color}/>
    case "list":
      return <ListOutline onClick={onClick} width={width} height={height} color={color}/>
    case "list-circle":
      return <ListCircleOutline onClick={onClick} width={width} height={height} color={color}/>
    case "line":
      return <LineOutline onClick={onClick} width={width} height={height} color={color}/>
    case "logout":
      return <LogoutOutline onClick={onClick} width={width} height={height} color={color}/>
    case "lock":
        return <LockOutline onClick={onClick} width={width} height={height} color={color}/>
    case "lock-open":
      return <LockOpenOutline onClick={onClick} width={width} height={height} color={color}/>
    case "location":
    case "location-outline":
      return <LocationOutline onClick={onClick} width={width} height={height} color={color}/>
    case "list-ol":
      return <ListOL onClick={onClick} width={width} height={height} color={color}/>
    case "list-ul":
      return <ListUL onClick={onClick} width={width} height={height} color={color}/>
    case "magnet":
    case "magnet-outline":
      return <MagnetOutline onClick={onClick} width={width} height={height} color={color}/>
    case "mail":
      return <MailOutline onClick={onClick} width={width} height={height} color={color}/>
    case "moon-stars":
      return <MoonStars onClick={onClick} width={width} height={height} color={color}/>
    case "moon-stars-fill":
      return <MoonStarsFill onClick={onClick} width={width} height={height} color={color}/>
    case "notifications":
      return <NotificationsOutline onClick={onClick} width={width} height={height} color={color}/>
   case "option":
         return <OptionOutline onClick={onClick} width={width} height={height} color={color}/>
   case "open":
      return <OpenOutline onClick={onClick} width={width} height={height} color={color}/>
    case "paper-plane":
      return <PaperPlaneOutline onClick={onClick} width={width} height={height} color={color}/>
    case "person":
      return <PersonOutline onClick={onClick} width={width} height={height} color={color}/>
    case "person-outline":
      return <PersonOutline onClick={onClick} width={width} height={height} color={color}/>
    case "person-circle":
        return <PersonCircleOutline onClick={onClick} width={width} height={height} color={color}/>
    case "person-plus":
      return <PersonPlus onClick={onClick} width={width} height={height} color={color}/>
    case "pulse":
      return <PulseOutline onClick={onClick} width={width} height={height} color={color}/>
    case "pin":
      return <PinOutline onClick={onClick} width={width} height={height} color={color}/>
    case "pie-chart":
      return <PieChartOutline onClick={onClick} width={width} height={height} color={color}/>
    case "project":
      return <ProjectOutline onClick={onClick} width={width} height={height} color={color}/>
    case "print":
      return <PrintOutline onClick={onClick} width={width} height={height} color={color}/>
    case "people":
      return <PeopleOutline onClick={onClick} width={width} height={height} color={color}/>
    case "qr-code":
      return <QRCodeOutline onClick={onClick} width={width} height={height} color={color}/>
    case "quote":
      return <QuoteOutline onClick={onClick} width={width} height={height} color={color}/>
    case "radio-button-off":
      return <RadioButtonOffOutline onClick={onClick} width={width} height={height} color={color}/>
    case "refresh":
      return <RefreshOutline onClick={onClick} width={width} height={height} color={color}/>
    case "repeat":
      return <RepeatOutline onClick={onClick} width={width} height={height} color={color}/>
    case "redo":
      return <RedoOutline onClick={onClick} width={width} height={height} color={color}/>
    case "reply":
      return <ReplyOutline onClick={onClick} width={width} height={height} color={color}/>
    case "receipt":
      return <ReceiptOutline onClick={onClick} width={width} height={height} color={color}/>
    case "rocket":
      return <RocketOutline onClick={onClick} width={width} height={height} color={color}/>
    case "robot":
      return <RobotOutline onClick={onClick} width={width} height={height} color={color}/>
    case "remove":
      return <RemoveCircleOutline onClick={onClick} width={width} height={height} color={color}/>
    case "shield-checkmark":
      return <ShieldCheckmarkOutline onClick={onClick} width={width} height={height} color={color}/>
    case "sad":
      return <SadOutline onClick={onClick} width={width} height={height} color={color}/>
    case "sync-circle":
      return <SyncCircleOutline onClick={onClick} width={width} height={height} color={color}/>
    case "shapes":
        return <ShapesOutline onClick={onClick} width={width} height={height} color={color}/>
    case "square":
      return <Square onClick={onClick} width={width} height={height} color={color}/>
    case "star":
      return <Star onClick={onClick} width={width} height={height} color={color}/>
    case "star-outline":
      return <StarOutline onClick={onClick} width={width} height={height} color={color}/>
    case "search":
        return <SearchOutline onClick={onClick} width={width} height={height} color={color}/>
    case "swap-horizontal":
        return <SwapHorizontalOutline onClick={onClick} width={width} height={height} color={color}/>
    case "strikethrough":
        return <Strikethrough onClick={onClick} width={width} height={height} color={color}/>
    case "table":
        return <TableOutline onClick={onClick} width={width} height={height} color={color}/>
    case "text":
    case "text-outline":
      return <TextOutline onClick={onClick} width={width} height={height} color={color}/>
    case "trending-up":
      return <TrendingUpOutline onClick={onClick} width={width} height={height} color={color}/>
    case "type-h1":
      return <Typeh1 onClick={onClick} width={width} height={height} color={color}/>
    case "type-h2":
      return <Typeh2 onClick={onClick} width={width} height={height} color={color}/>
    case "type-h3":
      return <Typeh3 onClick={onClick} width={width} height={height} color={color}/>
    case "time":
      return <TimeOutline onClick={onClick} width={width} height={height} color={color}/>
    case "toggle":
      return <ToggleOutline onClick={onClick} width={width} height={height} color={color}/>
    case "today":
      return <TodayOutline onClick={onClick} width={width} height={height} color={color}/>
    case "trash":
      return <TrashOutline onClick={onClick} width={width} height={height} color={color}/>
    case "video":
      return <VideoOutline onClick={onClick} width={width} height={height} color={color}/>
    case "undo":
      return <UndoOutline onClick={onClick} width={width} height={height} color={color}/>
    case "upload":
      return <UploadOutline onClick={onClick} width={width} height={height} color={color}/>
    case "underline":
      return <Underline onClick={onClick} width={width} height={height} color={color}/>
    case "form":
      return <Form onClick={onClick} width={width} height={height} color={color}/>
    default:
      return <AtOutline  onClick={onClick} width={width} height={height} color={color}/>;
  }
}

export default Icon;
