import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner, Title } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { imageStorage } from '../../services/Function';
import UseApi from '../../services/UseApi';
import Upload from "../../components/Media/Upload";

let localUrl = process.env.REACT_APP_API_LOCAL
let proURL = process.env.REACT_APP_API_URL
let mode = process.env.NODE_ENV;
let SERVER_URL = mode === 'development' ? localUrl : proURL

export default function Photo({user}) {

  let ask_photo = localStorage.getItem('ask_photo', false);
  var [userId, setUserId] = useState("");
  var [nameValue, setNameValue] = useState("");
  var [emailValue, setEmailValue] = useState("");
  var [fileUpload, setFileUpload] = useState([]);
  var [urlValue, setUrlValue] = useState('');
  var [isLoading, setLoading] = useState(false);
  var [show, setShow] = useState(false);

  useEffect(() => {
    if(user && user.id) {
      var { id, photo, name, email } = user;
      setUserId(id)
      setNameValue(name)
      setEmailValue(email)
      setUrlValue(photo?imageStorage(photo):'/user.png')

      setTimeout(function(){
        var sh;
        if(photo){
          sh = false
        }else if(ask_photo) {
          sh = false
        }else {
          sh = true
        }
       setShow(sh)
      }, 5000);
    }
  },[user])

  //Upload from Computer
   useEffect(() => {
     async function asyncInit() {
         setLoading(true)
         let fileRow = fileUpload[0]
           var {name, type} = fileRow;
           var fileData = new FormData()
           fileData.append("file", fileRow)
           fileData.append("name", name)
           fileData.append("subfolder", userId)
           const {data} = await UseApi.post('/api/file/upload', fileData, {
             headers: {
                 'Content-Type': 'multipart/form-data'
             }
           })
           var file = URL.createObjectURL(fileRow)
           setUrlValue(file)
           // update
           await UseApi.patch('/api/settings/profile',{
             name: nameValue,
             email: emailValue,
             photo: data,
           })
     }

      if(fileUpload.length){
       asyncInit()
       setLoading(false)
      }
     },
     [fileUpload],
   );

   const handleClose = () => {
       setShow(false)
   };

   const handleConfirm = () => {
       setShow(false)
       localStorage.setItem('ask_photo', true);
   };

  return (
    <>
    <Modal show={show} onClose={handleClose} size={"md"} className="modal-60w">
      <Title className="pt-3 pb-2 px-3 bg-light-secondary">
          {translate('imageProfile')}
      </Title>
      <div className="p-4">
        <div className="p-4">
          <div className="w-75 mx-auto">
          <div className="lead pb-2">{translate('imageProfileInfo')}</div>
          <div className="border rounded-pill bg-light" style={{width: 120, height: 120}}>
            {urlValue && <img className="img-fluid rounded-pill" src={urlValue}/>}
          </div>
          <Upload setFileUpload={setFileUpload} label={translate('fromComputer')} icon="image"/>
          {isLoading && <Spinner animation="grow" variant="success" />}
          <div>
          <Button variant="light" className="my-2" onClick={handleConfirm}>
             {translate('updateLater')}
          </Button>
          {urlValue &&
          <Button variant="primary" className="my-2 ms-3" onClick={handleConfirm}>
             {translate('complete')}
          </Button>}
          </div>
        </div>
        </div>
      </div>
    </Modal>

    </>
  );
}
