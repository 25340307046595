import React, { useState, useEffect, useReducer } from 'react';
import { Button, Alert, Spinner, Form } from '../../components/Uix';
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import Upgrade from '../../components/Upgrade';
import * as EmailValidator from 'email-validator';
import { USER_LIMITED } from '../../services/Defines';
import { useMutation, useQuery, gql } from "@apollo/client";
import { InvitesQuery } from '../../services/GraphqlApi';
 const initialState = { emails: [], nextEmailId: 0, newEmailTitle: ""};
 
 const InviteEmail = gql`
  mutation InviteEmail(
    $inviteToken: String!
    $email: String
  ){
   inviteEmail(
     email: $email
     inviteToken: $inviteToken
   )
 }
`;

 function reducer(state, action) {
   switch (action.type) {
     case "reset":
       return {
         ...state,
         emails: action.payload,
         nextEmailId: action.nextEmailId
       };
     case "add":
       if(state.newEmailTitle.length > 0) {
         return {
           emails: [
             ...state.emails,
             {
               id: state.nextEmailId,
               email: state.newEmailTitle,
             }
           ],
           nextEmailId: state.emails.length + 1,
           newEmailTitle: "",
         }
       }
     case "remove":
       return {
         ...state,
         emails: state.emails.filter(email => email.id !== action.payload)
       };
     case "updateTitle":
       return {
         ...state,
         newEmailTitle: action.payload,
       };
     default:
       return state;
   }
 }

export default function Invite({
  app_id,
  count,
  app
}) {

  const [invalid, setInvalid] = useState(false);
  const [show, setShow] = useState(false);
  const [sending, setSending] = useState(false);
  const [inviteToken, setInviteToken] = useState('');
  const [state, dispatch] = useReducer(reducer, initialState);
  const { emails, newEmailTitle } = state;
  const [inviteEmail] = useMutation(InviteEmail);
  const { loading, refetch, data } = useQuery(InvitesQuery, {
    variables: { app_id },
  });
  //Update Task with tags
  async function handleClick() {
      setSending(true)
      if(emails){
        emails.map(item => {
          inviteEmail( {variables: {
            email: item.email,
            inviteToken
           }})
        })
      }

      dispatch({ type: "reset", payload: [], nextEmailId: 1})
      setShow(true)
      setSending(false)
  };

  useEffect(() => {
    dispatch({ type: "reset", payload: [], nextEmailId: 0})
  }, []);

  useEffect(() => {
    if(data && data.invites){
      var invites = data.invites.filter(e => e.email == null)
      if(invites.length > 0){
        setInviteToken(invites[0].id)
      }
    }
  }, [data]);

  let textInput = React.useRef();

  return (
    <>
      <div>
        <h4><Icon size="md" icon="person"/> {translate('invite')}</h4>
        <div>
        <label className="text-dark-70"><Icon size="sm" icon="mail"/> {translate('email')}</label>
        <Alert show={show} variant="success"><Icon size="sm" icon="check"/>  {translate('sent')}</Alert>
        {sending && <Spinner animation="border" variant="danger" />}
        {emails && emails.map(email => (
          <div key={email.id} className="w-100 d-flex justify-content-between align-items-center">
            <Form.Input
              onKeyPress={({ key, target }) => {
                if(key === 'Enter'){
                  if(target.value.length > 0){
                    setTimeout(() => {
                      textInput.current?.focus()
                    }, 600);
                  }else {
                    dispatch({ type: "remove", payload: email.id })
                  }
                }
              }}
              onTextChange={(value) => dispatch({ type: 'editEmail', payload: email.id, title: value })}
              value={email.email}
              className={"border-0 border-bottom"}
            />
            <Button variant="link" size="sm" className="text-start ms-2 ps-0 text-secondary"
            onClick={() => dispatch({ type: "remove", payload: email.id })}>
              <Icon size="sm" icon="trash"/>
            </Button>
          </div>
        ))}
        </div>

        <div className="w-100 d-flex justify-content-between align-items-center">
          <div style={{width: '80%'}}>
          <Form.Input
            type="email"
            value={newEmailTitle}
            className="border-0 border-bottom"
            placeholder={translate('email')}
            onTextChange={(value) =>{
              dispatch({
                type: "updateTitle",
                payload: value,
              })
            }}
            onTextPress={() => {
              if(EmailValidator.validate(newEmailTitle)){
                dispatch({ type: "add" })
                setInvalid(false)
              }else {
                setInvalid(true)
              }
            }}
          />
          </div>
          <Button variant="link" size="sm" icon="add-outline" title={translate('add')}
          onClick={() => {
            if(EmailValidator.validate(newEmailTitle)){
              dispatch({ type: "add" })
              setInvalid(false)
            }else {
              setInvalid(true)
            }
          }}/>
        </div>
        {(app.live === false && count >= USER_LIMITED) ? <Upgrade btn={true} text={translate('invite')}/> : (
          emails.length > 0 ? <Button variant="primary" size="sm" className="text-start mt-4"
          onClick={() => handleClick()}> {translate('invite')} </Button> : ""
        ) }

      </div>
    </>
  );
}
