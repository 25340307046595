const APP_COLOR = '#286efa';
const GREY_COLOR = '#f5f6f8';
const DARK_COLOR = '#454545';
const RED_COLOR = "#e63946";
const BORDER_COLOR = "#d5d5d5";
const BACKGROUND_COLOR_LIGHT = "white";
const BACKGROUND_COLOR_DARK = '#222';

const ICON_COLOR = '#666';
const ICON_WIDTH = 18;
const ICON_HEIGHT = 18;
const BORDER_RADIUS = 13;
const TITLE_FONT_SIZE = 13;
const TITLE_COLOR = "#8f8f8f";
const BUTTON_FONT_WEIGHT = "normal";
const BUTTON_FONT_COLOR = "#007ff9";
const BUTTON_FONT_SIZE = 20;
const WORKFLOW_LIMITED = 3;
const USER_LIMITED = 3;
const STR_LIMITED = 14;

export {
  APP_COLOR,
  GREY_COLOR,
  DARK_COLOR,
  RED_COLOR,
  ICON_COLOR,
  ICON_WIDTH,
  ICON_HEIGHT,
  BORDER_COLOR,
  BORDER_RADIUS,
  TITLE_COLOR,
  TITLE_FONT_SIZE,
  BUTTON_FONT_WEIGHT,
  BUTTON_FONT_COLOR,
  BUTTON_FONT_SIZE,
  BACKGROUND_COLOR_LIGHT,
  BACKGROUND_COLOR_DARK,
  USER_LIMITED,
  STR_LIMITED,
  WORKFLOW_LIMITED
};
