import React, { Suspense, lazy} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { i18nConfig } from './services/Language';
import { useUserState } from "./context/UserContext";
// components
import Layout from "./components/Layout";
import { Spinner } from './components/Uix';

// pages
const Error = lazy(() => import("./pages/error"));
const Auth = lazy(() => import("./pages/auth"));
const Password = lazy(() => import("./pages/auth/Password"));
const Login = lazy(() => import("./pages/auth/Login"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const Invite = lazy(() => import("./pages/auth/Invite"));
const Pricing = lazy(() => import("./pages/setting/Pricing"));
const Return = lazy(() => import("./pages/setting/Return"));
const Thanks = lazy(() => import("./pages/setting/Thanks"));
const Starter = lazy(() => import("./pages/starter"));
const Form = lazy(() => import("./pages/form"));
const Drive = lazy(() => import("./pages/drive"));
const Shares = lazy(() => import("./pages/workflow/Shares"));
const Docs = lazy(() => import("./pages/docs"));
const Template = lazy(() => import("./pages/admin/Templates"));
const AppUser = lazy(() => import("./pages/admin/AppUser"));
const BoardLink = lazy(() => import("./pages/workflow/BoardLink"));
const BoardView = lazy(() => import("./pages/workflow/BoardView"));
const Apps = lazy(() => import("./pages/admin/Apps"))
const Analytic = lazy(() => import("./pages/admin/Analytic"))

export default function App() {

  // global
  var { isAuthenticated } = useUserState();
  i18nConfig()

  return (
    <BrowserRouter>
    <Suspense fallback={<div><Spinner animation="grow" variant="success" /></div>}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/w/home" />} />
        <Route
          exact
          path="/w"
          render={() => <Redirect to="/w/home" />}
        />
        <PrivateRoute path="/w" component={Layout} />
        <PrivateRoute path="/apps/:id" component={Pricing} />
        <PrivateRoute path="/return" component={Return} />
        <PrivateRoute path="/thankyou" component={Thanks} />
        <PrivateRoute path="/admin/templates" component={Template} />
        <PrivateRoute path="/admin/app/:id" component={AppUser} />
        <PrivateRoute path="/admin/apps" component={Apps} />
        <PrivateRoute path="/admin/analytic" component={Analytic} />
        <PublicRoute path="/account" component={Auth} />
        <PublicRoute exact path="/password" component={Password} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute path="/password/reset/:token" component={ResetPassword} />
        <PublicRoute path="/invite/:id" component={Invite} />
        <PublicRoute path="/templates" component={Starter} />
        <Route path="/shares/:id/:view" component={Shares} />
        <Route path="/shares/:id" component={Shares} />
        <Route path="/board/:id" component={BoardLink} />
        <Route path="/docs/:id" component={Docs} />
        <Route path="/view/:id" component={BoardView} />
        <Route path="/forms/:id" component={Form} />
        <Route path="/drive" component={Drive} />
        <Route component={Error} />
      </Switch>
      </Suspense>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/account",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
