import React, { useState, useEffect } from 'react';
import { Pagesheet, Tab, Item, Spinner } from "../../components/Uix";
import Icon from '../../components/Icon';
import { translate } from '../../services/Language';
import { TaskQuery } from '../../services/GraphqlApi';
import { useLazyQuery } from "@apollo/client";
//import Column from '../../components/Column';
import TaskItems from './TaskItems';
import TaskComment from './TaskComment';
import TaskHeader from './TaskHeader';
import CRMActivity from './CRMActivity';
import Media from '../../components/Media';
//import Subitem from '../../components/Stage/Subitem';
//import Addon from '../../components/Column/Addon';
import EditDesc from '../Task/EditDesc';

export default function Updates({ location, history, update, setUpdate, setOnTask, onTask, setContent, workflow, isPage, setOnUpdate, setForward }) {

  const [show, setShow] = useState(false);
  const [subitem, setSubItem] = useState(false);
  const [linkitem, setLinkItem] = useState(false);
  const handleShow = () => setShow(true);

  const [onField, setOnField] = useState(false);
  const [onEvent, setOnEvent] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [onActivity, setOnActivity] = useState(false);
  const [eventKey, setEventKey] = useState(update?.eventKey ?? "content");

  const [allowEdit, setAllowEdit] = useState(false);
  const [allowAdd, setAllowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subField, setSubField] = useState("");
  const [schema, setSchema] = useState([]);
  const [task, setTask] = useState([]);

  let user_id = localStorage.getItem('user_id');
  let app_id = localStorage.getItem('app_id');
  let email = localStorage.getItem('user_email');

  let { group_id, type, columns, permission, users, stages, fields } = workflow;

  const [dataQuery, { data }] = useLazyQuery(TaskQuery);

  useEffect(() => {
    if (data && data.task) {
      setTask(data.task)

      if (data.task.workflow && data.task.workflow.schema) {
        setSchema(JSON.parse(data.task.workflow.schema))
      }
      setShow(true)
    }
  }, [data]);

  useEffect(() => {
    if (update && update.id) {
      dataQuery({ variables: { id: update.id } })
    }
  }, [update]);

  useEffect(() => {
    if (onDelete) {
      setShow(false)
      setOnTask(true)
      setUpdate()
    }
  }, [onDelete]);

  useEffect(() => {
    if (onField) {
      setOnUpdate(true)
    }
  }, [onField]);

  //Get task
  useEffect(() => {
    if (schema) {
      Object.values(schema).map(f => {
        if (f.field === 'subitem') {
          setSubItem(true)
          setSubField(f.title)
        }
        if (f.field === 'link') {
          setLinkItem(true)
        }
      })
    }
  }, [schema]);

  useEffect(() => {
    var isEdit, isAdd;
    if (permission == 'owner') {
      isEdit = true
    } else if (permission == 'anything') {
      isEdit = true
    } else if (permission == 'rows' && (task.users.find(u => u.id == user_id) || task.user_id === user_id)) {
      isEdit = true
    } else if (permission == 'content') {
      isEdit = true
    } else if (permission == 'addRows' || permission == 'viewRows') {
      isAdd = true
    } else if (permission == 'onlyview') {
      isEdit = false
    }
    setAllowEdit(isEdit)
    setAllowAdd(isAdd)
  }, [permission])

  const handleClose = () => {
    setUpdate('')
    setOnTask(true)
    setShow(false)
    if (isPage) {
      history.push(`/w/a/${task.workflow_id}`)
    }
  };

  const customTitle = (icon, title) => {
    return (<div className={"d-flex justify-content-start align-items-center"}>
      <Icon icon={icon} />
      <span className="ms-2 fw-bold">{translate(title)}</span>
    </div>)
  }

  const bodyContainer = (
    <>
      {isLoading && <div className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" variant="warning" /></div>}

      {task && <TaskHeader task={task} setOnTask={setOnTask} setOnDelete={setOnDelete} onTask={onTask} setContent={setContent} setUpdate={setUpdate} allowEdit={allowEdit} history={history} isPage={isPage} setForward={setForward} />}

      <Tab activeKey={eventKey} full>
        <Item title={customTitle('list', 'content')} eventKey={'content'}>
          {(fields && task) &&
            <TaskItems task={task} stages={stages} fields={fields} setOnTask={setOnTask} setContent={setContent} allowEdit={allowEdit} onField={onField} users={users} schema={schema} />}

          {/*schema && task &&
            <Column workflow_id={task.workflow_id} setOnField={setOnField} task_id={task.id} stage_id={task.stage_id} schema={task.parent_id ? columns : schema} allowEdit={allowEdit} setContent={setContent} task={task} users={users} stages={stages} />*/}

          {/*!task.parent_id &&
            <>
              {subitem && <div className="mt-4"><Subitem single workflow_id={task.workflow_id} stage_id={task.stage_id} next={null} prev={null} schema={columns} setSchema={[]} setOnTask={setOnTask} setOnEvent={setOnEvent} fields={fields} badges={[]} setUpdate={setUpdate} permission={permission} scrollX={0} setContent={setContent} group_id={group_id} subVisiable={false} stage_index={task.stage?.index} task_id={task.id} subField={subField} type={type} allowEdit={allowEdit} onTask={onTask} users={users} /></div>}

              {(task && schema && !task.parent_id) &&
                <Addon workflow_id={task.workflow_id} setOnField={setOnField} onField={onField} task_id={task.id} stage_id={task.stage_id} schema={schema} allowEdit={allowEdit} setContent={setContent} task={task} users={users} />}
            </>*/}

          {task &&
            <div className="form-group mt-4">
              <label className="fw-bold text-secondary">{translate('description')}</label>
              <EditDesc task={task} allowEdit={allowEdit} />
            </div>}
        </Item>
        <Item title={customTitle('chatbubble', 'comment')} eventKey={'activities'}>
          {task && <TaskComment task={task} setOnTask={setOnTask} onTask={onTask} setContent={setContent} allowEdit={allowEdit} location={location} users={users} workflow_id={task.workflow_id} />}
        </Item>
        <Item title={customTitle('attach', 'files')} eventKey={'files'}>
          <Media task_id={task.id} workflow_id={task.workflow_id} users={users} task_name={task.name} location={location} />
        </Item>
        <Item title={customTitle('pulse', 'crmActivity')} eventKey={'CRMActivity'}>
          {task && <CRMActivity task={task} workflow_id={task.workflow_id} />}
        </Item>
      </Tab>

      {/*
        <Item title={customTitle('time', 'history')} eventKey={'history'}>
           {task && <TaskActivity task={task}/>}
        </Item>
        task.todos &&
        <div className="form-group mb-3">
          {task.todos.map(todo => <TodoList todo_id={todo.id} workflow_id={workflow.id} checkname={todo.title} task_id={task.id} setOnActivity={setOnEvent} allowEdit={allowEdit}/>)}
        </div>*/}
      {/*
        <Item title={customTitle('list', 'invoice')} eventKey={'invoice'}>
         <div className="w-100" style={{height: 600}}>
         <iframe src={`http://localhost:3001?board_id=${task.workflow_id}&task_id=${task.id}`} style={{top:0, left:0, bottom:0, right:0, width:'100%', height:'100%', border:'none', margin:0, padding:0, overflow:'hidden', zIndex:999999}}>
         Your browser doesn't support iframes
        </iframe>
         </div>
        </Item>

        <Item title={customTitle('cloud-upload', 'qrcode')} eventKey={'qrcode'}>
         <div className="w-100" style={{height: 600}}>
         <iframe src={`https://qr.workos.me?board_id=${task.workflow_id}&task_id=${task.id}&email=${email}`} style={{top:0, left:0, bottom:0, right:0, width:'100%', height:'100%', border:'none', margin:0, padding:0, overflow:'hidden', zIndex:999999}}>
         Your browser doesn't support iframes
        </iframe>
         </div>
        </Item>*/}

    </>
  );

  if (isPage) {
    return bodyContainer;
  } else {
    return (
      <Pagesheet show={show} onClose={handleClose} size={"md"}>
        {bodyContainer}
      </Pagesheet>
    );
  }
}