import { Button, Tooltip } from '../../../components/Uix';
import { translate } from '../../../services/Language';
export default function InlineStyleControls({editorState, onToggle}) {

  var INLINE_STYLES = [
    { label: "B", style: "BOLD", icon: 'bold', title: translate('bold') },
    { label: "I", style: "ITALIC", icon: 'italic', title: translate('italic') },
    { label: "U", style: "UNDERLINE", icon: 'underline', title: translate('underline') },
    { label: "S", style: "STRIKETHROUGH", icon: 'strikethrough', title: translate('strikethrough') }
  ];

  let currentStyle = editorState.getCurrentInlineStyle();

  return (<div className="d-flex">
    {INLINE_STYLES.map((type,key) => (
      <Tooltip key={key} placement="top" title={type.title}>
        <Button
          key={type.label}
          active={currentStyle.has(type.style)}
          title={''}
          icon={type.icon}
          onClick={() => onToggle(type.style)}
          size={'sm'}
        />
      </Tooltip>
    ))}
  </div>);

}
