import React, { useEffect, useState } from "react";
import { translate } from '../../services/Language';
import { automation } from '../../store/automation';
import { Form } from '../../components/Uix';

export default function InputNumber({
  dispatch,
  field,
  setOnEvent,
  setOnTask,
  allowEdit,
  isLabel,
  task
}){

  const [nameValue, setNameValue] = useState("");

  useEffect(() => {
    setNameValue(field.value)
  }, [field.value]);

  const handleChange = async(text) => {
     await dispatch({ type: 'updateField', payload: field.id, value: text })
     if(field && text){
       setTimeout(function () {
         var data = Object.assign({}, task);
         data.field = field.id;
         data.value = text;
         automation('columnChanges', data)
       }, 5000);
       //setOnTask(true)
     }
     setNameValue(text)
     setOnEvent(true)
  }

  return (<>
    <Form.Input placeholder={field.title}
       onTextChange={handleChange}
       disabled={!allowEdit} value={nameValue} type="number"
       className={"input-great text-center"}/>
  </>)
};
