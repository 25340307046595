import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { ModeProvider } from "./context/ModeContext";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import OneSignal from 'react-onesignal';
import { ApolloLink, HttpLink, ApolloProvider, ApolloClient, InMemoryCache, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import reducer from './reducers'
import './assets/app.scss';
let clientId = process.env.REACT_APP_PAYPAL_ID
let locale = localStorage.getItem('locale');
let mode = process.env.NODE_ENV;

const httpLink = new HttpLink({
  uri:  mode === 'development' ? process.env.REACT_APP_GRAPHQL_LOCAL : process.env.REACT_APP_GRAPHQL
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

/*
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem('token') || null,
    }
  }));

  return forward(operation);
})
*/
// use with apollo-client
const link = from([errorLink, httpLink])

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'none'
    }
  }
});

const initialOptions = {
    "client-id": clientId,
    currency: "USD",
    intent: "capture",
};

//75d6d750-032e-47e5-99f2-c39da40e2067
OneSignal.init({
  appId: 'd6f2b6c3-9220-49dc-9306-1d0a34ed5c21'
 }).then(() => {

})

OneSignal.getUserId(function(userId) {
  //console.log("OneSignal User ID:", userId);
  localStorage.setItem('device_id', userId);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
   <ApolloProvider client={client}>
    <LayoutProvider>
      <UserProvider>
         <ModeProvider>
            <PayPalScriptProvider options={initialOptions}>
              <App />
            </PayPalScriptProvider>
          </ModeProvider>
      </UserProvider>
    </LayoutProvider>
   </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
