import React, { useState } from 'react';
import Activities from '../Comment/Activities';
import AddActivity from '../Comment/AddActivity';


export default function TaskComment({task, users, setOnTask, onTask, setContent, allowEdit, location, workflow_id}){

  const [onEvent, setOnEvent] = useState(false);
  const [onActivity, setOnActivity] = useState(false);
  const [statusValue, setStatusValue] = useState("")
  const [fileResult, setFileResult] = useState("")
  const [isLoading, setIsLoading] = useState(false);

    return (
      <>
        <AddActivity task_id={task.id} task_name={task.name} workflow_id={workflow_id} user_id={task.user_id} fixed={true} setOnActivity={setOnActivity} setFileResult={setFileResult} setOnEvent={setOnEvent} allowEdit={allowEdit} location={location} teamUsers={users}/>
        <Activities task_id={task.id} task_name={task.name} workflow_id={workflow_id} user_id={task.user_id} fixed={true} onActivity={onActivity} setOnActivity={setOnActivity} allowEdit={allowEdit} setFileResult={setFileResult} setContent={setContent}/>
      </>
   );

}
