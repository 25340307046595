import { motion } from 'framer-motion';
const ItemTab = ({ title, text, eventKey, setActive, active, onToggle, onClick, center }) => {
  let className = `btn p-2 d-block lead border-0 border-2 text-${center? "center" : "start"} border-bottom`;
   if (active === eventKey) {
     className += ` fw-bold active border-warning`;
   }
  return(<motion.li onClick={() => {
    if(onToggle) onToggle(eventKey)
    if(onClick) onClick()
    setActive(eventKey)
  }} className={className}>
    {title}
    {(text && active === eventKey) && <p className="fw-normal text-secondary">{text}</p>}
    </motion.li>
)}

export default ItemTab;
