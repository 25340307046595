import React, { useEffect, useState, useRef } from "react";
import { Menu, Form } from '../../components/Uix';
import { jsonParse, text_truncate, formatPrice } from '../../services/Function';
import { STR_LIMITED } from '../../services/Defines';

export default function ViewMirror({
  dispatch,
  field,
  schema,
  setOnEvent,
  allowEdit
}){

  const [nameValue, setNameValue] = useState("");
  let ref = useRef()

  useEffect(() => {
    let value;
    if(!!field && !!field.value) {
      if(field.value.constructor === Object){
        if(field.value.name){
          value = field.value.name;
        }else if(field.value.link){
          value = field.value.link;
        }
      }else if(field.value.constructor === Array){
        value = field.value[0]?.name;
      }else {
        try {
            let json = JSON.parse(field.value);
            value = json[0] ? json[0].name : field.value.toString();
        } catch (e) {
            value = field.value.toString();
        }
      }
    }
    setNameValue(value)
  }, [field.value]);

  const handleChange = () => {
     dispatch({ type: 'updateField', payload: field.id, value: nameValue })
     setOnEvent(true)
     ref.current?.focus()
  }

  function displayValue(value){
    if(!isNaN(value)){
      value = formatPrice(value)
    }
    return value ? text_truncate(value, STR_LIMITED) : null;
  }

  return (
    <Menu title={displayValue(nameValue)} disabled={!allowEdit} size={"sm"} onToggle={handleChange} btnSize={"sm"} placement="left">
      <Form.Input placeholder={field.title}
         onTextChange={setNameValue}
         disabled={!allowEdit}
         value={nameValue}
         type="text"
         ref={ref}
         className={"input-great text-start"}/>
      </Menu>
  )

};
