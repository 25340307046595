import React, { useEffect, useState } from "react";
import Icon from '../../components/Icon';
import { Menu, Button } from '../../components/Uix';
import { translate } from '../../services/Language';
import ItemLink from './ItemLink';
import ItemStatus from './ItemStatus';
import ItemSelect from './ItemSelect';
import ItemFormula from './ItemFormula';
import ItemProgress from './ItemProgress';

export default function NameHead({
  schema,
  dispatch,
  field,
  setOnEdit,
  allowEdit,
  workflow_id
}){

  const [optionShow, setOptionShow] = useState(false);
  const [nameValue, setNameValue] = useState();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  //Update on each event is changed
  let app_id = localStorage.getItem('app_id');
  let textInput = React.useRef();

  useEffect(() => {
    setNameValue(field.title)
    setItems(field.enum)
  },[field])

  const handleChange = () => {
    if(typeof nameValue !== 'undefined'){
      dispatch({ type: 'editField', payload: field.id, title: nameValue })
      setOnEdit(true)
    }
  }

  //console.log(field.title, field.id)

  return (<div className="position-relative">
    <div className="d-flex align-items-center on-hover">
      {show === true ? <input
        onChange={({ target }) => setNameValue(target.value)}
        onBlur={handleChange}
        onMouseLeave={() => {
          handleChange();
          setShow(false)
        }}
        value={nameValue}
        className={"form-control border-hover input-great text-truncate"}
        ref={textInput}
      /> :
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="py-1 text-truncate" onClick={(e) => allowEdit ? setShow(true) : e.preventDefault()}><Icon icon={field.icon} /> <span className="fw-bold text-secondary">{nameValue}</span></div>
        <Menu icon={'chevron-down'} customWidth={open?360:255} disabled={!allowEdit} btnStyle={{padding: 0}}>
          <div className="w-100 p-3">
          <input
            onChange={({ target }) => setNameValue(target.value)}
            onBlur={handleChange}
            onMouseLeave={() => {
              handleChange();
            }}
            value={nameValue}
            className={"form-control mb-3"}
          />
          {(field.field === "link" || field.field === "select" || field.field === "status" || field.field === "progress" || field.field === "formula") && <Button
            title={open ? translate('close') : translate('customizeField')}
            icon={open ? "close" : field.icon}
            onClick={() => setOpen(!open)}
            className="p-2 border-bottom w-100 text-start"
          />}
          {open &&
            <div className="border rounded-2 p-3">
              {field.field === "link" && <ItemLink dispatch={dispatch} type={'editLink'} field={field} setOnEdit={setOnEdit}/>}
              {field.field === "select" && <ItemSelect items={items} type={'editEnum'} field={field} dispatchField={dispatch} setOnEdit={setOnEdit}/>}
              {field.field === "progress" && <ItemProgress dispatch={dispatch} schema={schema} field={field} type="editConfig" setOnEdit={setOnEdit}/>}
              {field.field === "status" && <ItemStatus items={items} type={'editEnum'} field={field} dispatchField={dispatch} setOnEdit={setOnEdit}/>}
              {field.field === "formula" && <ItemFormula dispatch={dispatch} schema={schema} field={field} type="editConfig" setOnEdit={setOnEdit}/>}
            </div>}

              <Button
                className={"p-2 d-block border-bottom w-100 text-start"}
                title={field.enabled===true?translate('show'):translate('hidden')}
                icon={field.enabled===true?"eye":"eye-off"}
                onClick={() => {
                  dispatch({
                    type: "enabled",
                    payload: field.id
                  })
                  setOnEdit(true)
                }}
              />
              <Button
                className={"p-2 d-block border-bottom w-100 text-start"}
                title={field.required===true?translate('required'):translate('notRequired')}
                icon={"option"}
                onClick={() => {
                  dispatch({
                    type: "required",
                    payload: field.id
                  })
                  setOnEdit(true)
                }}
              />
              <Button
                variant="link"
                className={"p-2 d-block w-100 text-start"}
                title={optionShow ? translate('close') : translate('delete')}
                icon={optionShow ? "chevron-back" : "trash"}
                onClick={() =>
                  setOptionShow(! optionShow)
                }
              />
              <div className="py-3">
              {optionShow && <Button
                className={"p-2 w-100 d-block"}
                variant="danger"
                onClick={() => {
                  dispatch({
                    type: "remove",
                    payload: field.id
                  })
                  setOnEdit(true)
                  setOptionShow(false)
                }}
              >
                {translate('delete')} ?
              </Button>}
            </div>
          </div>
        </Menu>
       </div>
      }
    </div>
   </div>
  )
};
