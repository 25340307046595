import React from "react";
import { Form } from '../../components/Uix';
import { automation } from '../../store/automation';

export default function InputCheck({
  dispatch,
  field,
  setOnEvent,
  allowEdit,
  task,
  ...props
}){

  const handleChange = (value) => {
     dispatch({ type: 'updateField', payload: field.id, value})
     if(field && value){
       var data = Object.assign({}, task);
       data.field = field.id;
       data.value = value;
       automation('columnChanges', data)
     }
     setOnEvent(true)
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
     <Form.Check type="checkbox" checked={field.value} onTextChange={handleChange} disabled={!allowEdit}/>
   </div>
  )
};
