import React from "react";

var ModeStateContext = React.createContext();
var ModeDispatchContext = React.createContext();

function modeReducer(state, action) {
  switch (action.type) {
    case "DARK_MODE":
      return { ...state, isDarkMode: !state.isDarkMode };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ModeProvider({ children }) {
  var [state, dispatch] = React.useReducer(modeReducer, {
    isDarkMode: localStorage.getItem("darkMode") == "darkMode" ? true : false,
  });
  return (
    <ModeStateContext.Provider value={state}>
      <ModeDispatchContext.Provider value={dispatch}>
        {children}
      </ModeDispatchContext.Provider>
    </ModeStateContext.Provider>
  );
}

function useModeState() {
  var context = React.useContext(ModeStateContext);
  if (context === undefined) {
    throw new Error("useModeState must be used within a ModeProvider");
  }
  return context;
}

function useModeDispatch() {
  var context = React.useContext(ModeDispatchContext);
  if (context === undefined) {
    throw new Error("useModeDispatch must be used within a ModeProvider");
  }
  return context;
}

export { ModeProvider, useModeState, useModeDispatch, toggleMode };

// ###########################################################
function toggleMode(dispatch, isDarkMode) {
  localStorage.setItem("darkMode", isDarkMode ? "" : "darkMode");
  dispatch({
    type: "DARK_MODE",
  });
}
