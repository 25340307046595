import React, { useState, useEffect } from "react";
import Icon from '../../components/Icon';
import { Button } from '../../components/Uix';
import { googleDrive } from '../../services/GoogleDrive';
import { translate } from '../../services/Language';

let localUrl = process.env.REACT_APP_SERVER_LOCAL
let proURL = process.env.REACT_APP_SERVER_URL
let mode = process.env.NODE_ENV;
let serverUrl = mode==='development' ? localUrl : proURL;

export default function Drive({
  setFile,
  location,
  ...props
}) {

  var [show, setShow] = useState(false);
  var [files, setFiles] = useState([]);

  const handleFile = async(file) => {
    if(file){
      setFile([file])
      setShow(false)
    }
  };

  window.popupCallback = function(obj) {
    if(obj) {
      initAsync(obj)
    }
  };

  const initAsync = async(code) => {
    let response = await googleDrive(code);
    setFiles(response.data.files)
    if(response && response.data && response.data.files){
      setShow(true)
    }
  }

  const handleClick = () => {
     window.open(`${serverUrl}/drive`, "Google Drive", "width=600,height=600");
  }

   return (
    <>
    <Button className="ms-0 d-block text-primary nav-link" variant={"link"} onClick={handleClick}>
       <Icon icon="drive" /> {translate('googleDrive')}
    </Button>
    {show &&
      <div className="overflow" style={{height: 400}}>
        {files.map((file, key) => <div key={key}>
         <div className={"border-top p-3"}>
            <div className="row">
              <div className="col-md-12 text-start">
                  <Button variant="link" className="nav-link text-dark text-start" onClick={() => handleFile(file)}> <Icon icon="document-attach" color="red"/> {file.name}</Button>
              </div>
            </div>
          </div>
         </div>
        )}
      </div>}
    </>
  );
}
