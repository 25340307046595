import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Form } from '../../components/Uix';
import Icon from '../../components/Icon';
import Upgrade from '../../components/Upgrade';
import { translate } from '../../services/Language';
import { CreateWorkflow } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";

import { WORKFLOW_LIMITED } from '../../services/Defines';


export default function AddForm({isOpen, setIsOpen, history, setOnEffect, isDarkMode, live, workspace_id, ...props}){

  let app_id = localStorage.getItem('app_id');
  let user_id = localStorage.getItem('user_id');
  let textForm = React.useRef();
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [typeValue, setTypeValue] = useState("items");
  const [privacyValue, setPrivacyValue] = useState("public");
  const [createWorkflow, { data }] = useMutation(CreateWorkflow);

  const handleClose = () => {
    setShow(false);
    setIsOpen(false)
    setNameValue("")
  }
  const handleShow = () => setShow(true);

  const handleClick = async() => {
    if(nameValue){
      createWorkflow( {variables: {
        name: nameValue,
        privacy: privacyValue,
        type: 'form',
        base: 'form',
        user_id,
        app_id,
        workspace_id,
        view_name: translate('forms'),
        stage_name: translate('newList'),
      }})
    }else {
      return;
    }
  };

  useMemo(() => {
    let isMounted = true;
    if(data && isMounted){
      if(data.createWorkflow && data.createWorkflow.id){
        setShow(false)
        setOnEffect(true)
        setIsOpen(false)
        history.push(`/w/form/${data.createWorkflow.id}`)
      }
    }
    return () => { isMounted = false };
  },[data])

  useEffect(() => {
      if(isOpen){
        setShow(true)
        setTimeout(() => {
          textForm.current?.focus()
        }, 600);
      }
    },[isOpen]);

  return (
    <>
      <Modal show={show} onClose={handleClose} title={translate('createForm')}>
        <div className={isDarkMode ? "dark-mode mb-3" : "mb-3"}>
          <form onSubmit={e => { e.preventDefault()}}>

            <Form.Input label={translate('name')} onTextChange={setNameValue} value={nameValue} onKeyPress={handleClick} useRef={textForm} className="mb-3"/>

            <Form.Radio checked={privacyValue === 'private' ? true : false} label={translate('private')} description={translate('privateMore')} onTextChange={setPrivacyValue} value={'private'} name="Privacy"/>

            <Form.Radio checked={privacyValue === 'public' ? true : false} label={translate('public')} description={translate('publicMore')} onTextChange={setPrivacyValue} value={'public'} name="Privacy"/>

          </form>
        </div>
        <div className={"position-relative"}>
        {limit >= WORKFLOW_LIMITED ? <Upgrade btn={true} text={translate('createForm')}/> :
          <Button variant="primary" onClick={handleClick}>
            {translate('createForm')}
          </Button>}
        </div>
      </Modal>
    </>
  );
}
