import React, { useEffect, useState, useMemo } from "react";
import Icon from '../../components/Icon';
import { Button, Menu } from '../../components/Uix';
import { translate } from '../../services/Language';
import TodoList from '../../components/Todo/TodoList';
import { CreateTodo } from '../../services/GraphqlApi';
import { useMutation } from "@apollo/client";

export default function InputTodo({
  task,
  workflow_id,
  dispatch,
  field,
  setOnEvent,
  setOnEdit,
  allowEdit,
  full,
}){

  const [nameValue, setNameValue] = useState("");
  const [todoId, setTodoID] = useState();
  const [todo, setTodo] = useState("");

  useEffect(() => {
    if(field && field.value){
      setTodoID(field.value)
    }
  },[])

  const [createTodo, { data, loading, error }] = useMutation(CreateTodo);
  const todoCreate = () => {
      createTodo( {variables: {name: field.title, task_id: task.id, workflow_id}})
  }

  useMemo(() => {
    if(data && data.createTodo){
      setTodoID(data.createTodo.id)
      dispatch({ type: 'updateField', payload: field.id, value: data.createTodo.id })
      setOnEvent(true)
    }
  },[data])

  useMemo(() => {
    if(task && task.todos){
      task.todos.map(todolist => {
        if(field.value && todolist.id === field.value){
          let todos = todolist.json ? JSON.parse(todolist.json) : [];
          let result = Object.values(todos).filter(todo => todo.done === true);
          if(todos.length > 0 && result.length > 0){
              setTodo(`${result.length} / ${todos.length}`)
          }
        }
      })
    }
  },[task])

  if(full){
    return (
      <div>
          {allowEdit &&
            <div className="text-start position-relative">
            {todoId ? <TodoList todo_id={todoId} workflow_id={workflow_id} checkname={field.title} task_id={task.id} setOnActivity={setOnEvent} allowEdit={allowEdit}/> :
            <Button className="btn-info text-white" variant="link" onClick={todoCreate} title={translate("start")}/>
            }
            </div>
          }
      </div>
    )
  }else {
    return (
      <Menu disabled={!allowEdit} icon="check" showIcon title={todo} btnSize="sm" btnStyle={{width: '100%'}}>
          {allowEdit &&
            <div className="text-start position-relative">
            <div className="fw-bold">{field.title}</div>
            {todoId ? <TodoList todo_id={todoId} workflow_id={workflow_id} checkname={field.title} task_id={task.id} setOnActivity={setOnEvent} allowEdit={allowEdit}/> :
            <Button className="btn-info text-white" variant="link" onClick={todoCreate} title={translate("start")}/>
            }
            </div>
          }
      </Menu>
    )
  }
};
