import React, { useEffect, useState } from "react";
import { formatPrice, text_truncate } from '../../services/Function';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { Menu, Button } from '../../components/Uix';
import ItemFormula from './ItemFormula';
import { Parser as FormulaParser } from 'hot-formula-parser';
import { STR_LIMITED } from '../../services/Defines';

export default function InputFormula({
  dispatch,
  field,
  setOnEvent,
  setOnEdit,
  task,
  schema,
  onTask
}){

    const parser = new FormulaParser();
    const [nameValue, setNameValue] = useState("");
    const collection = task.collection ? JSON.parse(task.collection) : [];

    function isDateFormat (date){
      if(date && date.length === 16){
        let dt = date.split('-');
        //make sure if day is numeric
        if(dt && dt[1] && !isNaN(dt[1])){
          return true;
        }
      }
      return false;
    }

    function changeStr(_, p1) {
      if(!collection) return;
      if(!task) return;
      if(p1 === 'created_at'){
        return task.created_at ? `"${moment(Number(task.created_at)).format('YYYY/MM/DD')}"` : p1;
      }else if(p1 === 'due_date'){
        return task.due_date ? `"${moment(Number(task.due_date)).format('YYYY/MM/DD')}"` : p1;
      }else if(p1 === 'start'){
        return task.start ? `"${moment(Number(task.start)).format('YYYY/MM/DD')}"` : p1;
      }else if(p1 === 'user_name'){
        return task.user ? `"${task.user.name}"` : p1;
      }else {
        if(isDateFormat(collection[p1])){
          return `"${moment(collection[p1]).format('YYYY/MM/DD HH:MM')}"`;
        }else {
          return collection[p1] ? Number(collection[p1]) : Number(p1);
        }

      }

    }

    useEffect(() => {
        let config = field.config
        if(config){
          if(config.constructor === Array || config.constructor === Object){
            config = ""
          }
          let formula = config.replace(/\{(.*?)\}/g, changeStr);
          let value;
          let data = parser.parse(formula);
          if(!data.error){
            value = data.result;
            dispatch({ type: 'updateField', payload: field.id, value })
          }
          setOnEvent(true)
          if(!isNaN(value)){
            value = formatPrice(value)
          }
          if(value) setNameValue(value)
        }
    }, [onTask]);

  return (<>
    <div className="d-flex justify-content-start align-items-center">
      <div className="">
        <Menu icon="create" size="md" btnSize={"sm"} btnCls="btn-small p-0">
          <ItemFormula dispatch={dispatch} schema={schema} field={field} type="editConfig" setOnEdit={setOnEdit}/>
        </Menu>
      </div>
      <Button title={nameValue && text_truncate(nameValue, STR_LIMITED)} className="p-0" size="sm" onClick={() => copy(nameValue)} />
    </div>
  </>)
};
