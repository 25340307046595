import React, { useState, useMemo, useEffect} from "react";
import Icon from '../../components/Icon';
import { Button } from '../../components/Uix';
import { translate } from '../../services/Language';
import { ThreadsQuery } from '../../services/GraphqlApi';
import { useQuery } from "@apollo/client";
import Comment from './Comment';
import Files from '../Media/Files';
import AddComment from './AddComment';

export default function Thread({
  workflow_id,
  task_id,
  task_name,
  setOnEvent,
  setFileResult,
  allowEdit,
  thread_id,
  is_thread,
  ...props
}) {

  const [comments, setComments] = useState([]);
  const [onActivity, setOnActivity] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [thread, setThread] = useState(false);
  let userId = localStorage.getItem('user_id');

  const { loading, refetch, data } = useQuery(ThreadsQuery, {
    variables: { thread_id },
  });

  useMemo(() => {
    if(data && data.threads){
      setComments(data.threads)
    }
  },[data])

  useEffect(() => {
      refetch()
      setOnDelete(false)
      setOnActivity(false)
    },
    [onDelete, onActivity],
  );

  const renderAttach = (attach) => {
    let data = JSON.parse(attach)
    if(data){
      return(
        <>
          {Array.isArray(data) && data.map((row, key) => <Files file={row} key={key} setOnDelete={null}/>)}
        </>
      )
    }
  }

  return (
    <>
     {comments.length > 0 &&
      <div className="overflow-y overflow-x-hide scroll-style" style={{maxHeight: 300}}>
        <ul className="nav flex-column">
          {comments.map((comment, key) => (
            <li className="nav-item w-auto rounded-3 my-2 p-2 w-100 bg-light">
              <Comment comment={comment} key={key} task_id={task_id} task_name={task_name} workflow_id={workflow_id} fixed={true} setOnActivity={setOnActivity} setFileResult={setFileResult} setOnEvent={setOnEvent} allowEdit={allowEdit} setOnDelete={setOnDelete}/>
            </li>
          ))}
        </ul>
      </div>}

      {is_thread === '0' &&
        <Button variant="primary" size="sm" className="mt-2" onClick={() => setThread(!thread)}>
            <Icon icon={thread ? "trash" : "reply"} className={"text-white me1"}/>
            <span>{translate('reply')}</span>
       </Button> }

       <div className="my-2">
          <AddComment thread_id={thread_id} task_id={task_id} task_name={task_name} workflow_id={workflow_id} user_id={userId} setOnActivity={setOnActivity} setOnEvent={setOnEvent} allowEdit={allowEdit} setFileResult={setFileResult}/>
       </div>

    </>
  );
}
